import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ExerciseClass,
  ExerciseTimelineItemType,
  ExerciseUserRole,
  HourItemI,
  IExerciseTimelineInjectRef,
  IExerciseTimelineItem,
} from "src/app/model/exercise.model";
import { ScreenSize } from "src/app/model/screenSize.model";
import { UiService } from "src/app/services/ui.service";
import gsap from "gsap";
import { ApiService } from "src/app/services/api.service";
import {
  differenceInDays,
  differenceInHours,
  addHours,
  differenceInSeconds,
  differenceInMinutes,
  isBefore,
  isAfter,
  isSameDay,
} from "date-fns";
import { TIME } from "src/app/services/helpers";
// import { CdkDrag } from '@angular/cdk/drag-drop';
import { Subscription } from "rxjs";
import { StorageService } from "../../services/storage.service";
import { ExerciseInjectToTimelineDto } from "src/app/model/dtos";
import * as _ from "lodash";
import { getExerciseDays, wait } from "src/app/services/utils/functions";
import { UTCDate } from "@date-fns/utc";
interface miniOvlItem {
  width: number;
  left: number;
  day: string;
}
@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
})
export class TimelineComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() exercise!: ExerciseClass;
  @ViewChild("timelineWrapper") timelineWrapper!: ElementRef;
  @ViewChild("timelineInnerWrapper") timelineInnerWrapper!: ElementRef;
  @ViewChild("_time_line_mini_map") _time_line_mini_map!: ElementRef;
  @ViewChild("timelineInjectsHolder") timelineInjectsHolder!: ElementRef;
  @ViewChild("hour_box_holder") hour_box_holder!: ElementRef;
  @ViewChild("_filterBar") _filterBar!: ElementRef;
  @ViewChild("_dragger") _dragger!: ElementRef;
  @ViewChild("_draggerHit") _draggerHit!: ElementRef;
  @ViewChild("_currentTarget") _currentTarget!: ElementRef;
  @ViewChild("_currentTargetMini") _currentTargetMini!: ElementRef;
  miniOvlItems: miniOvlItem[] = [];
  currentStageSize!: ScreenSize;
  _timelineHeight = 0;
  _timelineWidth = 0;
  _lowerTimelineHeight = 0;
  _lowerTimelineWidth = 0;
  animatedInjectId = 0;
  mainWidthScaleFact = 0;
  animationSub: Subscription | undefined;
  dragStartetWidth = 0;
  lastPosX = 0;
  isLoading = false;
  showUItems = false;
  checked = false;
  hourItems: HourItemI[] = [];
  // show = false
  currentTimeInterval: any;
  constructor(
    public ui: UiService,
    public api: ApiService,
    public storage: StorageService // private cd: ChangeDetectorRef
  ) {
    // console.log(this.cd);
  }
  ExerciseTimelineItemTypes = ExerciseTimelineItemType;
  ngAfterViewInit(): void {
    wait(1000, () => {
      this.checked = true;
    });
  }
  get timelineItems(): IExerciseTimelineItem[] {
    if (!this.storage.currentExer) {
      return [];
    } else {
      return this.storage.currentExer.timelineItems;
    }
  }
  getHeight(timelineItem: IExerciseTimelineItem) {
    if (timelineItem.height && this.checked) {
      const h = timelineItem.height;
      const h3 = 60 * h;
      //return (60 + (60 * h)).toString() + 'px'
      return `${h3 + 60}px`;
    } else {
      return "60px";
    }

    // if(this.checked) {

    //   return (60 + (60 * timelineItem.height)).toString() + 'px'
    // } else {
    //   return '60px'

    // }
  }
  intervalHours = 0;
  startCurrentTimeInterval() {
    if (this.currentTimeInterval != null) {
      clearInterval(this.currentTimeInterval);
    }
    this.currentTimeInterval = setInterval(() => {
      let tt = TIME.tt_currentUTC();
      if (this.ui.isSimulation) {
        tt = TIME.tt_currentUTC(this.intervalHours);
        // local = TIME.date_lcoal_to_tt(addHours(new Date(), this.intervalHours));
      }

      this._checkExerciseState(TIME.tt_toDate(tt));
      this.intervalHours++;
    }, 2000);
  }
  private _checkExerciseState(widthDateTime: Date) {
    if (!this.storage.currentExer) {
      return;
    }
    const startUTC = this.storage.currentExer.getDateTimeUTC();
    const endUTC = this.storage.currentExer.endDateTimeDate;
    const currentUTC = widthDateTime.toUTCString();

    const currentDateIsBeforeExerciseStart = isBefore(widthDateTime, startUTC);
    const currentDateIsAfterExerciseStart = isAfter(widthDateTime, startUTC);
    const currentDateIsAfterExerciseEnd = isAfter(widthDateTime, endUTC);
    // console.warn(`start UTC ${startUTC.toUTCString()}`);
    // console.warn(`end UTC ${endUTC.toUTCString()}`);
    // console.warn(`currentTUC ${currentUTC}`);
    // console.warn(`minBetween: ${minBetween}`);

    let message = "";

    if (currentDateIsBeforeExerciseStart) {
      message = "EX STARTS SOON";
      ///console.warn(message);
    } else {
      if (currentDateIsAfterExerciseStart && currentDateIsAfterExerciseEnd) {
        message = "EX IS DONE";
        // console.warn(message);
      } else {
        message = "EX IS RUNNING";
        // console.warn(message);
        const minBetween = differenceInMinutes(widthDateTime, startUTC);
        // console.warn(`minBetween: ${minBetween}`);
        this.setCurrentTarget(minBetween);
      }
    }
    const el = document.getElementById("injectsWrapper");
    // console.log(`el: ${el?.clientHeight}`);
    if (el) {
      const newTimelineHeight = el.clientHeight + (168 + 90);
      gsap.to(
        this.timelineWrapper.nativeElement,

        {
          height: newTimelineHeight,
          ease: "Expo.easeOut",
          duration: 0.25,
          onComplete: () => {},
        }
      );
    }

    //
    // console.warn("---------");
    // console.warn(
    //   `current Date Is BEFORE Exercise Start: ${currentDateIsBeforeExerciseStart}`
    // );
    // console.warn(
    //   `current Date Is AFTER Exercise Start: ${currentDateIsAfterExerciseStart}`
    // );
    // console.warn(
    //   `current Date Is AFTER Exercise END: ${currentDateIsAfterExerciseEnd}`
    // );
    // console.warn("");
    // console.warn(
    //   `currentDateIsAfterExerciseStart: ${currentDateIsAfterExerciseStart}\n`
    // );
    // console.warn("---------");
    // console.warn(`_checkExerciseState : ${this.storage.currentExer.status}`);
    // console.warn("");
    // console.warn("");
    //   if(this.exercise == null || this.exercise.startDateTime == null || this.exercise.endDateTime == null){
    //     return
    //   }
    //   let currentIsAfterE_start:boolean = isAfter(widthDateTime, TIME.tt_toDate(this.exercise.startDateTime))
    //   let currentIsBeforeE_end:boolean = isBefore(widthDateTime, TIME.tt_toDate(this.exercise.endDateTime))
    //   let secondsUntilStart = differenceInSeconds(widthDateTime, TIME.tt_toDate(this.exercise.startDateTime))
    //   if(currentIsAfterE_start === false){
    //     this.ui.setCurrentExerciseState(ExerciseState.SCHEDULED);
    //   } else if(currentIsAfterE_start === true &&  currentIsBeforeE_end == false){
    //     this.ui.setCurrentExerciseState(ExerciseState.DONE);
    //   } else if(currentIsAfterE_start === true &&  currentIsBeforeE_end == true){
    //     this.ui.setCurrentExerciseState(ExerciseState.RUNNING);
    //   } else {
    //     this.ui.setCurrentExerciseState(ExerciseState.NONE);
    //   }
  }
  toggleTimeSize() {
    this.ui.smallTimeLineItems = !this.ui.smallTimeLineItems;
    this.setTimelineScaleFacts();
  }
  setLabelPos(onTop: boolean) {
    this.ui.timeLineLabelOnTop = onTop;
  }
  private scroll = (event: any) => {
    if (!this.isDragging) {
      const __x = event.target.scrollLeft; //this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
      // const __x2 = e.source.element.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
      // console.log(`__x: ${__x} `);
      const _miniFact = this.ui.smallTimeLineItems ? 120 : 120;
      // console.log(`---->`);
      let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;

      // console.log(`_miniMapWidth ${_miniMapWidth} `);
      // console.log(`this.marginRange ${this.marginRange} `);
      const _marginRange = _.toInteger(Math.abs(this.marginRange)); //
      // console.log(`_marginRange ${_marginRange} `);
      // const progressAspect = __x / this.miniMapWidth
      // console.log(`progressAspect ${progressAspect} `);
      const progressAspect2 = (this.miniMapWidth - _miniFact) / _marginRange;
      // console.log(`progressAspect2 ${progressAspect2} `);
      // const progressAspect3 = (progressAspect2 * __x)
      // console.log(`progressAspect3 ${progressAspect3 } `);
      // console.log(this._dragger.nativeElement.style.transform);

      const _newPos = progressAspect2 * __x - 2; //(_marginRange * progressAspect) - 2//(this.ui.smallTimeLineItems ? 2 : 2)
      // console.log(`_newPos ${_newPos} `);
      const _finPos = _.toInteger(Math.abs(_newPos));
      // console.log(`_newPos ${_finPos} `);
      // console.log(event.target.scrollLeft);
      // console.log(this.timelineInnerWrapper.nativeElement.scrollLeft);
      // console.log(this.dragPosition);
      this._dragger.nativeElement.style = `transform: translate3d(${_finPos}px,0px,0px)`;
      // console.log(this.dragPosition);
      // const __x = this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
      this.dragPosition = { x: _finPos, y: 0 };
    }
  };
  ngOnInit(): void {
    this.showUItems = true;
    wait(100, () => {
      this.updateScreenAspects(this.ui.currentSize);
    });
    setTimeout(() => {
      const scroller = document.getElementById("timelineInnerWrapper");
      if (scroller) {
        scroller.addEventListener("scroll", this.scroll, false);
      }
      // console.log(this.exercise);
      this.animationSub = this.ui.injectAnimation.subscribe(
        (inject: IExerciseTimelineInjectRef) => {
          this.dragEndedSun(Number(inject.deliveryDateTime));
        }
      );
      this.currentStageSize = this.ui.currentScreenSize;
      this.hourItems = [];
      let exercistStartDate = this.exercise.startDateTime;
      let startHour = TIME.tt_toDate(exercistStartDate).getUTCHours();
      // console.log(`exercistStartDate : ${exercistStartDate.day}`);
      // console.log(`startHour : ${startHour}`);

      // let startHour = 1
      let hourCount = 24;
      let exercistEndDate = this.exercise.endDateTime;

      const hoursCount = differenceInHours(
        TIME.tt_toDate(exercistEndDate),
        TIME.tt_toDate(exercistStartDate)
      );
      // console.log(`start h ${startHour}, count h ${hoursCount}`);

      // startHour = this.startDateTime.getUTCHours();
      // hourCount = 24 - startHour;
      // console.log(hoursCount / 24);
      // console.log(hoursCount % 24);
      // var _lastAddedDate =
      // hourCount = hoursCount
      // var _hourIndex = startHour;

      // let starMinutes = "00"

      this.hourItems = getExerciseDays(Number(this.exercise.dateTimeStarted), this.exercise.durationInHours)
      // this.hourItems = this.getExerciseDays();
         console.log(this.hourItems);
      const dayCount = this.hourItems.filter(
        (item) => item.startNewDate == true
      );
      //differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
      // console.log(`dayCount: ${dayCount.length}`);
      // console.log(dayCount);

      this.setTimelineScaleFacts();

      // this.ui.miniTimelineUpdate.subscribe(
      //   _newS => {
      //     this.updateDragger(_newS)
      //   }
      // );
      this.ui.windowResize.subscribe((_newS) => {
        if (this.currentStageSize != _newS) {
          this.updateScreenAspects(_newS);
        }
      });

      // let tt = TIME.tt_currentUTC();

      // this._checkExerciseState(TIME.tt_toDate(tt));
    }, 500);

    wait(1500, () => {
      this.startCurrentTimeInterval();
    });
  }

  private updateScreenAspects(newSize: ScreenSize) {
    // let newTimelineHeight = newSize.height

    let newTimelineHeight = newSize.height;
    // let newTimelineHeight = newSize.height - 102;
    const sidebarWith = 402; //380 //412

    let newTimelineWidth =
      newSize.width - (this.ui.isMainToolbarOut ? 10 : sidebarWith) - 2;
    // let newTimelineWidth =  (newSize.width - (this.ui.isMainToolbarOut ? 44 : 412)) - 2
    // let currentTimelineHeight = this._timelineHeight
    // let currentTimelineWidht = this._timelineWidth

    gsap.to(
      this.timelineWrapper.nativeElement,
      // { 'height':currentTimelineHeight, "width":currentTimelineWidht},
      //'
      {
        width: newTimelineWidth,
        height: newTimelineHeight,
        ease: "Expo.easeOut",
        duration: 0.25,
        onComplete: () => {
          // console.log(`newTimelineHeight: ${newTimelineHeight}`);
          // this._timelineHeight = newTimelineHeight
          // this._timelineWidth = newTimelineWidth
        },
      }
    );

    // let newLowerHeight = newTimelineHeight - 174//-39;
    // let newLowerWidth = newTimelineWidth;
    // let currentLowerHeight = this._lowerTimelineHeight
    // let currentLowerWidth = this._lowerTimelineWidth
    // TweenMax.fromTo(this.lowerTimelineWrapper.nativeElement, 0.25,
    //
    gsap.to(this.timelineInnerWrapper.nativeElement, {
      width: newTimelineWidth - this.sidebarWidth,
      // "marginTop": 60,
      duration: 0.25,
      // 'height':newTimelineHeight - 330,
      ease: "Expo.easeOut",
      onComplete: () => {
        // this._timelineHeight = newTimelineHeight
        // this._timelineWidth = newTimelineWidth
      },
    });
    //   { 'height':currentLowerHeight, "width":currentLowerWidth},
    //   { 'height':newLowerHeight, "width":newLowerWidth,
    //   ease: Expo.easeOut,onComplete:()=>{
    //   this._lowerTimelineHeight = newLowerHeight
    //   this._lowerTimelineWidth = newLowerWidth
    // }});

    // const el = document.getElementById('injectsWrapper')
    //   // console.log(`el: ${el?.clientHeight}`);
    //   if(el) {
    //     const newTimelineHeight = el.clientHeight + (108 + 60)
    //     gsap.to(this.timelineWrapper.nativeElement,
    //       // { 'height':currentTimelineHeight, "width":currentTimelineWidht},
    //       //'
    //       {
    //        'height':newTimelineHeight ,
    //       ease: 'Expo.easeOut',duration: 0.25, onComplete:()=>{
    //         // console.log(`newTimelineHeight: ${newTimelineHeight}`);
    //       // this._timelineHeight = newTimelineHeight
    //       // this._timelineWidth = newTimelineWidth
    //     }}
    //   );
    //   }
    this.setTimelineScaleFacts();
  }
  async borderBoxTapped(
    hourIndex: number,
    topIndex: number,
    item: IExerciseTimelineItem
  ) {
    console.log(item);
    //return
    if (
      this.storage.getAccess([
        ExerciseUserRole.TRAINER,
        ExerciseUserRole.OBSERVER,
      ]) ||
      this.storage.isExerciseDeboarded
    ) {
      return;
    }

    const dto: ExerciseInjectToTimelineDto = {
      exerciseId: this.exercise.id,

      injectId: -1,

      timelineItemId: item.id,

      deliveryDateTime: hourIndex * 60,
      // deliveryDateTime: (hourIndex * 60) + 30,

      deliveryDurationInMinutes: 120,
    };
    this.ui.openInjectSelectOverlay(dto);
  }
  ngOnDestroy(): void {
    if (this.animationSub) {
      this.animationSub.unsubscribe();
      this.animationSub = undefined;
    }
  }

  /****************************************************************************/
  getTextColorForInject(inject: IExerciseTimelineInjectRef): string {
    return "#1E2A33";
    // if(inject.state.keyName === 'REQUESTED'){
    //   return "#1E2A33";
    // }
    // else {
    //   return  "#ffffff"
    // }
  }

  get sidebarWidth(): number {
    return 220; //380//220
  }
  get iconBtnText(): string {
    return this.ui.smallTimeLineItems ? "LARGE TIME ICON" : "SMALL TIME ICON";
  }

  getInjects(timelineItemId: number): IExerciseTimelineInjectRef[] {
    var items = this.storage.assigendExerciseInjects
      .filter((item) => item.exerciseTimelineId == timelineItemId)
      .sort((a, b) => a.deliveryDateTime - b.deliveryDateTime);

    for (var i = 0; i < items.length; i++) {
      let current = items[i];

      var items2 = items.filter(
        (item) =>
          Number(item.deliveryDateTime) >= Number(current.deliveryDateTime) &&
          Number(item.deliveryDateTime) <=
            Number(current.deliveryDateTime) +
              Number(current.deliveryDurationInMinutes)
      ); //.sort((a,b) => a.deliveryDateTime - b.deliveryDateTime)
      //items[i].topPos = 0//items2.length
      let topPos = 0;
      for (var j = 0; j < items2.length; j++) {
        if (current.id != items2[j].id && !current.topPos) {
          topPos++;
          items2[j].topPos = topPos;
        }
      }

      let roleItemIndex = this.exercise.timelineItems.findIndex(
        (item) => item.id == timelineItemId
      );
      if (roleItemIndex != -1) {
        let currentHeight = this.exercise.timelineItems[roleItemIndex].height;
        this.exercise.timelineItems[roleItemIndex].height =
          topPos > currentHeight ? topPos : currentHeight;
      }
    }

    return items; //this.api.injects.filter(item => item.timelineItemId ==  timelineItemId)
  }

  miniMapWidth: number = 0;
  progress: number = 0;
  pxPersecond: number = 0;
  marginRange = 0;
  pxPersecondMiniMap: number = 0;
  differenceStartEndInSeconds: number = 0;
  miniMapHourWidth: number = 0;

  private setTimelineScaleFacts() {
    this.differenceStartEndInSeconds = differenceInSeconds(
      TIME.tt_toDate(this.exercise.endDateTime),
      TIME.tt_toDate(this.exercise.startDateTime)
    );

    setTimeout(() => {
      //let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;

      let __timelineWidth;

      this.miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;

      __timelineWidth = this.timelineInjectsHolder.nativeElement.clientWidth;

      const doc = document.getElementById("HOUR_BOX_HOLDER");

      if (doc) {
        __timelineWidth = doc.clientWidth;
      }

      this.marginRange = -(__timelineWidth - this.miniMapWidth);

      this.mainWidthScaleFact = this.miniMapWidth / __timelineWidth;

      const dayCount = this.hourItems.filter(
        (item) => item.startNewDate == true
      );
      //differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
      // console.log(`dayCount: ${dayCount}`);
      // console.log(dayCount);
      // const _f1 = Math.round(this.miniMapWidth / dayCount.length)
      const hhh = _.map(dayCount, "hoursCount");
      var sumHours = 0;
      hhh.forEach((e) => {
        sumHours += e;
      });

      // console.log(`sumHours: ${sumHours}`);
      const _f1 = Math.round(this.miniMapWidth / sumHours);
      // const _f1 = Math.round(this.miniMapWidth / this.hourItems.length)
      this.miniOvlItems = [];
      var full = 0;
      var _lastWidth = 0;
      // console.log(`miniMapWidth: ${this.miniMapWidth} _f1: ${_f1}`);
      this.miniMapHourWidth = _f1;
      for (var i = 0; i < dayCount.length; i++) {
        // console.log(dayCount[i]);
        const _f2 = Math.round(_f1 * dayCount[i].hoursCount);
        // console.log(`_F2 ${_f2}`);
        full += _f2;
        this.miniOvlItems.push({
          width: _f2,
          day: dayCount[i].dateString,
          left: _lastWidth,
        });
        _lastWidth += _f2;
      }
      // console.log(this.miniOvlItems);
      // console.log(`full: ${full}`);
    }, 500);
  }

  // private getExerciseDays() {
  //   let _exerDateStart = new UTCDate(Number(this.exercise.dateTimeStarted));
  //   let _exerDateEnd = new UTCDate(this.exercise.endDateTimeDate);

  //   // console.log(`_exerDateStart  ${_exerDateStart}`);
  //   // console.log(`_exerDateEnd  ${_exerDateEnd}`);
  //   // console.log(`_exerDateStart  ${_exerDateStart.getTimezoneOffset()}`);
  //   // console.log(`_exerDateEnd  ${_exerDateEnd.getTimezoneOffset()}`);

  //   const days = differenceInDays(_exerDateEnd, _exerDateStart);
  //   const hoursCount = differenceInHours(_exerDateEnd, _exerDateStart);
  //   let startHour = _exerDateStart.getUTCHours();
  //   const hours1 = differenceInHours(
  //     TIME.endOf(_exerDateStart),
  //     _exerDateStart
  //   );
  //   // console.log(`start h ${startHour}, count h ${hoursCount} days: ${days} hours1: ${hours1}`);
  //   // console.log(`_exerDateStart ${_exerDateStart}`);
  //   // console.log(`endOfDay ${TIME.endOf(_exerDateStart)}`);
  //   // console.log(`endOfDay ${endOfDay(_exerDateStart)}`);
  //   // console.log(`endOfDay ${subMinutes(endOfDay(_exerDateStart), endOfDay(_exerDateStart).getTimezoneOffset())}`);

  //   var _currentStartHour = startHour;
  //   var _date: Date = _exerDateStart;
  //   var _lastAddedDate: Date = _date;
  //   var _exerciseDay = [];
  //   var _dayIndex = -1;
  //   var _hourCountPerDay = 0;
  //   for (var i = 0; i < hoursCount; i++) {
  //     let starMinutes = "00";

  //     let _dateSting = "";
  //     let _startNewDate = false;
  //     if (_currentStartHour == 0 || i == 0) {
  //       _dateSting = TIME.formatedDateStringWithoutYear(_date, "/");
  //       _lastAddedDate = _date;
  //       _startNewDate = true;
  //       _dayIndex++;
  //     } else {
  //     }

  //     _hourCountPerDay =
  //       // ist erster tag
  //       isSameDay(_date, _exerDateStart)
  //         ? differenceInHours(TIME.endOf(_exerDateStart), _exerDateStart) + 1
  //         : // ist letzter tag
  //         isSameDay(_date, _exerDateEnd)
  //         ? differenceInHours(_exerDateEnd, TIME.startOf(new UTCDate(_date)))
  //         : differenceInHours(
  //             TIME.endOf(new UTCDate(_date)),
  //             TIME.startOf(new UTCDate(_date))
  //           ) + 1;
  //     // _hourCountPerDay = (_dayIndex == days  ) ?
  //     // differenceInHours(TIME.tt_toDate(exercistEndDate), new Date(_date.toUTCString())) :
  //     // differenceInHours(endOfDay(new Date(_date.toUTCString())), new Date(_date.toUTCString()));
  //     // _hourCountPerDay = differenceInHours(endOfDay(new Date(_date.toUTCString()).setUTCHours(_currentStartHour)), new Date(_date.toUTCString()).setUTCHours(_currentStartHour));
  //     // console.log(`_hourCountPerDay: ${_hourCountPerDay} date: ${_date} i: ${i}`);
  //     let _hourItem: HourItemI = {
  //       index: i,
  //       dayIndex: _dayIndex,
  //       hoursCount: _hourCountPerDay,
  //       startNewDate: _startNewDate,
  //       dateString: _dateSting,
  //       hourString:
  //         (_currentStartHour < 10
  //           ? "0" + _currentStartHour.toString()
  //           : _currentStartHour.toString()) +
  //         ":" +
  //         starMinutes.toString(),
  //     };

  //     if (_currentStartHour < 23) {
  //       _currentStartHour++;
  //       // _hourCountPerDay ++;
  //     } else {
  //       _currentStartHour = 0;
  //       // _hourCountPerDay = 0;
  //     }

  //     _date = new UTCDate(addHours(_date, 1));

  //     _exerciseDay.push(_hourItem);
  //   }

  //   // this.hourItems = _exerciseDay
  //   // console.log(this.hourItems);
  //   return _exerciseDay;

  //   // old
  //   // let exercistStartDate = this.exercise.startDateTime
  //   // let exercistEndDate = this.exercise.endDateTime

  //   // const days = differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
  //   // const hoursCount = differenceInHours(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
  //   // let startHour = TIME.tt_toDate(exercistStartDate).getUTCHours()
  //   // console.log(`start h ${startHour}, count h ${hoursCount} days: ${days}`);

  //   // var _currentStartHour = startHour
  //   // var _date: Date =  TIME.tt_toDate(exercistStartDate);
  //   // var _lastAddedDate: Date = _date;
  //   // var _exerciseDay = []
  //   // var _dayIndex = -1
  //   // var _hourCountPerDay = 0
  //   // for(var i = 0; i < hoursCount; i ++) {

  //   //   let starMinutes = "00"

  //   //   let _dateSting = ''
  //   //   let _startNewDate = false
  //   //   if(_currentStartHour == 0 || i == 0 ) {
  //   //     _dateSting = TIME.formatedDateStringWithoutYear(_date, '/')
  //   //     _lastAddedDate = _date;
  //   //     _startNewDate = true
  //   //     _dayIndex ++

  //   //   } else {

  //   //   }
  //   //   _hourCountPerDay =
  //   //   // ist erster tag
  //   //   isSameDay(_date, TIME.tt_toDate(exercistStartDate)) ?
  //   //   differenceInHours(endOfDay(TIME.tt_toDate(exercistStartDate)), TIME.tt_toDate(exercistStartDate))
  //   //    :
  //   //    // ist letzter tag
  //   //   isSameDay(_date, TIME.tt_toDate(exercistEndDate)) ?
  //   //   differenceInHours(TIME.tt_toDate(exercistEndDate), startOfDay(new Date(_date)))
  //   //     :
  //   //     differenceInHours(endOfDay(new UTCDate(_date)), startOfDay(new UTCDate(_date)));
  //   //   // _hourCountPerDay = (_dayIndex == days  ) ?
  //   //   // differenceInHours(TIME.tt_toDate(exercistEndDate), new Date(_date.toUTCString())) :
  //   //   // differenceInHours(endOfDay(new Date(_date.toUTCString())), new Date(_date.toUTCString()));
  //   //   // _hourCountPerDay = differenceInHours(endOfDay(new Date(_date.toUTCString()).setUTCHours(_currentStartHour)), new Date(_date.toUTCString()).setUTCHours(_currentStartHour));
  //   //   // console.log(`_hourCountPerDay: ${_hourCountPerDay} date: ${_date} i: ${i}`);
  //   //   let _hourItem:HourItemI = {
  //   //     index: i,
  //   //     dayIndex: _dayIndex,
  //   //     hoursCount: _hourCountPerDay,
  //   //     startNewDate: _startNewDate,
  //   //     dateString: _dateSting,
  //   //     hourString: (_currentStartHour < 10 ? ("0" + _currentStartHour.toString()) : ( _currentStartHour.toString() )) + ":"+starMinutes.toString()
  //   //   };

  //   //   if (_currentStartHour < 23) {
  //   //     _currentStartHour ++;
  //   //     // _hourCountPerDay ++;
  //   //   } else {
  //   //     _currentStartHour = 0;
  //   //     // _hourCountPerDay = 0;
  //   //   }

  //   //   _date = new UTCDate(addHours(_date, 1));

  //   //   _exerciseDay.push(_hourItem);

  //   // }

  //   // // this.hourItems = _exerciseDay
  //   // // console.log(this.hourItems);
  //   // return _exerciseDay
  // }

  /****************************************************************************/
  setCurrentTarget(e: number) {
    // console.log(`\n--> setCurrentTarget ${e}`);

    /*     const _miniFact = this.ui.smallTimeLineItems ? 60 : 120;
    console.log(`---->`);

      let maxHour:number = (this.exercise.durationInHours * 60)
      let max = (this.miniMapWidth - _miniFact) - 2
      let maxHourPos = maxHour - _miniFact
      let scaleFact = max / maxHourPos

    console.log(e);

    const _newPos3:number =  Math.round( (e * scaleFact) - (this.ui.smallTimeLineItems ? 2 : 2) )
    //  console.log(`_newPos3 ${_newPos3} `);
    // //  this._dragger.nativeElement.style = `left: 0px`
     this.lastPosX = _newPos3
    // const sty = `transform: translate3d(${_newPos3}px,0px,0px)`
    // // console.log(sty);
    //  this._dragger.nativeElement.style = sty

    //  console.log(this._dragger.nativeElement.style.transform);

    const progressAspect3 =  _newPos3  / (this.miniMapWidth - _miniFact)

    console.log(`progressAspect ${progressAspect3} `);

    const _newPos4 = (this.marginRange * progressAspect3) - (this.ui.smallTimeLineItems ? 2 : 2)
    const _finPos = _.toInteger(Math.abs(_newPos4))
    console.log(`_newPos4 ${_newPos4} `);
    gsap.to(this._currentTarget.nativeElement,1, {'left':_finPos, 'opacity': 0.6}); */

    //
    const _miniFact = this.ui.smallTimeLineItems ? 60 : 120;

    let maxHour: number = this.exercise.durationInHours * 60;
    let max = this.miniMapWidth - _miniFact - 2;
    let maxHourPos = maxHour - _miniFact;
    let scaleFact = max / maxHourPos;
    // console.log(`scaleFact ${scaleFact} `);
    // console.log(e);
    const _newPos3: number = Math.round(
      e * scaleFact - (this.ui.smallTimeLineItems ? 2 : 2)
    );
    ////

    // //  console.log(`_newPos3 ${_newPos3} `);
    // // //  this._dragger.nativeElement.style = `left: 0px`
    // this.lastPosX = _newPos3
    // const sty = `transform: translate3d(${_newPos3}px,0px,0px)`
    // //
    //  this._dragger.nativeElement.style = sty

    //  console.log(this._dragger.nativeElement.style.transform);

    // const progressAspect =  _newPos3  / (this.miniMapWidth - _miniFact)

    // console.log(`progressAspect ${progressAspect} `);

    // const _newPos = (this.marginRange * progressAspect) - (this.ui.smallTimeLineItems ? 2 : 2)
    // const _finPos = _.toInteger(Math.abs(_newPos))
    // console.log(`_newPos ${_finPos} `);

    /* gsap.to(this._currentTarget.nativeElement, 1, {
      left: _newPos3,
      opacity: 0.6,
    }); */

    let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;
    const diffInMins = this.differenceStartEndInSeconds / 60;
    // console.log(`diffInMins: ${diffInMins}`);
    const progressAspect = _miniMapWidth / diffInMins;
    const progressAspect2 = e * progressAspect;

    // console.log(`_miniMapWidth ${_miniMapWidth} `);
    // console.log(`progressAspect ${progressAspect} `);
    // console.log(`progressAspect2 ${progressAspect2} `);
    // console.log(`marginRange ${this.marginRange} `);
    // console.log(this._dragger.nativeElement.style.transform);

    const _newPos = progressAspect2 - 2; //(this.ui.smallTimeLineItems ? 2 : 2)
    // console.log(`_newPos ${_newPos} `);
    // const _finPos = _.toInteger(Math.abs(_newPos))
    // console.log(`_finPos ${_finPos} `);

    // this.timelineInnerWrapper.nativeElement.scrollLeft = _finPos < 0 ? 0 : _finPos
    gsap.to(this._currentTargetMini.nativeElement, 1, {
      left: _newPos,
      opacity: 0.6,
    });
    // console.log(` setCurrentTarget <--\n`);
  }

  dragEndedSun(e: number) {
    console.log(`\n--> dragEndedSun ${e}`);
    console.log(this._dragger);

    const _miniFact = this.ui.smallTimeLineItems ? 60 : 120;
    console.log(`---->`);

    let maxHour: number = this.exercise.durationInHours * 60;
    let max = this.miniMapWidth - _miniFact - 2;
    let maxHourPos = maxHour - _miniFact;
    let scaleFact = max / maxHourPos;

    console.log(e);

    const _newPos3: number = Math.round(
      e * scaleFact - (this.ui.smallTimeLineItems ? 2 : 2)
    );
    //  console.log(`_newPos3 ${_newPos3} `);
    // //  this._dragger.nativeElement.style = `left: 0px`
    this.lastPosX = _newPos3;
    const sty = `transform: translate3d(${_newPos3}px,0px,0px)`;
    // console.log(sty);
    this._dragger.nativeElement.style = sty;

    console.log(this._dragger.nativeElement.style.transform);

    const progressAspect = _newPos3 / (this.miniMapWidth - _miniFact);

    console.log(`progressAspect ${progressAspect} `);

    const _newPos =
      this.marginRange * progressAspect - (this.ui.smallTimeLineItems ? 2 : 2);
    const _finPos = _.toInteger(Math.abs(_newPos));
    console.log(`_newPos ${_finPos} `);
    this.timelineInnerWrapper.nativeElement.scrollLeft =
      _finPos < 0 ? 0 : _finPos;
    // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':_newPos,});
    // gsap.to(this.hour_box_holder.nativeElement,1, {'left':_newPos});

    // this.dragPosition = {x: _newPos3, y: 0};
    // console.log(this._dragger);
    console.log(` dragEndedSun <--\n`);
  }

  cdkDragReleased(e: any) {
    // console.log(`\n --> cdkDragReleased <--\n`);
    console.log(`--> cdkDragReleased <--`);
    // console.log(e);
    this.isDragging = false;
  }
  isDragging = false;
  timeLineDragOn = false;
  dragPosition = { x: 0, y: 0 };
  xInitial = 0;
  /****************************************************************************/
  // onDraggerDrop(e:any) {
  //   // e.preventDefault();
  //   console.log(`\n\nonDraggerDrop `);
  //   console.log(e);
  //   console.log(`onDraggerDrop <--\n`);

  // }

  minimapDragStartet(event: any) {
    console.log(`--> minimapDragStartet <--`);
    // console.log(this.dragPosition);
    // const __x = this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    // this.dragPosition = {x: __x, y: 0};
    // console.log(this._dragger.nativeElement.style.transform);
    // this.dragPosition = {x: _newPos3, y: 0};
    this.isDragging = true;
    // this.closeTooltip()
    // console.log(`\n--> minimapDragStartet`);
    // console.log(event);
    // const __x = this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    // const __x2 = event.source.element.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    // console.log(`__x: ${__x} __x2: ${__x2}`);
    // // const __x = this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    // // // console.log(this._dragger.nativeElement.style.transform);
    // // this._dragger.nativeElement.style = `transform: translate3d(${__x}px,0,0)`
    // console.log(` minimapDragStartet <--\n`);
  }
  /****************************************************************************/
  dragMoved(e: any) {
    // console.log(`--> dragMoved <--`);
    // console.log(`\n --> dragMoved <--\n`);
    // console.log(e.distance.x);
    const __x = this._dragger.nativeElement.style.transform
      .replace("translate3d(", "")
      .split(",")[0]
      .replace("px", "");
    // const __x2 = e.source.element.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    // console.log(`__x: ${__x} `);
    const _miniFact = 120; //this.ui.smallTimeLineItems ? 120 : 120;
    // console.log(`---->`);
    let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;
    // console.log(`_miniMapWidth: ${_miniMapWidth} `);
    // let _timelineWidth = this.timelineInjectsHolder.nativeElement.clientWidth;
    // let _marginRange = ((_timelineWidth - _miniMapWidth)  )
    // let _mainWidthScaleFact = _miniMapWidth /_timelineWidth;
    // let maxHour:number = (this.exercise.durationInHours * 60)
    let max = _miniMapWidth - _miniFact - 2;
    // console.log(`max: ${max}`);

    if (__x <= 0) {
      // console.log('__x <= 0');
      // console.log(this._dragger.nativeElement.style.transform);
      // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':0});
      // gsap.to(this.hour_box_holder.nativeElement,1, {'left':0});
      this.timelineInnerWrapper.nativeElement.scrollLeft = 0;
      // this._dragger.nativeElement.style = `transform: translate3d(0px,0px,0px)`
    } else if (__x > max) {
      // console.log('__x > max', max);
      // console.log(this._dragger.nativeElement.style.transform);
      // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':max});
      // gsap.to(this.hour_box_holder.nativeElement,1, {'left':max});
      // this._dragger.nativeElement.style = `transform: translate3d(${max}px,0px,0px)`
      // this.timelineInnerWrapper.nativeElement.scrollLeft = max;
    } else {
      // console.log('>= --- <=');
      // console.log(this._dragger.nativeElement.style.transform);
      const progressAspect = __x / (this.miniMapWidth - _miniFact);

      // console.log(`progressAspect ${progressAspect} `);
      // console.log(this._dragger.nativeElement.style.transform);

      const _newPos = this.marginRange * progressAspect - 2; //(this.ui.smallTimeLineItems ? 2 : 2)
      // console.log(`_newPos ${_newPos} `);
      const _finPos = _.toInteger(Math.abs(_newPos));
      // console.log(`_newPos ${_finPos} `);

      this.timelineInnerWrapper.nativeElement.scrollLeft =
        _finPos < 0 ? 0 : _finPos;
      // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':_newPos});
      // gsap.to(this.hour_box_holder.nativeElement,1, {'left':_newPos});
    }
  }

  /*  dragEnded(e: any, el: any) {
    console.log(`--> dragEnded <--`);
    // this.isDragging = false
    // console.log(e);
    // console.log(el);

    // const __x = this.timelineInnerWrapper.nativeElement.scrollLeft
    // //event.target.scrollLeft//this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    //     // const __x2 = e.source.element.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    //     console.log(`__x: ${__x} `);
    //     const _miniFact = this.ui.smallTimeLineItems ? 120 : 120;
    //     // console.log(`---->`);
    //     let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth

    //     // console.log(`_miniMapWidth ${_miniMapWidth} `);
    //     // console.log(`this.marginRange ${this.marginRange} `);
    //     const _marginRange = _.toInteger(Math.abs(this.marginRange)) //
    //     // console.log(`_marginRange ${_marginRange} `);
    //     // const progressAspect = __x / this.miniMapWidth
    //     // console.log(`progressAspect ${progressAspect} `);
    //     const progressAspect2 = ((this.miniMapWidth - _miniFact )/ _marginRange)
    //     // console.log(`progressAspect2 ${progressAspect2} `);
    //     // const progressAspect3 = (progressAspect2 * __x)
    //     // console.log(`progressAspect3 ${progressAspect3 } `);
    //     // console.log(this._dragger.nativeElement.style.transform);

    //     const _newPos = (progressAspect2 * __x) - 2 //(_marginRange * progressAspect) - 2//(this.ui.smallTimeLineItems ? 2 : 2)
    //     // console.log(`_newPos ${_newPos} `);
    //     const _finPos = _.toInteger(Math.abs(_newPos))
    //     // console.log(`_newPos ${_finPos} `);
    //     // console.log(event.target.scrollLeft);
    //     // console.log(this.timelineInnerWrapper.nativeElement.scrollLeft);
    //     this._dragger.nativeElement.style = `transform: translate3d(${_finPos}px,0px,0px)`
  } */

  /****************************************************************************/
}

// export interface IExercisStageHour {
//   index: number;
//   date: string
//   hour: number

// }
export interface MiniMapItemI {
  X: string;
  Y: string;
  color: string;
}

// dragEnded(e: any, el: any){
//   // console.log(`\n--> dragEnded`);

//   // // console.log(el);
//   // // console.log(e);
//   // // // return
//   // // // console.log(e);
//   // // // console.log(this._dragger.nativeElement.style.transform);
//   // // // console.log(`dragPosition: ${this.dragPosition.x}`);
//   // const _miniFact = this.ui.smallTimeLineItems ? 60 : 120;
//   // // console.log(`---->`);
//   // let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth
//   // let _timelineWidth = this.timelineInjectsHolder.nativeElement.clientWidth;
//   // // let _marginRange = ((_timelineWidth - _miniMapWidth)  )
//   // // let _mainWidthScaleFact = _miniMapWidth /_timelineWidth;
//   // let maxHour:number = (this.exercise.durationInHours * 60)
//   // let max = (_miniMapWidth - _miniFact) - 2
//   // let maxHourPos = maxHour - _miniFact
//   // let scaleFact = max / maxHourPos
//   // // console.log(`_miniMapWidth: ${_miniMapWidth} _timelineWidth: ${_timelineWidth} _mainWidthScaleFact: ${_mainWidthScaleFact} _marginRange: ${_marginRange}`);
//   // console.log(`max: ${max} maxHourPos: ${maxHourPos} scaleFact: ${scaleFact}`);

//   // // console.log(`<----`);
//   // // console.log(`---->`);
//   // //   let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth
//   // //   let _timelineWidth = this.timelineInjectsHolder.nativeElement.clientWidth;
//   // //   let _marginRange = ((_timelineWidth - _miniMapWidth)  )
//   // //   let _mainWidthScaleFact = _miniMapWidth /_timelineWidth;
//   // //   let maxHour:number = this.exercise.durationInHours * 60
//   // //   let max = _miniMapWidth - _miniFact
//   // //   let maxHourPos = maxHour - _miniFact
//   // //   let scaleFact = max / maxHourPos
//   // //   // console.log(`_miniMapWidth: ${_miniMapWidth} _timelineWidth: ${_timelineWidth} _mainWidthScaleFact: ${_mainWidthScaleFact} _marginRange: ${_marginRange}`);
//   // //   console.log(`max: ${max} maxHourPos: ${maxHourPos} scaleFact: ${scaleFact}`);

//   // //   console.log(`<----`);
//   // const __x = this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
//   // // const __x2 = e.source.element.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
//   // console.log(`__x: ${__x} `);
//   // if( __x < 0) {

//   //   // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':0});

//   //   // gsap.to(this.hour_box_holder.nativeElement,1, {'left':0});
//   //   // this.timelineInnerWrapper.nativeElement.scrollLeft = 0;
//   //   this._dragger.nativeElement.style = `transform: translate3d(0px,0px,0px)`
//   // } else if( __x > max) {

//   //   console.log('\n\n\n __x > max \n\n\n');

//   //   // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':max});
//   //   // gsap.to(this.hour_box_holder.nativeElement,1, {'left':max});
//   //   // this._dragger.nativeElement.style = `transform: translate3d(${max}px,0px,0px)`
//   //   /* gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':0});
//   //   gsap.to(this.hour_box_holder.nativeElement,1, {'left':0}); */
//   //   this._dragger.nativeElement.style = `transform: translate3d(0px,0px,0px)`
//   // } else {
//   //   // const progressAspect =  __x  / (this.miniMapWidth - _miniFact)

//   //   // console.log(`progressAspect ${progressAspect} `);
//   //   // console.log(this._dragger.nativeElement.style.transform);

//   //   // const _newPos = (this.marginRange * progressAspect) - (this.ui.smallTimeLineItems ? 2 : 2)
//   //   // // console.log(`_newPos ${_newPos} `);
//   //   // const _finPos = _.toInteger(Math.abs(_newPos))
//   //   // console.log(`_newPos ${_finPos} `);

//   //   // this.timelineInnerWrapper.nativeElement.scrollLeft = _finPos < 0 ? 0 : _finPos
//   //   // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':_newPos});
//   //   // gsap.to(this.hour_box_holder.nativeElement,1, {'left':_newPos});
//   //   this._dragger.nativeElement.style = `transform: translate3d(${__x}px,0,0)`
//   // }

//   // console.log(`dragEnded <--\n`);
//   // console.log(this.timelineInjectsHolder.nativeElement.scrollLeft);

// }
