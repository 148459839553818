
import { Component,  Input, OnInit, ViewChild } from "@angular/core";
import { IExerciseTimelineInjectRef } from "src/app/model/exercise.model";
import { StorageService } from "src/app/services/storage.service";
// import { HtmlEditorService, MarkdownEditorService, PasteCleanupService, RichTextEditorComponent, TableService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
// import { texteditor_cleanup, toolbar_tools_NACHBEHANDLUNG } from "src/app/services/utils/functions";
@Component({
  selector: 'inject-detail-ol-description',
  template: `
    <div class="conrtainer h100">
      <!-- (blur)="textChangeFn($event)" -->
    <!-- <ejs-richtexteditor 
  
          #templateRTE 
          [enableResize]="false" 
          id="name" 
          (change)="textChangeFn($event)"
          (keyup)="modelChangeFn($event)"
          name="name" 
          [value]="nachbehandlungsschema_text" 
          [toolbarSettings]='tools' 
          [editorMode]='mode' 
          [pasteCleanupSettings]="pasteCleanupSettings"
          height="100%"
          >
          </ejs-richtexteditor> -->
      <!-- <textarea class="formControl" [(ngModel)]="inject.description" rows="5" cols="4" [disabled]='readonly'></textarea> -->
    </div>
  `,
    styleUrls: ['../inject-detail-overlay.component.scss'],
    // providers: [ToolbarService,  HtmlEditorService, TableService,PasteCleanupService, RichTextEditorComponent, MarkdownEditorService]
})
export class InjectDetailOL_DescriptionComponent implements OnInit {
  @Input() inject!: IExerciseTimelineInjectRef
  // pasteCleanupSettings: object = texteditor_cleanup
    
  // @ViewChild('templateRTE') rteEle!: RichTextEditorComponent;
  mode: string = 'HTML';
  // tools: object = toolbar_tools_NACHBEHANDLUNG
  readonly = false
  constructor(private storage: StorageService) {}
  get nachbehandlungsschema_text(): string {
    return this.inject.description ?? ''
  }
  set nachbehandlungsschema_text(val: string) {
     this.inject.description = val
  }
  ngOnInit(): void {
    // console.log(this.inject);
    // this.nachbehandlungsschema_text =  this.inject.description ?? ''
    
  }
  modelChangeFn(e:any){
    
    // this.hasChanges = true
    // this.nachbehandlungItems[this.currentIndex].text = e.target.value
    // console.log(this.nachbehandlungItems[this.currentIndex].text)
    // setTimeout(()=>{
    //   this.save()
    // },100)
  }
  textChangeFn(e:any){
    console.log(e.value)
    // this.nachbehandlungsschema_text = this.rteEle.value
  }
}
