import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { INavigationToggleBtn } from 'src/app/components/navigation-toggle-button/navigation-toggle-button.model';
import { iCompanyInject, iExerciseInject, InjSelectViewMode } from 'src/app/model/company.model';
import { ExerciseInjectToTimelineDto, InjectItemUpdateDto } from 'src/app/model/dtos';
import { IExerciseTimelineInjectRef, INJECT_DELIVERY_METHOD } from 'src/app/model/exercise.model';
import { Location } from 'src/app/model/location.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { wait } from 'src/app/services/utils/functions';
import * as _ from 'lodash';
import { AlertOverlayComponent } from '../alert-overlay/alert-overlay.component';
import { ALERT_BUTTON_ACTION_TYPE, ALERT_BUTTON_COLOR_TYPE, HTTP_METHOD } from 'src/app/model/enums';


@Component({
  selector: 'app-inject-select-overlay',
  templateUrl: './inject-select-overlay.component.html',
  styleUrls: ['./inject-select-overlay.component.scss']
})

export class InjectSelectOverlayComponent implements OnInit{

  selectedInjectOE: iExerciseInject | undefined
  selectedInject: iExerciseInject | undefined
  newCompanyInject: iExerciseInject | undefined
  selectedAssignedInject: IExerciseTimelineInjectRef | undefined
  VIEW_MODES = InjSelectViewMode
  currentViewMode: InjSelectViewMode = InjSelectViewMode.LOADING;
  exerciseInjectToTimelineDto: ExerciseInjectToTimelineDto | undefined
  
  toggleBtns: INavigationToggleBtn[] = [
    { id: 1, label: 'INJECTS', activeValue: InjSelectViewMode.INJECT_LIST, 
      values: [InjSelectViewMode.INJECT_LIST, InjSelectViewMode.INJECT_DETAIL] },
    { id: 2, label: 'ASSIGNED INJECTS', activeValue: InjSelectViewMode.ASSIGNED_INJECTS_LIST,
      values: [InjSelectViewMode.ASSIGNED_INJECTS_LIST, InjSelectViewMode.ASSIGNED_INJECT_DETAIL]
     },
    { id: 3, label: 'CREATE INJECT', activeValue: InjSelectViewMode.CREATE_INJECT,
      values: [InjSelectViewMode.CREATE_INJECT]
     },
    // { id: 4, label: 'CREATE INJECT', value: InjSelectViewMode.LIST_INJECTS },
  ]

  exerciseInjects: iExerciseInject[] = []
  saveEnabled = true
  locations: Location[] = []

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InjectSelectOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExerciseInjectToTimelineDto,
    public storage: StorageService,
    public api: ApiService,
    ) {
      this.exerciseInjectToTimelineDto = data
    }

    ngOnInit(): void {
      this.load()
      if(this.storage.currentExer) {
        this.locations = this.storage.currentExer.getLocations()
      }
    }

    private async loadInjects() : Promise<iExerciseInject[]> {
      if(!this.storage.currentExer) { return [] }
      const injectsByEx = await this.api.apiGetP(`inject/templates-by-exercise/${this.storage.currentExer.id}`)
      const out = injectsByEx as iExerciseInject[];
      return out  
    }
    
    private async load() {
      // if(!this.storage.currentExer) { return }
      // const injectsByEx = await this.api.apiGetP(`inject/templates-by-exercise/${this.storage.currentExer.id}`)
      this.exerciseInjects = await this.loadInjects()//injectsByEx as iExerciseInject[];
      this.currentViewMode = InjSelectViewMode.INJECT_LIST
    }

    closeWith(item: IExerciseTimelineInjectRef) {
      this.dialogRef.close(item)
    }

    toggleBtnSelected(e: INavigationToggleBtn) {
      console.log(e);
      
      const newViewmode = e.activeValue as InjSelectViewMode
      console.log(newViewmode);
      if (newViewmode == this.VIEW_MODES.CREATE_INJECT) {
        console.log(this.storage.currentUser);
        console.log(this.storage.currentExer); 
        if (!this.storage.currentExer) { return }
        this.newCompanyInject = {
          id: -1,
          exerciseId: this.storage.currentExer.id,
        
          short_title: '',
          inject_no: '',
          deliveryMethod: INJECT_DELIVERY_METHOD.OTHER,
          responsibility_for_delivery_by: '',
          description: '',
          related_documents: '',
          related_equipment: '',
          role_players_instruction: '',
          additional_guidance_caution_options: '',
          expected_outcomes: '',
          on_scene_preparation: '',
          trainers_action_requirement: '',
        }
      } else if (newViewmode == this.VIEW_MODES.ASSIGNED_INJECTS_LIST) {
        console.log(this.storage.assigendExerciseInjects);
      } else if (newViewmode == this.VIEW_MODES.INJECT_LIST) {
        console.log(this.exerciseInjects);
      }
      this.currentViewMode = newViewmode;
    }

    // viewModeChanged(e:InjSelectViewMode) {
    //   console.log(e);
    //   console.log(this.currentViewMode);
    //   if (e == this.VIEW_MODES.CREATE_INJECT) {
    //     console.log(this.storage.currentUser);
    //     console.log(this.storage.currentExer); 
    //   } else if (e == this.VIEW_MODES.ASSIGNED_INJECTS_LIST) {
    //     console.log(this.storage.assigendExerciseInjects);
    //   } else if (e == this.VIEW_MODES.INJECT_LIST) {
    //     console.log(this.exerciseInjects);
    //   }
    // }
    close(){
      this.dialogRef.close();
    }

    async save() {}
   
    get canSaveAndPushIntoTimeline(): boolean {
      return this.selectedInject != undefined;
    }
    get canSaveCompanyInject(): boolean {
      if (this.selectedInject && this.selectedInjectOE) {
        return _.isEqual(this.selectedInject, this.selectedInjectOE) ? false : true
      } else {
        return false
      }
    }
    get canInsertIntoTimeline(): boolean {
      if (this.selectedInject && this.selectedInjectOE) {
        return _.isEqual(this.selectedInject, this.selectedInjectOE);
      } else {
        return false
      }
    }
    get subNavbarPaddingLeft(): number {
      const btn = this.toggleBtns.find(item => item.values.includes(this.currentViewMode))
      if (btn && btn.offsetLeft) {
        return btn.offsetLeft
      } else {
        return 0
      }
      
    }
    get canSaveAssignedInject(): boolean {
      return this.selectedAssignedInject != undefined;
    }
    async saveAssignedInject() {
      console.log(this.selectedAssignedInject);
      if (!this.storage.currentExer || !this.selectedAssignedInject) { return }
    
      const dto:InjectItemUpdateDto = {
       exerciseId: this.storage.currentExer.id,
       injectItemId: this.selectedAssignedInject.id,
       description: this.selectedAssignedInject.description,
       // timelineItemId: inj.id,
       stateId: this.selectedAssignedInject.state.id,
       deliveryDateTime: this.selectedAssignedInject.deliveryDateTime,
       deliveryDurationInMinutes: this.selectedAssignedInject.deliveryDurationInMinutes,
      //  locationId: this.injectLocation ?this.injectLocation.id : -1,
 
       related_documents: this.selectedAssignedInject.related_documents,
       related_equipment: this.selectedAssignedInject.related_equipment,
       role_players_instruction: this.selectedAssignedInject.role_players_instruction,
       on_scene_preparation: this.selectedAssignedInject.on_scene_preparation,
       additional_guidance_caution_options: this.selectedAssignedInject.additional_guidance_caution_options,
       expected_outcomes: this.selectedAssignedInject.expected_outcomes,
       trainers_action_requirement: this.selectedAssignedInject.trainers_action_requirement,
 
     }
     console.log(dto);
     this.storage.socketInjectUodate(dto)
    //  if(this.injectLocation || (this.inject && this.inject.location)) {
    //    if(this.inject!.location?.id != this.injectLocation?.id) {
    //      // console.log(`location needs to be changed ${this.injectLocation?.id}`);
    //      this.storage.injectsLocationChangedEmitter.emit()
    //    }
    //  }
    //  this.close()
    }
   



    async pushCompanyInjectIntoTimeLine() {
      console.log(this.exerciseInjectToTimelineDto);
      if (!this.storage.currentExer || !this.exerciseInjectToTimelineDto) { return }
      // const dto = {
      //   ...this.selectedInject,
      //   exerciseId: this.storage.currentExer.id,
      // }
      const dto = {
        exerciseId: this.storage.currentExer.id,
        injectId: this.selectedInject!.id,
        timelineItemId: this.exerciseInjectToTimelineDto.timelineItemId,
        deliveryDateTime: this.exerciseInjectToTimelineDto.deliveryDateTime,
        deliveryDurationInMinutes: this.exerciseInjectToTimelineDto.deliveryDurationInMinutes,
      };
      console.log(dto);
      this.storage.socketPush(dto);
      wait(1000, () => {
        this.dialogRef.close()
      })
      // this.dialogRef.close(this.selectedInject)
    }

    injectSelected(e:iExerciseInject) {
      if (!this.storage.currentExer) { return }
      this.selectedInject = {
        ...e,
        exerciseId: this.storage.currentExer.id,
      }
      console.log(this.selectedInject);
      this.selectedInjectOE = _.cloneDeep(this.selectedInject)
      console.log(this.selectedInjectOE);
      this.currentViewMode = InjSelectViewMode.INJECT_DETAIL
    }
    assignedInjectSelected(e:IExerciseTimelineInjectRef) {
      if (!this.storage.currentExer) { return }
      this.selectedAssignedInject = {
        ...e,
        exerciseId: this.storage.currentExer.id,
      }
      console.log(this.selectedAssignedInject);
      this.currentViewMode = InjSelectViewMode.ASSIGNED_INJECT_DETAIL
    }

    async saveCompanyInject() {
      
      // this.dialogRef.close(this.selectedInject)
      if (this.selectedInject && this.selectedInjectOE) {
        // console.log(this.selectedInject == this.selectedInjectOE);
        // console.log(this.selectedInject);  
        // console.log(this.selectedInjectOE);  
        // console.log(_.isEqual(this.selectedInject, this.selectedInjectOE));
        this.showAlert(this.selectedInject) 
      }
    }
    private showAlert(inject: iExerciseInject) {
      const dialogReg = this.dialog.open(AlertOverlayComponent, {
        width:"400px",
        disableClose:true,
        data: {
          title:"Achtung",
          bodyMessage: `Die Änderungen werden in der Inject Vorlagen gespeichert. .... Alle Inject Vorlagen <strong> ${inject.inject_no} | ${inject.short_title} </strong> werden überarbeitet`,
            actions: [
              {title:'Save', color: ALERT_BUTTON_COLOR_TYPE.PRIMARY, actionType: ALERT_BUTTON_ACTION_TYPE.RESUME},
              {title:'Cancel', color: ALERT_BUTTON_COLOR_TYPE.BASIC, actionType: ALERT_BUTTON_ACTION_TYPE.CANCEL},
            ]
          },
        backdropClass:"backdrop-background"
      }).afterClosed().subscribe((result) => {
        if(result != null){
          console.log(result);
          
          if(result === ALERT_BUTTON_ACTION_TYPE.RESUME){
            this.finalSaveCompanyInject(inject);
          } else if(result === ALERT_BUTTON_ACTION_TYPE.CANCEL){
            this.selectedInject = _.cloneDeep(this.selectedInjectOE)
            wait(100, () => {
              this.currentViewMode = InjSelectViewMode.INJECT_LIST
            })
          }
        }
      });
    }
    private async finalSaveCompanyInject(inject: iExerciseInject) {
      console.log(inject);
      if (inject.inject_no.length >= 3 && inject.short_title.length > 3 && inject.id) {
        var dto = {
          ...inject,
          exerciseId: undefined
        }
        delete dto.exerciseId
        
        console.log(dto);
          
        const r = await this.api.apiReq(HTTP_METHOD.PATCH, `inject/company-inject/${inject.id}`,dto )
        console.log(r);
        this.selectedInjectOE = r as iExerciseInject;
        this.selectedInject = _.cloneDeep(this.selectedInjectOE)
        //this.storage.addCompanyInject(r as iCompanyInject)
        // this.close()
        // return
      }
      // wait(100, () => {
      //   this.currentViewMode = InjSelectViewMode.INJECT_LIST
      // })
      
    }
    get canCreateNewInject(): boolean {
      return this.newCompanyInject != undefined;
    }

    async createNewInject() {
      console.log(this.storage.currentExer);
      
      if (!this.storage.currentExer || !this.storage.currentExer.companyId) { return }
      if (this.newCompanyInject && this.newCompanyInject.inject_no.length >= 3 && this.newCompanyInject.short_title.length > 3) {
        if(this.newCompanyInject)  {

          const dto = {
            ...this.newCompanyInject,
            id: undefined,
            companyId: this.storage.currentExer.companyId,
          }
          delete dto.id
            console.log(dto);
            
          const r = await this.api.apiReq(HTTP_METHOD.POST, `inject/company-inject`,dto )
          console.log(r);
        
          const exercise = await this.api.apiPost('inject/template-to-exercise/', {
            id: (r as iCompanyInject).id, 
            exerciseId: this.storage.currentExer.id
          })
          console.log(exercise);
          
          // let out:iExerciseInject[] = []
          // out = await this.loadInjects()
          this.exerciseInjects = await this.loadInjects()//injectsByEx as iExerciseInject[];
          //this.currentViewMode = InjSelectViewMode.INJECT_LIST
          const itemJ = this.exerciseInjects.find(item => item.id == (exercise as iExerciseInject).id)
          if(itemJ) {
            this.injectSelected(itemJ)
          }
          // this.storage.addCompanyInject(r as iCompanyInject)
          // this.close()
        }
        return 
      }
    }
}
