import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { iCompanyLocal, ModifyUserCompanyOverlayData, USR, USR_LOCAL } from '../models';
import { usrCompFix, usrRoleFix } from 'src/app/services/utils/functions';
import { ICompanyUserRoleType } from 'src/app/model/exercise.model';
import { iCompanyFlat } from 'src/app/model/company.model';
import * as _ from 'lodash'
interface tmpRle {
  role: ICompanyUserRoleType
  viewVal: string
}

@Component({
  selector: 'app-modify-user-company-overlay',
  templateUrl: './modify-user-company-overlay.component.html',
  styleUrls: ['./modify-user-company-overlay.component.scss']
})
export class ModifyUserCompanyOverlayComponent implements OnInit {
  companies: iCompanyFlat[] = []
  roles: ICompanyUserRoleType[] = []
  selectedRole: tmpRle | undefined
   user: USR_LOCAL | undefined
    constructor(
      public dialogRef: MatDialogRef<ModifyUserCompanyOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModifyUserCompanyOverlayData,
      public api: ApiService,
      public storage: StorageService
    ) {


    }

    // companiesFiltered: iCompanyFlat[] = []
  get companiesFiltered(): iCompanyFlat[]  {
    if(!this.user) {return []}
    return this.companies.filter(filter => !_.map(this.user!.companies, 'id').includes(filter.id))

  }
  // getCompaniesFiltered()  {
  //   if(!this.user) {return }
  //   this.companiesFiltered = []
  //   const comIds = _.map(this.user!.companies, 'id')
  //   console.log(comIds);
  //   this.companiesFiltered = this.companies.filter(filter => !comIds.includes(filter.id))
  //   // forEach(item => {
  //   //   const index = item.companyUsers.findIndex(usrs => usrs.userId == this.user!.uid)
  //   //   if (index != -1) {
  //   //     this.companiesFiltered.push(item)

  //   //   }
  //   // })
  //   // this.companies.filter(item => (item.companyUsers.find(user => user.userId == this.user!.uid)) )
  //   console.log(this.companiesFiltered);
  // }
  ngOnInit(): void {
    this.roles = [
      ICompanyUserRoleType.ADMIN, ICompanyUserRoleType.EDITOR, ICompanyUserRoleType.VIEWER
    ]
    // this.roles = [
    //   {role: ICompanyUserRoleType.ADMIN, viewVal: 'ADMIN' },
    //   {role: ICompanyUserRoleType.EDITOR, viewVal: 'EDITOR' },
    //   {role: ICompanyUserRoleType.VIEWER, viewVal: 'VIEWER' },
    // ]
    const u: USR_LOCAL = {
      uid: this.data.usr.uid,
      email: this.data.usr.email,
      name: this.data.usr.name,
      authState: this.data.usr.authState,
      roles: usrRoleFix(this.data.usr.roles),
      companies: usrCompFix(this.data.usr.companies)
    }
    this.user = u
    this.loadCOmp()
    // this.selectedRole = this.roles.find(item => item.role == this.user.)
  }

  private async loadCOmp() {
    const res = await this.api.apiGetP(`company/list-flat`);
    console.log(res);
    this.companies = res as iCompanyFlat[]
  }
  close() {
    // console.log(this.companiesFiltered);
    // this.getCompaniesFiltered()
    this.dialogRef.close()
  }
  async onRoleChanged(e:ICompanyUserRoleType, c: iCompanyLocal) {
    console.log(e);
    console.log(c);
    const dto = {
      uid: this.user?.uid,
      userRole: e
    }
    await this.api.apiPatch('company/user-update', c.id, dto)
  }
  async onRemoveUserFromComp(c: iCompanyLocal) {

    if (!this.user) {
      return
    }
    const index = this.user?.companies.findIndex(item => item.id == c.id)
    console.log(c);
    const dto = {
      uid: this.user?.uid,
    }
    await this.api.apiPatch('company/user-remove', c.id, dto)

    if(index != -1) {
      this.user.companies.splice(index, 1)
    }
  }
  async addCompToUser(c: iCompanyFlat) {

    console.log(c);
    if (!this.user) {
      return
    }
    const dto = {
      uid: this.user.uid,
      userRole: 'VIEWER'
    }
    await this.api.apiPatch('company/user-update', c.id, dto)
    this.user.companies.push({
      id: c.id,
      name: c.name,
      role: ICompanyUserRoleType.VIEWER,
      icon: 'person'
    })
  }
}
