import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import {
  ExerciseClass,
  ExerciseTimelineItemType,
  ExerciseUserRole,
  HourItemI,
  IExerciseTimelineInjectRef,
  IExerciseTimelineItem,
} from "src/app/model/exercise.model";
import { ScreenSize } from "src/app/model/screenSize.model";
import { UiService } from "src/app/services/ui.service";
import { gsap } from "gsap";
import { ApiService } from "src/app/services/api.service";
import {
  differenceInHours,
  differenceInSeconds,
  differenceInMinutes,
  isBefore,
  isAfter,
  addMinutes,
  subMinutes,
} from "date-fns";
import { TIME } from "src/app/services/helpers";
import { Subscription } from "rxjs";
import { StorageService } from "../../services/storage.service";
import { ExerciseInjectToTimelineDto } from "src/app/model/dtos";
import * as _ from "lodash";
import { getExerciseDays, wait } from "src/app/services/utils/functions";
import { ViewportScroller } from "@angular/common";
import { iTimelineScrollEventItem, miniOvlItem } from "src/app/model/models";
import { LEVEL, Logger } from "src/app/services/logger.service";
import { UTCDate } from "@date-fns/utc";
import { ExerciseRunning } from "src/app/model/enums";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
})
export class TimelineComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() exercise!: ExerciseClass;
  @ViewChild("timelineWrapper") timelineWrapper!: ElementRef;
  @ViewChild("timelineInnerWrapper") timelineInnerWrapper!: ElementRef;
  @ViewChild("_time_line_mini_map") _time_line_mini_map!: ElementRef;
  @ViewChild("timelineInjectsHolder") timelineInjectsHolder!: ElementRef;
  @ViewChild("hour_box_holder") hour_box_holder!: ElementRef;
  @ViewChild("_timeBar") _timeBar!: ElementRef;
  @ViewChild("_timeBarInner") _timeBarInner!: ElementRef;
  @ViewChild("_filterBar") _filterBar!: ElementRef;
  @ViewChild("_timelineHolderBar") _timelineHolderBar!: ElementRef;
  @ViewChild("_dragger") _dragger!: ElementRef;
  @ViewChild("_draggerHit") _draggerHit!: ElementRef;
  @ViewChild("_currentTarget") _currentTarget!: ElementRef;
  @ViewChild("_currentTargetMini") _currentTargetMini!: ElementRef;
  miniOvlItems: miniOvlItem[] = [];
  currentStageSize!: ScreenSize;
  _timelineHeight = 0;
  _timelineWidth = 0;
  _lowerTimelineHeight = 0;
  _lowerTimelineWidth = 0;
  animatedInjectId = 0;
  mainWidthScaleFact = 0;
  animationSub: Subscription | undefined;
  dragStartetWidth = 0;
  lastPosX = 0;
  isLoading = false;
  showUItems = false;
  private checked = false;
  name = "";
  hourItems: HourItemI[] = [];
  // show = false
  currentTimeInterval: any;
  constructor(
    public ui: UiService,
    public api: ApiService,
    private route: ActivatedRoute,
    private scroller: ViewportScroller,
    public storage: StorageService // private cd: ChangeDetectorRef
  ) {
    // console.log(this.cd);
    effect(() => {
      console.log(`The current count is: ${this.mins()}`);
    });
  }

  mins = signal(0);
  ExerciseTimelineItemTypes = ExerciseTimelineItemType;
  ngAfterViewInit(): void {
    wait(1000, () => {
      this.checked = true;
    });
  }

  ngOnInit(): void {
    this.name = `${this.constructor.name} `;
    this.showUItems = true;
    wait(100, () => {
      this.updateScreenAspects(this.ui.currentSize);
    });
    setTimeout(() => {
      const tlScroller = document.getElementById("tlView");
      if (tlScroller) {
        tlScroller.addEventListener("scroll", this.tlViewScroll, false);
      }
      const scroller = document.getElementById("timelineInnerWrapper");
      if (scroller) {
        scroller.addEventListener("scroll", this.scroll, false);
      }
      // console.log(this.exercise);
      this.animationSub = this.ui.injectAnimation.subscribe(
        (inject: IExerciseTimelineInjectRef) => {
          // Logger.log(`animated check `, LEVEL.ERROR)
          // console.log(inject);
          this.dragEndedSun(Number(inject.deliveryDateTime));
        }
      );
      this.currentStageSize = this.ui.currentScreenSize;
      this.hourItems = [];
      let exercistStartDate = this.exercise.startDateTime;
      let startHour = TIME.tt_toDate(exercistStartDate).getUTCHours();
      // console.log(`exercistStartDate : ${exercistStartDate.day}`);
      // console.log(`startHour : ${startHour}`);

      // let startHour = 1
      let hourCount = 24;
      let exercistEndDate = this.exercise.endDateTime;

      const hoursCount = differenceInHours(
        TIME.tt_toDate(exercistEndDate),
        TIME.tt_toDate(exercistStartDate)
      );
      // console.log(`start h ${startHour}, count h ${hoursCount}`);

      // startHour = this.startDateTime.getUTCHours();
      // hourCount = 24 - startHour;
      // console.log(hoursCount / 24);
      // console.log(hoursCount % 24);
      // var _lastAddedDate =
      // hourCount = hoursCount
      // var _hourIndex = startHour;

      // let starMinutes = "00"

      this.hourItems = getExerciseDays(
        Number(this.exercise.dateTimeStarted),
        this.exercise.durationInHours
      );
      // this.hourItems = this.getExerciseDays();
      //  console.log(this.hourItems);
      const dayCount = this.hourItems.filter(
        (item) => item.startNewDate == true
      );
      //differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
      // console.log(`dayCount: ${dayCount.length}`);
      // console.log(dayCount);

      this.setTimelineScaleFacts();

      // this.ui.miniTimelineUpdate.subscribe(
      //   _newS => {
      //     this.updateDragger(_newS)
      //   }
      // );
      this.ui.windowResize.subscribe((_newS) => {
        if (this.currentStageSize != _newS) {
          this.updateScreenAspects(_newS);
        }
      });

      // let tt = TIME.tt_currentUTC();

      // this._checkExerciseState(TIME.tt_toDate(tt));
    }, 200);

    wait(1500, () => {
      this.startCurrentTimeInterval();
      });
    // wait(100, () => {
    //   for (var i = 0; i < this.exercise.timelineItems.length; i++) {
    //     this.exercise.timelineItems[i].visible = !this.ui.hiddenTimlineVis(
    //       this.exercise.timelineItems[i].id
    //     );
    //   }
    // });
  }
  onTlItemTappedId(e: number) {
    // console.log(`e: ${e}`);
    this.ui.toggleTlViId(e);

    wait(100, () => {
      for (var i = 0; i < this.exercise.timelineItems.length; i++) {
        this.exercise.timelineItems[i].visible = !this.ui.hiddenTimlineVis(
          this.exercise.timelineItems[i].id
        );
      }
    });
  }
  onTlItemTapped(e: IExerciseTimelineItem) {
    // console.log(`e: ${e}`);
    this.ui.toggleTlVis(e);

    wait(100, () => {
      for (var i = 0; i < this.exercise.timelineItems.length; i++) {
        this.exercise.timelineItems[i].visible = !this.ui.hiddenTimlineVis(
          this.exercise.timelineItems[i].id
        );
      }
    });
  }
  onScrollToEvent(s: iTimelineScrollEventItem) {
    const tlScroller = document.getElementById("tlView");
    if (tlScroller) {
      tlScroller.scrollTop = s.injectHeightFact;
    }
  }
  get timelineItems(): IExerciseTimelineItem[] {
    if (!this.storage.currentExer) {
      return [];
    } else {
      return this.storage.currentExer.timelineItems; //.filter(item => item.visible === true);
    }
  }
  getHeight(timelineItem: IExerciseTimelineItem) {
    if (timelineItem.height && this.checked) {
      const h = timelineItem.height;
      const h3 = 60 * h;
      //return (60 + (60 * h)).toString() + 'px'
      return `${h3 + 60}px`;
    } else {
      return "60px";
    }
  }
  intervalHours = 0;
  startCurrentTimeInterval() {
    if (this.currentTimeInterval != null) {
      clearInterval(this.currentTimeInterval);
    }
    if (!this.storage.currentExer) {
      return;
    }

    if (ExerciseRunning(this.storage.currentExer.currentExerciseState)) {
      this.currentTimeInterval = setInterval(() => {
        let tt = TIME.tt_currentUTC();

        if (this.ui.isSimulation) {
          tt = TIME.tt_currentUTC(this.intervalHours);
          // local = TIME.date_lcoal_to_tt(addHours(new Date(), this.intervalHours));
        }

        this._checkExerciseState(TIME.tt_toDate(tt));
        this.intervalHours++;
      }, 2000);
    }
  }

  private _checkExerciseState(widthDateTime: Date) {
    // Logger.log(`${this.name} _checkExerciseState`, LEVEL.DEBUG);
    if (!this.storage.currentExer) {
      return;
    }
    const startUTC = this.storage.currentExer.startDateTimeDate;
    // const startUTC = this.storage.currentExer.getDateTimeUTC();
    const endUTC = this.storage.currentExer.endDateTimeDate;
    // const currentUTC = widthDateTime.toUTCString();
    const currentDateIsBeforeExerciseStart = isBefore(widthDateTime, startUTC);
    const currentDateIsAfterExerciseStart = isAfter(widthDateTime, startUTC);
    const currentDateIsAfterExerciseEnd = isAfter(widthDateTime, endUTC);
    // console.warn(`start UTC`, startUTC);
    const tzOffset = new Date().getTimezoneOffset();
    // console.warn(`end UTC ${endUTC.toUTCString()}`);
    // console.warn(`currentTUC ${currentUTC}`);
    // console.warn(`minBetween: ${minBetween}`);

    let message = "";

    if (currentDateIsBeforeExerciseStart) {
      message = "EX STARTS SOON";
      ///console.warn(message);
    } else {
      if (currentDateIsAfterExerciseStart && currentDateIsAfterExerciseEnd) {
        message = "EX IS DONE";
        // console.warn(message);
      } else {
        message = "EX IS RUNNING";
        // console.warn(message);
        const minBetween = differenceInMinutes(
          subMinutes(new Date(), tzOffset),
          startUTC
        );
        // const minBetween = differenceInMinutes(widthDateTime, startUTC);
        // console.warn(`minBetween: ${minBetween}`);
        // console.warn(`offset: ${tzOffset} `);
        this.setCurrentTarget(minBetween);
        this.mins.set(minBetween);
      }
    }
  }

  toggleTimeSize() {
    this.ui.smallTimeLineItems = !this.ui.smallTimeLineItems;
    this.setTimelineScaleFacts();
  }

  setLabelPos(onTop: boolean) {
    this.ui.timeLineLabelOnTop = onTop;
  }

  private scroll = (event: any) => {
    if (!this.isDragging) {
      const __x = event.target.scrollLeft; //this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
      //     console.log(`scroll: ${__x}`);

      this._timeBarInner.nativeElement.scrollLeft = __x;

      const _miniFact = this.ui.smallTimeLineItems ? 120 : 120;
      // console.log(`---->`);
      let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;
      // console.log(`_miniMapWidth ${_miniMapWidth} `);
      // console.log(`this.marginRange ${this.marginRange} `);
      const _marginRange = _.toInteger(Math.abs(this.marginRange)); //
      // console.log(`_marginRange ${_marginRange} `);
      // const progressAspect = __x / this.miniMapWidth
      // console.log(`progressAspect ${progressAspect} `);
      const progressAspect2 = (this.miniMapWidth - _miniFact) / _marginRange;
      // console.log(`progressAspect2 ${progressAspect2} `);
      // const progressAspect3 = (progressAspect2 * __x)
      // console.log(`progressAspect3 ${progressAspect3 } `);
      // console.log(this._dragger.nativeElement.style.transform)
      const _newPos = progressAspect2 * __x - 2; //(_marginRange * progressAspect) - 2//(this.ui.smallTimeLineItems ? 2 : 2)
      // console.log(`_newPos ${_newPos} `);
      const _finPos = _.toInteger(Math.abs(_newPos));
      // console.log(`_newPos ${_finPos} `);
      // console.log(event.target.scrollLeft);
      // console.log(this.timelineInnerWrapper.nativeElement.scrollLeft);
      // console.log(this.dragPosition);
      this._dragger.nativeElement.style = `transform: translate3d(${_finPos}px,0px,0px)`;
      // console.log(this.dragPosition);
      // const __x = this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
      this.dragPosition = { x: _finPos, y: 0 };
    }
  };
  private tlViewScroll = (event: any) => {
    // console.log(`tlViewScroll ${event.target.scrollTop}`);
    let holderbarHeight = this._timelineHolderBar.nativeElement.clientHeight;
    // let innerheight = this.timelineInnerWrapper.nativeElement.clientHeight
    this.changeInnerHeight(holderbarHeight);
    // console.log(`holderbarHeight: ${holderbarHeight} innerheight: ${innerheight}`);
  };
  private innerHeight: number = 600;
  private changeInnerHeight(newHeight: number) {
    if (this.innerHeight != newHeight) {
      this.innerHeight = newHeight + 80;
      gsap.to(this.timelineInnerWrapper.nativeElement, {
        height: newHeight + 80,
        duration: 0.15,
      });
    }
  }

  private updateScreenAspects(newSize: ScreenSize) {
    let newTimelineHeight = newSize.height;

    const sidebarWith = 402; //380 //412

    let newTimelineWidth =
      newSize.width - (this.ui.isMainToolbarOut ? 10 : sidebarWith) - 2;

    //216
    //242
    // console.log(`newTimelineWidth: ${newTimelineWidth} this.miniMapWidth: ${this.miniMapWidth}`);
    //this.miniMapWidth
    // gsap.to(this._timeBar.nativeElement, { width: newTimelineWidth - 180, duration: 0.25})
    gsap.to(
      this.timelineWrapper.nativeElement,
      // { 'height':currentTimelineHeight, "width":currentTimelineWidht},
      //
      {
        width: newTimelineWidth,
        // height: newTimelineHeight,
        ease: "Expo.easeOut",
        duration: 0.25,
        onComplete: () => {
          // console.log(`newTimelineHeight: ${newTimelineHeight}`);
          // this._timelineHeight = newTimelineHeight
          // this._timelineWidth = newTimelineWidth
        },
      }
    );

    // let newLowerHeight = newTimelineHeight - 174//-39;
    // let newLowerWidth = newTimelineWidth;
    // let currentLowerHeight = this._lowerTimelineHeight
    // let currentLowerWidth = this._lowerTimelineWidth
    // TweenMax.fromTo(this.lowerTimelineWrapper.nativeElement, 0.25,
    //
    gsap.to(this.timelineInnerWrapper.nativeElement, {
      width: newTimelineWidth - this.sidebarWidth,
      // "marginTop": 60,
      duration: 0.25,
      // 'height':newTimelineHeight - 330,
      ease: "Expo.easeOut",
      onComplete: () => {
        // this._timelineHeight = newTimelineHeight
        // this._timelineWidth = newTimelineWidth
      },
    });
    this.setTimelineScaleFacts();
  }

  async borderBoxTapped(
    hourIndex: number,
    topIndex: number,
    item: IExerciseTimelineItem
  ) {
    // console.log(item);
    //return
    if (
      this.storage.getAccess([
        ExerciseUserRole.TRAINER,
        ExerciseUserRole.OBSERVER,
      ]) ||
      this.storage.isExerciseDeboarded
    ) {
      return;
    }

    const dto: ExerciseInjectToTimelineDto = {
      exerciseId: this.exercise.id,

      injectId: -1,

      timelineItemId: item.id,

      deliveryDateTime: hourIndex * 60,
      // deliveryDateTime: (hourIndex * 60) + 30,

      deliveryDurationInMinutes: 120,
    };
    this.ui.openInjectSelectOverlay(dto);
  }
  ngOnDestroy(): void {
    if (this.animationSub) {
      this.animationSub.unsubscribe();
      this.animationSub = undefined;
    }
  }

  /****************************************************************************/
  getTextColorForInject(inject: IExerciseTimelineInjectRef): string {
    return "#1E2A33";
    // if(inject.state.keyName === 'REQUESTED'){
    //   return "#1E2A33";
    // }
    // else {
    //   return  "#ffffff"
    // }
  }

  get sidebarWidth(): number {
    return 220; //380//220
  }
  get iconBtnText(): string {
    return this.ui.smallTimeLineItems ? "LARGE TIME ICON" : "SMALL TIME ICON";
  }
  /*   timelineItemsChanged(e: MatSelectChange) {
    console.log(e.value);
    const ids = _.map(e.value, 'id')
    if (this.storage.currentExer) {
      for(var i = 0; i < this.storage.currentExer.timelineItems.length; i ++) {
        const current = this.storage.currentExer.timelineItems[i];
        console.log(current);
        if (ids.includes(current.id)) {
          this.storage.currentExer.timelineItems[i].visible = true
        } else {
          this.storage.currentExer.timelineItems[i].visible = false
        }
      }
    }
    // for(var i = 0; i < e.value.length; i ++) {
    //   console.log(e.value[i]);
    // }
  } */
  getInjects(timelineItemId: number): IExerciseTimelineInjectRef[] {
    var items = this.storage.assigendExerciseInjects
      .filter((item) => item.exerciseTimelineId == timelineItemId)
      .sort((a, b) => a.deliveryDateTime - b.deliveryDateTime);

    for (var i = 0; i < items.length; i++) {
      let current = items[i];

      var items2 = items.filter(
        (item) =>
          Number(item.deliveryDateTime) >= Number(current.deliveryDateTime) &&
          Number(item.deliveryDateTime) <=
            Number(current.deliveryDateTime) +
              Number(current.deliveryDurationInMinutes)
      ); //.sort((a,b) => a.deliveryDateTime - b.deliveryDateTime)
      //items[i].topPos = 0//items2.length
      let topPos = 0;
      for (var j = 0; j < items2.length; j++) {
        if (current.id != items2[j].id && !current.topPos) {
          topPos++;
          items2[j].topPos = topPos;
        }
      }

      let roleItemIndex = this.exercise.timelineItems.findIndex(
        (item) => item.id == timelineItemId
      );
      if (roleItemIndex != -1) {
        let currentHeight = this.exercise.timelineItems[roleItemIndex].height;
        this.exercise.timelineItems[roleItemIndex].height =
          topPos > currentHeight ? topPos : currentHeight;
      }
    }

    return items; //this.api.injects.filter(item => item.timelineItemId ==  timelineItemId)
  }
  tlBgColor(tl:IExerciseTimelineItem): any[] {
    if (tl.color && tl.color != '')  {
      // return `#${this.tlItem.color}`
      // return `rgba($color: #${this.tlItem.color}, $alpha: 0.2)`
      // return `rgba($color: #000000, $alpha: 1.15);`
      return  [`#${tl.color}0f`, 0.1]
    } else {

      return  ['#2a2e36', 1]
    }
  }
  miniMapWidth: number = 0;
  progress: number = 0;
  pxPersecond: number = 0;
  marginRange = 0;
  pxPersecondMiniMap: number = 0;
  differenceStartEndInSeconds: number = 0;
  miniMapHourWidth: number = 0;

  private setTimelineScaleFacts() {
    this.differenceStartEndInSeconds = differenceInSeconds(
      TIME.tt_toDate(this.exercise.endDateTime),
      TIME.tt_toDate(this.exercise.startDateTime)
    );

    setTimeout(() => {
      //let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;

      let __timelineWidth;

      this.miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;

      __timelineWidth = this.timelineInjectsHolder.nativeElement.clientWidth;

      const doc = document.getElementById("HOUR_BOX_HOLDER");

      if (doc) {
        __timelineWidth = doc.clientWidth;
      }

      this.marginRange = -(__timelineWidth - this.miniMapWidth);

      this.mainWidthScaleFact = this.miniMapWidth / __timelineWidth;

      const dayCount = this.hourItems.filter(
        (item) => item.startNewDate == true
      );
      //differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
      // console.log(`dayCount: ${dayCount}`);
      // console.log(dayCount);
      // const _f1 = Math.round(this.miniMapWidth / dayCount.length)
      const hhh = _.map(dayCount, "hoursCount");
      var sumHours = 0;
      hhh.forEach((e) => {
        sumHours += e;
      });

      // console.log(`sumHours: ${sumHours}`);
      const _f1 = Math.round(this.miniMapWidth / sumHours);
      // const _f1 = Math.round(this.miniMapWidth / this.hourItems.length)
      this.miniOvlItems = [];
      var full = 0;
      var _lastWidth = 0;
      // console.log(`miniMapWidth: ${this.miniMapWidth} _f1: ${_f1}`);
      this.miniMapHourWidth = _f1;
      for (var i = 0; i < dayCount.length; i++) {
        // console.log(dayCount[i]);
        const _f2 = Math.round(_f1 * dayCount[i].hoursCount);
        // console.log(`_F2 ${_f2}`);
        full += _f2;
        this.miniOvlItems.push({
          width: _f2,
          day: dayCount[i].dateString,
          left: _lastWidth,
        });
        _lastWidth += _f2;
      }
      // console.log(this.miniOvlItems);
      // console.log(`full: ${full}`);
    }, 500);
  }

  /****************************************************************************/
  setCurrentTarget(e: number) {
    // console.log(`\n--> setCurrentTarget ${e}`);
    Logger.log(`${this.name} setCurrentTarget: ${e}`, LEVEL.DEBUG);
    const _miniFact = this.ui.smallTimeLineItems ? 60 : 120;

    let maxHour: number = this.exercise.durationInHours * 60;
    let max = this.miniMapWidth - _miniFact - 2;
    let maxHourPos = maxHour - _miniFact;
    let scaleFact = max / maxHourPos;
    // console.log(`scaleFact ${scaleFact} `);
    // console.log(e);
    const _newPos3: number = Math.round(
      e // * scaleFact //- (this.ui.smallTimeLineItems ? 2 : 2)
    );
    ////
    const _newPos4 = this.ui.smallTimeLineItems
      ? (_newPos3 * 61) / 60
      : (_newPos3 * 121) / 60;
    //  this.inject.leftPos = (minsFromCurrentTime * 121) / 60;
    //     this.inject.leftPosSmall = (minsFromCurrentTime * 61) / 60;
    // console.log(`_newPos3 ${_newPos3} `);
    // console.log(`_newPos4 ${_newPos4} `);
    // // //  this._dragger.nativeElement.style = `left: 0px`
    // this.lastPosX = _newPos3
    // const sty = `transform: translate3d(${_newPos3}px,0px,0px)`
    // //
    //  this._dragger.nativeElement.style = sty

    //  console.log(this._dragger.nativeElement.style.transform);

    // const progressAspect =  _newPos3  / (this.miniMapWidth - _miniFact)

    // console.log(`progressAspect ${progressAspect} `);

    // const _newPos = (this.marginRange * progressAspect) - (this.ui.smallTimeLineItems ? 2 : 2)
    // const _finPos = _.toInteger(Math.abs(_newPos))
    // console.log(`_newPos ${_finPos} `);

    gsap.to(this._currentTarget.nativeElement, {
      duration: 1,
      left: _newPos4,
      opacity: 0.6,
    });

    let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;
    const diffInMins = this.differenceStartEndInSeconds / 60;
    // console.log(`diffInMins: ${diffInMins}`);
    const progressAspect = _miniMapWidth / diffInMins;
    const progressAspect2 = e * progressAspect;

    // console.log(`_miniMapWidth ${_miniMapWidth} `);
    // console.log(`progressAspect ${progressAspect} `);
    // console.log(`progressAspect2 ${progressAspect2} `);
    // console.log(`marginRange ${this.marginRange} `);
    // console.log(this._dragger.nativeElement.style.transform);

    const _newPos = progressAspect2 - 2; //(this.ui.smallTimeLineItems ? 2 : 2)
    // console.log(`_newPos ${_newPos} `);
    // const _finPos = _.toInteger(Math.abs(_newPos))
    // console.log(`_finPos ${_finPos} `);

    // this.timelineInnerWrapper.nativeElement.scrollLeft = _finPos < 0 ? 0 : _finPos
    gsap.to(this._currentTargetMini.nativeElement, 1, {
      left: _newPos,
      opacity: 0.6,
    });
    // console.log(` setCurrentTarget <--\n`);
  }

  dragEndedSun(e: number) {
    console.log(`\n--> dragEndedSun ${e}`);
    console.log(this._dragger);

    const _miniFact = this.ui.smallTimeLineItems ? 60 : 120;
    console.log(`---->`);

    let maxHour: number = this.exercise.durationInHours * 60;
    let max = this.miniMapWidth - _miniFact - 2;
    let maxHourPos = maxHour - _miniFact;
    let scaleFact = max / maxHourPos;

    console.log(e);

    const _newPos3: number = Math.round(
      e * scaleFact - (this.ui.smallTimeLineItems ? 2 : 2)
    );
    //  console.log(`_newPos3 ${_newPos3} `);
    // //  this._dragger.nativeElement.style = `left: 0px`
    this.lastPosX = _newPos3;
    const sty = `transform: translate3d(${_newPos3}px,0px,0px)`;
    // console.log(sty);
    this._dragger.nativeElement.style = sty;

    console.log(this._dragger.nativeElement.style.transform);

    const progressAspect = _newPos3 / (this.miniMapWidth - _miniFact);

    console.log(`progressAspect ${progressAspect} `);

    const _newPos =
      this.marginRange * progressAspect - (this.ui.smallTimeLineItems ? 2 : 2);
    const _finPos = _.toInteger(Math.abs(_newPos));
    console.log(`_newPos ${_finPos} `);
    this.timelineInnerWrapper.nativeElement.scrollLeft =
      _finPos < 0 ? 0 : _finPos;
    // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':_newPos,});
    // gsap.to(this.hour_box_holder.nativeElement,1, {'left':_newPos});

    // this.dragPosition = {x: _newPos3, y: 0};
    // console.log(this._dragger);
    console.log(` dragEndedSun <--\n`);
  }

  cdkDragReleased(e: any) {
    // console.log(`\n --> cdkDragReleased <--\n`);
    console.log(`--> cdkDragReleased <--`);
    // console.log(e);
    this.isDragging = false;
  }
  isDragging = false;
  timeLineDragOn = false;
  dragPosition = { x: 0, y: 0 };
  xInitial = 0;
  /****************************************************************************/
  // onDraggerDrop(e:any) {
  //   // e.preventDefault();
  //   console.log(`\n\nonDraggerDrop `);
  //   console.log(e);
  //   console.log(`onDraggerDrop <--\n`);

  // }

  minimapDragStartet(event: any) {
    console.log(`--> minimapDragStartet <--`);
    this.isDragging = true;
  }
  /****************************************************************************/
  dragMoved(e: any) {
    // console.log(`--> dragMoved <--`);
    // console.log(`\n --> dragMoved <--\n`);
    // console.log(e.distance.x);
    const __x = this._dragger.nativeElement.style.transform
      .replace("translate3d(", "")
      .split(",")[0]
      .replace("px", "");
    // const __x2 = e.source.element.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    // console.log(`__x: ${__x} `);
    const _miniFact = 120; //this.ui.smallTimeLineItems ? 120 : 120;
    // console.log(`---->`);
    let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth;
    // console.log(`_miniMapWidth: ${_miniMapWidth} `);
    // let _timelineWidth = this.timelineInjectsHolder.nativeElement.clientWidth;
    // let _marginRange = ((_timelineWidth - _miniMapWidth)  )
    // let _mainWidthScaleFact = _miniMapWidth /_timelineWidth;
    // let maxHour:number = (this.exercise.durationInHours * 60)
    let max = _miniMapWidth - _miniFact - 2;
    // console.log(`max: ${max}`);

    if (__x <= 0) {
      // console.log('__x <= 0');
      // console.log(this._dragger.nativeElement.style.transform);
      // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':0});
      // gsap.to(this.hour_box_holder.nativeElement,1, {'left':0});
      this.timelineInnerWrapper.nativeElement.scrollLeft = 0;
      this._timeBarInner.nativeElement.scrollLeft = 0;
      // this._dragger.nativeElement.style = `transform: translate3d(0px,0px,0px)`
    } else if (__x > max) {
      // console.log('__x > max', max);
      // console.log(this._dragger.nativeElement.style.transform);
      // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':max});
      // gsap.to(this.hour_box_holder.nativeElement,1, {'left':max});
      // this._dragger.nativeElement.style = `transform: translate3d(${max}px,0px,0px)`
      // this.timelineInnerWrapper.nativeElement.scrollLeft = max;
    } else {
      // console.log('>= --- <=');
      // console.log(this._dragger.nativeElement.style.transform);
      const progressAspect = __x / (this.miniMapWidth - _miniFact);

      // console.log(`progressAspect ${progressAspect} `);
      // console.log(this._dragger.nativeElement.style.transform);

      const _newPos = this.marginRange * progressAspect - 2; //(this.ui.smallTimeLineItems ? 2 : 2)
      // console.log(`_newPos ${_newPos} `);
      const _finPos = _.toInteger(Math.abs(_newPos));
      // console.log(`_newPos ${_finPos} `);

      this.timelineInnerWrapper.nativeElement.scrollLeft =
        _finPos < 0 ? 0 : _finPos;
      this._timeBarInner.nativeElement.scrollLeft = _finPos < 0 ? 0 : _finPos;
      // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':_newPos});
      // gsap.to(this.hour_box_holder.nativeElement,1, {'left':_newPos});
    }
  }

  /*  dragEnded(e: any, el: any) {
    console.log(`--> dragEnded <--`);
    // this.isDragging = false
    // console.log(e);
    // console.log(el);

    // const __x = this.timelineInnerWrapper.nativeElement.scrollLeft
    // //event.target.scrollLeft//this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    //     // const __x2 = e.source.element.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
    //     console.log(`__x: ${__x} `);
    //     const _miniFact = this.ui.smallTimeLineItems ? 120 : 120;
    //     // console.log(`---->`);
    //     let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth

    //     // console.log(`_miniMapWidth ${_miniMapWidth} `);
    //     // console.log(`this.marginRange ${this.marginRange} `);
    //     const _marginRange = _.toInteger(Math.abs(this.marginRange)) //
    //     // console.log(`_marginRange ${_marginRange} `);
    //     // const progressAspect = __x / this.miniMapWidth
    //     // console.log(`progressAspect ${progressAspect} `);
    //     const progressAspect2 = ((this.miniMapWidth - _miniFact )/ _marginRange)
    //     // console.log(`progressAspect2 ${progressAspect2} `);
    //     // const progressAspect3 = (progressAspect2 * __x)
    //     // console.log(`progressAspect3 ${progressAspect3 } `);
    //     // console.log(this._dragger.nativeElement.style.transform);

    //     const _newPos = (progressAspect2 * __x) - 2 //(_marginRange * progressAspect) - 2//(this.ui.smallTimeLineItems ? 2 : 2)
    //     // console.log(`_newPos ${_newPos} `);
    //     const _finPos = _.toInteger(Math.abs(_newPos))
    //     // console.log(`_newPos ${_finPos} `);
    //     // console.log(event.target.scrollLeft);
    //     // console.log(this.timelineInnerWrapper.nativeElement.scrollLeft);
    //     this._dragger.nativeElement.style = `transform: translate3d(${_finPos}px,0px,0px)`
  } */

  /****************************************************************************/

  zoomOut() {
    if (this.ui.zoomFact < 1.1) {
      this.ui.zoomFact += 0.1;
      this.zooom();
    }
  }
  zoomIn() {
    if (this.ui.zoomFact > 0.1) {
      this.ui.zoomFact -= 0.1;
      this.zooom();
    }
  }
  private zooom() {
    // gsap.to('.zoooms', { duration: 0.15, scale: this.zoomFact, ease: 'power1.inOut'})
    // gsap.to(this.timelineWrapper.nativeElement, { duration: 0.15, scale: this.zoomFact, ease: 'power1.inOut'})
  }
}

// export interface IExercisStageHour {
//   index: number;
//   date: string
//   hour: number

// }
export interface MiniMapItemI {
  X: string;
  Y: string;
  color: string;
}

// dragEnded(e: any, el: any){
//   // console.log(`\n--> dragEnded`);

//   // // console.log(el);
//   // // console.log(e);
//   // // // return
//   // // // console.log(e);
//   // // // console.log(this._dragger.nativeElement.style.transform);
//   // // // console.log(`dragPosition: ${this.dragPosition.x}`);
//   // const _miniFact = this.ui.smallTimeLineItems ? 60 : 120;
//   // // console.log(`---->`);
//   // let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth
//   // let _timelineWidth = this.timelineInjectsHolder.nativeElement.clientWidth;
//   // // let _marginRange = ((_timelineWidth - _miniMapWidth)  )
//   // // let _mainWidthScaleFact = _miniMapWidth /_timelineWidth;
//   // let maxHour:number = (this.exercise.durationInHours * 60)
//   // let max = (_miniMapWidth - _miniFact) - 2
//   // let maxHourPos = maxHour - _miniFact
//   // let scaleFact = max / maxHourPos
//   // // console.log(`_miniMapWidth: ${_miniMapWidth} _timelineWidth: ${_timelineWidth} _mainWidthScaleFact: ${_mainWidthScaleFact} _marginRange: ${_marginRange}`);
//   // console.log(`max: ${max} maxHourPos: ${maxHourPos} scaleFact: ${scaleFact}`);

//   // // console.log(`<----`);
//   // // console.log(`---->`);
//   // //   let _miniMapWidth = this._time_line_mini_map.nativeElement.clientWidth
//   // //   let _timelineWidth = this.timelineInjectsHolder.nativeElement.clientWidth;
//   // //   let _marginRange = ((_timelineWidth - _miniMapWidth)  )
//   // //   let _mainWidthScaleFact = _miniMapWidth /_timelineWidth;
//   // //   let maxHour:number = this.exercise.durationInHours * 60
//   // //   let max = _miniMapWidth - _miniFact
//   // //   let maxHourPos = maxHour - _miniFact
//   // //   let scaleFact = max / maxHourPos
//   // //   // console.log(`_miniMapWidth: ${_miniMapWidth} _timelineWidth: ${_timelineWidth} _mainWidthScaleFact: ${_mainWidthScaleFact} _marginRange: ${_marginRange}`);
//   // //   console.log(`max: ${max} maxHourPos: ${maxHourPos} scaleFact: ${scaleFact}`);

//   // //   console.log(`<----`);
//   // const __x = this._dragger.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
//   // // const __x2 = e.source.element.nativeElement.style.transform.replace('translate3d(','').split(',')[0].replace('px','');
//   // console.log(`__x: ${__x} `);
//   // if( __x < 0) {

//   //   // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':0});

//   //   // gsap.to(this.hour_box_holder.nativeElement,1, {'left':0});
//   //   // this.timelineInnerWrapper.nativeElement.scrollLeft = 0;
//   //   this._dragger.nativeElement.style = `transform: translate3d(0px,0px,0px)`
//   // } else if( __x > max) {

//   //   console.log('\n\n\n __x > max \n\n\n');

//   //   // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':max});
//   //   // gsap.to(this.hour_box_holder.nativeElement,1, {'left':max});
//   //   // this._dragger.nativeElement.style = `transform: translate3d(${max}px,0px,0px)`
//   //   /* gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':0});
//   //   gsap.to(this.hour_box_holder.nativeElement,1, {'left':0}); */
//   //   this._dragger.nativeElement.style = `transform: translate3d(0px,0px,0px)`
//   // } else {
//   //   // const progressAspect =  __x  / (this.miniMapWidth - _miniFact)

//   //   // console.log(`progressAspect ${progressAspect} `);
//   //   // console.log(this._dragger.nativeElement.style.transform);

//   //   // const _newPos = (this.marginRange * progressAspect) - (this.ui.smallTimeLineItems ? 2 : 2)
//   //   // // console.log(`_newPos ${_newPos} `);
//   //   // const _finPos = _.toInteger(Math.abs(_newPos))
//   //   // console.log(`_newPos ${_finPos} `);

//   //   // this.timelineInnerWrapper.nativeElement.scrollLeft = _finPos < 0 ? 0 : _finPos
//   //   // gsap.to(this.timelineInjectsHolder.nativeElement,1, {'left':_newPos});
//   //   // gsap.to(this.hour_box_holder.nativeElement,1, {'left':_newPos});
//   //   this._dragger.nativeElement.style = `transform: translate3d(${__x}px,0,0)`
//   // }

//   // console.log(`dragEnded <--\n`);
//   // console.log(this.timelineInjectsHolder.nativeElement.scrollLeft);

// }

// private getExerciseDays() {
//   let _exerDateStart = new UTCDate(Number(this.exercise.dateTimeStarted));
//   let _exerDateEnd = new UTCDate(this.exercise.endDateTimeDate);

//   // console.log(`_exerDateStart  ${_exerDateStart}`);
//   // console.log(`_exerDateEnd  ${_exerDateEnd}`);
//   // console.log(`_exerDateStart  ${_exerDateStart.getTimezoneOffset()}`);
//   // console.log(`_exerDateEnd  ${_exerDateEnd.getTimezoneOffset()}`);

//   const days = differenceInDays(_exerDateEnd, _exerDateStart);
//   const hoursCount = differenceInHours(_exerDateEnd, _exerDateStart);
//   let startHour = _exerDateStart.getUTCHours();
//   const hours1 = differenceInHours(
//     TIME.endOf(_exerDateStart),
//     _exerDateStart
//   );
//   // console.log(`start h ${startHour}, count h ${hoursCount} days: ${days} hours1: ${hours1}`);
//   // console.log(`_exerDateStart ${_exerDateStart}`);
//   // console.log(`endOfDay ${TIME.endOf(_exerDateStart)}`);
//   // console.log(`endOfDay ${endOfDay(_exerDateStart)}`);
//   // console.log(`endOfDay ${subMinutes(endOfDay(_exerDateStart), endOfDay(_exerDateStart).getTimezoneOffset())}`);

//   var _currentStartHour = startHour;
//   var _date: Date = _exerDateStart;
//   var _lastAddedDate: Date = _date;
//   var _exerciseDay = [];
//   var _dayIndex = -1;
//   var _hourCountPerDay = 0;
//   for (var i = 0; i < hoursCount; i++) {
//     let starMinutes = "00";

//     let _dateSting = "";
//     let _startNewDate = false;
//     if (_currentStartHour == 0 || i == 0) {
//       _dateSting = TIME.formatedDateStringWithoutYear(_date, "/");
//       _lastAddedDate = _date;
//       _startNewDate = true;
//       _dayIndex++;
//     } else {
//     }

//     _hourCountPerDay =
//       // ist erster tag
//       isSameDay(_date, _exerDateStart)
//         ? differenceInHours(TIME.endOf(_exerDateStart), _exerDateStart) + 1
//         : // ist letzter tag
//         isSameDay(_date, _exerDateEnd)
//         ? differenceInHours(_exerDateEnd, TIME.startOf(new UTCDate(_date)))
//         : differenceInHours(
//             TIME.endOf(new UTCDate(_date)),
//             TIME.startOf(new UTCDate(_date))
//           ) + 1;
//     // _hourCountPerDay = (_dayIndex == days  ) ?
//     // differenceInHours(TIME.tt_toDate(exercistEndDate), new Date(_date.toUTCString())) :
//     // differenceInHours(endOfDay(new Date(_date.toUTCString())), new Date(_date.toUTCString()));
//     // _hourCountPerDay = differenceInHours(endOfDay(new Date(_date.toUTCString()).setUTCHours(_currentStartHour)), new Date(_date.toUTCString()).setUTCHours(_currentStartHour));
//     // console.log(`_hourCountPerDay: ${_hourCountPerDay} date: ${_date} i: ${i}`);
//     let _hourItem: HourItemI = {
//       index: i,
//       dayIndex: _dayIndex,
//       hoursCount: _hourCountPerDay,
//       startNewDate: _startNewDate,
//       dateString: _dateSting,
//       hourString:
//         (_currentStartHour < 10
//           ? "0" + _currentStartHour.toString()
//           : _currentStartHour.toString()) +
//         ":" +
//         starMinutes.toString(),
//     };

//     if (_currentStartHour < 23) {
//       _currentStartHour++;
//       // _hourCountPerDay ++;
//     } else {
//       _currentStartHour = 0;
//       // _hourCountPerDay = 0;
//     }

//     _date = new UTCDate(addHours(_date, 1));

//     _exerciseDay.push(_hourItem);
//   }

//   // this.hourItems = _exerciseDay
//   // console.log(this.hourItems);
//   return _exerciseDay;

//   // old
//   // let exercistStartDate = this.exercise.startDateTime
//   // let exercistEndDate = this.exercise.endDateTime

//   // const days = differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
//   // const hoursCount = differenceInHours(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
//   // let startHour = TIME.tt_toDate(exercistStartDate).getUTCHours()
//   // console.log(`start h ${startHour}, count h ${hoursCount} days: ${days}`);

//   // var _currentStartHour = startHour
//   // var _date: Date =  TIME.tt_toDate(exercistStartDate);
//   // var _lastAddedDate: Date = _date;
//   // var _exerciseDay = []
//   // var _dayIndex = -1
//   // var _hourCountPerDay = 0
//   // for(var i = 0; i < hoursCount; i ++) {

//   //   let starMinutes = "00"

//   //   let _dateSting = ''
//   //   let _startNewDate = false
//   //   if(_currentStartHour == 0 || i == 0 ) {
//   //     _dateSting = TIME.formatedDateStringWithoutYear(_date, '/')
//   //     _lastAddedDate = _date;
//   //     _startNewDate = true
//   //     _dayIndex ++

//   //   } else {

//   //   }
//   //   _hourCountPerDay =
//   //   // ist erster tag
//   //   isSameDay(_date, TIME.tt_toDate(exercistStartDate)) ?
//   //   differenceInHours(endOfDay(TIME.tt_toDate(exercistStartDate)), TIME.tt_toDate(exercistStartDate))
//   //    :
//   //    // ist letzter tag
//   //   isSameDay(_date, TIME.tt_toDate(exercistEndDate)) ?
//   //   differenceInHours(TIME.tt_toDate(exercistEndDate), startOfDay(new Date(_date)))
//   //     :
//   //     differenceInHours(endOfDay(new UTCDate(_date)), startOfDay(new UTCDate(_date)));
//   //   // _hourCountPerDay = (_dayIndex == days  ) ?
//   //   // differenceInHours(TIME.tt_toDate(exercistEndDate), new Date(_date.toUTCString())) :
//   //   // differenceInHours(endOfDay(new Date(_date.toUTCString())), new Date(_date.toUTCString()));
//   //   // _hourCountPerDay = differenceInHours(endOfDay(new Date(_date.toUTCString()).setUTCHours(_currentStartHour)), new Date(_date.toUTCString()).setUTCHours(_currentStartHour));
//   //   // console.log(`_hourCountPerDay: ${_hourCountPerDay} date: ${_date} i: ${i}`);
//   //   let _hourItem:HourItemI = {
//   //     index: i,
//   //     dayIndex: _dayIndex,
//   //     hoursCount: _hourCountPerDay,
//   //     startNewDate: _startNewDate,
//   //     dateString: _dateSting,
//   //     hourString: (_currentStartHour < 10 ? ("0" + _currentStartHour.toString()) : ( _currentStartHour.toString() )) + ":"+starMinutes.toString()
//   //   };

//   //   if (_currentStartHour < 23) {
//   //     _currentStartHour ++;
//   //     // _hourCountPerDay ++;
//   //   } else {
//   //     _currentStartHour = 0;
//   //     // _hourCountPerDay = 0;
//   //   }

//   //   _date = new UTCDate(addHours(_date, 1));

//   //   _exerciseDay.push(_hourItem);

//   // }

//   // // this.hourItems = _exerciseDay
//   // // console.log(this.hourItems);
//   // return _exerciseDay
// }
