  <div class="overlay-toolbar">
    <!-- {{inject?.inject_no}} -->
    <div class="d-flex p0 pl10" >
      <div class="p-0 stateIcon" [ngStyle]="{'background-color':getBackgroundColor(), 'color': '#1E2A33'}">
        <div class="stateIconTxt">
          {{inject?.state?.title}}
        </div>
      </div>
      
    </div>
    <span class="toolbar-spacer"></span>
    <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
  </div>
  
  <div class="p0 bgDarg cHolder" *ngIf='inject' style="height: calc(100% - 88px); overflow: hidden; position: relative;">
  
    <div class="stateBorder" [ngStyle]="{'background-color': getBackgroundColor()}"></div>

    <div class="sideBarContainer">
      <!-- <div class="sideBarBox p-0">
        <inject-detail-ol-delivery-method [inject]="inject"></inject-detail-ol-delivery-method>
      </div> -->
      <div class="sideBarBox" *ngFor="let navItem of sideNavItems" >
        <app-navigation-list-item 
        [active]="activeSideNavId == navItem.id"
        [model]="navItem" (selected)="selectNav($event)" ></app-navigation-list-item>
      </div>
    </div>

    <div class="titleBar">
      

      <div class="p-1 d-flex flex-column" style="min-width: 220px;">
        <div class="p-0 uc itemTitle fs12 m0 mb3">Inject No.</div>
        <div class="p-0 titleBarTxt h38">
          {{inject.inject_no}}
        </div>
      </div>
      <div class="p-1 pl0 d-flex flex-column" style="min-width: 250px;">
        <div class="p-0 uc itemTitle fs12 m0 mb3">Inject Title</div>
        <div class="p-0 titleBarTxt h38">
          {{inject.short_title}}
        </div>
      </div>

      <inject-detail-ol-delivery-method class="p-1 pl0" 
        [deliveryMethod]="inject.deliveryMethod"
        [canChange]="storage.getAccess([ExerciseUserRoles.ADMIN, ExerciseUserRoles.EXCON])"
        (onChanged)="onDeliveryMethodChanged($event)">
      </inject-detail-ol-delivery-method>
      
      <inject-detail-ol-delivery-date-time class="p-1 pl0" [deliveryDateTime]="inject.deliveryDateTime" [inject]="inject"></inject-detail-ol-delivery-date-time>
      
      <inject-detail-ol-locations class="p-1 pl0" 
      (selected)="locationChanged($event)"
      [canChange]="storage.getAccess([ExerciseUserRoles.ADMIN, ExerciseUserRoles.EXCON])"
      [injectLocation]="injectLocation"
      [locations]="locations"></inject-detail-ol-locations>
      <!-- [inject]="inject"  -->
      
      <inject-detail-ol-state class="p-1 pl0" title="new status" [selectable]="true"
      [injectState]="getInjectState(inject)"
      [canChange]="storage.getAccess([ExerciseUserRoles.ADMIN, ExerciseUserRoles.EXCON])"
      (onChanged)="onStateChanged($event)"></inject-detail-ol-state>
    </div>

    <div class="contentCOntainer">
      <ng-template add-host></ng-template>
      <!-- <div add-host></div> -->
    </div>
  
  
  
  </div>

  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <ect-color-btn class="mr5" title="Delete" icon="delete" color="warn"  (clicked)="removeInject()" *exerciseAccess='[ExerciseUserRoles.ADMIN, ExerciseUserRoles.EXCON]'></ect-color-btn>
    <ect-color-btn class="mr5" title="Save" icon="save" color="primary"  (clicked)="save()" *exerciseAccess='[ExerciseUserRoles.ADMIN, ExerciseUserRoles.EXCON, ExerciseUserRoles.TRAINER]'></ect-color-btn>
  </mat-toolbar>