import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { iExerciseInject } from "src/app/model/company.model";
import { INJECT_DELIVERY_METHOD_LIST_ITEM } from "src/app/model/exercise.model";
import { InjectDeliveryMethods } from "src/app/services/utils/companyIn.help";

@Component({
  selector: 'injects-table-view',
  template: `
  <div class="d-flex flex-column" style="height: calc(100vh - 330px); overflow: hidden!important;">
    <div class="p-2">
      <!-- <mat-label class='mat-caption uc'>Suche...</mat-label> -->
      <input type="text" class="formControl textfield100 textFieldH40" 
        placeholder="Search..."  (keyup)="applyFilter($event)" #input
        [(ngModel)]="searchTxt"  (ngModelChange)='onSearch($event)'>
    </div>
    <div class="p-0" *ngIf="dataSource" style="height: 100%; overflow: scroll;">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 p0">
  
      <ng-container matColumnDef="inject_no">
        <th mat-header-cell *matHeaderCellDef>Inject Number</th>
        <td mat-cell *matCellDef="let element" style="width: 120px;"> {{element.inject_no}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="short_title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let element" style="width: 220px;"> {{element.short_title}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="deliveryMethod">
        <th mat-header-cell *matHeaderCellDef>Delivery Method</th>
        <td mat-cell *matCellDef="let element" style="width: 120px;"> {{element.deliveryMethod}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element" class='textCut h100 align-items-center' style="padding-top:10px" [innerHtml]='element?.description'> 
        
      </td>
      </ng-container>
      
      <!-- <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element" style="width: 80px;">XXX</td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="element-row"  *matRowDef="let row; columns: displayedColumns;" (click)='editInject(row)'></tr>
    </table>
</div>
      

  `,

   styleUrls: ['./injects-table-view.component.scss']
})
export class InjectsTableViewComponent implements OnInit {
  @Input() exerciseInjects: iExerciseInject[] = [];
  @Output() injectSelect: EventEmitter<iExerciseInject> = new EventEmitter<iExerciseInject>();

  displayedColumns: string[] = ['inject_no', 'short_title', 'deliveryMethod', 'description'];
  dataSource: MatTableDataSource<iExerciseInject> | undefined;

  searchTxt: string = '';
  deliveryMethods: INJECT_DELIVERY_METHOD_LIST_ITEM[] = [];
  selectedDelivertMethod: INJECT_DELIVERY_METHOD_LIST_ITEM | undefined;

  ngOnInit(): void {
    this.deliveryMethods = InjectDeliveryMethods
    this.dataSource = new MatTableDataSource(this.exerciseInjects)
    // this.selectedDelivertMethod = selectedInjectDeliveryMethodItem(this.inject.deliveryMethod)
  }

  onDeliMethodChanged(e: INJECT_DELIVERY_METHOD_LIST_ITEM) {
    this.selectedDelivertMethod = e
    console.log(this.selectedDelivertMethod);
    // this.inject.deliveryMethod = e.itemEnum
  }

  editInject(e: iExerciseInject) {
    // console.warn(`E:`, e);
    this.injectSelect.emit(e)
  }

  onSearch(e:string) {

  }
  applyFilter(event: Event) {
    if(!this.dataSource) { return }
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}