import { Component, inject, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { wait, getExerciseDays } from '../../services/utils/functions';
import { HourItemI } from '../../model/exercise.model';
import { TimeLineExportC, TLExporHourItem } from '../../services/excel.service';
import { ExportAsService, ExportAsConfig, SupportedExtensions } from 'ngx-export-as';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-excel-export',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss']
})
export class ExcelExportComponent implements OnInit  {
  public dialogRef = inject(MatDialogRef<ExcelExportComponent>)
  private storage = inject(StorageService)
  hourItems: HourItemI[] = []
   _fileName =  `Export-${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
  timelines:any[] = []
  timelineExport:TimeLineExportC[] = []
  config: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'mytable',
    options: {
      jsPDF: {
        orientation: 'landscape'
      },
      pdfCallbackFn: this.pdfCallbackFn // to add header and footer
    }
  };
  ngOnInit(): void {
    wait(1000, () => {
      this.load();
    })
  }
  constructor(
    private exportAsService: ExportAsService
  ) { }
  async  load() {
    if (!this.storage.currentExer) {
      return;
    }
    this.hourItems = getExerciseDays(Number(this.storage.currentExer.dateTimeStarted), this.storage.currentExer.durationInHours)
    const tlines = this.storage.currentExer.timelineItems
    tlines.forEach((tl) => {
      this.timelines.push({
        name: tl.title,
        injects: this.storage.assigendExerciseInjects.filter(item => item.timelineItem!.id == tl.id )
      })
    })
    this.timelines.forEach((timeline) => {
      var itt:TLExporHourItem[] = []
      var timelineExportItem:TimeLineExportC = new TimeLineExportC(timeline.name)
      // = {
      //   teamName: timeline.name,
      //   // injectTitle: []
      // }

      for(var i = 0; i < this.hourItems.length; i ++) {
        const hh = this.hourItems[i]
        let str:TLExporHourItem = {
          hourString: hh.hourString,
          injectTitle: ''
        }
        timeline.injects.forEach((element:any) => {

          if(element.deliveryDateTime >= hh.hour! && element.deliveryDateTime < hh.hour! + 7) {
            console.log(`hur: ${hh.hour} dd ${element.deliveryDateTime} ${element.short_title}`);
            // itt.push(element.short_title)
            str.injectTitle = element.short_title

          } else {
            // itt.push(`${i}`)
          }
        })
        itt.push(str)

        // Object.assign(timelineExportItem, {injectTitle: str})
      }
      //  hourItems.forEach((hh) => {



      // })
      timelineExportItem.setItems(itt)
      // Object.assign(timelineExportItem, {injectTitle: itt})
      // timelineExportItem.injectTitle = itt
      this.timelineExport.push(timelineExportItem)
    })
    console.log(this.timelineExport);
  }
  pdfCallbackFn (pdf: any) {

  }
  exportAs(type: SupportedExtensions, opt?: string) {
    this.config.type = type;
    if (opt) {
      this.config.options.jsPDF.orientation = opt;
    }
    this.exportAsService.save(this.config, this._fileName).subscribe(() => {
      // save started
    });
    // this.exportAsService.get(this.config).subscribe(content => {
    //   const link = document.createElement('a');
    //   const fileName = 'export.pdf';

    //   link.href = content;
    //   link.download = fileName;
    //   link.click();
    //   console.log(content);
    // });
  }
  close() {
    this.dialogRef.close()
  }
}
