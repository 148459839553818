import { ExerciseUserRole, ICompanyUserRoleType, IExerciseUserState, INJECT_DELIVERY_METHOD } from "./exercise.model";
import { iCompanyTrainingObjective } from "./trainingObj.model";

export type iCompany = {
  id: string;
  companyId: string;
  role: ICompanyUserRoleType;
  name: string
  // injects?: iCompanyInject[]
  injectTemplates?: iCompanyInject[]
  trainingObjectives?: iCompanyTrainingObjective[]
  learning_objectives?: string[]
  // trainingObjectives?: iCompanyTrainingObjective[]
}
export type iCompanyFlat = {
  id: string;

  companyUsers:CompanyUser[]
  name: string

  // trainingObjectives?: iCompanyTrainingObjective[]
}
export type iCompanyInject = {
  id: number;
  companyId: string;
  short_title: string;
  inject_no: string;
  deliveryMethod: INJECT_DELIVERY_METHOD
  responsibility_for_delivery_by?: string;
  description?: string;
  related_documents?: string;
  related_equipment?: string;
  role_players_instruction?: string;
  additional_guidance_caution_options?: string;
  expected_outcomes?: string;
  on_scene_preparation?: string;
  trainers_action_requirement?: string;
  learning_objectives?: string;
  inject_set?: string;
  // learning_1?: string;
  // learning_2?: string;
  // learning_3?: string;
}

export type iExerciseInject = {
  exercise_inject_id: number;
  // id: number;
  exerciseId: string;
  short_title: string;
  inject_no: string;
  deliveryMethod: INJECT_DELIVERY_METHOD
  responsibility_for_delivery_by?: string;
  description?: string;
  related_documents?: string;
  related_equipment?: string;
  role_players_instruction?: string;
  additional_guidance_caution_options?: string;
  expected_outcomes?: string;
  on_scene_preparation?: string;
  trainers_action_requirement?: string;
  learning_objectives?: string;
}

export class CompanyInject {
  item: iCompanyInject
  constructor(iten: iCompanyInject) {
    this.item = iten
  }
}
export enum InjSelectViewMode {
  LOADING = 'LOADING',
  INJECT_LIST = 'INJECT_LIST',
  ASSIGNED_INJECTS_LIST = 'ASSIGNED_INJECTS_LIST',
  INJECT_DETAIL = 'INJECT_DETAIL',
  ASSIGNED_INJECT_DETAIL = 'ASSIGNED_INJECT_DETAIL',
  CREATE_INJECT = 'CREATE_INJECT',
}
export enum CompanyUserRole {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  VIEWER = "VIEWER",
}
export interface CompanyUser {
  email: string;
  name: string;
  userId: string;
  userRole: CompanyUserRole;
  userState?: IExerciseUserState;
  exerciseUserRole?: ExerciseUserRole;
}
