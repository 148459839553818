import { Directive, EventEmitter, HostListener, Output, Renderer2 } from "@angular/core";
import { wait } from "../services/utils/functions";

@Directive({
  selector: 'textarea[resize]'
})
export class ResizableTextAreaDirective {
  @Output() resize = new EventEmitter();

  width: number | undefined;
  height: number | undefined;

  mouseMoveListener: Function | undefined;

  @HostListener('mousedown', ['$event.target'])
  onMouseDown(el:any) {
    this.width = el.offsetWidth;
    this.height = el.offsetHeight;
    this.mouseMoveListener = this.renderer.listen('document', 'mousemove', () => {
      this.resize.emit({ width: el.offsetWidth, height: el.offsetHeight });
      // if (this.width !== el.offsetWidth || this.height !== el.offsetHeight) {
      // }
    });
  }

  // @HostListener('document:mouseup')
  @HostListener('document:mouseup')
  onMouseUp(el:any) {
    // this.width = el.offsetWidth;
    // this.height = el.offsetHeight;
    // this.mouseMoveListener = this.renderer.listen('document', 'mouseup', () => {
    //   this.resize.emit({ width: el.offsetWidth, height: el.offsetHeight });
    //   // if (this.width !== el.offsetWidth || this.height !== el.offsetHeight) {
    //   // }
    // });
    wait(100, () => {
      this.ngOnDestroy();
    })
  }

  constructor(private renderer: Renderer2) {}

  ngOnDestroy() {
    if (this.mouseMoveListener) {
      this.mouseMoveListener();
    }
  }
}