
<mat-card class="dashboard-main-card-background mt1 pt10 pb10 pr10 pl10 ">
  <div class="d-flex">
    <div class="p-2 txtLightblue">
      Timeline rows
    </div>
    <div class="p-0 ml-auto">
      <mat-icon *ngIf="!isAddingMode" class="dxfx_icon_btn_medium" matTooltip="Add new User" [class.DISABLED]="inputDisabled" (click)="onCreateNew()">add</mat-icon>
      <mat-icon *ngIf="isAddingMode" class="dxfx_icon_btn_medium" [class.DISABLED]="inputDisabled" (click)="onCloseCreateNew()">close</mat-icon>
    </div>
  </div>
  <div class="container-fluid" >
    <div class="container" *ngIf="isAddingMode && selectedTlItem">

      <app-timelinetype-modify [item]="selectedTlItem" (onUpdate)="onUpdate($event)"></app-timelinetype-modify>

      </div>
    </div>
    <table class="table table-dark table-hover" style="background-color: rgba(0,0,0,.2)!important;     font-size: 12px!important;
    font-weight: 200!important;
    color: #a2a686!important; width: 100%!important;"  >
      <thead>
      <tr>
        <!-- ordering -->
        <th scope="col" style="width: 30px;"></th>
        <th scope="col">name</th>
        <th scope="col"></th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
    </table>

    <div  cdkDropList class="example-list"
    (cdkDropListDropped)="drop($event)"
    >
    <div
      class="example-box"
      *ngFor="let tlItem of timelines"
      [cdkDragData]="tlItem"
      [cdkDragDisabled]="inputDisabled"
      cdkDrag >
      <div class="d-flex w100 align-items-center">
        <div class="p-2 " style="width: 30px;">
          <div class="colorMarker" [ngStyle]="{'background-color': '#'+tlItem.color}"></div>
          <!-- {{tlItem.topIndex}} -->
        </div>
        <div class="p-2 ">{{tlItem.title}}</div>
        <div class="p-0 ml-auto w40"><mat-icon class="dxfx_icon_btn_mini" [class.DISABLED]="inputDisabled" (click)="openTimeline(tlItem)">edit</mat-icon></div>
        <div class="p-0 w40 mr10">
          <mat-icon  *exerciseAccess='[ExerciseUserRoles.ADMIN]' class="dxfx_icon_btn_mini" [class.DISABLED]="inputDisabled" (click)="deleteTimeline(tlItem)">delete</mat-icon>
        </div>
      </div>
    </div>
    </div>




</mat-card>

