import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ExerciseTimelineItemType, IExerciseTimelineItem } from 'src/app/model/exercise.model';
import { ApiService } from 'src/app/services/api.service';
interface bgcolor {
  color: string;
  enabled: boolean;
  tooltip?: string
}
@Component({
  selector: 'app-timelinetype-modify',
  templateUrl: './timelinetype-modify.component.html',
  styleUrls: ['./timelinetype-modify.component.scss']
})
export class TimelinetypeModifyComponent implements OnInit, OnDestroy {
  @Input() item!: IExerciseTimelineItem
  @Output() onUpdate: EventEmitter<IExerciseTimelineItem> = new EventEmitter<IExerciseTimelineItem>();

  colors: bgcolor[] = [
    {
      color: '#7C41AA', enabled: false
    },
    {
      color: '#9E2C68', enabled: false
    },
    {
      color: '#A42929', enabled: false
    },
    {
      color: '#CB4920', enabled: false
    },
    {
      color: '#AA8200', enabled: false
    },
    {
      color: '#AAAF8D', enabled: false
    },
    {
      color: '#727700', enabled: false
    },
    {
      color: '#1C7F46', enabled: false
    },
    {
      color: '#255799', enabled: false
    },
    {
      color: '#9BA8B6', enabled: false
    },
    {
      color: '#2DAAF5', enabled: false
    },
    {
      color: '#417E88', enabled: false
    },
    {
      color: '#2F4654', enabled: false
    },
    {
      color: '#7E817B', enabled: false
    },
    {
      color: '#2a2e36', enabled: false, tooltip: 'Clear'
    },
  ]

  selectColor(val: bgcolor) {
    for(const item of this.colors) {
      if(item.color == val.color) {
        item.enabled = true
      } else {
        item.enabled = false
      }
    }
    if(val.color != '#2a2e36') {
      this.item.color = val.color.replace('#','')
    } else {
      this.item.color = ''
    }
  }

  timelineName = ''
  show = false
  // selectedItem: IExerciseTimelineItem | undefined
  itemType?: ExerciseTimelineItemType | undefined
  itemTypes: ExerciseTimelineItemType[] = [
    ExerciseTimelineItemType.EXCON,
    ExerciseTimelineItemType.EVENTS,
    ExerciseTimelineItemType.TEAM,
    // ExerciseTimelineItemType.LOGISTICS,
    // ExerciseTimelineItemType.SUPPORT,
    // ExerciseTimelineItemType.ROLEPLAY,
    ExerciseTimelineItemType.OBSERVER,
  ]
  constructor(private api: ApiService) {
    this.itemType = undefined

    console.log('constructor');
  }
  ngOnInit(): void {
    console.log('ngOnInit');
    if (this.item.itemType) {
      this.itemType = this.item.itemType as ExerciseTimelineItemType

    }
    this.timelineName = this.item.title
    console.log(this.itemType);
    setTimeout(()=>{

      this.show = true
    },30)
    // this.selectedItem = this.item
    // if(this.selectedItem) {
    //   console.log(this.selectedItem);
    //   this.itemType = this.item.itemType as ExerciseTimelineItemType
    //   this.timelineName = this.item.title
    //   console.log(this.itemType);
    //   setTimeout(()=>{

    //     this.show = true
    //   },30)
    // }

  }
  ngOnDestroy(): void {
      console.log('DESTRY');

  }
  async load() {

  }
  onChanged(e:any) {
    console.log(e.value);
    this.itemType = e.value
    this.timelineName = e.value

  }
  save() {
    if(this.itemType) {

      this.item.title = this.timelineName
      this.item.itemType = this.itemType
      this.onUpdate.emit(this.item)
    }
  }
  get teamRoleSelected(): boolean {
    if(this.itemType && this.itemType == ExerciseTimelineItemType.TEAM) {
      return true
    }
    return false
  }
  get roleNameError(): boolean {
    if(this.timelineName.length < 4 && this.teamRoleSelected) {
      return true
    }
    return false
  }
  get saveBtnDisabled(): boolean {
    if(!this.itemType) {
      return true
    }
    if(this.itemType && this.itemType != ExerciseTimelineItemType.TEAM) {
      return false
    } else if(!this.roleNameError) {
      return false
    } else {
      return true
    }
  }
}
