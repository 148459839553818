import { InjectExportAssignedInject } from "../../excel.service";

export const injectListSchema = [
  {
    column: "inject_no",
    type: String,
    width: 30,

    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.inject_no,
  },
  {
    column: "short_title",
    type: String,
    width: 50,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.short_title,
  },
  {
    column: "deliveryMethod",
    type: String,
    // alignVertical: "top",
    // width: 50,
    value: (item: InjectExportAssignedInject) => item.deliveryMethod,
  },
  {
    column: "description",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",

    value: (item: InjectExportAssignedInject) => item.description,
  },
  {
    column: "related_documents",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.related_documents,
  },
  {
    column: "related_equipment",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.related_equipment,
  },
  {
    column: "role_players_instruction",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.role_players_instruction,
  },
  {
    column: "on_scene_preparation",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.on_scene_preparation,
  },
  {
    column: "additional_guidance_caution_options",
    type: String,
    width: 50,

    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) =>
      item.additional_guidance_caution_options,
  },
  {
    column: "expected_outcomes",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.expected_outcomes,
  },
  {
    column: "trainers_action_requirement",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) =>
      item.trainers_action_requirement,
  },
];
