import { Component, inject, Input, OnInit } from "@angular/core";
import {
  IExerciseTimelineInjectRef,
  TacticalTime,
} from "src/app/model/exercise.model";
import { TIME } from "src/app/services/helpers";
import { StorageService } from "../../services/storage.service";
import { addMinutes } from "date-fns";

@Component({
  selector: "inject-detail-ol-delivery-date-time",
  template: `
    <div class="d-flex flex-column p0">
      <div class="p-0 uc itemTitle fs12 m0 mb3">DELIVERY DATE AND TIME</div>

      <div class="p-0 d-flex" *ngIf="tacticalTime">
        <div class="p-0 w40 h38 tt-box-m tlc">{{ tacticalTime.day }}</div>
        <div class="p-0 w40 h38 tt-box-m ml1 tlc">{{ tacticalTime.hours }}</div>
        <div class="p-0 w40 h38 tt-box-m ml1 tlc">
          {{ tacticalTime.minutes }}
        </div>
        <div class="p-0 w60 h38 tt-box-m ml1 tlc">
          {{ tacticalTime.month.viewVal }}
        </div>
        <div class="p-0 w40 h38 tt-box-m ml1 tlc">{{ tacticalTime.year }}</div>
      </div>
    </div>
  `,
  styleUrls: ["./inject-detail-overlay.component.scss"],
})
export class InjectDetailOL_DeliveryDateTimeComponent implements OnInit {
  @Input() deliveryDateTime!: number;
  @Input() inject!: IExerciseTimelineInjectRef;

  // tacticalTime: TacticalTime | undefined

  // canEditDeliveryMethod = true
  // // isSaving = false
  // disabled = false
  // editDaysActive = false
  // editHoursActive = false
  // editMinutesActive = false
  // max = 100;
  // min = 1;
  // step = 1;
  // selectedTimeItem:string = ""
  // startSliderVal = 3
  // changeTime = false

  private storage = inject(StorageService);

  get deliveryRounded(): number {
    return Math.round((Number(this.inject.leftPosSmall) / 61) * 60);
  }
  get tacticalTime(): TacticalTime | undefined {
    if (this.storage.currentExer) {
      return TIME.date_to_tt(
        addMinutes(
          this.storage.currentExer.getDateTimeRaw(),
          this.deliveryRounded
        )
      );
    } else {
      return undefined;
    }
  }
  ngOnInit(): void {
    //     console.log(`ubu ${this.deliveryDateTime}`);
    //     console.log(this.inject);
    //     if(this.storage.currentExer) {
    //       let pos2:number = Number(this.inject.leftPosSmall)
    //       console.log(`pos2 : ${pos2}`);
    //       const newDeliveryDateTime: number = Math.round((pos2 / 61) * 60)
    //       console.log(`newDeliveryDateTime : ${newDeliveryDateTime}`);
    //       const startDateTime = TIME.date_to_tt(addMinutes(this.storage.currentExer.getDateTimeRaw(), newDeliveryDateTime))
    //       // console.log(`startDateTime ${startDateTime}`);
    //       // console.log(startDateTime);
    //       // const extStart = this.storage.currentExer.getDateTimeRaw()
    //       // const extStart2 = this.storage.currentExer.startDateTime
    //       // console.log(extStart);
    //       // console.log(extStart2);
    //       // console.log(new Date(extStart));
    //       // console.log(`deliveryDateTime: ${this.deliveryDateTime}`);
    // //      this.tacticalTime = TIME.date_lcoal_to_tt(addMinutes(new Date(extStart), this.deliveryDateTime))
    //       this.tacticalTime = startDateTime//TIME.date_to_tt(new Date(extStart))
    //       console.log(this.tacticalTime);
    //       // const hours = Math.floor(this.deliveryDateTime / 60);
    //       // const minutes = this.deliveryDateTime % 60;
    //     // console.log(`hours ${hours}`);
    //     // console.log(`minutes ${minutes}`);
    //           //  this.tacticalTime.hours =  hours.toString()
    //            //this.tacticalTime.minutes =  minutes.toString()
    //     }
  }

  // changeTimeFor(item:string){

  //   if(item == 'DAY') {

  //   } else if(item == 'HOURS') {

  //     this.min = 0;
  //     this.max = 23;
  //     this.step = 1;
  //     this.startSliderVal = Math.floor(this.inject!.deliveryDateTime / 60)
  //     this.selectedTimeItem = item
  //   } else if(item == 'MINUTES') {

  //     this.min = 0;
  //     this.max = 59;
  //     this.step = 1;
  //     this.startSliderVal = this.inject!.deliveryDateTime
  //     this.selectedTimeItem = item
  //   }
  //   // this.formatLabel(this.startSliderVal)

  //   this.changeTime = true;
  // }
  // sliderChanged(e: any) {
  //   console.log(e.target.value);
  //   console.log(this.startSliderVal);
  //   if(this.selectedTimeItem == 'DAY') {

  //   } else if(this.selectedTimeItem == 'HOURS') {
  //     const hours = Math.floor(this.startSliderVal);
  //     // const minutes = this.inject!.deliveryDateTime % 60;
  //     // this.inject!.deliveryDateTime = this.startSliderVal * 60
  //     console.log(this.inject!.deliveryDateTime);

  //         this.tacticalTime.hours =  hours.toString()
  //         // this.tacticalTime.minutes =  minutes.toString()
  //     this.inject!.deliveryDateTime = Number(this.startSliderVal * 60)
  //   } else if(this.selectedTimeItem == 'MINUTES') {
  //     const hours = Math.floor(this.inject!.deliveryDateTime / 60);
  //     const minutes = this.startSliderVal % 60;
  //   console.log(`h ${hours * 60} m ${minutes / 60}`);
  //   const t = (hours * 60) + (minutes)
  //   // console.log(t);
  //   // console.log(Math.round(t));
  //   this.inject!.deliveryDateTime = Number(t)
  //   console.log(this.inject!.deliveryDateTime);
  //     // this.inject!.deliveryDateTime = this.startSliderVal / 60
  //     // const hours = Math.floor(this.inject!.deliveryDateTime / 60);
  //     // const minutes = this.inject!.deliveryDateTime % 60;

  //     //     this.tacticalTime.hours =  hours.toString()
  //     //     this.tacticalTime.minutes =  minutes.toString()
  //   }
  // }
}
// <!-- <div class="p-0 flex-fill pl1 pb4" style="max-width: 225px!important; border-bottom: 1px solid rgb(138,173,192,0.2);">
// <div class="d-flex flex-column  p0 " >
//   <div class="p-2 flex-fill p0 uc itemTitle fs12 m0">
//     DELIVERY DATE AND TIME:
//   </div>
//   <div class="p-0">
//     <div class="d-flex flex-column">
//       <div class="p-0">
//         <div class="d-flex flex-row p0 mb0">

//           <div class="p-2">
//             <div class="d-flex flex-column pt0 pb0 justify-content-center">
//               <div class="p-2  p0 inject-dateTime mt5 m_b5 tt_selection" [class.injectDateTimeActive]="editDaysActive"  (click)='changeTimeFor("DAY")'>
//                 {{tacticalTime.day}}
//               </div>
//             </div>
//           </div>

//           <div class="p-2">
//             <div class="d-flex flex-column pt0 pb0 justify-content-center">
//               <div class="p-2 p0 inject-dateTime mt5 m_b5 tt_selection" [class.injectDateTimeActive]="editHoursActive"  (click)='changeTimeFor("HOURS")'>
//                 {{tacticalTime.hours}}
//               </div>
//             </div>
//           </div>

//           <div class="p-2">
//             <div class="d-flex flex-column pt0 pb0 justify-content-center">
//               <div class="p-0 inject-dateTime mt5 m_b5 tt_selection" [class.injectDateTimeActive]="editMinutesActive"  (click)='changeTimeFor("MINUTES")'>
//                 {{tacticalTime.minutes}}
//               </div>
//             </div>
//           </div>

//           <div class="p-2">
//             <div class="d-flex flex-column pt0 pb0 justify-content-center">
//               <div class="p-2  p0 inject-dateTime mt5 m_b5 pl10 pr10 uc" >
//                 {{tacticalTime.month.viewVal}}
//               </div>
//             </div>
//           </div>

//         </div>
//       </div>

//       <div class="p-0 h36 _br" >
//         <div *ngIf='changeTime'>
//           <mat-slider [min]="min" [max]="max" [step]="step"  showTickMarks discrete [displayWith]="formatLabel" (input)='sliderChanged($event)' >
//             <input matSliderThumb [(ngModel)]="startSliderVal" >
//           </mat-slider>
//         </div>
//       </div>

//       <div class="p-0 h36 _br" >
//         <div *ngIf='changeTime'>
//           <mat-slider min="60" max="600" step="10"  >
//             <input matSliderThumb [(ngModel)]="durationInMinytes" (input)='sliderDurationSliderChanged($event)'>
//           </mat-slider>
//         </div>
//       </div>

//     </div>

//   </div>
// </div>
// </div> -->
