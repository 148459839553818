import {
  Component,
  inject,
  ViewChild,
  OnDestroy,
  OnInit,
  ElementRef,
} from "@angular/core";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { StorageService } from "src/app/services/storage.service";
import { UiService } from "src/app/services/ui.service";
import { Location } from "src/app/model/location.model";
import { Subscription } from "rxjs";
import { MAP_STYLE_2, MAP_STYLE_3 } from "src/app/services/utils/mapstyle";
import { IExerciseTimelineInjectRef } from "src/app/model/exercise.model";
import gsap from "gsap";
import * as _moment from "moment";
// const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
@Component({
  selector: "app-exercise-map-view",
  templateUrl: "./exercise-map-view.component.html",
  styleUrls: ["./exercise-map-view.component.scss"],
})
export class ExerciseMapViewComponent implements OnInit, OnDestroy {
  public ui = inject(UiService);
  public storage = inject(StorageService);
  @ViewChild("_map", { static: false }) _map: GoogleMap | undefined;
  @ViewChild("_testMarker") _testMarker: ElementRef | undefined;
  // @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  exerciseLocations: Location[] = [];
  // markers: any[] = [];
  subS: Subscription | undefined;
  animationSub: Subscription | undefined;
  zoom = 1;
  mapMarkers: any = [];
  infoWindow: google.maps.InfoWindow | undefined;
  options: google.maps.MapOptions = {
    // mapId: '4504f8b37365c3d0',
    center: { lat: 25, lng: 0 },
    // draggable: false,
    disableDoubleClickZoom: true,
    mapTypeId: "roadmap",
    backgroundColor: "#202B31",
    styles: MAP_STYLE_3,

    // mapId: 'DEMO_MAP_ID',
    // mapId: 'MMMID',
    // disableDefaultUI: true,
    // // scrollwheel: false,
    // zoomControl: false,
    // scaleControl: false,
    // panControl: false,

    disableDefaultUI: false,

    zoomControl: true,
    scrollwheel: true,
    scaleControl: true,
    panControl: true,
  };
  ngOnDestroy(): void {
    if (this.subS) {
      this.subS.unsubscribe();
    }
    if (this.animationSub) {
      this.animationSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subS = this.storage.injectsLocationChangedEmitter.subscribe((x) => {
      // setTimeout(()=> {
      //   this.initLocations()
      // },500)
    });
    this.animationSub = this.ui.injectAnimation.subscribe(
      (inject: IExerciseTimelineInjectRef) => {
        // const marker = this._map!.googleMap.m .find(item => item.title == inject.id)
        console.log(inject);
        const location = inject.location;
        if (location) {
          const marker = this.mapMarkers.find(
            (item: any) => item.title == location.id
          );
          // console.log(this._map!.googleMap);

          if (marker) {
            console.log(marker);
            // marker.setMap(this._map!.googleMap);
            // marker.setAnimation(google.maps.Animation.BOUNCE);
            this._map!.panTo({
              lat: Number(location.lat),
              lng: Number(location.lng),
            });
            // this.zoom = 15;
          }
        }

        // console.log(this.exerciseLocations);
        // const injectsWithLocation = this.storage.assigendExerciseInjects.filter(item => item.location);
        // console.log(injectsWithLocation);

        // if(inject.id == this.inject.id) {
        //   this.startAnimation()
        // } else {
        //   // if (this.isAnimated) {
        //   //   this.isAnimated = false
        //   // }
        // }
      }
    );
    setTimeout(() => {
      if (this.storage.currentExer) {
        this.exerciseLocations = this.storage.currentExer.locations;
      }
    }, 100);
    setTimeout(() => {
      // this.initLocations();
      this.mapInit();
    }, 200);
  }

  close() {
    this.ui.toogleMapView();
  }
  // openInfoWindow(marker: any) {
  //   // const m = marker as MapMarker;
  //   console.log(marker.position.toString());
  //   // this.infoWindow?.open(marker);
  // }
  // private addElement(position: any, i: number) {
  //   console.log(`addElement--<`);
  //   // private addElement(x: number, y: number) {
  //   const loc = this.exerciseLocations[i];
  //   if (loc) {
  //     const contentString =
  //       '<div id="content">' +
  //       // '<div id="siteNotice">' +
  //       // "</div>" +
  //       `<h3 id="firstHeading" class="firstHeading">${loc.title}</h3>` +
  //       '<div id="bodyContent">' +
  //       `<p>${loc.description}</p>` +
  //       // "<p><b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large " +
  //       // "sandstone rock formation in the southern part of the " +
  //       // "Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) " +
  //       // "south west of the nearest large town, Alice Springs; 450&#160;km " +
  //       // "(280&#160;mi) by road. Kata Tjuta and Uluru are the two major " +
  //       // "features of the Uluru - Kata Tjuta National Park. Uluru is " +
  //       // "sacred to the Pitjantjatjara and Yankunytjatjara, the " +
  //       // "Aboriginal people of the area. It has many springs, waterholes, " +
  //       // "rock caves and ancient paintings. Uluru is listed as a World " +
  //       // "Heritage Site.</p>" +
  //       // '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">' +
  //       // "https://en.wikipedia.org/w/index.php?title=Uluru</a> " +
  //       // "(last visited June 22, 2009).</p>" +
  //       "</div>" +
  //       "</div>";

  //     const infowindow = new google.maps.InfoWindow({
  //       content: contentString,
  //       ariaLabel: "Uluru",
  //     });
  //     infowindow.open(this._map!.googleMap);
  //     infowindow.setPosition(position);
  //     infowindow.setOptions({
  //       pixelOffset: { width: 0, height: -40 } as google.maps.Size,
  //     });
  //   }
  //   // infowindow.set;

  //   // if (this._testMarker) {
  //   //   gsap.to(this._testMarker.nativeElement, {
  //   //     duration: 0.925,
  //   //     x: x - 400,
  //   //     y: y - 90,
  //   //   });
  //   // }
  // }

  mapInit() {
    this.infoWindow = new google.maps.InfoWindow();
    console.log(`mapInit--<`);
    if (this.exerciseLocations.length > 0) {
      var bounds = new google.maps.LatLngBounds();
      for (let loc of this.exerciseLocations) {
        const position = new google.maps.LatLng(
          Number(loc.lat!),
          Number(loc.lng!)
        );
        console.log(`loc: ${position.lat}`);
        const tempMarker = new google.maps.Marker({
          position: position,
          title: loc.id!.toString(),
          map: this._map?.googleMap,
          icon: "/assets/marker/markerBlue6.png",
          label: {
            text: loc.title!,
            // color: 'rgb(139, 232, 223)',
            // // color: '#CFD2D7',
            // fontSize: '16px', fontWeight: '500', fontFamily: 'Arial',

            className: "mapLabelTT",
            // text: `${injectsWithLocation.length}`, color: '#CFD2D7', fontSize: '12px', fontWeight: '500', backgroundColor: '#212121',
          },
        });
        tempMarker.addListener(
          "click",
          ((tempMarker, map, infoWindow) => {
            return () => {
              infoWindow.setContent(
                `<p class="locHeadline">${loc.title}</p>` +
                  `<p class="locDesc">${loc.description}</p>`
                // "<p><b>Longitude</b> : " +
                //   position.lng +
                //   "</p><p><b>Latitude</b> : " +
                //   position.lat +
                //   "</p>"
              );
              infoWindow.open(map, tempMarker);
              infoWindow.setOptions({ maxWidth: 450 });
            };
          })(tempMarker, this._map?.googleMap, this.infoWindow)
        );
        this.mapMarkers.push(tempMarker);
        bounds.extend(position);
      }

      if (this.exerciseLocations.length > 1) {
        this._map!.fitBounds(bounds);
      }
    }
  }
}
//   async initMap() {
//     // Request needed libraries.
//     const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
//     const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

//     const map = new Map(document.getElementById('map') as HTMLElement, {
//         center: { lat: 37.4239163, lng: -122.0947209 },
//         zoom: 14,
//         mapId: '4504f8b37365c3d0',
//     });

//     const marker = new AdvancedMarkerElement({
//         map,
//         position: { lat: 37.4239163, lng: -122.0947209 },
//         title: "1"
//     });

// }

// private initLocations() {
//   if (this.storage.currentExer) {
//     /*
//   //       const injectsWithLocation = this.storage.assigendExerciseInjects.filter(item => item.location);
//   //       this.markers = []
//   //       var bounds = new google.maps.LatLngBounds();
//   //       for (let i = 0; i < injectsWithLocation.length; i++) {
//   //         const currentInject = injectsWithLocation[i];
//   //         if (currentInject.location) {

//   //           const position = new google.maps.LatLng(Number(currentInject.location.lat!),Number(currentInject.location.lng!))
//   //           bounds.extend(position);
//   //           //
//   //           const marker = new google.maps.Marker( {
//   //             map: this._map!.googleMap,
//   //             position: position,
//   //             title: currentInject.id.toString(),
//   //             // animation: google.maps.Animation.DROP,
//   //             label: {

//   //                 text: `${currentInject.id}`, color: '#ECF1FA', fontSize: '16px', fontWeight: '500'
//   //               },
//   //             // title: '1',
//   //             // opacity: 1,
//   //             // draggable: true,
//   //             // clickable: true,

//   //             // options : { icon: '/assets/marker/markerBlue6.png', draggable: true, label: {
//   //             //   text: `${injectsWithLocation.length}`, color: '#242F3E', fontSize: '16px', fontWeight: '500'
//   //             // } },
//   //           });
//   // // marker.setClickable(true)
//   // // marker.setLabel("asd")
//   //           this.markers.push(marker)
//   //           // this.markers.push({
//   //           //   map: this._map!,
//   //           //   title: currentInject.id,
//   //           //   label: currentInject.id.toString(),
//   //           //   position: position,

//   //           //   // options : { icon: '/assets/marker/markerBlue6.png', draggable: true, label: {
//   //           //   //   text: `${injectsWithLocation.length}`, color: '#242F3E', fontSize: '16px', fontWeight: '500'
//   //           //   // } },
//   //           //   // options : {  label: '12' },
//   //           //   animation: google.maps.Animation.DROP,
//   //           // })
//   //         }
//   //         if (injectsWithLocation.length > 1 ) {
//   //           this._map!.fitBounds(bounds);
//   //         }
//   //       }
//   */

//     this.exerciseLocations = this.storage.currentExer.locations;

//     if (this.exerciseLocations.length > 0) {
//       var bounds = new google.maps.LatLngBounds();
//       this.markers = [];

//       for (let i = 0; i < this.exerciseLocations.length; i++) {
//         const currentExerciseLocation = this.exerciseLocations[i];
//         const injectsWithLocation =
//           this.storage.assigendExerciseInjects.filter(
//             (item) => item.location?.id == currentExerciseLocation.id
//           );
//         console.log(
//           `injectsWithLocation count: ${injectsWithLocation.length}`
//         );

//         const position = new google.maps.LatLng(
//           Number(currentExerciseLocation.lat!),
//           Number(currentExerciseLocation.lng!)
//         );
//         const marker = new google.maps.Marker({
//           map: this._map!.googleMap,
//           // const marker = {
//           title: currentExerciseLocation.id!.toString(),
//           // label: currentExerciseLocation.title,
//           position: position,
//           // icon: '/assets/marker/markerBlue6Long.png',
//           icon: "/assets/marker/markerBlue6.png",
//           label: {
//             text: currentExerciseLocation.title!,
//             // color: 'rgb(139, 232, 223)',
//             // // color: '#CFD2D7',
//             // fontSize: '16px', fontWeight: '500', fontFamily: 'Arial',

//             className: "mapLabelTT",
//             // text: `${injectsWithLocation.length}`, color: '#CFD2D7', fontSize: '12px', fontWeight: '500', backgroundColor: '#212121',
//           },
//           //
//           /*  options: {
//             icon: "/assets/marker/markerBlue6.png",
//             draggable: true,
//             label: {
//               text: currentExerciseLocation.title,
//               color: "#CFD2D7",
//               fontSize: "14px",
//               fontWeight: "500",
//               fontFamily: "Arial",
//               marginTop: "-30px",
//               background: "#212121",
//               className: "navigationTT",
//               // text: `${injectsWithLocation.length}`, color: '#CFD2D7', fontSize: '12px', fontWeight: '500', backgroundColor: '#212121',
//             },
//           }, */
//           // options : {  label: '12' },
//           animation: google.maps.Animation.DROP,
//         });
//         // var infowindow = new google.maps.InfoWindow();
//         this.markers.push(marker);
//         // google.maps.event.addListener(
//         //   marker,
//         //   "click",
//         //   (function (marker, i) {
//         //     return function () {
//         //       console.log(`PPPPP; ${position.toString()}`);
//         //       this.addElement(position);
//         //       // infowindow.setContent(marker);
//         //       // console.log(map);
//         //       // console.log(i);
//         //       console.log(`i: ${i}`);
//         //       // console.log(marker);
//         //       // console.log(event.latLng().lat);
//         //       console.log(marker);

//         //       // infowindow.open();
//         //     };
//         //   })(marker, i)
//         // );

//         // google.maps.event.addListener(
//         //   marker,
//         //   "click",
//         //   (function (marker, i) {
//         //     return function () {
//         //       console.log(`PPPPP; ${position.toString()}`);
//         //       this.addElement(position);
//         //       // infowindow.setContent(marker);
//         //       // console.log(map);
//         //       // console.log(i);
//         //       console.log(`i: ${i}`);
//         //       // console.log(marker);
//         //       // console.log(event.latLng().lat);
//         //       console.log(marker);

//         //       // infowindow.open();
//         //     };
//         //   })(marker, i)
//         // );

//         marker.addListener("click", (event: any) => {
//           console.log(event);
//           console.log(`i: ${i}`);
//           console.log(`position`, position);

//           this.addElement(position, i);
//           // console.log(marker);
//           // console.log(event.latLng().lat);
//           console.log(event.domEvent);
//           // this.addElement(event.domEvent.clientX, event.domEvent.clientY);
//         });
//         bounds.extend(position);
//       }
//       if (this.exerciseLocations.length > 1) {
//         this._map!.fitBounds(bounds);
//       }
//     }
//   }
// }
