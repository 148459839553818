export enum TrainerFeedbackVoteType {
  NOT_RATED = 'NOT_RATED',
  BELOW = 'BELOW',
  MET = 'MET',
  ABOVE = 'ABOVE',
}

export enum TrainerFeedbackType {
  BEST_PRACTISE = 'BEST_PRACTISE',
  LESSONS_IDENTIFIED = 'LESSONS_IDENTIFIED',
  GENERAL_OBSERVATION = 'GENERAL_OBSERVATION',
}


// export interface iTrainerFeedback {
//   id: number;
//   createdAt: Date;
//   vote:TrainerFeedbackVoteType
//   feedbacks?: iTrainerFeedbackVoteItem[]
// }

export interface iTrainerFeedbackVoteItem {
  id: number;
  itemType:TrainerFeedbackType
  createdAt: Date;
  user?: iTrainerFeedbackVoteUser;
  comment?:string;

}
export interface iTrainerFeedbackVoteUser {
  email: string;
  name: string;
}
