<div class="container-fluid p0">
  <div class="d-flex p0 list-box-trenner-row">
    <div class="p-2 p0 pl0 list-box-trenner-col">
      <div class="list-box-bg-rect-mini"></div>
    </div>
    <div class="p-2 p0  pr0 ml-auto list-box-trenner-col">
      <div class="list-box-bg-rect-mini"></div>
    </div>
  </div>
</div>
