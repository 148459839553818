
import { Component,  EventEmitter,  Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
// import { IExerciseTimelineInjectRef } from "src/app/model/exercise.model";
import { StorageService } from "src/app/services/storage.service";
import { HtmlEditorService, MarkdownEditorService, PasteCleanupService, QuickToolbarService, RichTextEditorComponent, TableService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { texteditor_cleanup, toolbar_tools_NACHBEHANDLUNG } from "src/app/services/utils/functions";
import { DynamicComponent } from "src/app/model/dynamic.component";
// import { Editor, Toolbar } from 'ngx-editor';
// import { FormControl, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: 'inject-detail-ol-texteditor',
  template: `
  <!-- *ngIf="editor" -->
    <div class=" h100 " >
      <!-- <div class="d-flex">
        <div class="p-0 flex-fill" *ngIf="!isEditMode">
          <div class="textArea"  [innerHTML]="textValue"></div>
        </div>
        <div class="p-0 flex-fill" *ngIf="isEditMode">
          <ejs-richtexteditor
            #templateRTE
            [enableResize]="false"
            id="name"
            (change)="textChangeFn($event)"
            (keyup)="modelChangeFn($event)"
            (focus)="onTextfieldFocus()"
            (blur)="onTextfieldFocusOut()"
            name="name"
            [value]="textValue"
            [toolbarSettings]='tools'
            [editorMode]='mode'
            [pasteCleanupSettings]="pasteCleanupSettings"
            height="100%"
            >
          </ejs-richtexteditor>
        </div>
        <div class="p-2">
          <mat-icon class="dxfx_icon_btn_mini ml5" (click)='isEditMode = !isEditMode'>edit</mat-icon>
        </div>
      </div> -->
      <ejs-richtexteditor
            #templateRTE
            [enableResize]="false"
            [readonly]="!editable"
            id="name"
            (change)="textChangeFn($event)"
            (keyup)="modelChangeFn($event)"
            (focus)="onTextfieldFocus()"
            (blur)="onTextfieldFocusOut()"
            name="name"
            [value]="textValue"
            [pasteCleanupSettings]="pasteCleanupSettings"
            [toolbarSettings]='tools'
            [editorMode]='mode'
            height="100%"
            >
          </ejs-richtexteditor>
          <!-- [quickToolbarSettings]='tools'   -->
          <!--  -->
          <!--  -->
    </div>
  `,
    styleUrls: ['../inject-detail-overlay.component.scss'],
    providers: [ToolbarService,  HtmlEditorService, TableService,PasteCleanupService, RichTextEditorComponent, MarkdownEditorService, QuickToolbarService]
})
export class InjectDetailOL_TextEditorComponent implements OnInit, DynamicComponent, OnDestroy{
  @ViewChild('templateRTE') rteEle!: RichTextEditorComponent;

  @Input() data!:any
  @Input() editable!:boolean
  @Output() textChanged = new EventEmitter<string>();
  textInput: any// = ''

  pasteCleanupSettings: object = texteditor_cleanup

  mode: string = 'HTML';
  // mode: string = 'Markdown';
  tools: object = toolbar_tools_NACHBEHANDLUNG
  readonly = false

  constructor(private storage: StorageService) {}

  onTextfieldFocus() {
    // console.log('onTextfieldFocus');

  }
  onTextfieldFocusOut() {
    // console.log('onTextfieldFocusOut');

  }
  get textValue(): string {
    return this.textInput
  }
  // set textValue(val: string) {
    //    this.inject = val
    // }
    ngOnInit(): void {

      this.textInput = this.data

  }
  onChange(html: string) {
    // this.html = '';
    // this.textInput = html
    // console.log(html);
    console.log(this.textInput);

  }
  // focusOut() {
  //   // console.log(this.editorContent);
  //   console.log(this.textInput);
  //   return
  //   console.log('out ');
  //   this.textChanged.emit(this.textInput);
  // }
  modelChangeFn(e:any){
    console.log('modelChanged');

    // this.hasChanges = true
    // this.nachbehandlungItems[this.currentIndex].text = e.target.value
    // console.log(this.nachbehandlungItems[this.currentIndex].text)
    // setTimeout(()=>{
    //   this.save()
    // },100)
  }
  textChangeFn(e:any){
    console.log('textChangeFn');
    console.log(e.value)
    this.textChanged.emit(this.rteEle.value);
  }
  ngOnDestroy(): void {
    // if (this.editor) {
    //   this.editor.destroy();
    // }
  }
}

