import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';

import { ApiService } from '../services/api.service';
import { ExerciseUserRole, IUserRole } from '../model/exercise.model';
import { StorageService } from '../services/storage.service';

@Directive({
  selector: '[exerciseAccess]'
})
export class ExerxiseAccessDirective implements OnInit {
  userRole: ExerciseUserRole[] = []
  constructor(
    private templateRef: TemplateRef<any>,
    private storage: StorageService,
    private viewContainer: ViewContainerRef
  ) { }

  @Input() 
  set exerciseAccess(role: ExerciseUserRole[]) {
    // console.warn(role);
    
      // if (!role || !role.length) {
      //     throw new Error('Roles value is empty or missed');
      // }

      this.userRole = role;
  }

  ngOnInit() {
      let hasAccess = false;

      
      if (this.storage.currentExer && this.userRole && this.storage.currentExer.exerciseUser ) {
        hasAccess = this.userRole.includes(this.storage.currentExer.exerciseUser.userRole) ;
        
        // console.log(this.api.currentExer.exerciseUser.userRole);
        
        

        // hasAccess = this.userRole.some(r => this.api.currentExer!.exerciseUser!.userRole);
      }
      // console.log(hasAccess);
      if (hasAccess || this.userRole.includes(ExerciseUserRole.ALL)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
          this.viewContainer.clear();
      }
  }
}