import { ExerciseLog } from "src/app/model/exercise.model";

export const logbookSchema = [
  {
    column: "createdAt",

    type: Date,
    format: "mm/dd/yyyy hh:mm",
    width: 30,

    value: (item: ExerciseLog) => new Date(parseInt(item.createdAt.toString())),
  },
  {
    column: "logType",
    type: String,
    width: 30,
    value: (item: ExerciseLog) => item.logType,
  },

  {
    column: "user",
    type: String,
    width: 30,
    value: (item: ExerciseLog) => item.user,
  },
  {
    column: "message",
    type: String,
    width: 120,
    value: (item: ExerciseLog) =>
      `${item.assignedInjectName ?? ""} ${item.message}`,
  },
];
