import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { StorageService } from "../../services/storage.service";
import { UiService } from "src/app/services/ui.service";
import {
  ExerciseTimelineItemType,
  IExerciseTimelineInjectRef,
  IExerciseTimelineItem,
} from "src/app/model/exercise.model";
import { Subscription } from "rxjs";
import { ViewportScroller } from "@angular/common";
import { iTimelineScrollEventItem } from "src/app/model/models";

@Component({
  selector: "side-nav-role-item",
  template: `
    <!-- [ngStyle]="styles" -->
    <!-- -->
    <!-- { 'height': tlItemHeight, 'background-color':  'rgba($color: '+bgColor[0]+' $alpha: '+bgColor[1]+') ' } -->
    <div class="p-0 sideNavRoleItem" [ngStyle]="{ 'height': tlItemHeight }"

      [class.sideNavRoleItemlight]="!ui.darkMode"
      id="{{ itemId }}"
    >

    <div class="roleItemContent">
        <div class="d-flex align-items-center h100">
          <div class="p-0 pl5">
            <mat-icon class="dxfx_icon_btn_mini_role_item" (click)="tapped()"
              >visibility_off</mat-icon
            >
          </div>
          <div class="p-0 ml-auto fs16 tlr  d-flex flex-column pr5">
            <div
              class="p-0 itemTitle ml-auto"
              *ngIf="tlItem.itemType == ExerciseTimelineItemTypes.TEAM"
            >
              {{ tlItem.itemType }}
            </div>
            <div class="p-0 ">{{ tlItem.title }}</div>
          </div>
        </div>

      </div>
      <div class="roleItemBg" [ngStyle]="{'background-color': bgColor[0], opacity: bgColor[1]}"></div>
    </div>
  `,
  styleUrls: ["./timeline.component.scss"],
})
export class SideNavRoleItemComponent implements OnInit, OnDestroy {
  @Input() tlItem!: IExerciseTimelineItem;
  @Input() tlItemHeight!: string;
  @Output() onScrollTo = new EventEmitter<iTimelineScrollEventItem>();
  @Output() onTapped = new EventEmitter<IExerciseTimelineItem>();
  ExerciseTimelineItemTypes = ExerciseTimelineItemType;
  animationSub: Subscription | undefined;
  itemId: string = "id";

  constructor(public ui: UiService, private scroller: ViewportScroller) {}

  get bgColor(): any[] {
    if (this.tlItem.color && this.tlItem.color != '')  {
      // return `#${this.tlItem.color}`
      // return `rgba($color: #${this.tlItem.color}, $alpha: 0.2)`
      // return `rgba($color: #000000, $alpha: 1.15);`
      return  [`#${this.tlItem.color}`, 0.06]
    } else {

      return  ['#2A2E36', 1]
    }
  }

  // get styles(): any {
  //   return {

  //     backgroundColor: ` #ffffff`,
  //     opacity: 0.2,

  //     'height': this.tlItemHeight,

  //   };
  // }
  ngOnInit(): void {
    this.itemId = `tlItem-${this.tlItem.id}`;
    this.animationSub = this.ui.injectAnimation.subscribe(
      (inject: IExerciseTimelineInjectRef) => {
        if (inject.timelineItem) {
          if (inject.timelineItem.id == this.tlItem.id) {
            console.log(`treffer ${this.tlItemHeight}`);
            // console.log(inject.heightFact);

            const item: iTimelineScrollEventItem = {
              timelineId: this.tlItem.id,
              timelineTopIndex: this.tlItem.topIndex,
              timelineHeight: this.tlItem.height,
              injectTopIndex: inject.topIndex,
              injectHeightFact: inject.heightFact ?? 0,
            };
            this.onScrollTo.emit(item);

            // `${this.tlItem.id}###${this.tlItem.height}###${inject.topIndex}###`
            // document.getElementById(this.itemId)!.scrollIntoView();
            // {
            //   behavior: "smooth",
            //   block: "center",
            //   inline: "center"
            // }
          }
        }
      }
    );
  }
  tapped() {
    this.onTapped.emit(this.tlItem);
  }
  ngOnDestroy(): void {
    if (this.animationSub) {
      this.animationSub.unsubscribe();
      this.animationSub = undefined;
    }
  }
  // getHeight(timelineItem: IExerciseTimelineItem) {
  //   if (timelineItem.height ) {
  //     const h = timelineItem.height;
  //     const h3 = 60 * h;
  //     //return (60 + (60 * h)).toString() + 'px'
  //     return `${h3 + 60}px`;
  //   } else {
  //     return "60px";
  //   }
  // }
}
