<div class="timelineInner " #timelineWrapper>
  <div id="upperTimelineWrapper">
    <div class="d-flex">
      <div id='filterBar' #_filterBar [ngStyle]="{'width':  sidebarWidth +'px'}">
        <div class="d-flex flex-column p0 ">

          <div class="p-2 p0 pb1 ">

            <!-- style="height: 200px;" -->
            <div class="d-flex p0">
              <div class="p-2 flex-fill p0">
                <div class='miniToggleButton fs12 uc tlc' [class.miniToggleButton_active]='!ui.smallTimeLineItems'
                  (click)="toggleTimeSize()">{{iconBtnText}}</div>
              </div>
            </div>


          </div>
          <div class="p-0 d-flex flex-wrap">
            <div class="p-0 itemTitleToggle" *ngFor="let id of ui.hiddenTimlineIds" (click)="onTlItemTappedId(id.id)">
              {{id.title}}


            </div>
            <!-- <div class="p-0">
              <mat-icon class="dxfx_icon_btn_medium" (click)="zoomIn()">zoom_in</mat-icon>
            </div>
            <div class="p-0">
              <mat-icon class="dxfx_icon_btn_medium" (click)="zoomOut()">zoom_out</mat-icon>
            </div> -->
          </div>
          <!--  <div class="p-0">
          <select name="cars" id="cars" multiple>
              <option value="volvo">Volvo</option>
              <option value="saab">Saab</option>
              <option value="opel">Opel</option>
              <option value="audi">Audi</option>
            </select>
            <mat-form-field appearance="outline" class="textfield100 select-form-field" *ngIf="storage.currentExer">
              <mat-select multiple (selectionChange)='timelineItemsChanged($event)'>
                [(value)]='selectedtrainingObjectives'
                [compareWith]="compareFn" multiple [disabled]='readonly || !editable' (openedChange)='openedChange($event)'
                <mat-option *ngFor="let obj of storage.currentExer.timelineItems" [value]="obj">
                  {{obj.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        -->
          <!-- <div class="p-0 " style="height: 200px;">
            <div class="miniGoogleMapHolder">

              <div class="hitter">
                <div class="hitter-menu d-flex h100 align-items-center " (click)="ui.openExerciseLocationsOverlay()">
                  <div class="p-0" style="margin: auto;"><mat-icon>edit</mat-icon></div>
                </div>
              </div>
              <div class="gmapInner">
                <timeline-mini-google-map></timeline-mini-google-map>

              </div>
            </div>

          </div> -->
        </div>
      </div>

      <div class="p-0 flex-fill " style="position: relative;">
        <div id="timeline-mini-map" #_time_line_mini_map>
          <div class="currentTargetMini" #_currentTargetMini></div>
          <div class="miniMapDaysHolder">
            <div class="minOvlBox" *ngFor="let bb of miniOvlItems"
              [ngStyle]="{'width':  bb.width + 'px', 'left': bb.left + 'px'}">
              <div class="dayStr">
                {{bb.day}}
              </div>
            </div>
          </div>
          <div *ngIf='mainWidthScaleFact > 0 && !isLoading  '>

            <div *ngFor='let injectItem of storage.assigendExerciseInjects'>
              <minimap-item [item]="injectItem" [fact]="mainWidthScaleFact"
                [hourWidth]="miniMapHourWidth"></minimap-item>
            </div>
          </div>

          <!--
          (cdkDragEnded)='dragEnded($event, _time_line_mini_map)'
           -->
          <div id="dragger" class="draggerRef draggerBIG" #_dragger cdkDragLockAxis="x" cdkDrag
            (cdkDragStarted)='minimapDragStartet($event)' (cdkDragMoved)="dragMoved($event)"
            (cdkDragReleased)="cdkDragReleased($event)" cdkDragBoundary="#timeline-mini-map"
            [cdkDragFreeDragPosition]="dragPosition">
            <!-- [class.draggerSMALL]='ui.smallTimeLineItems' [class.draggerBIG]='!ui.smallTimeLineItems' -->
            <!--  -->
            <div id="dragger-hit" class="draggerBIG" #_draggerHit></div>
            <!-- [class.draggerSMALL]='ui.smallTimeLineItems'
            [class.draggerBIG]='!ui.smallTimeLineItems'  -->
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="timeBar " #_timeBar>
    <!-- [ngStyle]="{'margin-left':  (sidebarWidth +1) +'px'}"  -->
    <div class="timeBarInner "
      style="position: absolute; left: 220px; height: 60px; width: calc(100% - 220px); overflow: hidden; pointer-events: none;"
      #_timeBarInner>
      <!--  -->
      <div id="HOUR_BOX_HOLDER" #hour_box_holder class="zoooms">
        <div *ngFor='let hour of hourItems; let hourIndex = index;' class="p0"
          [class.hour-box-big]='!ui.smallTimeLineItems' [class.hour-box]='ui.smallTimeLineItems'>
          <div class="time-copy">
            <p class="dateStr " *ngIf="hour.startNewDate"> {{hour.dateString}}</p>
            <p class="dateStr " *ngIf="!hour.startNewDate"> </p>
            <p class="hourStr">{{hour.hourString}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tlView " *ngIf="showUItems" id="tlView">

    <div style="position: absolute;  top: 0px; z-index: 9999999; overflow: hidden;"
      [ngStyle]="{'width':  (sidebarWidth * ui.zoomFact) +'px'}" class="sideShadow zoooms" #_timelineHolderBar>
      <!-- height: calc(100% - 0px);  -->
      <div class="d-flex flex-column ">
        <!-- <div class="p-0 sideNavRoleItem" *ngFor="let tlItem of exercise.timelineItems" [ngStyle]="{'height': 60 + (60 * tlItem.height) +'px'}"> -->
        <div *ngFor="let tlItem of exercise.timelineItems">

          <side-nav-role-item *ngIf="tlItem.visible" [tlItem]="tlItem" (onTapped)="onTlItemTapped($event)"
            [tlItemHeight]="getHeight(tlItem)" (onScrollTo)="onScrollToEvent($event)"></side-nav-role-item>
        </div>

        <!-- <div class="p-0 sideNavRoleItem" *ngFor="let tlItem of exercise.timelineItems" [ngStyle]="{'height': getHeight(tlItem)}"
          [class.sideNavRoleItemlight]="!ui.darkMode">

          <div class="d-flex align-items-center h100">
            <div class="p-0 ml-auto fs16 tlr font-main d-flex flex-column pr5">

              <div class="p-0 itemTitle ml-auto" *ngIf="tlItem.itemType == ExerciseTimelineItemTypes.TEAM">{{tlItem.itemType}}</div>
              <div class="p-0 font-main">{{tlItem.title}}</div>

            </div>
          </div>
        </div> -->
      </div>

    </div>

    <!-- <div  style="position: absolute ;  height: calc(100% - 230px);left: 220px; overflow-x: scroll;" #timelineInnerWrapper [ngStyle]="{'left':  sidebarWidth +'px'}" > -->
    <div #timelineInnerWrapper [ngStyle]="{'left':  (sidebarWidth * ui.zoomFact) +'px'}" class="timelineInnerWrapper "
      id="timelineInnerWrapper">
      <div class="currentTarget" #_currentTarget></div>
      <!-- height: calc(100% - 330px); -->

      <!-- <div id="HOUR_BOX_HOLDER" #hour_box_holder>
        <div *ngFor='let hour of hourItems; let hourIndex = index;' class="p0"
          [class.hour-box-big]='!ui.smallTimeLineItems' [class.hour-box]='ui.smallTimeLineItems'>
          <div class="time-copy">
            <p class="dateStr ">{{hour.dateString}}</p>
            <p class="hourStr">{{hour.hourString}}</p>
          </div>
        </div>
      </div> -->

      <div id="timelineInjectsHolder" #timelineInjectsHolder style="overflow-x: hidden;">
        <div id="injectsWrapper" class="zoooms">
          <div *ngFor='let hour of hourItems; let hourIndex = index;'>
            <div *ngFor="let roleItem of exercise.timelineItems; let topIndex = index;">
              <div class="no-select" [class.border-box-big]='!ui.smallTimeLineItems' *ngIf="roleItem.visible"
              [ngStyle]="{'height':  (60 + (60 * roleItem.height) * ui.zoomFact) +'px', 'background-color': tlBgColor(roleItem)[0],}"
              [class.border-box]='ui.darkMode && ui.smallTimeLineItems'
              [class.border-box-light]="!ui.darkMode && ui.smallTimeLineItems"
                (click)="borderBoxTapped(hourIndex, topIndex, roleItem)">

              </div>
              <!-- opacity: tlBgColor(roleItem)[1] -->
            </div>
          </div>
        </div>
        <div class='team-holder zoooms' *ngFor="let roleItem of exercise.timelineItems; let topIndex = index;">

          <div *ngIf="roleItem.visible">

            <timeline-inject-item *ngFor='let inject of getInjects(roleItem.id)' [inject]="inject" [exercise]="exercise"
              [roleItem]="roleItem"></timeline-inject-item>

          </div>

        </div>
      </div>

    </div>
  </div>
</div>
