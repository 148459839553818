import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import gsap from 'gsap';
import { ApiService } from 'src/app/services/api.service';
import { AUTH_RESPONSE_TYPE, AUTH_VIEW_MODES, AuthResponse } from 'src/app/model/ap.response.model';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  
  @ViewChild('_content') _content!:ElementRef;
  
  VIEW_MODES = AUTH_VIEW_MODES
  
  email: string = ""
  viewMode: AUTH_VIEW_MODES = AUTH_VIEW_MODES.LOADING

  _currentCenter = 0

  constructor(private router:Router, private ui: UiService, private api: ApiService
    ,private storage: StorageService
  ) { }

  ngOnInit() {
    setTimeout(()=> {
      this.viewMode = AUTH_VIEW_MODES.SIGN_IN
    }, 200)
    this.ui.windowResize.subscribe(
      _newWindowSize => {
         if(this._currentCenter != _newWindowSize.height / 2){
           this.changeCenterY(_newWindowSize.height / 2);
         } 
      }
    )
    setTimeout(()=>{
      this.fadeIn();
    }, 1000)
  }
  
  changeCenterY(newCenterY:number){
    this._currentCenter =  (this.ui.currentScreenSize.height / 2) 
    // let newYPositionStart = (this.ui.currentScreenSize.height / 2) - 250 
    let newYPosition = (this.ui.currentScreenSize.height / 2) - 215
    gsap.to(this._content.nativeElement,
    { 'opacity':1, 'margin-top':newYPosition, duration:  0.35,  ease: 'Expo.easeOut', delay: 0.15});

  }

  fadeIn(){
    this._currentCenter =  (this.ui.currentScreenSize.height / 2) 
    // let newYPositionStart = (this.ui.currentScreenSize.height / 2) - 250 
    let newYPosition = (this.ui.currentScreenSize.height / 2) - 215
    gsap.to(this._content.nativeElement,
    { 'opacity':1, 'margin-top':newYPosition, duration:  0.35,  ease: 'Expo.easeOut', delay: 0.15});
  }

  fadeOut(){
    // this._currentCenter =  (ControllerService.currentScreenSize.height / 2) 
    // let newYPositionStart = (ControllerService.currentScreenSize.height / 2) - 190 
    // let newYPosition = (ControllerService.currentScreenSize.height / 2) - 100
    // TweenMax.fromTo(this._content.nativeElement, 0.35, { 'opacity':1 , 'margin-top':newYPositionStart}, 
    // { 'opacity':0, 'margin-top':newYPosition, ease: Expo.easeOut, });
  }

  async SignIn(email: string, password: string) {
    const _email = email.trim().toLowerCase()
   const r = await this.signIn(_email, password)
   const authR = r as AuthResponse;
  let responseMessage = ''
    if (authR.type == AUTH_RESPONSE_TYPE.ERROR_EMAIL_NOT_FOUND) {

      responseMessage = "Email was not found"

    } else if (authR.type == AUTH_RESPONSE_TYPE.ERROR_USER_BLOCKED) {

      responseMessage = "Account was blocked by admin"

    } else if (authR.type == AUTH_RESPONSE_TYPE.ERROR_WRONG_PASSWORD) {
      
      responseMessage = 'Wrong password'


    } else if (authR.type == AUTH_RESPONSE_TYPE.SUCCESS) {
      this.navigate('exercise-overview')


    } else if (authR.type == AUTH_RESPONSE_TYPE.NEED_NEW_PW) {
      
      this.viewMode = AUTH_VIEW_MODES.SET_NEW_PASSWORD
    }

    if (responseMessage.length > 0) {
      this.ui.snackEmitter.emit({
        panelClass: "customSnackWarn",
        duration: 5000, 
        message: responseMessage
      })
    }
    
    // this.viewMode = AUTH_VIEW_MODES.RESPONSE_MESSAGE
    // setTimeout(()=> {
    //   this.viewMode = AUTH_VIEW_MODES.SIGN_IN
    // }, 6000)

  //  if(r) {
  //   this.router.navigate([''])
  //  }
   
  }

  async lostPassword(email: string) {
    const r = await this.onRecoverPassword(email)
    const authR = r as AuthResponse;
    let responseMessage = ''

    if (authR.type == AUTH_RESPONSE_TYPE.ERROR_EMAIL_NOT_FOUND) {

      responseMessage = "Email was not found"

    } else if (authR.type == AUTH_RESPONSE_TYPE.ERROR_USER_BLOCKED) {
      
      responseMessage = "Account was blocked by admin"
      
    } else if (authR.type == AUTH_RESPONSE_TYPE.SUCCESS) {
      
      responseMessage = "Success. Please check your emails..."
      
    }
    
    this.ui.snackEmitter.emit({
      panelClass: "customSnackWarn",
      duration: 5000, 
      message: responseMessage
    })

    setTimeout(()=> {
      this.viewMode = AUTH_VIEW_MODES.SIGN_IN
    }, 1000)
  }


  async setNewPassword() {
    console.log(this.email);
    const r = await this.onSetNewPassword(this.email, this.firstPassword)
    const authR = r as AuthResponse;
    console.log(authR);
    let responseMessage = ''

    if (authR.type == AUTH_RESPONSE_TYPE.ERROR_EMAIL_NOT_FOUND) {

      responseMessage = "Email was not found"

    } else if (authR.type == AUTH_RESPONSE_TYPE.ERROR_USER_BLOCKED) {
      
      responseMessage = "Account was blocked by admin"
      
    } else if (authR.type == AUTH_RESPONSE_TYPE.SUCCESS) {
      // this.router.navigateByUrl('exercise-overview')
      this.navigate('exercise-overview')
      return
      
    } else if (authR.type == AUTH_RESPONSE_TYPE.NEED_NEW_PW) {
      
      this.viewMode = AUTH_VIEW_MODES.SET_NEW_PASSWORD
    }
    

    if (responseMessage.length > 0) {
      this.ui.snackEmitter.emit({
        panelClass: "customSnackWarn",
        duration: 5000, 
        message: responseMessage
      })
    }
  }

    items = `1 symbol (# $ @ & * ! %)`

  navigate(path:string){
    this.fadeOut()
    setTimeout(()=>{
      this.router.navigateByUrl(path)
    },650)
  }
  charLengthOk = false
  specialCharOk = false
  upperCaseCharOK = false
  firstPassword:string = ''
  secondPassword:string = ''
  saveNewPasswordEnabled = false
  passwordChanged(e: any){
    this.upperCaseCharOK = this.hasUpperCase(e)
    this.specialCharOk = this.hastSpecialChar(e)
    this.charLengthOk = e.length > 7;
    this.checkValidation()
  }

  secondPasswordChanged(e: any){
    this.checkValidation()
  }
  private hastSpecialChar(str: any) {
    return (/[#,$,@,&,*,!,%]/.test(str));
  }
  private hasUpperCase(str: any) {
    return (/[A-Z]/.test(str));
  }
  checkValidation(){
    //this.emailFormControl.valid
     if(this.upperCaseCharOK &&
     this.specialCharOk &&
     this.charLengthOk && this.secondPassword === this.firstPassword) {
        this.saveNewPasswordEnabled = true;
      }
  }

  private async signIn(email: string, pw: string ) {
    const r = await this.api.apiPost('auth/', {
      email: email, 
      password: pw
    });
    const authR = r as AuthResponse;
    console.log(authR);
    if (authR.type == AUTH_RESPONSE_TYPE.NEED_NEW_PW || authR.type == AUTH_RESPONSE_TYPE.SUCCESS) {
      if(authR.user && authR.token) {
        this.storage.initUser(authR.user, {token: authR.token})
      }
    }
    // if (authR.token && (authR.type == AUTH_RESPONSE_TYPE.SUCCESS)) {
    //   this.storage.saveToken({token: authR.token})
      
    // } else if (authR.token && (authR.type == AUTH_RESPONSE_TYPE.NEED_NEW_PW || authR.type == AUTH_RESPONSE_TYPE.SUCCESS)) {
    //   this.storage.saveToken({token: authR.token})
    // }
      return authR


    
  }
  private async onSetNewPassword(email: string, pw: string ) {
    const r = await this.api.apiPost(`auth/set-new-password`, {
      email: email, 
      password: pw
    })
    const authR = r as AuthResponse;
    if (authR.type == AUTH_RESPONSE_TYPE.NEED_NEW_PW || authR.type == AUTH_RESPONSE_TYPE.SUCCESS) {
      if(authR.user && authR.token) {
        this.storage.initUser(authR.user, {token: authR.token})
      }
    }
    // if (authR.token && (authR.type == AUTH_RESPONSE_TYPE.SUCCESS)) {
    //   this.storage.saveToken({token: authR.token})
      
    // } else if (authR.token && (authR.type == AUTH_RESPONSE_TYPE.NEED_NEW_PW)) {
    //   this.storage.saveToken({token: authR.token})
    // }
    return authR


    
  }
  async onRecoverPassword(email: string): Promise<AuthResponse> {
    const r = await this.api.apiPost(`auth/lost-password`, {
      email: email, 
    })
    const authR = r as AuthResponse;
    return authR
  }
}
