import { Component, EventEmitter, Input, Output } from '@angular/core';
import { iCompanyInject } from 'src/app/model/company.model';

@Component({
  selector: 'app-admin-inject-detail',
  templateUrl: './admin-inject-detail.component.html',
  styleUrls: ['./admin-inject-detail.component.scss']
})
export class AdminInjectDetailComponent {
  @Input() inject: iCompanyInject | undefined
  @Output() onSave: EventEmitter<iCompanyInject> = new EventEmitter<iCompanyInject>();
  @Input() errorCopy: string = ""
  @Input() injectNoHasError: boolean = false
  isSaving = false
  disabled = false
  isMainInfos = true

  injectNoKeyDown(e:any) {
    this.injectNoHasError = false
    this.errorCopy = ''
  }

  saveItem() {
    this.onSave.emit(this.inject)
  }
  close() {
    
  }
}
