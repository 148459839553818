import { IToggleButtonItem } from "src/app/components/toggle-button/toggleButtonItem.model";
import { trainerFeedbackTypeToString, trainerFeedbackVoteTypeToString } from "./functions";
import { TrainerFeedbackType, TrainerFeedbackVoteType } from "src/app/model/trainerFeedback.model";

export function TrainerfeedbackVoteBtns() : IToggleButtonItem[] {
  return [
    { id: 0, title: trainerFeedbackTypeToString(TrainerFeedbackType.BEST_PRACTISE), preTitle:"(0) " },
    { id: 1, title: trainerFeedbackTypeToString(TrainerFeedbackType.LESSONS_IDENTIFIED),  preTitle:"(0) " },
    { id: 2, title: trainerFeedbackTypeToString(TrainerFeedbackType.GENERAL_OBSERVATION), preTitle:"(0) "  },
  ];
}


export function TrainerfeedbackRateBtns() : IToggleButtonItem[] {
  return [
    { id: 0, title: trainerFeedbackVoteTypeToString(TrainerFeedbackVoteType.NOT_RATED), enum: TrainerFeedbackVoteType.NOT_RATED },
    { id: 1, title: trainerFeedbackVoteTypeToString(TrainerFeedbackVoteType.BELOW), enum: TrainerFeedbackVoteType.BELOW },
    { id: 2, title: trainerFeedbackVoteTypeToString(TrainerFeedbackVoteType.MET), enum: TrainerFeedbackVoteType.MET},
    { id: 3, title: trainerFeedbackVoteTypeToString(TrainerFeedbackVoteType.ABOVE), enum: TrainerFeedbackVoteType.ABOVE }
  ];
}
// export default TrainerfeedbackVoteBtns;
// export default TrainerfeedbackRateBtns;

export const instanceOf = <T>(value: any, fieldName: string): value is T =>
  fieldName in value;
