export const environment = {
  production: true,
  endpoints:{
    apiBasePath: "https://ksk-ect-nest-api-dev-447485609164.europe-west3.run.app/",
    SOCKET_ENDPOINT: 'https://ksk-ect-nest-api-dev-447485609164.europe-west3.run.app/exercise',
    // PUSH_ENDPOINT: 'https://europe-west3-ksk-ect.cloudfunctions.net/pushMessages-1/',
    PUSH_ENDPOINT: 'https://pushmessages-dev-447485609164.europe-west3.run.app/',
    // PUSH_ENDPOINT: 'https://europe-west3-ksk-ect.cloudfunctions.net/pushMessage/',
    // PUSH_ENDPOINT2: 'https://europe-west3-ksk-ect.cloudfunctions.net/pushMessages/'
    // PUSH_ENDPOINT2: 'https://europe-west3-ksk-ect.cloudfunctions.net/pushMessages-1/'
  },
};

//// /cloudsql//ksk-ect:europe-west3:ect-root
