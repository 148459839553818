import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { StorageService } from "../../services/storage.service";
import { ExerciseUserRole, IExerciseUser } from "src/app/model/exercise.model";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "user-table-row",
  template: `
    <div class="d-flex align-items-center tbodyTR">
      <div class="p-0 pl10" style="width: calc((100% - 360px) / 2);">
        {{ user.name }}
      </div>
      <div class="p-0 borderRight flex-fill pl10" style="min-width: 220px;">{{ user.email }}</div>
      <div class="p-0 borderRight ml-auto w120 userRoleBtn tlc" [matMenuTriggerFor]="menu"
      [class.userRoleBtnDisabled]="currentUserId && currentUserId == user.userId">
      {{ user.userRole }}
      </div>
      <mat-menu #menu="matMenu" >
            <button mat-menu-item *ngFor="let role of exerciseUserRoles" (click)="selectRole(role)">
              <span>{{role}}</span>
            </button>
            </mat-menu>
      <div class="p-0 borderRight tlc w120">{{ user.userState }}</div>

      <div class="p-0 w40 borderRight tlc">
        <mat-icon class="dxfx_icon_btn_mini_circ"
        matTooltip="Edit User"
        matTooltipClass="navTT"
        *ngIf="currentUserId && currentUserId != user.userId" (click)="editUserTapped()">edit</mat-icon>
      </div>
      <div class="p-0 w40 borderRight tlc">
        <mat-icon
        matTooltip="Send invite email"
        matTooltipClass="navTT"
          *ngIf="user.userState == 'DRAFT'"
          class="dxfx_icon_btn_mini_circ"
          [class.DISABLED]="inputDisabled"
          (click)="inviteUserForExTapped()"
          >share</mat-icon
        >
      </div>
      <div class="p-0 w40 borderRight tlc" *ngIf="currentUserId && currentUserId != user.userId">
        <mat-icon
        matTooltip="Remove User"
        matTooltipClass="navTT"
          *exerciseAccess='[EXERCISE_USER_ROLES.ADMIN]'
          class="dxfx_icon_btn_mini_circ"
          [class.DISABLED]="inputDisabled"
          (click)="remoceUserFromExTapped()"
          >person_off</mat-icon
        >
      </div>
      <div class="p-0 w40 borderRight tlc" *ngIf="!currentUserId || currentUserId == user.userId"></div>
    </div>
  `,
  styleUrls: ["./exercise-users-view.component.scss"],
})
export class UserTableRowComponent implements OnInit, OnDestroy {
  @Input() user!: IExerciseUser;
  @Input() currentUserId: string  | undefined
  @Output() onInviteUserForEx = new EventEmitter<IExerciseUser>();
  @Output() onEditUser = new EventEmitter<IExerciseUser>();
  @Output() onRemoveUser = new EventEmitter<IExerciseUser>();
  // uRoleSelected: any;
  constructor(public storage: StorageService, private api: ApiService) {}
  inputDisabled = false;
  EXERCISE_USER_ROLES = ExerciseUserRole;
  exerciseUserRoles: ExerciseUserRole[] = [
    ExerciseUserRole.ADMIN,
    ExerciseUserRole.EXCON,
    ExerciseUserRole.OBSERVER,
    ExerciseUserRole.TRAINER,
  ];
  ngOnDestroy(): void {}
  ngOnInit(): void {}
  inviteUserForExTapped() {
    this.onInviteUserForEx.emit(this.user);
  }
  editUserTapped() {
    this.onEditUser.emit(this.user);
  }
  // async onURoleChanged(e: any) {
  //   if (this.inputDisabled) {
  //     return;
  //   }
  //   console.log(e);
  //   this.uRoleSelected = e.value; //.toString()
    // const dto = {
    //   userRole: this.uRoleSelected
    // }
    // console.log(dto);
  // }
  remoceUserFromExTapped() {
    this.onRemoveUser.emit(this.user);
  }

  async selectRole(e: ExerciseUserRole) {
    if (this.inputDisabled) {
      return;
    }
    this.user.userRole = e;
    const dto = {
      userRole: this.user.userRole
    }
    console.log(dto);
    await this.api.apiPatch('user/change-role', this.user.id.toString() ,dto)
  }
}
