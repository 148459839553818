<mat-toolbar class="overlay-toolbar">
  
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="bgDarg pt5" style="height: calc(100% - 93px);">

  <div class="d-flex flex-column h100">

    <div class="p-2 pt0 pb0">
      <input type="text" class="formControl textfield100 textFieldH40" style="width: 300px;"
  placeholder="Search..."  (keyup)="applyFilter($event)" #input
  [(ngModel)]="searchTxt"  (ngModelChange)='onSearch($event)'>
    </div>
    <div class="p-0" style="height:calc(100% - 0px); overflow: hidden;">
      <!-- *ngIf="logDataSource" -->



      <div class="scroller" style="height: 100%; overflow-y:scroll;">

        <table mat-table [dataSource]="logDataSource" matSort style="width: 100%; border-radius: 0px; ">

          <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w180p"><span class='pl10'>TEAM</span></th>
            <td mat-cell *matCellDef="let element" class="w180p">
              <span class='pl10' *ngIf="element.team">{{element.team}}</span>
            </td>
          </ng-container>


          <ng-container matColumnDef="injectTitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w250"><span class='pl10'>INJECT TITLE</span>
            </th>
            <td mat-cell *matCellDef="let element" class="w250">
              <span class='pl10' *ngIf="element.injectTitle">
                {{element.injectTitle}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="inject_no">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w180p"><span class='pl10'>INJECT ID</span></th>
            <td mat-cell *matCellDef="let element" class="w180p">
              <span class='pl10' *ngIf="element.inject_no">
                {{element.inject_no}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><span class='pl10'>COMMENT</span></th>
            <td mat-cell *matCellDef="let element">
              <span class='pl10' *ngIf="element.comment">
                {{element.comment}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="itemType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w180p" style="width: 180px;"><span
                class='pl10'>FEEDBACK TYPE</span>
            </th>
            <td mat-cell *matCellDef="let element" class="w180p">
              {{element.itemType}}
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w180p"><span class='pl10'>TIMESTAMP</span></th>
            <td mat-cell *matCellDef="let element" class="w180p">
              <span class='pl10'>{{element.createdAt | date: 'MM/dd/yyyy, HH:mm'}}</span>
              <!-- <span class='pl10'>{{element.createdAt | date:'short':'UTC +0'}}</span> -->
            </td>
          </ng-container>



          <ng-container matColumnDef="author" justify='end'>
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w180p"><span class='pl10'>TRAINER</span></th>
            <td mat-cell *matCellDef="let element" class="w180p">
              <span class='pl10' *ngIf="element.author">{{element.author}}</span>
            </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="dispCols; sticky: true"></tr>
          <tr mat-row class="element-row pointer" *matRowDef="let row; columns: dispCols;">
          </tr>
        </table>
      </div>
    </div>


  </div>

</div>

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <!-- <ect-color-btn class="mr5" title="Save" icon="save" color="primary" [disabled]="message == null || message == ''"
    (clicked)="save()"></ect-color-btn> -->
  <!-- <ect-color-btn class="mr5" title="Save" icon="save" color="primary" [disabled]="saveDisabled" (clicked)="save()" ></ect-color-btn> -->
</mat-toolbar>