import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IToggleButtonItem } from './toggleButtonItem.model';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent {
  
  @Input() model!: IToggleButtonItem;
  @Input() active!: boolean;
  @Input() disabled: boolean = false;
  
  @Output() selected = new EventEmitter<IToggleButtonItem>();

  onTapped() {
    this.selected.emit(this.model);
  }
}
