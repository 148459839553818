<div class="p-0 " *ngIf="!iconOnly">
  <div class="d-flex align-items-center dxfx-icon-button pr0" (click)='tapped()' 
  [class.primary-colors]="color === 'primary' "
  [class.accent-colors]="color === 'accent' "
  [class.warn-colors]="color === 'warn' "
  [class.dxfx-icon-button-disabled]="disabled"
  >
    <div class="p_2 align-text-end  pr0 mr0">
      <mat-icon class="nav-icon-mini pr0 mr0" [class.nav-icon-mini-disabled]="disabled">{{icon}}</mat-icon>
    </div>
    <div class="p-2 dxfx-icon-button-copy align-text-start p_b5 pl0 ml0 pr12 uc">{{title}}</div>
  </div>
</div>

<div class="p-0 icon-btn-logout" [class.circular]="circular" *ngIf="iconOnly">
  <mat-icon class="icn" (click)='tapped()' [matTooltip]='title' matTooltipClass='navigationTT'>{{icon}}</mat-icon>
</div>