<div class="d-flex flex-column" *ngIf="show">
  <div class="p-2">
      {{itemType}}
    </div>

  <div class="p-2">
    <div class="d-flex">
      
      <div class="p-2" style="width: 50%;">
        <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
          <mat-select [(value)]='itemType'  (selectionChange)='onChanged($event)' 
          class="font-main">
          <mat-option *ngIf="!itemType">------</mat-option>
            <mat-option *ngFor="let iTypr of itemTypes" [value]="iTypr">
              {{iTypr}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <select [(ngModel)]='itemType'  class="font-dark select-cg"  (change)="onChanged($event)">
          <option *ngIf="!itemType">------</option>
          <option *ngFor="let iTypr of itemTypes" [value]="iTypr">
            {{iTypr}}
          </option>
        </select> -->
        
      </div>
      <div class="p-2" style="width: 50%;">
        <div class="formGroup pb20" *ngIf="teamRoleSelected">
          <input type="text" class="formControl" placeholder="Custom name..." [(ngModel)]="timelineName"   [class.formControlError]="roleNameError">
        </div>
      </div>
    </div>
  </div>
</div>



<div class="p-2">
  <div class="p-2">
    <ect-color-btn title="Save" icon="save" color="primary" [disabled]="saveBtnDisabled" (clicked)="save()" ></ect-color-btn>
  </div>
</div>