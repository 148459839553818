import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  IExerciseTimelineInjectRef,
  TacticalTime,
} from "src/app/model/exercise.model";
import { TIME } from "src/app/services/helpers";
import { UiService } from "src/app/services/ui.service";

@Component({
  selector: "app-injects-toolbar-action-item",
  templateUrl: "./injects-toolbar-action-item.component.html",
  styleUrls: ["./injects-toolbar-action-item.component.scss"],
})
export class InjectsToolbarActionItemComponent {
  @Input() inject!: IExerciseTimelineInjectRef;
  // @Input() exerciseDocId:string
  // @Output() comfirmed:EventEmitter<IExerciseTimelineInjectRef> = new EventEmitter<IExerciseTimelineInjectRef>();

  confirmBtnCopy: string = "show more";
  changeTime: boolean = false;
  tacticalTime: TacticalTime = TIME.date_lcoal_to_tt(new Date());

  tickInterval: number = 1;
  max = 100;
  min = 0;
  step = 1;
  selectedTimeItem: string = "";
  startSliderVal = 0;
  showTimeEditCursor: boolean = true;
  showDeleteButton: boolean = false;
  constructor(public ui: UiService, private dialog: MatDialog) {}

  ngOnInit(): void {
    // this.tacticalTime = _.cloneDeep(this.inject.deliveryDateTime)
    // if(this.ui.currentUserAccess != null && this.ui.currentUserAccess.role != null){
    //   switch (this.ui.currentUserAccess.role) {
    //     case ROLES.Role.EXCON:
    //         if(this.inject.state.value === InjectStates.DRAFT){
    //           // this.confirmBtnCopy ='set planned'
    //           this.confirmBtnCopy ='set next status'
    //         } else if(this.inject.state.value === InjectStates.PLANNED){
    //           this.confirmBtnCopy ='start inject'
    //         }
    //       break;
    //       case ROLES.Role.ADMIN:
    //         this.showDeleteButton = true;
    //         if(this.inject.state.value === InjectStates.DRAFT){
    //           // this.confirmBtnCopy ='set planned'
    //           this.confirmBtnCopy ='set next status'
    //         } else if(this.inject.state.value === InjectStates.PLANNED){
    //           this.confirmBtnCopy ='start inject'
    //         }
    //       break;
    //     case ROLES.Role.MAIN_TRAINER:
    //         if(this.inject.state.value === InjectStates.REQUESTED){
    //           this.confirmBtnCopy ='set next status'
    //         } else if(this.inject.state.value === InjectStates.RUN){
    //           // this.confirmBtnCopy ='set delivered'
    //           this.confirmBtnCopy ='set next status'
    //         }
    //       break;
    //       case ROLES.Role.Roleplay_Coordinator:
    //         if(this.inject.state.value === InjectStates.REQUESTED){
    //           this.confirmBtnCopy ='set next status'
    //         } else if(this.inject.state.value === InjectStates.RUN){
    //           // this.confirmBtnCopy ='set delivered'
    //           this.confirmBtnCopy ='set next status'
    //         }
    //       break;
    //       case ROLES.Role.TEAM_TRAINER:
    //         if(this.inject.state.value === InjectStates.REQUESTED){
    //           this.confirmBtnCopy ='set next status'
    //         } else if(this.inject.state.value === InjectStates.RUN){
    //           // this.confirmBtnCopy ='set delivered'
    //           this.confirmBtnCopy ='set next status'
    //         }
    //       break;
    //     default:
    //       break;
    //   }
    // }
    // if(this.inject.state.value === InjectStates.RUN || this.inject.state.value === InjectStates.DELIVERED || this.inject.state.value === InjectStates.CANCELED){
    //   this.showTimeEditCursor = false;
    // }
  }
  deleteInject() {
    // let alertData:AlertOverlayDataI = {
    //   alertTitle:'ALERT',
    //   alertMessage:"Are you sure you want to delete this inject?"
    // }
    // this.dialog.open(AlertOverlayComponent,{
    //   minWidth: "15px",
    //   minHeight: "15px",
    //   disableClose:true,
    //   backdropClass:"backdrop-background",
    //   data:alertData
    // }).afterClosed().subscribe(
    //   (_resultAction:AlertOverlayResponeAction) => {
    //     if(_resultAction != null){
    //       switch(_resultAction){
    //         case AlertOverlayResponeAction.CANCEL:
    //           console.log("CANCEL")
    //           break;
    //         case AlertOverlayResponeAction.RESUME:
    //           console.log("DELETE")
    //           this.deleteAssignedInject()
    //           break;
    //       }
    //     }
    //   }
    // )
  }
  async deleteAssignedInject() {
    //this.fire.deleteAssignedInjectForExercise(this.inject, this.exerciseDocId);
  }
  getTextColorForInject(inject: IExerciseTimelineInjectRef): string {
    // return"#ffffff"
    return "#1E2A33";
  }
  confirm() {
    console.log("confirm");

    // this.comfirmed.emit(this.inject)
  }
  loadTeamName(): boolean {
    // if(this.inject.delivery_for_team != null){
    //   if(this.allRoles.includes(this.inject.delivery_for_team as ROLES.Role)){
    //     return true
    //   }
    //   // console.log(typeof(this.inject.delivery_for_team))
    // }
    return false;
  }
  openDetail(e: any) {
    e.stopPropagation();
    e.preventDefault();
    if (this.inject) {
      this.ui.openInjectDetailOverlay(this.inject);
    }
  }
  get showLocBtn(): boolean {
    if (this.ui.isMapVisible) {
      return this.inject.location != null;
    } else {
      return true;
    }
  }
  showOnMap(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.ui.injectAnimation.emit(this.inject);
    // if(this.ui.isMapVisible){
    //   // console.log(this.ui.locationsObjects)
    //   // console.log(this.inject.related_site_location)
    //   const loc = this.inject.related_site_location != null && this.inject.related_site_location != '' ? this.ui.locationsObjects.find(item => item.documentId == this.inject.related_site_location) : null
    //   if(loc){
    //     this.ui.showMarkerLocationEmitter.emit(loc)
    //   }
    // } else {
    //   this.ui.timelineInjectAnimateForInject.emit(this.inject)
    // }
  }
  /* formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  } */
  sliderChanged(e: any) {
    // if(this.selectedTimeItem == null) return
    // if(this.selectedTimeItem === "DAY"){
    //   this.tacticalTime.day = e.value < 10 ? '0'+ e.value.toString() : e.value.toString();
    // } else if(this.selectedTimeItem === "HOURS"){
    //   this.tacticalTime.hours = e.value < 10 ? '0'+ e.value.toString() : e.value.toString();
    // } else if(this.selectedTimeItem === "MINUTES"){
    //   this.tacticalTime.minutes = e.value < 10 ? '0'+ e.value.toString() : e.value.toString();
    // }
  }

  changeTimeFor(item: string) {
    // if(this.inject.state.value === InjectStates.RUN || this.inject.state.value === InjectStates.DELIVERED || this.inject.state.value === InjectStates.CANCELED){
    //   this.changeTime = false;
    //   if(this.ui.currentUserRole != ROLES.Role.ADMIN){
    //     return
    //   }
    // }
    // this.changeTime = true;
    // this.selectedTimeItem = item;
    // let _daysInMonth = this.ui.exerciseDays.filter(item => item.month.index == this.tacticalTime.month.index)
    // console.log('this.tacticalTime ', this.tacticalTime)
    // console.log("this.ui.exerciseDays ", this.ui.exerciseDays)
    // console.log('_daysInMonth ',_daysInMonth)
    // // let _dayFromDaysInMonth = _daysInMonth.find(item => item.day == this.tacticalTime.day)
    // // console.log('_dayFromDaysInMonth ', _dayFromDaysInMonth)
    // // console.log('min hours ', _dayFromDaysInMonth.hours)
    // // console.log('min hours ', parseInt(_dayFromDaysInMonth.hours))
    // const _minDay = _.minBy(_daysInMonth,  function(o:TacticalTime) { return parseInt(o.day) }).day;
    // const _maxDay = _.maxBy(_daysInMonth,  function(o:TacticalTime) { return parseInt(o.day) }).day;
    // // console.log('_.minBy(_daysInMonth, day) ',_.minBy(this.ui.exerciseDays, 'day'));
    // let _isLastDay = this.ui.lastExerciseDay.day == this.tacticalTime.day && this.ui.lastExerciseDay.month.index == this.tacticalTime.month.index
    // // console.log('_isLastDay ' ,_isLastDay);
    // let _isFirstDay = this.ui.firstExerciseDay.day == this.tacticalTime.day && this.ui.firstExerciseDay.month.index == this.tacticalTime.month.index
    // // console.log('_isFirstDay ' ,_isFirstDay);
    // console.log('_minDay ', _minDay)
    // const _maxHours = _isLastDay ? parseInt(this.ui.lastExerciseDay.hours) : 23//_.maxBy(_daysInMonth, 'hours').hours
    // const _minHours = _isFirstDay ? parseInt(this.ui.firstExerciseDay.hours) : 0//_.minBy(_daysInMonth, 'hours').hours
    // console.log('_maxDay ',_maxDay)
    // // console.log("_minHours ",_minHours)
    // // console.log('_maxHours ',_maxHours)
    // console.log('_minDay ' + _minDay + ' _maxDay ' + _maxDay + " _minHours " + _minHours + ' _maxHours ' +_maxHours)
    // // console.log('lastDay ', this.ui.lastExerciseDay)
    // if(item === "DAY"){
    //   this.min = parseInt(_minDay);
    //   this.max = parseInt(_maxDay);
    //   this.step = 1;
    //   this.startSliderVal = parseInt(this.tacticalTime.day)
    // } else if(item === "HOURS"){
    //   this.min = _minHours;// _dayFromDaysInMonth != null && _dayFromDaysInMonth.hours != null ? parseInt(_dayFromDaysInMonth.hours) : 0;
    //   this.max = _maxHours;
    //   this.step = 1;
    //   this.startSliderVal = parseInt(this.tacticalTime.hours)
    // } else if(item === "MINUTES"){
    //   this.min = 0;
    //   this.max = 59;
    //   this.step = 5;
    //   this.startSliderVal = parseInt(this.tacticalTime.minutes)
    // }
  }
  saveTimeEditing() {
    // this.inject.deliveryDateTime = _.cloneDeep(this.tacticalTime)
    // this.ui.saveInjectEmitter.emit(this.inject)
    // this.changeTime = false;
    // this.selectedTimeItem = null
  }
  resetTimeEditing() {
    // this.tacticalTime.day = this.inject.deliveryDateTime.day
    // this.tacticalTime.minutes = this.inject.deliveryDateTime.minutes
    // this.tacticalTime.hours = this.inject.deliveryDateTime.hours
    // this.changeTime = false;
    // this.selectedTimeItem = null
  }
}
