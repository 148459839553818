import {
  TrainerFeedbackType,
  TrainerFeedbackVoteType,
} from "src/app/model/trainerFeedback.model";
import * as _ from "lodash";
import { InjectExportAssignedInject } from "../excel.service";
import {
  exerInjectTrainingObjectivesToExportString,
  HourItemI,
  HourItemIState,
  IExerciseTimelineInjectRef,
  injectTrainingObjectivesToExportString,
  IUserRole,
} from "src/app/model/exercise.model";
import { UTCDate } from "@date-fns/utc";
import {
  addHours,
  differenceInDays,
  differenceInHours,
  isSameDay,
} from "date-fns";
import { TIME } from "../helpers";
import { iCompany, iExerciseInject } from "src/app/model/company.model";
import { iExerciseTrainingObjective } from "src/app/model/trainingObj.model";
import { IusrRoleLocal, iCompanyLocal } from "src/app/pages/admin/models";

function getTTDate(a?: number): Date {
  return a ? new Date(a) : new Date();
}

export default getTTDate;

export function DATE_TO_STRING(): string {
  let date = new Date();
  let MM: number = date.getMonth() + 1;
  let DAY: number = date.getDate();

  let _d: string = DAY < 10 ? "0" + DAY.toString() : DAY.toString();
  let _m: string = MM < 10 ? "0" + MM.toString() : MM.toString();

  let _h = date.getHours();
  let _min = date.getMinutes();

  return `${_d}.${_m}.${date.getFullYear()} ${_h}:${_min}`;
}

export function wait(duration: number, callback: () => void): void {
  setTimeout(() => {
    // console.warn('done ...');
    callback();
  }, duration);
}
export function isNegative(duration: number): boolean {
  return duration < 0;
}
export const texteditor_cleanup: object = {
  prompt: false,
  plainText: false,
  keepFormat: false,
  deniedTags: [
    "a",
    "span",
    "p",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H6",
  ],
  deniedAttrs: ["class", "title", "id", "dir", "style"],
  allowedStyleProps: [],
};
export const toolbar_tools_NACHBEHANDLUNG: object = {
  /* ,'SourceCode' */
  items: ["Undo", "Redo", "Bold", "Italic", "UnorderedList", "|", "CreateLink"],
  // ,  'ClearFormat', 'SourceCode'
};

export function trainerFeedbackTypeToString(type: TrainerFeedbackType): string {
  return _.replace(type.toString(), "_", " ");
}
export function trainerFeedbackTypeFromString(
  str: string
): TrainerFeedbackType {
  return _.replace(str, " ", "_") as TrainerFeedbackType;
}
export function trainerFeedbackVoteTypeToString(
  type: TrainerFeedbackVoteType
): string {
  return _.replace(type.toString(), "_", " ");
}
export function trainerFeedbackVoteTypeFromString(
  str: string
): TrainerFeedbackVoteType {
  return _.replace(str, " ", "_") as TrainerFeedbackVoteType;
}

export function convertInject(
  element: IExerciseTimelineInjectRef
): InjectExportAssignedInject {
  // const regex = /<p>/gi;
  // console.log(paragraph.replaceAll(regex, 'ferret'));

  return {
    inject_no: element.inject_no!,
    short_title: element.short_title!,
    team: element.timelineItem!.title,
    deliveryDateTime: element.deliveryDateTime.toString(),
    deliveryMethod: element.deliveryMethod,
    description: element.description ? stripHtmlTags(element.description) : "",
    //
    // description: element.description ? element.description.replace(/<[^>]*>,&nbsp;/g, '') : '',
    related_documents: element.related_documents
      ? stripHtmlTags(element.related_documents)
      : "",
    related_equipment: element.related_equipment
      ? stripHtmlTags(element.related_equipment)
      : "",
    role_players_instruction: element.role_players_instruction
      ? stripHtmlTags(element.role_players_instruction)
      : "",
    on_scene_preparation: element.on_scene_preparation
      ? stripHtmlTags(element.on_scene_preparation)
      : "",
    additional_guidance_caution_options:
      element.additional_guidance_caution_options
        ? stripHtmlTags(element.additional_guidance_caution_options)
        : "",
    expected_outcomes: element.expected_outcomes
      ? stripHtmlTags(element.expected_outcomes)
      : "",
    trainers_action_requirement: element.trainers_action_requirement
      ? stripHtmlTags(element.trainers_action_requirement)
      : "",
    objectives: injectTrainingObjectivesToExportString(element),
    // element.trainingObjectives ? stripHtmlTags(element.trainingObjectives) : '',
  };
}
export function convertExInject(
  element: iExerciseInject,
  trainObts: iExerciseTrainingObjective[]
): InjectExportAssignedInject {
  // const regex = /<p>/gi;
  // console.log(paragraph.replaceAll(regex, 'ferret'));

  const exerInjectTrainingObjectivesToExportString = () => {
    if (element.learning_objectives && element.learning_objectives.length > 0) {
      let out = "";
      let arr = element.learning_objectives.split(",");
      arr.forEach((item) => {
        const txtItem = trainObts.find((el) => el.id == parseInt(item));
        if (txtItem && txtItem.title) {
          out += `${txtItem.title}\n`;
        }
      });
      return out;
    } else {
      return "";
    }
  };
  return {
    inject_no: element.inject_no!,
    short_title: element.short_title!,
    team: "",
    deliveryDateTime: "",
    deliveryMethod: element.deliveryMethod,
    description: element.description ? stripHtmlTags(element.description) : "",
    //
    // description: element.description ? element.description.replace(/<[^>]*>,&nbsp;/g, '') : '',
    related_documents: element.related_documents
      ? stripHtmlTags(element.related_documents)
      : "",
    related_equipment: element.related_equipment
      ? stripHtmlTags(element.related_equipment)
      : "",
    role_players_instruction: element.role_players_instruction
      ? stripHtmlTags(element.role_players_instruction)
      : "",
    on_scene_preparation: element.on_scene_preparation
      ? stripHtmlTags(element.on_scene_preparation)
      : "",
    additional_guidance_caution_options:
      element.additional_guidance_caution_options
        ? stripHtmlTags(element.additional_guidance_caution_options)
        : "",
    expected_outcomes: element.expected_outcomes
      ? stripHtmlTags(element.expected_outcomes)
      : "",
    trainers_action_requirement: element.trainers_action_requirement
      ? stripHtmlTags(element.trainers_action_requirement)
      : "",
    objectives: exerInjectTrainingObjectivesToExportString(),
    // element.trainingObjectives ? stripHtmlTags(element.trainingObjectives) : '',
  };
}
//const stripHTMLTags = str => str.replace(/<[^>]*>/g, '');

export function stripHtmlTags(str: string): string {
  // .replaceAll("<br>" , " CHAR(10)")
  return str
    .replace(/(<([^>]+)>)/gi, "")
    .replaceAll("&nbsp;", " ")
    .replaceAll("&amp;", "&");
}

export function getExerciseDays(
  dateTimeStarted: number,
  durationInHours: number
): HourItemI[] {
  var out: HourItemI[] = [];
  let _exerDateStart = new UTCDate(Number(dateTimeStarted));
  let _exerDateEnd = new UTCDate(addHours(_exerDateStart, durationInHours));
  const days = differenceInDays(_exerDateEnd, _exerDateStart);
  const hoursCount = differenceInHours(_exerDateEnd, _exerDateStart);
  let startHour = _exerDateStart.getUTCHours();
  const hours1 = differenceInHours(TIME.endOf(_exerDateStart), _exerDateStart);

  var _currentStartHour = startHour;
  var _date: Date = _exerDateStart;
  var _lastAddedDate: Date = _date;
  var _posFromLeft = 0;

  var _dayIndex = -1;
  var _day: number = 0;
  var _month: number = 0;
  var _dayHour: number = 0;
  var _hourCountPerDay = 0;
  let _dateSting = "";
  for (var i = 0; i < hoursCount; i++) {
    let starMinutes = "00";

    let _startNewDate = false;
    _day = parseInt(TIME.date_to_tt(_date).day);
    _month = TIME.date_to_tt(_date).month.index;
    _dayHour = parseInt(TIME.date_to_tt(_date).hours);
    _dateSting = TIME.formatedDateStringWithoutYear(_date, "/");
    if (_currentStartHour == 0 || i == 0) {
      _lastAddedDate = _date;
      _startNewDate = true;
      _dayIndex++;
    } else {
    }

    _hourCountPerDay =
      // ist erster tag
      isSameDay(_date, _exerDateStart)
        ? differenceInHours(TIME.endOf(_exerDateStart), _exerDateStart) + 1
        : // ist letzter tag
        isSameDay(_date, _exerDateEnd)
        ? differenceInHours(_exerDateEnd, TIME.startOf(new UTCDate(_date)))
        : differenceInHours(
            TIME.endOf(new UTCDate(_date)),
            TIME.startOf(new UTCDate(_date))
          ) + 1;

    let _hourItem: HourItemI = {
      date: new Date(TIME.formatedCleanDate(_date).getTime()),
      day: _day,
      month: _month,
      dayHour: _dayHour,
      index: i,
      dayIndex: _dayIndex,
      hoursCount: _hourCountPerDay,
      startNewDate: _startNewDate,
      dateString: _dateSting,
      hour: _posFromLeft,
      hourString:
        (_currentStartHour < 10
          ? "0" + _currentStartHour.toString()
          : _currentStartHour.toString()) +
        ":" +
        starMinutes.toString(),
    };

    if (_currentStartHour < 23) {
      _currentStartHour++;
      // _hourCountPerDay ++;
    } else {
      _currentStartHour = 0;
      // _hourCountPerDay = 0;
    }
    _posFromLeft += 60;
    _date = new UTCDate(addHours(_date, 1));

    out.push(_hourItem);
  }
  return out;
}

export function newHourItem(
  index: number,
  dayIndex: number,
  date: Date,
  state: HourItemIState
): HourItemI {
  var _day: number = parseInt(TIME.date_to_tt(date).day);
  var _month: number = TIME.date_to_tt(date).month.index;
  var _dayHour: number = parseInt(TIME.date_to_tt(date).hours);
  return {
    date: new UTCDate(date.getTime()),
    // date: new UTCDate(date.getTime()),
    day: _day,
    month: _month,
    dayHour: _dayHour,
    index: index,
    dayIndex: dayIndex,
    hoursCount: 0,
    startNewDate: false,
    dateString: TIME.formatedDateStringWithoutYear(
      new UTCDate(date.getTime()),
      "/"
    ),
    hour: 0,
    hourString: (_dayHour < 10 ? `0${_dayHour}` : `${_dayHour}`) + ":00",
    state: state,
  };
}

export function usrRoleFix(usrR: IUserRole[]): IusrRoleLocal[] {
  var out: IusrRoleLocal[] = [];
  for (const element of usrR) {
    const icon =
      element == IUserRole.ADMIN
        ? "engineering"
        : element == IUserRole.SUPER_ADMIN
        ? "admin_panel_settings"
        : "person";
    out.push({
      name: element,
      role: element,
      icon: icon,
    });
  }

  return out;
}
export function usrCompFix(usrC: iCompany[]): iCompanyLocal[] {
  var out: iCompanyLocal[] = [];
  for (const element of usrC) {
    const icon =
      element.role == "EDITOR"
        ? "engineering"
        : element.role == "ADMIN"
        ? "admin_panel_settings"
        : "person";
    out.push({
      id: element.companyId,
      name: element.name,
      role: element.role,
      icon: icon,
    });
  }
  // usrC.forEach(element => {
  //   const icon = element.role == ICompanyUserRoleType.EDITOR ? 'engineering' : ICompanyUserRoleType.ADMIN ? 'admin_panel_settings' : 'person'
  //    out.push({
  //     name: element.name,
  //     role: element.role,
  //     icon: icon
  //    })
  //   });
  return out;
}
