
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container-fluid bgDarg " style="height: calc(100% - 88px);">

  <div class="container d-flex flex-column">

    <div class="p-2 d-flex">

      <div class="p-2" *ngIf="selectedComp">
        Company: {{selectedComp.name}}
      </div>
  
      <div class="p-2 w33p ml-auto">
        <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
          <mat-select [(value)]='selectedComp'  (selectionChange)='onChanged($event)'  class="font-main">
            <mat-option *ngFor="let comp of companies" [value]="comp">
            {{comp.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>


    <div class="p-2">
      <mat-label class='mat-caption uc'>Name</mat-label>
      <input type="text" class="formControl textfield100 textFieldH40" placeholder=""
              [(ngModel)]="newExercise.name" >
    </div>

    <div class="p-2 ">
      <mat-label class='mat-caption uc'>Description</mat-label>
      <div class="formGroup textfield100">
        <textarea class="formControl" [(ngModel)]="newExercise.description" rows="5" cols="4" ></textarea>
      </div>
    </div>

    <!-- <div class="p-2 d-flex">

      <div class="p-2 w50p">
        <mat-label class='mat-caption uc'>Name</mat-label>
        <input type="text" class="formControl textfield100 textFieldH40" placeholder=""
                [(ngModel)]="newExercise.name" >
      </div>

      <div class="p-2 _br">
        <mat-label class='mat-caption uc'>Description</mat-label>
        <div class="formGroup textfield100">
          <textarea class="formControl" [(ngModel)]="newExercise.description" rows="5" cols="4" ></textarea>
        </div>
      </div>

      <div class="p-2 _br">
        <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
        <mat-select [(value)]='selectedComp'  (selectionChange)='onChanged($event)'  class="font-main">
          <mat-option *ngFor="let comp of companies" [value]="comp">
          {{comp.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>

    </div> -->

  </div>

</div>

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <ect-color-btn class="mr5" title="Save" icon="save" color="primary" [disabled]="saveDisabled" (clicked)="save()" ></ect-color-btn>
</mat-toolbar>
