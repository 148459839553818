import { Component, EventEmitter, HostListener, inject, Input, OnInit, Output } from '@angular/core';
import { DynamicItemDataType, INavigationListItem } from '../navigation-list-item/navigationListitem.model';
import { wait } from 'src/app/services/utils/functions';
import { ExerciseTimelineItemType, ExerciseUserRole, IExerciseTimelineInjectRef } from 'src/app/model/exercise.model';
import { iExerciseInject } from 'src/app/model/company.model';
import { DynamicItem } from 'src/app/model/dynamic.types';
import { InjectDetailOL_TextEditorComponent } from 'src/app/overlays/inject-detail-overlay/components/inject-detail-ol-texteditor.component';
import { StorageService } from 'src/app/services/storage.service';
import { InjectDetailOL_TrainerFeedbackComponent } from 'src/app/overlays/inject-detail-overlay/components/inject-detail-ol-trainerFeedback.component';
import { InjectDetailOL_TrainingObjectivesComponent } from 'src/app/overlays/inject-detail-overlay/components/inject-detail-ol-trainingObjective.component';
import { instanceOf } from 'src/app/services/utils/ui.utils';

@Component({
  selector: 'app-inject-detail-sidenav',
  templateUrl: './inject-detail-sidenav.component.html',
  styleUrls: ['./inject-detail-sidenav.component.scss']
})
export class InjectDetailSidenavComponent implements OnInit{

  @Input() injectModel: IExerciseTimelineInjectRef | iExerciseInject | undefined
  @Output() onNaviSelected = new EventEmitter<INavigationListItem>();

  private storage = inject(StorageService)

  sideNavItem: INavigationListItem | undefined
  sideNavItems: INavigationListItem[] = []

  // @HostListener('window:keydown.control.1', ['$event'])
  //   bigFont(event: KeyboardEvent) {
  //     event.preventDefault();
  //     this.selectNav(this.sideNavItems[0])

  //   }
  //   @HostListener('window:keydown.control.2', ['$event'])
  //   bigFon2(event: KeyboardEvent) {
  //     event.preventDefault();
  //     console.log(event);
  //     this.selectNav(this.sideNavItems[1])
  //   }

  //   @HostListener('window:keydown.control.3', ['$event'])
  //   bigFon3(event: KeyboardEvent) {
  //     event.preventDefault();
  //     this.selectNav(this.sideNavItems[2])
  //   }
  //   @HostListener('window:keydown.control.4', ['$event'])
  //   bigFon4(event: KeyboardEvent) {
  //     event.preventDefault();
  //     this.selectNav(this.sideNavItems[3])
  //   }
    @HostListener('window:keydown.control.1', ['$event'])
    @HostListener('window:keydown.control.2', ['$event'])
    @HostListener('window:keydown.control.3', ['$event'])
    @HostListener('window:keydown.control.4', ['$event'])
    @HostListener('window:keydown.control.5', ['$event'])
    @HostListener('window:keydown.control.6', ['$event'])
    @HostListener('window:keydown.control.7', ['$event'])
    @HostListener('window:keydown.control.8', ['$event'])
    @HostListener('window:keydown.control.9', ['$event'])
    @HostListener('window:keydown.control.0', ['$event'])
    onNavEventInput(event: KeyboardEvent) {
      event.preventDefault();
      // console.log(event.key);
      const k = parseInt(event.key)
      if ( k < this.sideNavItems.length ) {

        this.selectNav(this.sideNavItems[k])
      }
      // this.selectNav(this.sideNavItems[3])
    }
  ngOnInit(): void {

    if (this.injectModel) {

      this.initNavItems(this.injectModel)
      if(instanceOf<IExerciseTimelineInjectRef>(this.injectModel, "leftPos")) {
      } else if(instanceOf<iExerciseInject>(this.injectModel, "exercise_inject_id")) {

      } else {
        console.log(`ERROR LOADING MODEL`);
      }



      // this.initNavItems(this.timelineInjectRef)
    //   this.sideNavItems =
    //   [
    //     { id: 0, title: 'Description',  typeValue: 'description', dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.description ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //     { id: 1, title: 'EXPECTED OUTCOME', typeValue: 'expected_outcomes' , dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.expected_outcomes ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //     { id: 2, title: 'TRAINER FEEDBACK' ,typeValue: 'TRAINER_FEEDBACK',  dynComponent: new DynamicItem(InjectDetailOL_TrainerFeedbackComponent, this.timelineInjectRef.description ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //     // { id: 3, title: 'OBJECTIVES' ,typeValue: 'OBJECTIVES',  dynComponent: new DynamicItem(InjectDetailOL_TrainingObjectivesComponent, this.timelineInjectRef.learning_objectives ?? [],this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //     { id: 3, title: 'OBJECTIVES' ,typeValue: 'OBJECTIVES',  dynComponent: new DynamicItem(InjectDetailOL_TrainingObjectivesComponent, this.timelineInjectRef.trainingObjectives ?? [],this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //     { id: 4, title: 'ON SCENE PREPARATION AND EQUIPMENT' ,typeValue: 'on_scene_preparation',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.on_scene_preparation ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //     { id: 5, title: 'RELATED DOCUMENTS', typeValue: 'related_documents',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.related_documents ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //     { id: 6, title: 'TRAINERS ACTION REQUIRED' ,typeValue: 'trainers_action_requirement',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.trainers_action_requirement ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //     { id: 7, title: 'ROLE PLAY INSTRUCTIONS' ,typeValue: 'role_players_instruction',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.role_players_instruction ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //     { id: 8, title: 'ADDITIONAL GUIDANCE CAUTION OPTIONS ', typeValue: 'additional_guidance_caution_options' ,  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.additional_guidance_caution_options ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
    //   ]
    // } else  if (this.companyInjectTemplate) {
    //   // this.title = this.companyInjectTemplate.short_title
    //   // this.initNavItems(this.companyInjectTemplate)
    //   this.sideNavItems = [
    //     { id: 0, title: 'Description',  typeValue: 'description',
    //       dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.description ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.DESCRIPTION },
    //     { id: 1, title: 'EXPECTED OUTCOME', typeValue: 'expected_outcomes' , dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.expected_outcomes ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.EXPECTED_OUTCOME },
    //     // { id: 2, title: 'TRAINER FEEDBACK' ,typeValue: 'TRAINER_FEEDBACK',  dynComponent: new DynamicItem(InjectDetailOL_TrainerFeedbackComponent, this.timelineInjectRef.description ?? '')},
    //     // { id: 3, title: 'OBJECTIVES' ,typeValue: 'OBJECTIVES',  dynComponent: new DynamicItem(InjectDetailOL_TrainingObjectivesComponent, this.inject.trainingObjectives ?? [])},
    //     { id: 4, title: 'ON SCENE PREPARATION AND EQUIPMENT' ,typeValue: 'on_scene_preparation',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.on_scene_preparation ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.ON_SCENE_PREPARATION},
    //     { id: 5, title: 'RELATED DOCUMENTS', typeValue: 'related_documents',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.related_documents ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.RELATED_DOCUMENTS },
    //     { id: 6, title: 'TRAINERS ACTION REQUIRED' ,typeValue: 'trainers_action_requirement',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.trainers_action_requirement ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.TRAINERS_ACTION_REQUIRED },
    //     { id: 7, title: 'ROLE PLAY INSTRUCTIONS' ,typeValue: 'role_players_instruction',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.role_players_instruction ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.ROLE_PLAY_INSTRUCTIONS},
    //     { id: 8, title: 'ADDITIONAL GUIDANCE CAUTION OPTIONS ', typeValue: 'additional_guidance_caution_options' ,  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.additional_guidance_caution_options ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.ADDITIONAL_GUIDANCE_CAUTION_OPTIONS},
    //   ]
    }
  }

  private initNavItems(inj: IExerciseTimelineInjectRef | iExerciseInject) {


    this.sideNavItems = [
      { id: 0,
        title: 'Generic Inject Description',
        typeValue: 'description',
        dynItemType: DynamicItemDataType.DESCRIPTION,
        injectModel: inj,
        dynComponent:
        new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          inj.description ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])
        )
        },
      { id: 1,
        title: 'Expected Outcomes',
        typeValue: 'expected_outcomes' ,
        dynItemType: DynamicItemDataType.EXPECTED_OUTCOME,
        injectModel: inj,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          inj.expected_outcomes ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 2,
        title: 'TRAINER FEEDBACK',
        typeValue: 'TRAINER_FEEDBACK',
        injectModel: inj,
        dynItemType: DynamicItemDataType.TRAINER_FEEDBACK,
        dynComponent: new DynamicItem(
          InjectDetailOL_TrainerFeedbackComponent,
          inj.description ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 3,
        title: 'OBJECTIVES' ,
        typeValue: 'OBJECTIVES',
        dynItemType: DynamicItemDataType.OBJECTIVES,
        injectModel: inj,
        dynComponent: new DynamicItem(
          InjectDetailOL_TrainingObjectivesComponent,
          instanceOf<IExerciseTimelineInjectRef>(inj, "leftPos") ?
          (inj as IExerciseTimelineInjectRef).trainingObjectives : instanceOf<iExerciseInject>(inj, "exercise_inject_id") ?
          (inj as iExerciseInject).learning_objectives : '',
          // ?? [] :
          // this.inject.learning_objectives ?? [],
          // [],
         //  inj.trainingObjectives ?? [],
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 4,
        title: 'Preparations' ,
        typeValue: 'on_scene_preparation',
        dynItemType: DynamicItemDataType.ON_SCENE_PREPARATION,
        injectModel: inj,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          inj.on_scene_preparation ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 5,
        title: 'Information',
        typeValue: 'related_documents',
        dynItemType: DynamicItemDataType.RELATED_DOCUMENTS,
        injectModel: inj,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          inj.related_documents ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 6,
        title: 'Resources and Documents',
        typeValue: 'related_equipment',
        dynItemType: DynamicItemDataType.RELATED_EQUIPMENT,
        injectModel: inj,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          inj.related_equipment ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 7,
        title: 'Outputs' ,
        typeValue: 'trainers_action_requirement',
        dynItemType: DynamicItemDataType.TRAINERS_ACTION_REQUIRED,
        injectModel: inj,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          inj.trainers_action_requirement ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 8,
        title: 'Roleplay Instructions' ,
        typeValue: 'role_players_instruction',
        dynItemType: DynamicItemDataType.ROLE_PLAY_INSTRUCTIONS,
        injectModel: inj,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          inj.role_players_instruction ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 9,
        title: 'Safety and Security Guidance',
        typeValue: 'additional_guidance_caution_options' ,
        dynItemType: DynamicItemDataType.ADDITIONAL_GUIDANCE_CAUTION_OPTIONS,
        injectModel: inj,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          inj.additional_guidance_caution_options ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]),
        )
        },
    ];



    if(instanceOf<IExerciseTimelineInjectRef>(inj, "leftPos")) {
      const inItem: IExerciseTimelineInjectRef = inj as IExerciseTimelineInjectRef
      // console.log(`inIteminIteminItem `, inItem);
      if(inItem.itemType == ExerciseTimelineItemType.EVENTS || inItem.itemType == ExerciseTimelineItemType.EXCON) {
        this.sideNavItems.splice(3, 1);
      }
      if((inItem.itemType == ExerciseTimelineItemType.EVENTS || inItem.itemType == ExerciseTimelineItemType.EXCON) || this.storage.exerciseUserRole == ExerciseUserRole.OBSERVER) {
        this.sideNavItems.splice(2, 1);
      }
    } else {
      this.sideNavItems.splice(2, 1);

    }


    wait(100, () => {
      this.selectNav(this.sideNavItems[0]);
    });
  }

  selectNav(e: INavigationListItem) {//sideNavItem){
    this.sideNavItem = e;
    this.onNaviSelected.emit(e);

  }

  get activeSideNavId() : number {
    return this.sideNavItem ? this.sideNavItem.id : -1;
  }
}
