import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { da } from 'date-fns/locale';
import { TextEditOverlayDataI } from 'src/app/model/texteditOLdata.model';

@Component({
  selector: 'app-text-edit-overlay',
  templateUrl: './text-edit-overlay.component.html',
  styleUrls: ['./text-edit-overlay.component.scss']
})
export class TextEditOverlayComponent implements OnInit {

  title:string =''
  editText:string =''
  isSingleLine = false
  constructor(public dialogRef: MatDialogRef<TextEditOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextEditOverlayDataI) {
      
      if(data.singleLineTxt === true) {
        this.isSingleLine = true
      }
      this.title = data.title
      this.editText = data.editText
    }

  ngOnInit(): void {
  }
  close(){
    this.dialogRef.close();
  }
  save(){
    this.dialogRef.close(this.editText);
  }
}
