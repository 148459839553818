import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ExercisePerformanceTeamItem, ExerciseTimelineItemType, IExerciseTimelineInjectRef, IExerciseTimelineItem } from 'src/app/model/exercise.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import * as _ from 'lodash';
import { wait } from 'src/app/services/utils/functions';
import { iExerciseTrainingObjective } from 'src/app/model/trainingObj.model';

@Component({
  selector: 'app-exercise-team-performance-overlay',
  templateUrl: './exercise-team-performance-overlay.component.html',
  styleUrls: ['./exercise-team-performance-overlay.component.scss']
})
export class ExerciseTeamPerformanceOverlayComponent implements OnInit{
  @ViewChild('wrapper') wrapper: ElementRef | undefined
  public api = inject(ApiService);
  public storage = inject(StorageService);
  public dialogRef = inject(MatDialogRef<ExerciseTeamPerformanceOverlayComponent>)

  timelineItems: IExerciseTimelineItem[] = []
  selectedTimelineItem: IExerciseTimelineItem | undefined
  performanceItem: ExercisePerformanceTeamItem | undefined
  get exerciseTrainingObjects():iExerciseTrainingObjective[] {
    return this.storage.exerciseTrainingObjectsAdmin;
  }

  ngOnInit(): void {
    if (this.storage.currentExer) {
      this.timelineItems = this.storage.currentExer.timelineItems.filter(item => item.itemType == ExerciseTimelineItemType.TEAM)
      console.log(this.timelineItems);    
      const assinjsts = this.storage.assigendExerciseInjects.filter(item => item.itemType == ExerciseTimelineItemType.TEAM)
      console.log(assinjsts);
      console.log('TRAINING ONJECTIVES ');
      console.log(this.storage.exerciseTrainingObjectsAdmin);
    }
    wait(1000, () => {
      console.log(this.wrapper?.nativeElement.clientWidth);
      
    })
    wait(2500, () => {
      if (this.timelineItems[0]) {
        this.selectedTimelineItem = this.timelineItems[0]
        this.loadTimelinePerformance(this.selectedTimelineItem)
      }
    })
  }

  getInjectsForTl(tlItem: IExerciseTimelineItem): IExerciseTimelineInjectRef[] {
    return this.storage.assigendExerciseInjects.filter(item => item.exerciseTimelineId == tlItem.id)
  }
  getInjectsForTrObj(trObj: iExerciseTrainingObjective): IExerciseTimelineInjectRef[] {
    if (this.performanceItem) {
      // return this.storage.assigendExerciseInjects.filter(item => item.exerciseTimelineId == tlItem.id)
      const injects: IExerciseTimelineInjectRef[] = []
      for (const injt of this.performanceItem.assignedInjects) {
        if (injt.trainingObjectives) {
          const ids = _.map(injt.trainingObjectives, 'id')
          if(ids.includes(trObj.id)) {
              injects.push(injt)
          }
        }
      }
      return injects
    } else {
      return []
    }
  }

  getAssigendExerciseInjects(exerciseTimelineId: number):IExerciseTimelineInjectRef[] {
    const assinjsts = this.storage.assigendExerciseInjects.filter(item => item.exerciseTimelineId == exerciseTimelineId)
      return assinjsts
  }
  onTimelineItemChanged(e:any) {
    this.performanceItem = undefined
    // // const comp = e.value as IExerciseTimelineItem
    console.log(e);
    console.log(this.selectedTimelineItem);
    if (this.selectedTimelineItem)
    this.loadTimelinePerformance(this.selectedTimelineItem)
  }
  private async loadTimelinePerformance(tlItem:IExerciseTimelineItem) {
    console.log(`loadTimelinePerformance id: ${tlItem.id}`);
    
    const res = await this.api.apiGetP(`exercise/timeline-performance/${tlItem.id}`)
    this.performanceItem = res as ExercisePerformanceTeamItem
    // const _performanceItem:ExercisePerformanceTeamItem = res as ExercisePerformanceTeamItem
    // console.log(_performanceItem);
    
    // for(var i = 0; i < this.exerciseTrainingObjects.length; i ++) {
    //   const trngObj = this.exerciseTrainingObjects[i];
    //   console.log(`trngObj: ${trngObj.title} ......`);
    //   // console.log(_performanceItem.assignedInjects);
    //   const injects: IExerciseTimelineInjectRef[] = []
    //   for (const injt of _performanceItem.assignedInjects) {
    //     if (injt.trainingObjectives) {
    //       const ids = _.map(injt.trainingObjectives, 'id')
    //       if(ids.includes(trngObj.id)) {
    //           injects.push(injt)
    //       }
    //       // const items = _.filter(injt.trainingObjectives, item => item.id == trngObj.id)
    //       // console.log(items);
          
    //     }
        
    //   }
    //   console.log(injects);
      
    //   // if (_performanceItem.assignedInjects) {
    //   // }
    //   console.log(`<<<......`);
    // }
    
  }

  close() {
    this.dialogRef.close();
  }

  number_of_injects = 0
  number_of_objectives = 0
  // number_of_trainers = 0
  // number_of_ect_users = 0
  number_of_objects_rated = 0
  number_of_ect_comment = 0

  private clearStats() {
    this.number_of_injects = 0
    this.number_of_objectives = 0
    // this.number_of_trainers = 0
    // this.number_of_ect_users = 0
    this.number_of_objects_rated = 0
    this.number_of_ect_comment = 0
  }

}
