<!-- <div class="ddd" style="overflow: hidden;">

    
</div> -->
<mat-toolbar class="overlay-toolbar tlc justify-content-center" style="color: #D32F2F;">
  {{title}}
  </mat-toolbar>
  <div class="container pt40 pb40 bgDarg" >
  
    <div class="row">
      <div class="col-12 pr32 pl32" style="text-align:center; font-size:16px; font-weight:300;"
       [innerHtml]='data.bodyMessage'>
        
      </div>
    </div>
  
  </div>
  <mat-toolbar class="overlay-toolbar tlc justify-content-center">
    <div class="d-flex h100 align-items-center">
      <div class="p-0 alertActionBtn" *ngFor='let action of data.actions'  
      [ngStyle]="{'background-color':colorForActionColorType(action.color),}"
       (click)="tapped(action.actionType)">
      {{action.title}}
      </div>
    </div>
  </mat-toolbar>