
import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { DynamicComponent } from "src/app/model/dynamic.component";
import { IExerciseTimelineInjectRef, INJECT_DELIVERY_METHOD_LIST_ITEM } from "src/app/model/exercise.model";
import { iExerciseTrainingObjective } from "src/app/model/trainingObj.model";
import { StorageService } from "src/app/services/storage.service";
import * as _ from 'lodash';

@Component({
  selector: 'inject-detail-ol-training-objectives',
  template: `

<div class="conrtainer h100"  >

<mat-form-field appearance="outline" class="textfield100 select-form-field" >
  <mat-select  [(value)]='selectedtrainingObjectives' (selectionChange)='learningObtSelected($event)'
  [compareWith]="compareFn" multiple [disabled]='readonly || !editable' (openedChange)='openedChange($event)' >
    <mat-option *ngFor="let obj of storage.exerciseTrainingObjectsAdmin" [value]="obj">
      <!-- {{obj.id}} -->
      {{obj.title}}
    </mat-option>
  </mat-select>
</mat-form-field>
</div>

  `,
    styleUrls: ['../inject-detail-overlay.component.scss']
})
export class InjectDetailOL_TrainingObjectivesComponent implements OnInit, DynamicComponent {
  @Input() inject!:IExerciseTimelineInjectRef
  @Input() data!:any
  @Input() editable!:boolean
  storage = inject(StorageService);

  selectedtrainingObjectives: iExerciseTrainingObjective[] = []
  readonly = false
  
  // @Output() selected = new EventEmitter<Location>();
  
  // canEditDeliveryMethod = true
  // // isSaving = false
  // disabled = false
  

  
  ngOnInit(): void {
    this.selectedtrainingObjectives = this.inject!.trainingObjectives ?? []
  }
  compareFn(item1:iExerciseTrainingObjective, item2:iExerciseTrainingObjective){
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }
  learningObtSelected(e:any) {
    console.log(e.value);
    // this.selectedtrainingObjectives =  e.value;
    console.log(this.selectedtrainingObjectives);
  }
  async openedChange(e:any) {
    // console.log(e);
    if(e === false) {
      console.log(`this.selectedtrainingObjectives: `, this.selectedtrainingObjectives);
      console.log(`this.inject?.trainingObjectives: `, this.inject!.trainingObjectives);
      const included = this.inject!.trainingObjectives ? _.map(this.inject!.trainingObjectives!, 'id') : []
      const selected = _.map(this.selectedtrainingObjectives, 'id')
      if (included.length != selected.length) {
        if (included.length > selected.length) {
          for(var i = 0; i < included.length; i ++) {
            const currentIncluded = included[i]
            if (selected.includes(currentIncluded)) {
              // console.log(`included.length > selected.length .. id ${currentIncluded}  exists`);
              
            } else {
              console.log(`included.length > selected.length .. id ${currentIncluded} not exists > NEEDS TO BE ADDED`);
              this.updateTr(currentIncluded)
            }
          }
        } else {
          for(var i = 0; i < selected.length; i ++) {
            const currentSelected = selected[i]
            if (included.includes(currentSelected)) {
              // console.log(`included.length < selected.length .. id ${currentSelected}  exists`);
              
            } else {
              console.log(`included.length < selected.length .. id ${currentSelected} not exists > NEEDS TO BE ADDED`);
              this.updateTr(currentSelected)
            }
          }
        }
      } else if (included.length == selected.length && included.length > 0) {
        for(var i = 0; i < selected.length; i ++) {
          const currentSelected = selected[i]
          if (included.includes(currentSelected)) {
            // console.log(`id ${currentSelected}  exists`);
            
          } else {
            console.log(`id ${currentSelected} not exists > NEEDS TO BE ADDED`);
            this.updateTr(currentSelected)
          }
        }
        for(var j = 0; j < included.length; j ++) {
          const currentIncluded = included[j]
          if (selected.includes(currentIncluded)) {
            // console.log(`id ${currentIncluded}  exists`);
            
          } else {
            console.log(`id ${currentIncluded} not exists > NEEDS TO BE ADDED`);
            this.updateTr(currentIncluded)
          }
        }
      }
      
      // console.log(this.inject?.trainingObjectives);
      //const diffs = _.difference(this.inject?.trainingObjectives, this.selectedtrainingObjectives)
      // const diffs = _.differenceBy(this.inject!.trainingObjectives, this.selectedtrainingObjectives, 'id')
      // console.log(diffs);
      // const diffs1 = _.difference(this.selectedtrainingObjectives, this.inject!.trainingObjectives!)
      // console.log(diffs1);
      // const diffs3 = _.differenceWith(this.selectedtrainingObjectives, this.inject!.trainingObjectives!, _.isEqual)
      // console.log(diffs3);
      // const diffs2 = _.intersectionWith(this.inject!.trainingObjectives!, this.selectedtrainingObjectives,  _.isEqual);
      // console.log(diffs2);
      
    }
  }
  private updateTr(trainingObjectId: number) {
    if (!this.storage.currentExer || !this.inject) { return }
     const dto = {
      injectId: this.inject.id,
      trainingObjectId: trainingObjectId,
    }
    console.log(dto);
    this.storage.postSocket('traningobject-to-inject', dto)
  }
}