import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UiService } from './services/ui.service';
import { ISnackData } from './model/enums';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from './services/api.service';
import { Router } from '@angular/router';
import { StorageService } from './services/storage.service';
import { iUser } from './model/iuser.model';
import { gsap } from 'gsap';
import { version } from 'src/environments/version';
import { DeviceDetectorService } from 'ngx-device-detector';
import { wait } from './services/utils/functions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AppComponent implements OnInit {
  @ViewChild('sessionTimeOutDiv') sessionTimeOutDiv!: ElementRef;
  resizeTimeout: any;
  title = 'ect';
  timeoutmessage = 'Your URL is wrong or deprecated.<br>You will redirect to https://dev.ksk-ect.eu';
  isLoggedIn = false
  sessionTimeoutVisible = false;

  constructor(
    private api: ApiService,
    private storage: StorageService,
    private router: Router,
    public ui: UiService,
    private deviceService: DeviceDetectorService,
    public snackBar: MatSnackBar) {
    this.onResize()

  }

  ngOnInit(): void {
    // console.log('check log ub');

    console.log(this.deviceService);
    console.log(`%c ${version.version}`,'color:#1976D2');

    if (!this.ui.isDebugMode) {
      console.log = function() {}
      console.warn = function() {}
    }

    if (this.deviceService.deviceType == 'tablet' || this.deviceService.deviceType == 'mobile' ) {

      // window.location = `ECTAPP://open/signin/?email=c@doxfox.io` as any;
    }
    // wait(1000, ()=> {
    //   alert(this.deviceService.deviceType)
    // })
    //

    // console.log(`%c 2.203.3`, 'color: #1976D2')
    // console.log = function() {}
    //

    this.ui.snackEmitter.subscribe((snack: ISnackData) => {
      this.snackCustom(snack)
    })
     this.checkUrl();
  }

  onResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
      this.resizeTimeout = setTimeout((() => {
      this.ui.changeScreenAspect({width:window.innerWidth, height:window.innerHeight})
    }).bind(this), 500);
  }

  snackCustom(item:ISnackData) {
    this.snackBar.open(
      item.message, undefined, {
        panelClass: item.panelClass,
      duration: item.duration,
    });
  }

  // access:boolean= false;

  uid:string | undefined
  adminPanel() {
    this.router.navigateByUrl('admin')
  }
  signOutTapped() {
    this.api.signOut()
    setTimeout(() => {
      this.router.navigateByUrl('')
    }, 500)
  }
  // private adminUser: iCompanyAdmin | undefined

  private checkUrl() {
    const winLoc = window.location.origin;

    if (winLoc.includes('ect-dev-2.web.app') || winLoc.includes('ect-dev-2.firebaseapp.com')) {
      setTimeout(() => {
        this.initSessionTimeoutWin()

      }, 1000)
    } else {
      //this.checkLogin()

      // setInterval(() => {
      //   if (this.isLoggedIn != this.storage.isLoggedIn) {
      //     this.checkLogin()
      //   }
      //   // console.log(this.api.isLoggedIn);
      // }, 5000)
    }
  }

  // private async checkLogin() {
  //   console.log('checkLogin');

  //   if (this.storage.isLoggedIn) {
  //     const currentUser = await this.api.apiGetP(`user/`)
  //     this.storage.currentUser = currentUser as iUser
  //     console.log(this.storage.currentUser);
  //     this.isLoggedIn = this.storage.isLoggedIn
  //     // this.adminUser = currentUser as iCompanyAdmin
  //     // if(this.adminUser && this.adminUser.company) {
  //     //   this.access = true
  //     // }
  //   } else {
  //     this.isLoggedIn = false
  //     // this.adminUser = undefined
  //     // this.access = false
  //   }
  //   // console.log(this.adminUser);
  //   //this.isLoggedIn = this.api.isLoggedIn
  // }

  private initSessionTimeoutWin() {
    if(!this.sessionTimeoutVisible) {
      this.sessionTimeoutVisible = true
      // gsap.to('.sessionTimeout',  { 'opacity':1, 'blurX':0, ease: 'Expo.easeInOut',duration: 0.85, delay: 0.25});
      gsap.to(this.sessionTimeOutDiv.nativeElement,  { 'opacity':1, 'blurX':0, ease: 'Expo.easeInOut',duration: 0.85, delay: 0.25});
      // gsap.to(this.sessionTimeOutDiv.nativeElement, 0.85, { 'opacity':1, 'blurX':0, ease: Expo.easeInOut, delay: 0.25});
      setTimeout(()=>{
        this.signOutTapped()
      },2600)
      setTimeout(() => {
        this.deInitSessionTimeoutWin()
      },8000)
    }
  }
  private deInitSessionTimeoutWin() {
    if(this.sessionTimeoutVisible) {
      this.sessionTimeoutVisible = false
      gsap.to(this.sessionTimeOutDiv.nativeElement,  { 'opacity':0, ease: 'Expo.easeOut',duration:1, delay: 0.25});

      setTimeout(()=>{
        window.location.href = 'https://dev.ksk-ect.eu'
        // this.authS.SignOut()
      },1500)
    }
  }
}
