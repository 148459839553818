import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from "lodash";
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public storage: StorageService, private router:Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
    

      // let u = this.auth.getUser()

      if(this.storage.isLoggedIn !== true) {
        this.router.navigate(['sign-in']);
        return false;
      }

      // if(!next.data.roles){
      //   return true;
      // }

      if(this.storage.isLoggedIn === true){
        return true;
      }

      // if(next.data.roles && r && 
      //   _.intersection(next.data.roles, r).length > 0) {
      //   return true;
      // }


      return false;
  }
  
}
