import { Injectable } from '@angular/core';
import { IAuthToken } from '../model/auth.token';

const TOKEN_KEY = 'auth-token';
@Injectable({
  providedIn: 'root'
})
export class TokenstorageService {

  constructor() { }

  public getToken(): IAuthToken | null {
    // console.log(`%c TokenstorageService > getToken()`,'color:#32A555');
     const item = sessionStorage.getItem(TOKEN_KEY)
     if  (item) {
       return JSON.parse(item) as IAuthToken;
     } else {
       return null
     }
   }

   public saveToken(tItem:IAuthToken): void {
    // console.log(`%c TokenstorageService > saveToken()`,'color:#32A555');
     window.sessionStorage.removeItem(TOKEN_KEY);
     window.sessionStorage.setItem(TOKEN_KEY, JSON.stringify(tItem));
   }

   public clear() {
    window.sessionStorage.clear();
   }
}
