import { InjectExportAssignedInject } from "../../excel.service";

export const injectListSchema = [
  {
    column: "Inject Number",
    type: String,
    width: 30,

    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.inject_no,
  },
  {
    column: "Title",
    type: String,
    width: 50,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.short_title,
  },
  {
    column: "Delivery Method",
    type: String,
    // alignVertical: "top",
    // width: 50,
    value: (item: InjectExportAssignedInject) => item.deliveryMethod,
  },
  {
    column: "Generic Inject Description",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",

    value: (item: InjectExportAssignedInject) => item.description,
  },
  {
    column: "Information",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.related_documents,
  },
  {
    column: "Resources and Documents",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.related_equipment,
  },
  {
    column: "Roleplay Instructions",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.role_players_instruction,
  },
  {
    column: "Preparations",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.on_scene_preparation,
  },
  {
    column: "Safety and Security Guidance",
    type: String,
    width: 50,

    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) =>
      item.additional_guidance_caution_options,
  },
  {
    column: "Expected Outcomes",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) => item.expected_outcomes,
  },
  {
    column: "Outputs",
    type: String,
    width: 50,
    wrap: true,
    // alignVertical: "top",
    value: (item: InjectExportAssignedInject) =>
      item.trainers_action_requirement,
  },
  {
    column: "Learning Objectives",
    type: String,
    width: 50,
    // alignVertical: 'top',
    wrap: true,
    value: (item: InjectExportAssignedInject) =>
      item.objectives,
  },
];
