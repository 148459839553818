
<div class="sessionTimeout" #sessionTimeOutDiv>
  <div class="d-flex h100 align-items-center justify-content-center">
    <div class="p-2" style="max-width: 70%; color: #fff; text-align: center; font-size: 64px; line-height: normal;">
      <h1 [innerHTML]="timeoutmessage"></h1>
    </div>
  </div>
</div>
<div class="dev-banner"></div>
<router-outlet></router-outlet>
