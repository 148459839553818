<mat-toolbar style="height: 36px; border-radius: 0px; background-color: rgba(0,0,0,0.5)!important;" class="pl0">
  <span class="pl20">{{title}}</span>
  <span class="toolbar-spacer"></span>
    <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container pb0">
  <div class="d-flex">
    <div class="p-2 flex-fill" *ngIf="isSingleLine">
      <input type="text"
              class=" textfield100  formControl"  required 
              placeholder="Name" style="text-transform: uppercase;"
              [(ngModel)]="editText" >
      <!-- <textarea class="mat-body formControl textfield100" rows="4" [(ngModel)]='editText' >
      </textarea> -->
    </div>
    <div class="p-2 flex-fill" *ngIf="!isSingleLine">
      <textarea class="mat-body formControl textfield100" rows="4" [(ngModel)]='editText' >
      </textarea>
    </div>
  </div>
</div>
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_mini" (click)="save()">save</mat-icon>
</mat-toolbar>
<!-- <mat-toolbar style="height: 38px; width: 100%; border-radius: 0px; background-color:rgba(0,0,0,0.2)!important;" 
  class="">
  <div style="width:92px; text-align:center;" class="tlc justify-content-center" >
  <button mat-flat-button color='accent' (click)='save()'>SAVE</button>
  </div>
</mat-toolbar> -->