
<mat-toolbar class="overlay-toolbar txtLightblue">
  TEAM PERFORMANCE DASHBOARD
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="bgDarg p0" style="height: calc(100% - 88px);" #wrapper>

  <div class="d-flex" style="height: 100%; overflow: hidden;">

    <div class="p-0" style="width: 50%; height: 100%; overflow-x: hidden; overflow-y: scroll;">

      <mat-card class="dashboard-main-card-background mt1 pr0 pl0">

        <div class="container-fluid p0 pr5 ">

          <div class="d-flex flex-column ">
            <div class="p-0 d-flex flex-column txtLightblue" *ngFor="let col of timelineItems">

              <div class="p-0"><app-divider></app-divider></div>
              <div class="p-2 d-flex flex-column">

                <div class="p-2 fs14">
                  OVERALL RATING {{col.title}}
                </div>
                <div class="p-2 listitem-copy">
                  <teamperformance-progress-bar [injects]="getInjectsForTl(col)" ></teamperformance-progress-bar>

                </div>
              </div>
            </div>
          </div>
        </div>

      </mat-card>
    </div>


    <div class="p-0" style="width: 50%; height: 100%; overflow-x: hidden; overflow-y: scroll;">

      <mat-card class="dashboard-main-card-background mt1 pr0 pl0">

        <div class="container-fluid p0 pr5 ">

          <div class="d-flex flex-column ">


            <div class="p-0">
              <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
                <mat-select [(value)]='selectedTimelineItem'  (selectionChange)='onTimelineItemChanged($event)'  class="font-main">
                  <mat-option *ngFor="let timelineItem of timelineItems" [value]="timelineItem">
                  {{timelineItem.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="p-0" *ngIf="performanceItem">
              <div class="p-0 d-flex flex-column txtLightblue" *ngFor="let col of exerciseTrainingObjects">
              <div class="p-0"><app-divider></app-divider></div>
              <div class="p-2 d-flex flex-column">

                <div class="p-2 fs14">
                 {{col.title}}
                </div>
                <div class="p-2 listitem-copy">
                  <teamperformance-progress-bar [injects]="getInjectsForTrObj(col)" ></teamperformance-progress-bar>
                  <!-- <teamperformance-progress-bar [timelineItem]="col" ></teamperformance-progress-bar> -->
                  <!-- <div class="d-flex">
                    <div class="p-2">

                    </div>
                    <div class="p-2 ml-auto fs14">{{getAssigendExerciseInjects(col.id).length}}</div>
                  </div> -->

                </div>
              </div>
            </div>
            </div>
          </div>
        </div>

      </mat-card>
    </div>

  </div>

</div>

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <!-- <ect-color-btn class="mr5" title="Save" icon="save" color="primary" [disabled]="saveDisabled" (clicked)="save()" ></ect-color-btn> -->
</mat-toolbar>
