import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExerciseComment } from 'src/app/model/exercise.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';


@Component({
  selector: 'app-comments-overview-overlay',
  templateUrl: './comments-overview-overlay.component.html',
  styleUrls: ['./comments-overview-overlay.component.scss']
})
export class CommentsOverviewOverlayComponent  implements OnInit {
  public api = inject(ApiService);
  public storage = inject(StorageService);
  public dialogRef = inject(MatDialogRef<CommentsOverviewOverlayComponent>)
  dispCols: string[] = ['team', 'injectTitle' , 'inject_no', 'comment', 'itemType', 'createdAt', 'author'];
  comments: ExerciseComment[] = []
  
    searchTxt: string = ''
  // logSelected = false
  logDataSource:MatTableDataSource<ExerciseComment> = new MatTableDataSource<ExerciseComment>(this.comments);
  @ViewChild(MatSort) sort!: MatSort;
  ngOnInit(): void {
    this.load()
  }


  private async load() {
    if (this.storage.currentExer) {
      const id = this.storage.currentExer.id;
      const exDe = await this.api.apiGetP(`inject/exercise-comments/${id}`)
      
      
      this.comments = exDe as ExerciseComment[]
       
      this.logDataSource = new MatTableDataSource<ExerciseComment>(this.comments);
      this.logDataSource.sort = this.sort!;
      
    }
  }
 

  onSearch(e:string) {

  }
  applyFilter(event: Event) {
    if(!this.logDataSource) { return }
    const filterValue = (event.target as HTMLInputElement).value;
    this.logDataSource.filter = filterValue.trim().toLowerCase();
  }

  close() {
    this.dialogRef.close();
  }
}
