import { RatingExportItem } from "../../excel.service";

export const reportCommentSchema = [
  {
    column: "inject_no",
    type: String,
    width: 30,
    // alignVertical: 'top',
    value: (item: RatingExportItem) => item.inject_no,
  },
  {
    column: "vote",
    type: String,
    // width: 50,
    // alignVertical: 'top',
    value: (item: RatingExportItem) => item.vote,
  },
  {
    column: "type",
    type: String,
    // width: 50,
    // alignVertical: 'top',
    value: (item: RatingExportItem) => item.itemType,
  },
  {
    column: "userName",
    type: String,
    // width: 50,
    // alignVertical: 'top',
    value: (item: RatingExportItem) => item.userName,
  },
  {
    column: "team",
    type: String,
    width: 30,
    value: (item: RatingExportItem) => item.teamName,
  },
  {
    column: "createdAt",
    type: Date,
    format: "mm/dd/yyyy hh:mm",
    width: 30,
    // alignVertical: 'top',
    value: (item: RatingExportItem) => new Date(item.createdAt),
  },
  {
    column: "comment",
    type: String,
    width: 120,
    // alignVertical: 'top',
    value: (item: RatingExportItem) => item.comment,
  },
];
