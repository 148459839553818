// Dashboard Nav Event

import { ExerciseUserRole } from "./exercise.model";

export enum NAV_EVENT {
  GOTO_MAIN = "GOTO_MAIN",
  SHOW_DURATION = "SHOW_DURATION",
  SHOW_TEAM_PERFORMANCE = "SHOW_TEAM_PERFORMANCE",
  // SHOW_REPORT = 'SHOW_REPORT',
  SHOW_LOGBOOK = "SHOW_LOGBOOK",
  SHOW_FULL_REPORT = "SHOW_FULL_REPORT",
  SHOW_TIMELINE_REPORT = "SHOW_TIMELINE_REPORT",
  SHOW_ADMIN_PANEL = "SHOW_ADMIN_PANEL",
  SIGN_OUT = "SIGN_OUT",
  SHOW_PERFORMANCE = "SHOW_PERFORMANCE",
  SHOW_USER_GROUPS = "SHOW_USER_GROUPS",
  SHOW_LOCATIONS = "SHOW_LOCATIONS",
  TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR",
  TOGGLE_MAP = "TOGGLE_MAP",
  DARKMODE = "DARKMODE",
  COMMENT_OVERVIEW = "COMMENT_OVERVIEW",
  // HIDE_SIDE_NAV = 'HIDE_SIDE_NAV',
  // SHOW_SIDE_NAV = 'SHOW_SIDE_NAV',
}

export type ExerciseDashboardNavIcon = {
  icon: string;
  event: NAV_EVENT;
  tooltip: string;
  access: ExerciseUserRole[];
  isToggle?: boolean;
  isActive?: boolean;
};

export interface miniOvlItem {
  width: number;
  left: number;
  day: string;
}

export enum BUTTON_ERROR_TYPE {
  ADD_DAY = 'ADD_DAY',
  MINUS_DAY = 'MINUS_DAY',
  ADD_HOUR = 'ADD_HOUR',
  MINUS_HOUR = 'MINUS_HOUR',
}

export interface iTimelineScrollEventItem {
  timelineId: number;
  timelineTopIndex: number;
  timelineHeight: number;
  injectTopIndex: number;
  injectHeightFact: number

}
