<!-- [ngStyle]="{'background-color':inject.state.color, 'color': getTextColorForInject(inject)}" -->
<div class="action-item" (click)="openDetail($event)" [class.action-item-light]="!ui.darkMode">

  <div class="d-flex flex-column align-items-stretch pt1 pb0">
    <div class="p-0">
      <div class="d-flex p0">

        <div class="p-0 inject_title  ml3">
          {{inject.short_title}}
        </div>


        <div class="p-0 ml-auto ">
          <div class="state-item"
            [ngStyle]="{'background-color':inject.state.color, 'color': getTextColorForInject(inject)}">
            {{inject.state.title}}</div>
        </div>

      </div>
    </div>

    <div class="p-0 ">
      <div class="d-flex ">
        <div class="p-0 inject_no ml3">
          {{inject.inject_no}}
        </div>


        <div class="p-0 mt2" [class.ml-auto]='showDeleteButton' *ngIf='showDeleteButton'>
          <mat-icon class='icon-mini-btn' color='warn' (click)="deleteInject()">delete</mat-icon>
        </div>
        <div class="p-0 " [class.ml-auto]='!showDeleteButton'>
          <mat-icon class='icon-mini-btn' *ngIf="showLocBtn" (click)='showOnMap($event)'>my_location</mat-icon>
        </div>

      </div>
    </div>


    <div class="p-0 ">
      <div class="d-flex ">
        <div class="p-0 timelineName ml3">
          {{inject.timelineItem?.title}}
        </div>

      </div>
    </div>

  </div>
</div>