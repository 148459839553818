<mat-card class="dashboard-main-card-background mt1 pt10 pb10 pr10 pl10 ">
  <div class="d-flex align-items-center">
    <div class="p-2 txtLightblue">
      All Users
    </div>
    <div class="p-0 ml-auto">
      <!-- BUTTON ADD -->
      <!-- <mat-icon *ngIf="viewMode == BASE_VIEW_MODES.OVERVIEW" class="dxfx_icon_btn_medium" 
      [class.DISABLED]="inputDisabled" 
      [matTooltip]="inputDisabled ? 'Exercise is De-Boarded' : 'Add new User' " 
      (click)="changeView(BASE_VIEW_MODES.ADD)">add</mat-icon> -->
      <mat-icon *ngIf="viewMode == BASE_VIEW_MODES.OVERVIEW" class="dxfx_icon_btn_medium" 
      [class.DISABLED]="inputDisabled" 
      [matTooltip]="inputDisabled ? 'Exercise is De-Boarded' : 'Add new User' " 
      (click)="setAddMode()">add</mat-icon>
      <mat-icon *ngIf="viewMode == BASE_VIEW_MODES.ADD || viewMode == BASE_VIEW_MODES.EDIT" class="dxfx_icon_btn_medium"
      (click)="changeView(BASE_VIEW_MODES.OVERVIEW)">close</mat-icon>

      
    </div>
    <div class="p-0 pr12"><ect-color-btn title="Show all" icon="list" color="base"  (clicked)="showAllUsers()" ></ect-color-btn></div>
  </div>
  
  <div class="container-fluid p0" >
    <div class="container" *ngIf="viewMode == BASE_VIEW_MODES.ADD">
     
          <div class="d-flex flex-column">
            <div class="p-2">
              {{uRoleSelected}}
            </div>
            <div class="p-2">
              <div class="d-flex">
                
                <div class="p-2" style="width: 50%;">
                  <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
                    <mat-select [(value)]='uRoleSelected'  (selectionChange)='onURoleChanged($event)' 
                    class="font-main">
                    <mat-option >
                      
                    </mat-option>
                      <mat-option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
                        {{uRole}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                 <!--  <select [value]='uRoleSelected'  class="font-dark select-cg"  (change)="onURoleChanged($event)" [disabled]="inputDisabled">
                    <option >
                      
                    </option>
                    <option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
                      {{uRole}}
                    </option>
                  </select> -->
                  
                </div>
                <div class="p-2" style="width: 50%;">
                  <div class="formGroup pb20">
                    <mat-checkbox [(ngModel)]="sendInvite">invite</mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2" >
              <div class="d-flex">
                
                <div class="p-2" style="width: 50%;">
                  <div class="formGroup pb20">
                    <input type="text" class="formControl" placeholder="Name"   [(ngModel)]="addedUserName"  [class.formControlError]="userNameError" [disabled]="inputDisabled">
                  </div>
                </div>
                <div class="p-2" style="width: 50%;">
                  <div class="formGroup pb20">
                    <input type="text" class="formControl" placeholder="Email"  [(ngModel)]="addedEmail" [class.formControlError]="emailError" [disabled]="inputDisabled">
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2">
              <ect-color-btn title="Save" icon="save" color="primary" [disabled]="saveBtnDisabled || inputDisabled" (clicked)="saveNewUser()" ></ect-color-btn>
            </div>
          </div>
         
    
    </div>

    <div class="container-fluid p0" *ngIf="viewMode == BASE_VIEW_MODES.OVERVIEW">

      <div class="d-flex flex-column" style="height: calc(100vh - 88px); overflow: hidden;">

        <div class="p-0 h40 d-flex align-items-center  tableTrBorder">
          <div class="p-0 pl10 tableTr" style="width: calc((100% - 360px) / 2);">name</div>
          <div class="p-0 pl10 tableTr flex-fill" >email</div>
          <div class="p-0 tableTr ml-auto w120 tlc">role</div>
          <div class="p-0 tableTr w120 tlc">state</div>
          <div class="p-0 tableTr w40"></div>
          <div class="p-0 tableTr w40"></div>
          <div class="p-0 tableTr w40"></div>
        </div>

        <div class="p-0"  style="overflow-y: scroll;">
          <user-table-row 
          *ngFor="let user of exerciseUser" [user]="user" [currentUserId]="currentUserId"
          (onEditUser)="editUser($event)"
          (onRemoveUser)="deleteUser($event)"
          (onInviteUserForEx)="inviteUserForEx($event)"
          ></user-table-row>
        </div>
      </div>

      <!-- <table class="table table-dark table-hover" style="font-size: 12px!important;
        font-weight: 200!important;
        color: #a2a686!important; width: 100%!important;" >
          <thead>
        <tr>
          
          <th scope="col">name</th>
          <th scope="col">email</th>
          <th scope="col">role</th>
          <th scope="col">state</th>
          <th scope="col"> </th>
          <th scope="col"> </th>
          <th scope="col"> </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of exerciseUser" [class.strokedText]="user.userState == uStates.REMOVED">
          
          <td>{{user.name}}</td>
          <td>{{user.email}}</td>
          <td>
            {{user.userRole}}
           
          </td>
          <td>{{user.userState}}</td>
          <td>
            <mat-icon class="dxfx_icon_btn_mini" *ngIf="currentUserId && currentUserId != user.userId" [class.DISABLED]="inputDisabled" (click)="editUser(user)">edit</mat-icon>
          </td>
          <td>
            <mat-icon *ngIf="user.userState == 'DRAFT' "  class="dxfx_icon_btn_mini" [class.DISABLED]="inputDisabled" (click)="inviteUserForEx(user)">share</mat-icon>
          </td>
          <td>
            <mat-icon *ngIf="api.iscurrentExerAdmin && user.userState != uStates.REMOVED"  class="dxfx_icon_btn_mini"  (click)="deleteUser(user)">person_off</mat-icon>
            <mat-icon *ngIf="api.iscurrentExerAdmin && user.userState === uStates.REMOVED"  class="dxfx_icon_btn_mini"  (click)="inviteUserForEx(user)">person_check</mat-icon>
          </td>
        </tr>
  
      </tbody>
  
      </table> -->
    </div>

    <!-- SHOW ALL -->
    <div class="container-fluid p0" *ngIf="viewMode == BASE_VIEW_MODES.SHOWALL">

      <div class="d-flex flex-column" style="height: calc(100vh - 88px); overflow: hidden;">

        <div class="p-0 h40 d-flex align-items-center  tableTrBorder">
          <div class="p-0 pl10 tableTr" style="width: calc((100% - 380px) / 2);">name</div>
          <div class="p-0 pl10 tableTr flex-fill" >email</div>
          <div class="p-0 tableTr ml-auto w120 tlc">state</div>
          <div class="p-0 tableTr w120 tlc">role</div>
          <!-- <div class="p-0 w120">role in comp</div> -->
          <div class="p-0 tableTr w40">
          
          </div>
        </div>

        <div class="p-0"  style="overflow-y: scroll;">
          
          <company-user-table-row *ngFor="let user of companyUsers" [user]="user" 
          (inviteCompanyUserForEx)="inviteCompanyUserForEx($event)"></company-user-table-row>
        </div>
      </div>

     
    </div>
    <!-- SHOW ALL END -->
    
    <div class="container" *ngIf="viewMode == BASE_VIEW_MODES.EDIT">
     
      <div class="d-flex flex-column">
        <!-- <div class="p-2">
          {{uRoleSelected}}
        </div> -->
        <div class="p-2">
          <div class="d-flex">
            
            <div class="p-2" style="width: 50%;">


              <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
                <mat-select [(value)]='selectedUserRole'  (selectionChange)='onURoleChanged($event)' 
                class="font-main">
                  <mat-option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
                    {{uRole}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <select [value]='selectedUserRole'  class="font-dark select-cg"
              (change)="onURoleChanged($event)">
                <option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
                  {{uRole}}
                </option>
              </select> -->
              
            </div>
           
          </div>
        </div>
        <div class="p-2" >
          <div class="d-flex">
            
            <div class="p-2" style="width: 50%;">
              <div class="formGroup pb20">
                <input type="text" class="formControl" placeholder="Name"
                [(ngModel)]="selectedUserName" [disabled]="inputDisabled">
              </div>
            </div>
            <div class="p-2" style="width: 50%;">
              <div class="formGroup pb20">
                <input type="text" class="formControl" placeholder="Email"
                [(ngModel)]="selectedUserEmail" disabled>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2">
          <ect-color-btn title="Save" icon="save" color="primary" (clicked)="updateUser()" ></ect-color-btn>
          <!-- [disabled]="saveBtnDisabled" -->
        </div>
      </div>
    
    </div>

  </div>
  
</mat-card>
