
<mat-toolbar class="overlay-toolbar">
  <ect-color-btn class="mr5"
  title="Export as xlsx" icon="table" color="primary"
  (clicked)="exportAs('xlsx')" ></ect-color-btn>
  <ect-color-btn class="mr5"
      title="Export as image" icon="image" color="primary"
      (clicked)="exportAs('png')" ></ect-color-btn>
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<!-- <div class="container-fluid">
  <div class="d-flex">
    <div class="p-0">
      <ect-color-btn class="mr5"
      title="Export as xlsx" icon="table" color="primary"
      (clicked)="exportAs('xlsx')" ></ect-color-btn>
    </div>
    <div class="p-0">
      <ect-color-btn class="mr5"
      title="Export as pdf" icon="picture_as_pdf" color="primary"
      (clicked)="exportAs('pdf', 'landscape')" ></ect-color-btn>
    </div>
    <div class="p-0">
      <ect-color-btn class="mr5"
      title="Export as image" icon="image" color="primary"
      (clicked)="exportAs('png')" ></ect-color-btn>
    </div>
    <div class="p-0">

    </div>
  </div>


</div> -->
<div class="container-fluid" style="width: 100%; overflow: hidden;">
<div class="scrl" style="overflow-x: scroll;">
  <table id="mytable" class="table">
    <thead>
        <tr>
            <th>team</th>
            <th *ngFor="let hh of hourItems">
             <span *ngIf="hh.startNewDate">{{hh.dateString}}</span> {{hh.hourString}}
            </th>

        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let tl of timelineExport">
            <td>{{tl.teamName}}</td>
            <td *ngFor="let item of tl.items">{{item.injectTitle}}</td>
            <!-- <td>1363480000</td>
            <td>March 24 2014</td>
            <td>19.1</td>
            <td>₹</td> -->
        </tr>
        <!-- <tr *ngFor="let i of rows">
            <td>Syria</td>
            <td>0000000</td>
            <td>March 24 2014</td>
            <td>11</td>
            <td>{{ i }}</td>
        </tr> -->
    </tbody>
  </table>
</div>
</div>


<!-- <button (click)="exportAsString('pdf', 'portrait')">From String</button> -->
<!-- <button (click)="exportAs('pdf', 'portrait')">export as pdf</button>
<button (click)="exportAs('pdf', 'landscape')">export as pdf landscape</button>
<button (click)="exportAs('png')">export as png</button>
<button (click)="exportAs('csv')">export as csv</button>
<button (click)="exportAs('txt')">export as txt</button>
<button (click)="exportAs('xlsx')">export as Ms-Excel</button>
<button (click)="exportAs('docx')">export as MS-Word</button>
<button (click)="exportAs('json')">export as json</button>
<button (click)="exportAs('xml')">export as xml</button> -->
