<div class="container-fluid p0" style="height: 100%; overflow: hidden;">
<div class="overlay-toolbar">
  <div class="d-flex p0 " >
    <div class="p-0 " *ngFor="let togglebtn of toggleBtns" >
      <navigation-toggle-button
      [model]="togglebtn"
      [active]="togglebtn.values.includes(currentViewMode)"
      (selected)="toggleBtnSelected($event)"></navigation-toggle-button>
    </div>

  </div>
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</div>

  <!-- <mat-toolbar class="overlay-toolbar">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="currentViewMode" (ngModelChange)="viewModeChanged($event)">
      <mat-button-toggle [value]="VIEW_MODES.INJECT_LIST">Inject List</mat-button-toggle>
      <mat-button-toggle [value]="VIEW_MODES.ASSIGNED_INJECTS_LIST">Assigned Inject List</mat-button-toggle>
      <mat-button-toggle [value]="VIEW_MODES.CREATE_INJECT">Add new</mat-button-toggle>
    </mat-button-toggle-group>
    <span class="toolbar-spacer"></span>
    <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
  </mat-toolbar> -->

  <div class="container-fluid p0" style=" height: calc(100% - 88px); overflow-y: hidden;">
    <!-- <div class="container-fluid p0 h100 bgDarg"> -->

    <div class="p-2" *ngIf="currentViewMode == VIEW_MODES.LOADING">
      <h1>....LOADING</h1>
    </div>



      <div class="p0 h100 bgDarg w100 d-flex flex-column" *ngIf="currentViewMode == VIEW_MODES.INJECT_LIST">
        <injects-table-view
        (injectSelect)="injectSelected($event)"
        [exerciseInjects]="exerciseInjects"></injects-table-view>
      </div>
      <div class="p0 h100 bgDarg w100 d-flex flex-column" *ngIf="currentViewMode == VIEW_MODES.ASSIGNED_INJECTS_LIST">
        <assigned-injects-table-view
        (injectSelect)="assignedInjectSelected($event)"
        [exerciseInjects]="storage.assigendExerciseInjects"></assigned-injects-table-view>
      </div>

      <div class="p0 h100 bgDarg w100 " *ngIf="currentViewMode == VIEW_MODES.INJECT_DETAIL && selectedInject">
        <!-- <inject-template-detail [inject]="selectedInject"
        [locations]="locations"
        ></inject-template-detail> -->
        <app-inject-detail-component [currentViewMode]="currentViewMode"
        [companyInjectTemplate]="selectedInject" [subNavbarPaddingLeft]="subNavbarPaddingLeft"></app-inject-detail-component>
      </div>
      <div class="p0 h100 bgDarg w100" *ngIf="currentViewMode == VIEW_MODES.ASSIGNED_INJECT_DETAIL && selectedAssignedInject">
        <!-- <inject-template-detail [inject]="selectedInject"
        [locations]="locations"
        ></inject-template-detail> -->
        <app-inject-detail-component [currentViewMode]="currentViewMode"
        [timelineInjectRef]="selectedAssignedInject" [subNavbarPaddingLeft]="subNavbarPaddingLeft"></app-inject-detail-component>
      </div>


        <div class="p0 h100 bgDarg w100" *ngIf="currentViewMode == VIEW_MODES.CREATE_INJECT  && newCompanyInject">
          <app-inject-detail-component [currentViewMode]="currentViewMode"
          [companyInjectTemplate]="newCompanyInject" [showIdAndTitle]="true" [subNavbarPaddingLeft]="subNavbarPaddingLeft"></app-inject-detail-component>
        </div>

    <!-- </div> -->





  </div>

  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>

    <ect-color-btn class="mr5"
    *ngIf="currentViewMode == VIEW_MODES.CREATE_INJECT && newCompanyInject"
    title="Save" icon="save" color="primary"
    [disabled]="!canCreateNewInject" (clicked)="createNewInject()" ></ect-color-btn>

    <ect-color-btn class="mr5"
    *ngIf="currentViewMode == VIEW_MODES.ASSIGNED_INJECT_DETAIL && selectedAssignedInject"
    title="Save" icon="save" color="primary"
    [disabled]="!canSaveAssignedInject" (clicked)="saveAssignedInject()" ></ect-color-btn>

    <ect-color-btn class="mr5" *ngIf="currentViewMode == VIEW_MODES.INJECT_DETAIL && selectedInject"
    title="Save Inject" icon="save" color="primary" [disabled]="!canSaveCompanyInject"
    (clicked)="saveCompanyInject()" ></ect-color-btn>

    <ect-color-btn class="mr5" *ngIf="currentViewMode == VIEW_MODES.INJECT_DETAIL && selectedInject"
    title="insert into timeline" icon="playlist_add_check" color="primary" [disabled]="!canInsertIntoTimeline"
    (clicked)="pushCompanyInjectIntoTimeLine()" ></ect-color-btn>
    <!-- <ect-color-btn class="mr5" title="save" icon="save" color="primary" [disabled]="!saveEnabled" (clicked)="save()" ></ect-color-btn> -->
  </mat-toolbar>
</div>
