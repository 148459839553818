import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { addMinutes, differenceInMinutes, subMinutes } from "date-fns";
import { INavigationToggleBtn } from "src/app/components/navigation-toggle-button/navigation-toggle-button.model";
import { INJECT_TIME_CHANGE_TYPE, TIME_MODE } from "src/app/model/enums";
import {
  IExerciseInjectState,
  IExerciseTimelineInjectRef,
  TacticalTime,
  TimeChangeOverlayData,
} from "src/app/model/exercise.model";
import { TIME } from "src/app/services/helpers";
import { LEVEL, Logger } from "src/app/services/logger.service";
import { StorageService } from "src/app/services/storage.service";
import { isNegative } from "src/app/services/utils/functions";

@Component({
  selector: "inject-detail-ol-status-change-time-overlay",
  template: `
    <div class="bgDarg h100">
      <mat-toolbar class="overlay-toolbar">
        <span class="toolbar-spacer"></span>
        <mat-icon class="dxfx_icon_btn_medium ml5" (click)="close()"
          >close</mat-icon
        >
      </mat-toolbar>

      <div class="container" style="height: calc(100% - 88px);">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <div class="p-2">
            <h2 *ngIf="mode == INJECT_TIME_CHANGE_TYPES.START_TIME">
              START TIME
            </h2>
            <h2 *ngIf="mode == INJECT_TIME_CHANGE_TYPES.DURATION">DURATION</h2>
          </div>
          <div class="p-0 mat-caption-12 mt10">
            <span *ngIf="mode == INJECT_TIME_CHANGE_TYPES.START_TIME">{{
              newPlannedDateTime | date: "MM/dd/yyyy, HH:mm"
            }}</span>
            <span *ngIf="mode == INJECT_TIME_CHANGE_TYPES.DURATION">{{
              currentDuration
            }}</span>
          </div>

          <div class="p-0 mt5">
            <navigation-toggle-button
              [model]="button1"
              [active]="button1.values.includes(selectedTimeMode)"
              (selected)="toggleBtnSelected($event)"
            ></navigation-toggle-button>
          </div>

          <div class="p-0 mat-caption-12 mt10">
            <span *ngIf="mode == INJECT_TIME_CHANGE_TYPES.START_TIME">{{
              currentDateTime | date: "MM/dd/yyyy, HH:mm"
            }}</span>
            <span *ngIf="mode == INJECT_TIME_CHANGE_TYPES.DURATION">{{
              newDuration
            }}</span>
          </div>
          <div class="p-0 mt5">
            <navigation-toggle-button
              [model]="button2"
              [active]="button2.values.includes(selectedTimeMode)"
              (selected)="toggleBtnSelected($event)"
            ></navigation-toggle-button>
          </div>
        </div>
      </div>
      <mat-toolbar class="overlay-toolbar">
        <span class="toolbar-spacer"></span>
        <ect-color-btn
          class="mr5"
          title="Save"
          icon="save"
          color="primary"
          (clicked)="save()"
        ></ect-color-btn>
      </mat-toolbar>
    </div>
  `,
  styleUrls: ["../inject-detail-overlay.component.scss"],
})
export class InjectDetailOL_ChangeTimeOverlay implements OnInit {
  inject: IExerciseTimelineInjectRef | undefined;
  mode: INJECT_TIME_CHANGE_TYPE | undefined;
  INJECT_TIME_CHANGE_TYPES = INJECT_TIME_CHANGE_TYPE;
  selectedTimeMode = TIME_MODE.PLANNED;
  TIME_MODES = TIME_MODE;
  button1: INavigationToggleBtn = {
    id: 1,
    label: "Use Planned Time",
    activeValue: TIME_MODE.PLANNED,
    values: [TIME_MODE.PLANNED],
  };
  button2: INavigationToggleBtn = {
    id: 2,
    label: "Use Current Time",
    activeValue: TIME_MODE.CURRENT,
    values: [TIME_MODE.CURRENT],
  };
  plannedDateTime = new Date();
  currentDateTime = new Date();
  currentDuration = 120;
  newDuration: number | undefined;
  constructor(
    public storage: StorageService,
    private dialogRef: MatDialogRef<InjectDetailOL_ChangeTimeOverlay>,
    @Inject(MAT_DIALOG_DATA) public data: TimeChangeOverlayData
  ) {
    this.inject = data.inject;
    this.mode = data.mode;
  }

  ngOnInit(): void {
    Logger.log("----------------------------", LEVEL.DEBUG);
    if (this.storage.currentExer && this.inject) {
      const newDeliveryDateTime: number = Math.round(
        (Number(this.inject.leftPosSmall) / 61) * 60
      );
      Logger.log(`newDeliveryDateTime : ${newDeliveryDateTime}`, LEVEL.DEBUG);

      const startDateTime = new Date(
        addMinutes(
          this.storage.currentExer.getDateTimeRaw(),
          newDeliveryDateTime
        )
      );

      this.plannedDateTime = startDateTime;
      Logger.log(`startDateTime : ${startDateTime}`, LEVEL.DEBUG);

      const minsFromStart = differenceInMinutes(
        startDateTime,
        this.storage.currentExer.getDateTimeRaw()
      );
      Logger.log(`minsFromStart : ${minsFromStart}`, LEVEL.DEBUG);

      this.currentDateTime = new Date();
      const minsFromCurrentTime = differenceInMinutes(
        this.currentDateTime,
        this.storage.currentExer.getDateTimeRaw()
      );
      Logger.log(
        `minsFromStartToCurrentTime : ${minsFromCurrentTime}`,
        LEVEL.DEBUG
      );
      this.currentDuration = this.inject.deliveryDurationInMinutes;
      if (this.mode && this.mode == INJECT_TIME_CHANGE_TYPE.DURATION) {
        this.button1.label = "Use Planned Duration";
        this.button2.label = "Use New Duration";

        const tzOffset = new Date().getTimezoneOffset();

        let diffInMins = 0;
        // console.warn(`isNegative: ${isNegative(tzOffset)} `);
        // console.warn(`offset: ${tzOffset} `);
        if (isNegative(tzOffset)) {
          diffInMins = differenceInMinutes(
            subMinutes(this.currentDateTime, tzOffset),
            startDateTime
          );
        } else {
          diffInMins = differenceInMinutes(
            addMinutes(this.currentDateTime, tzOffset),
            startDateTime
          );
        }
        // const diffInMins = differenceInMinutes(
        //   this.currentDateTime,
        //   startDateTime
        // );
        Logger.log(`diffInMins : ${diffInMins}`, LEVEL.DEBUG);
        this.newDuration = diffInMins;
      } else {
        this.button1.label = "Use Planned Time";
        this.button2.label = "Use Current Time";
      }
    }
  }
  toggleBtnSelected(e: INavigationToggleBtn) {
    console.log(e);
    this.selectedTimeMode = e.activeValue as TIME_MODE;
  }
  save() {
    this.dialogRef.close(this.selectedTimeMode);
  }
  close() {
    this.dialogRef.close(this.selectedTimeMode);
  }
  get deliveryRounded(): number {
    if (!this.inject) {
      return 0;
    }
    return Math.round((Number(this.inject.leftPosSmall) / 61) * 60);
  }
  get tacticalTime(): TacticalTime | undefined {
    if (!this.inject) {
      return undefined;
    }
    if (this.storage.currentExer) {
      return TIME.date_to_tt(
        addMinutes(
          this.storage.currentExer.getDateTimeRaw(),
          this.deliveryRounded
        )
      );
    } else {
      return undefined;
    }
  }
  get newPlannedDateTime(): Date {
    if (!this.tacticalTime) {
      return new Date();
    } else {
      return TIME.tt_toDate(this.tacticalTime);
    }
  }
}
