<div #_holder style="width: 100%;   height: 25px; font-weight: 500;">

  <div class="d-flex item-copy h100">
    <div class="p-0 flex-fill h100">

      <div class="d-flex h100 justify-content-center align-items-center" #_barHolder>

        <div class="p-0 tlc pt3" *ngFor="let votItem of votItems" [ngStyle]="{'background-color': votItem.color, 'width': votItem.width + 'px'}" 
        style="border-left: 1px solid black; color: black; height: 25px; font-weight: 600; user-select: none;" [matTooltip]="votItem.voteType" [class.colorLight]="votItem.voteType == TrainerFeedbackVoteTypes.NOT_RATED">
          {{votItem.count}}
        </div>
        
      </div>

    </div>
    
    <div class="p-0 ml-auto w40 tlr">{{injects.length}}</div>
  </div>
  <!-- <div class="ratingBarHolder" >
    <div class="ratingAnimationBar" #_progressBar [ngStyle]="{'background-color':color}"></div>
   <div class="ratingbar-grid">
    <div class="d-flex">
      <div class="p-0 rb_bLeft  ratingBox"></div>
      <div class="p-0  ratingBox"></div>
      <div class="p-0  ratingBox"></div>
      <div class="p-0 rb_bRight ratingBox"></div>
    </div>
   </div>
   
  </div> -->
</div>