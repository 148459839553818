import { Component, ElementRef, inject, Input, OnInit, ViewChild } from "@angular/core";
import { ChatMessageLocal } from "src/app/model/chatMessage.model";
import { StorageService } from "src/app/services/storage.service";
import { wait } from "src/app/services/utils/functions";
import gsap from 'gsap';
@Component({
  selector: 'toolbar-chat-item',
  template: `
    <div class="p-0 msgbox" [class.fadeOut]="!messageItem.isNew" [class.fadeIn]="messageItem.isNew" #msgbox>
    <!--    -->

<div class="d-flex align-items-center" [class.boxRight]="!messageItem.userName" >

  <div class="p-0 chatBubbleIcon" *ngIf="messageItem.userName" [matTooltip]="storage.getTooltipText(messageItem)" matTooltipClass="chatTT" matTooltipPosition="before" > 
    <!-- style="height: 18px!important;" -->
    <!-- <mat-icon style="font-size: 18px!important;">person</mat-icon> -->
  </div>
  <div class="p-0 chatBubble " [class.chatBubbleOwn]="!messageItem.userName" [matTooltip]="storage.getTooltipText(messageItem)" matTooltipClass="chatTT" matTooltipPosition="before" > 
    <!-- [class.tlr]="!comment.userName" -->
    {{messageItem.message}}
   </div>
</div>

<!-- <div class="p-0  flex-column">
  <div class="p-0 w100 chatMessageDate" [class.tlr]="!comment.userName" > 
    {{comment.userName}} {{comment.dayTime }} 
  </div>
  <div class="p-0 chatBubble chatMessageComment" [class.tlr]="!comment.userName" > 
   {{comment.message}}
  </div>
</div> -->


</div>
      

  `,

styleUrls: ['./injects-toolbar.component.scss'],
})
export class ToolbarChatItemComponent implements OnInit {

  @ViewChild('msgbox') msgbox!: ElementRef
  public storage = inject(StorageService)
  @Input() messageItem!: ChatMessageLocal
  // @Input() isLast: boolean = false
  
 // @Output() injectSelect: EventEmitter<IExerciseTimelineInjectRef> = new EventEmitter<IExerciseTimelineInjectRef>()
  ngOnInit(): void {
   wait(200, () => {
    if(this.messageItem.isNew) {
      const ran = this.getRandomTime()
      // console.log(`rand: ${ran}`);
      
      gsap.to(this.msgbox.nativeElement, {duration: 0.8,  height: 'auto',  delay: ran, ease: 'expo.inOut' })
      gsap.to(this.msgbox.nativeElement, {duration: 0.25,   opacity: 1, delay: 0.1, ease: 'expo.inOut' })
    }
   })
  //  wait(2600, ()=> {
  //   if(this.isLast) {
  //     console.log('last');
  //     //this.msgbox.nativeElement.scrollIntoView({behavior: 'smooth'});
  //   }
  //  })
  }
  private getRandomTime(){
    let min:number = 3;
    let max:number = 10;
    return (Math.random() * (max - min) + min) / 100; 
  }
 
}