import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { GoogleMap } from "@angular/google-maps";
import { Location } from "src/app/model/location.model";
import { MAP_STYLE_2 } from "src/app/services/utils/mapstyle";
import { ApiService } from "src/app/services/api.service";
import { StorageService } from "src/app/services/storage.service";
import { MAP_STYLE_3 } from "../../services/utils/mapstyle";
@Component({
  selector: "app-location-detail-overlay",
  templateUrl: "./location-detail-overlay.component.html",
  styleUrls: ["./location-detail-overlay.component.scss"],
})
export class LocationDetailOverlayComponent implements OnInit {
  @ViewChild("_map", { static: false }) _map: GoogleMap | undefined;
  saveEnabled: boolean = true;
  // isEditLocation:boolean = false;
  hasChanges: boolean = false;
  location: Location | undefined;
  // selectedLocation: Location | undefined
  locations: Location[] = [];
  markers: any[] = [];
  // zoom = 5
  options: google.maps.MapOptions = {
    center: { lat: 48.6622503, lng: 20.403186 },
    // draggable: false,
    // disableDoubleClickZoom: true,
    // clickableIcons: true,
    mapTypeId: "roadmap",
    zoom: 5,
    maxZoom: 20,
    minZoom: 5,
    backgroundColor: "#202B31",
    // disableDefaultUI: false,
    disableDefaultUI: false,
    styles: MAP_STYLE_3,
    zoomControl: true,
    scrollwheel: true,
    scaleControl: true,
    panControl: true,
  };
  constructor(
    public dialogRef: MatDialogRef<LocationDetailOverlayComponent>,
    public api: ApiService,
    public storage: StorageService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.initLocations();
    }, 1250);
  }

  private initLocations() {
    if (this.storage.currentExer) {
      this.hasChanges = false;
      this.locations = this.storage.currentExer.getLocations();
      var bounds = new google.maps.LatLngBounds();
      this.markers = [];
      for (let i = 0; i < this.locations.length; i++) {
        const element = this.locations[i];

        const position = new google.maps.LatLng(
          Number(element.lat!),
          Number(element.lng!)
        );
        this.markers.push({
          map: this._map!,
          title: element.id,
          position: {
            lat: Number(element.lat!),
            lng: Number(element.lng!),
          },
          // options : { icon: '/assets/marker/markerBlue.png', draggable: true },
          animation: google.maps.Animation.DROP,
        });
        bounds.extend(position);
      }
      if (this.locations.length > 1) {

        this._map!.fitBounds(bounds);
      }
    }
  }
  // markerOptions()
  checkLoc() {
    if (this.location && this._map) {
      if (this.location.lat != "" && this.location.lng != "") {
        // this.isEditLocation = true
        this.setMarker(this.location.lat!, this.location.lng!);
        setTimeout(() => {
          this._map!.panTo({
            lat: Number(this.location!.lat),
            lng: Number(this.location!.lng),
          });
        }, 500);
      }
    }
  }

  // mapTapped(event:any) {
  //   // console.log(event);

  //   if(this.markers.length > 0) { return }
  //   // if(this.location) { return }
  //   let latLng = event.latLng.toJSON()//JSON.stringify(event.latLng.toJSON(), null, 2)
  //   if(this.isEditLocation === false) {
  //     if (this.location) {
  //       this.location.lat = latLng.lat
  //       this.location.lng = latLng.lng
  //     } else {
  //       this.location = {
  //         id: -1,
  //         title: '',
  //         description: '',
  //         lat: latLng.lat,
  //         lng: latLng.lng,
  //       }
  //     }
  //     this.setMarker(latLng.lat, latLng.lng, false)
  //   }

  //   // console.log(latLng.lat)
  //   // console.log(latLng.lng)

  // }

  mapMousedown(e: any, marker: any) {
    // console.log(e);
    // console.log(marker.title);

    const index = this.locations.findIndex((item) => item.id == marker.title);
    // console.log(index);
    if (index != -1) {
      this.location = this.locations[index];
      this._map!.panTo({
        lat: Number(this.location!.lat),
        lng: Number(this.location!.lng),
      });
    }
    // // this.selected = e;
    // console.log(this.location);
    // this._map!.panTo({lat : Number(this.location!.lat), lng: Number(this.location!.lng)});
  }
  // markerDrag(e:any) {
  //   let latLng = e.latLng.toJSON()//JSON.stringify(event.latLng.toJSON(), null, 2)
  //   console.log(latLng);
  // }

  dragChanged(e: any, marker: any) {
    let latLng = e.latLng.toJSON(); //JSON.stringify(event.latLng.toJSON(), null, 2)

    if (this.location) {
      this.location.lat = latLng.lat;
      this.location.lng = latLng.lng;
      this.hasChanges = true;
    }
  }

  close() {
    this.dialogRef.close();
  }

  addLocation() {
    const newL: Location = {
      id: -1,
      title: "New Location",
      description: "",
      lat: "48.6622503",
      lng: "20.403186",
    };
    this.locations.push(newL);
    this.location = newL;
    this.markers.push({
      map: this._map!,
      title: newL.id,
      position: {
        lat: Number(newL.lat!),
        lng: Number(newL.lng!),
      },
      animation: google.maps.Animation.DROP,
    });
    this.hasChanges = true;
  }

  get locationTitle(): string {
    if (this.location) {
      return this.location.title ?? "";
    } else {
      return "";
    }
  }
  set locationTitle(val: string) {
    if (this.location) {
      this.location.title = val;
    } else {
      this.location = {
        id: -1,
        title: val,
        lat: "",
        lng: "",
      };
    }
    this.hasChanges = true;
  }
  get locationDescription(): string {
    if (this.location) {
      return this.location.description ?? "";
    } else {
      return "";
    }
  }
  set locationDescription(val: string) {
    if (this.location) {
      this.location.description = val;
    } else {
      this.location = {
        id: -1,
        title: "",
        description: val,
        lat: "",
        lng: "",
      };
    }
    this.hasChanges = true;
  }

  async save() {
    if (!this.location) {
      return;
    }
    const dto = {
      title: this.locationTitle,
      description: this.locationDescription,
      exerciseId: this.storage.currentExer!.id,
      lat: this.location.lat!,
      lng: this.location.lng!,
    };
    // console.log(dto);

    if (this.location.id != -1) {
      const r = await this.api.apiPatch(
        "location",
        this.location.id!.toString(),
        dto
      );
      this.storage.currentExer?.setLocations(r as Location[]);
      setTimeout(() => {
        this.location = undefined;
        this._map!.panTo({ lat: 48.6622503, lng: 20.403186 });
        this._map!.googleMap?.setZoom(5);
        this.initLocations();
      }, 500);
    } else {
      const r = await this.api.apiPost("location", dto);
      this.storage.currentExer?.setLocations(r as Location[]);
      setTimeout(() => {
        this.location = undefined;
        this._map!.panTo({ lat: 48.6622503, lng: 20.403186 });
        this._map!.googleMap?.setZoom(5);
        this.initLocations();
      }, 500);
    }
  }

  selectionSelect(e: Location) {
    this.location = e;
    // this.selected = e;
    console.log(this.location);
    this._map!.panTo({
      lat: Number(this.location!.lat),
      lng: Number(this.location!.lng),
    });
  }

  setMarker(lat: string, lng: string, draggable: boolean = true) {
    console.log("setMarker");

    // this.markers = []

    // this.markers.push(
    //   {
    //   map: this._map!,

    //    position: {
    //      lat: Number(lat),
    //      lng: Number(lng)
    //    },

    //     options : { icon: '/assets/marker/markerBlue.png', draggable: true },
    //     animation: google.maps.Animation.DROP,
    //  }
    //  )
  }
}

//       var bounds = new google.maps.LatLngBounds();
//       var infowindow = new google.maps.InfoWindow();

//       for (let i = 0; i < this.locations.length; i++) {
//         const element = this.locations[i];
//         const position = new google.maps.LatLng(Number(element.lat!),Number(element.lng!))
//         // console.log(position);
//         var marker = new google.maps.Marker({
//           position: position,
//           map: this._map!.googleMap
//           // position: new google.maps.LatLng(Number(this.locations[i].lat!), Number(this.locations[i].lng!)),
//           // map: this._map!
//         });

//         //extend the bounds to include each marker's position
//         bounds.extend(marker.getPosition()!);
// // console.log(marker.getPosition());

// google.maps.event.addListener(marker, 'click', (function(marker, i) {
//   return function() {
//     infowindow.setContent(locations[i][0]);
//     infowindow.open(map, marker);
//   }
// })(marker, i));
//       }

//       //now fit the map to the newly inclusive bounds
//       this._map!.fitBounds(bounds);

//       //(optional) restore the zoom level after the map is done scaling
//       // var listener = google.maps.event.addListener(map, "idle", function () {
//       //     map.setZoom(3);
//       //     google.maps.event.removeListener(listener);
//       // });
