import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CreateLogbookEntryDto } from 'src/app/model/dtos';
import { ExerciseLog } from 'src/app/model/exercise.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { wait } from 'src/app/services/utils/functions';

@Component({
  selector: 'app-exercise-logbook-overlay',
  templateUrl: './exercise-logbook-overlay.component.html',
  styleUrls: ['./exercise-logbook-overlay.component.scss']
})
export class ExerciseLogbookOverlayComponent implements OnInit, AfterViewInit {
  public api = inject(ApiService);
  public storage = inject(StorageService);
  public dialogRef = inject(MatDialogRef<ExerciseLogbookOverlayComponent>)
  dispCols: string[] = ['createdAt', 'logType' , 'assignedInjectName', 'teamName', 'user_id', 'message'];
  logbookEntries: ExerciseLog[] = []
  selectedLogbookEntry: ExerciseLog | undefined
  message:string = ''
  commentFor:string = ''
  wordCount = 0
  isSaving = false
  // logSelected = false
  logDataSource:MatTableDataSource<ExerciseLog> = new MatTableDataSource<ExerciseLog>(this.logbookEntries);
  @ViewChild(MatSort) sort!: MatSort;
  ngOnInit(): void {
    this.load()
  }
  ngAfterViewInit() {
    // if(this.logDataSource) {

    //   this.logDataSource.sort = this.sort!;
    // }
  }

  private async load() {
    if (this.storage.currentExer) {
      const id = this.storage.currentExer.id;
      const exDe = await this.api.apiGetP(`exercise/logbook/${id}`)
      // console.log(exDe);
      
      this.logbookEntries = exDe as ExerciseLog[]
       console.log(this.logbookEntries);
      this.logDataSource = new MatTableDataSource<ExerciseLog>(this.logbookEntries);
      this.logDataSource.sort = this.sort!;
      // if(this.logDataSource) {

      //   this.logDataSource.sort = this.sort!;
      // }
    }
  }
  // getInjectNo(injectDocId:string){
  //   return injectDocId != null && injectDocId != '' ? this.ui.exerciseinjects.find(item => item.documentId === injectDocId).short_title : ''
  // }
  injectTapped(e:ExerciseLog){
    if (this.selectedLogbookEntry && this.selectedLogbookEntry == e) {
      this.selectedLogbookEntry = undefined
      this.commentFor = ''
      this.message = ''
    } else {
      this.selectedLogbookEntry = e
      this.commentFor =  this.selectedLogbookEntry.assignedInjectName ? ` for ${this.selectedLogbookEntry.assignedInjectName}` : '',
      this.message = this.selectedLogbookEntry.message ?? ''
    }
    // this.logSelected = true
    
  }
  clear(){
    console.log('clear');
    
    // if(this.selectedLogbookEntry){

    //   this.selectedLogbookEntry = undefined
    //   this.message = ''
    // }
  }
  onChange(e:any){
    this.wordCount = this.message.length
  }
  async save() {
    if (!this.storage.currentExer) { return }

    if (this.selectedLogbookEntry) {
      let dto = {
        message: this.message
      }
    
      await this.api.apiPatch(`exercise/logbook`, this.selectedLogbookEntry.id.toString(), dto)
    } else {

      let dto: CreateLogbookEntryDto = {
        exerciseId: this.storage.currentExer.id,
        message: this.message
      }
     
      await this.api.apiPost(`exercise/logbook`, dto)
    }

    wait(250,()=>{
      this.load()
      this.selectedLogbookEntry = undefined
      this.commentFor = ''
      this.message = ''
    })  
  
  }
  get inputDisabled(): boolean {
    // if(this.selectedLogbookEntry) {
    //   return true;
    // } else {
    //   return false;
    // }
    
    return false;
  }
  close() {
    this.dialogRef.close();
  }
}
