import { iCompany } from "src/app/model/company.model";
import { ICompanyUserRoleType, IUserRole } from "src/app/model/exercise.model";
import { UserAuthState } from "src/app/model/iuser.model";

export enum ADMIN_VIEW_MODE {
  LOADING = "LOADING",
  VIEW_INJECTS = "VIEW_INJECTS",
  VIEW_TR_OBJ = "VIEW_TR_OBJ",
  USERS_VIEW = 'USERS_VIEW'
}
export interface USR {
  uid: string;
  name: string;
  roles: IUserRole[]
  companies: iCompany[]
  email:string;
  authState: UserAuthState;
}

export interface USR_LOCAL {
  uid: string;
  name: string;
  roles: IusrRoleLocal[]
  companies: iCompanyLocal[]
  email:string;
  authState: UserAuthState;
}
export interface iCompanyLocal {
  id: string
  name: string
    role: ICompanyUserRoleType;
    icon: string
}
export interface IusrRoleLocal {
  name: string
  role: IUserRole;
  icon: string
}

export interface ModifyUserCompanyOverlayData {
  usr: USR
}
