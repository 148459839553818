import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ExerciseState } from "src/app/model/enums";
import {
  ExerciseClass,
  HourItemI,
  HourItemIState,
  IExerciseTimelineInjectRef,
  IExerciseTimelineItem,
  TacticalMonth,
  TacticalTime,
} from "src/app/model/exercise.model";
import { TIME } from "src/app/services/helpers";
import { map, startWith } from "rxjs/operators";
import { addMinutes, differenceInDays, differenceInMinutes } from "date-fns";
import * as _ from "lodash";
import { ApiService } from "src/app/services/api.service";
import { getExerciseDays, wait } from "src/app/services/utils/functions";
import { StorageService } from "../../services/storage.service";
import { InjectUpdateDto } from "src/app/model/dtos";
import { BUTTON_ERROR_TYPE, miniOvlItem } from "src/app/model/models";
import { UiService } from "src/app/services/ui.service";
import { LEVEL, Logger } from "src/app/services/logger.service";

@Component({
  selector: "app-exercise-duration-overlay",
  templateUrl: "./exercise-duration-overlay.component.html",
  styleUrls: ["./exercise-duration-overlay.component.scss"],
})
export class ExerciseDurationOverlayComponent implements OnInit {
  assigendExerciseInjects: IExerciseTimelineInjectRef[] = [];
  @ViewChild("_time_line_mini_map_box") _time_line_mini_map_box:
    | ElementRef
    | undefined;
  @ViewChild("timelineInjectsHolder") timelineInjectsHolder:
    | ElementRef
    | undefined;
  @ViewChild("_dayInput") _dayInput: ElementRef | undefined;
  @ViewChild("_monthStart") _monthStart: ElementRef | undefined;
  @ViewChild("_monthEnd") _monthEnd: ElementRef | undefined;
  @ViewChild("_timeBar") _timeBar: ElementRef | undefined;

  // durationDays:string =''
  // durationHours:string =''
  // durationMinutes:string =''
  // durationDiffInSec:number =0;
  startDateTime: TacticalTime | undefined;
  endDateTime: TacticalTime | undefined;
  exerciseDurationInMinutes: number = 0;

  // lastStartDateTime:TacticalTime  | undefined
  // lastExerciseDurationInMinutes: number = 0
  //timeStart:TacticalTime  | undefined
  durationInDays = "00";
  durationInHours = "00";
  durationInMinutes = "00";
  hourItems: HourItemI[] = [];

  miniOvlItems: miniOvlItem[] = [];
  startMonthFormC = new FormControl();

  selectedStartMonth: TacticalMonth | undefined;
  adjustInjectStartTime = true;
  isLoading = false;
  preloaderVisible = true;
  allowdDays = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  allowdDaysHours = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  errorText = "";
  currentFact = 0;

  buttonErrors: BUTTON_ERROR_TYPE[] = [];
  BUTTON_ERROR_TYPES = BUTTON_ERROR_TYPE;

  HourItemIStates = HourItemIState;

  get isReadOnly(): boolean {
    if (!this.excercise) {
      return true;
    }
    return !this.excercise.isAdmin;
  }

  showSaveButton: boolean = true;
  months: TacticalMonth[] = [
    { viewVal: "jan", index: 0 },
    { viewVal: "feb", index: 1 },
    { viewVal: "mar", index: 2 },
    { viewVal: "apr", index: 3 },
    { viewVal: "may", index: 4 },
    { viewVal: "jun", index: 5 },
    { viewVal: "jul", index: 6 },
    { viewVal: "aug", index: 7 },
    { viewVal: "sep", index: 8 },
    { viewVal: "oct", index: 9 },
    { viewVal: "nov", index: 10 },
    { viewVal: "dec", index: 11 },
  ];

  filteredMonths: Observable<TacticalMonth[]> | undefined;

  exerciseStates = ExerciseState;

  excercise: ExerciseClass;

  dayInput = 0;
  hourInput = 0;
  minInput = 0;
  yearInput = 0;
  name = "";
  miniMapWidth: number = 0;

  get hasAssignedInjects(): boolean {
    return this.storage.assigendExerciseInjects.length > 0;
  }

  constructor(
    public dialogRef: MatDialogRef<ExerciseDurationOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExerciseClass,
    public storage: StorageService,
    public ui: UiService,
    private api: ApiService
  ) {
    this.excercise = data;
    const _c: TacticalTime = TIME.tt_currentUTC();
    this.isLoading = true;
    this.preloaderVisible = true;
  }

  ngOnInit(): void {
    this.name = `${this.constructor.name}`;

    // const txOfs = new Date().getTimezoneOffset();

    // Logger.log(`${this.name} offset ${txOfs}`, LEVEL.DEBUG);

    setTimeout(() => {
      if (!this.excercise) {
        return;
      }
      this.changedInjectOffset = 0;
      this.startDateTime = TIME.date_lcoal_to_tt(this.excercise.getDateTime());

      this.selectedStartMonth = this.startDateTime.month;

      this.exerciseDurationInMinutes = this.excercise.durationInHours * 60;
      const days = Math.round(this.excercise.durationInHours / 24);
      const hours = this.excercise.durationInHours % 24;
      // const mins = this.excercise.durationInHours % 24

      this.durationInDays = `${days}`;
      this.durationInHours = `${hours}`;
      /** HIER 1 */

      this.assigendExerciseInjects = _.cloneDeep(
        this.storage.assigendExerciseInjects
      );

      this.calcDuration(() => {
        wait(160, () => {
          this.isLoading = false;
          this.init();
          wait(160, () => {
            this.preloaderVisible = false;
          });
        });
      });
      this.filteredMonths = this.startMonthFormC.valueChanges.pipe(
        startWith(""),
        map((value) => (typeof value === "string" ? value : value.name)),
        map((name) => (name ? this.applyFilter(name) : this.months.slice()))
      );

      this.miniMapWidth = this._time_line_mini_map_box
        ? this._time_line_mini_map_box.nativeElement.clientWidth
        : 1200;
    }, 500);
  }

  private init() {
    wait(50, () => {
      // Logger.log(`${this.name} windot: ${window.innerHeight}`);
      if (this._time_line_mini_map_box) {
        const height = this._time_line_mini_map_box.nativeElement.clientHeight;
        Logger.log(`${this.name} height: ${height}`, LEVEL.DEBUG);

        if (this.timelineInjectsHolder) {
          const injectHolderHeight =
            this.timelineInjectsHolder.nativeElement.clientHeight;
          Logger.log(
            `${this.name} injectHolderHeight: ${injectHolderHeight}`,
            LEVEL.DEBUG
          );

          // if (injectHolderHeight > 350) {

          //   const newHeight = (window.innerHeight - 120) - 400
          //   Logger.log(`${this.name} newHeight ${newHeight}`, LEVEL.DEBUG);
          //   gsap.to(this._time_line_mini_map_box.nativeElement, { duration: 0.25, delay: 0.4, height: newHeight, ease: 'power1.inOut' })
          // }
        }
      }
    });
    wait(1300, () => {
      if (this._dayInput) {
        this._dayInput.nativeElement.focus();
        this._dayInput.nativeElement.select();
      }
    });
  }

  private setupTimeline(dateTimeStarted: number, durationInHours: number) {
    this.hourItems = getExerciseDays(dateTimeStarted, durationInHours);
  }

  applyFilter(name: string): TacticalMonth[] {
    const filterValue = name.toLowerCase();

    return this.months.filter(
      (option) => option.viewVal.toLowerCase().indexOf(filterValue) === 0
    );
  }

  formatedString(tt?: TacticalTime) {
    if (!tt) {
      return "";
    }

    const dt = TIME.tt_toDate(tt);
    return TIME.formatedDateString(dt) + " " + TIME.formatedTimeString(dt);
  }

  calcDuration(callback: () => void) {
    if (this.startDateTime) {
      // Logger.log(`${this.name} this.exerciseDurationInMinutes ${this.exerciseDurationInMinutes}`);
      if (this.exerciseDurationInMinutes < 1440) {
        this.exerciseDurationInMinutes = 1440;
      }
      const endDate = new Date(
        addMinutes(
          TIME.tt_toDate(this.startDateTime),
          this.exerciseDurationInMinutes
        )
      );
      this.endDateTime = TIME.date_to_tt(endDate);
      this.exerciseDurationInMinutes = differenceInMinutes(
        endDate,
        TIME.tt_toDate(this.startDateTime)
      );

      // if(this.durationDiffInSec != (this.timelineController.exerciseDurationInMinutes * 60) ){
      //   this.durationDiffInSec != (this.timelineController.exerciseDurationInMinutes * 60)
      //   this.showSaveButton = true;
      // }
      this.setupTimeline(
        Number(TIME.tt_toDate(this.startDateTime).getTime()),
        this.exerciseDurationInMinutes / 60
      );
    }

    callback();
  }

  onTFChanged(event: any, type: string) {
    Logger.log(`${this.name} onTFChanged`, LEVEL.DEBUG);

    let canCalc = true;
    if (type == "DAY") {
      let charsAt = this.startDateTime!.day;
      let cleanUp = false;
      for (let c of charsAt.split("")) {
        Logger.log(`${this.name} c: ${c}`, LEVEL.DEBUG);
        if (!this.allowdDays.includes(c)) {
          cleanUp = true;
          canCalc = false;
        }
      }
      const _day = _.parseInt(this.startDateTime!.day);
      if (cleanUp || (_day && _day > 31)) {
        setTimeout(() => {
          this.startDateTime!.day = TIME.date_lcoal_to_tt(new Date()).day;
        }, 10);
      } else if (_day && _day < 10) {
        setTimeout(() => {
          this.startDateTime!.day = `0${this.startDateTime!.day}`;
        }, 10);
      }
      // setTimeout(()=>{
      //   this.timelineController.changeStartByTT(this.startDateTime)
      // },10)
    } else if (type == "HOUR") {
      let charsAt = this.startDateTime!.hours;
      let cleanUp = false;
      for (let c of charsAt.split("")) {
        if (!this.allowdDays.includes(c)) {
          cleanUp = true;
          canCalc = false;
        }
      }
      const _hours = _.parseInt(this.startDateTime!.hours);
      // Logger.log(`${this.name} cleanUp: ${cleanUp}`);
      // Logger.log(`${this.name} _hours: ${_hours}`);
      if (cleanUp || (_hours && _hours > 23)) {
        setTimeout(() => {
          this.startDateTime!.hours = TIME.date_lcoal_to_tt(new Date()).hours;
        }, 10);
      } else if (_hours && _hours < 10) {
        setTimeout(() => {
          this.startDateTime!.hours = `0${this.startDateTime!.hours}`;
        }, 10);
      } else {
        if (event.target.value && event.target.value == "0") {
          setTimeout(() => {
            this.startDateTime!.hours = `00`;
          }, 10);
        }
      }
      // setTimeout(()=>{
      //   this.timelineController.changeStartByTT(_startDateTime)
      // },10)
      // Logger.log(this.startDateTime);
    } else if (type == "MIN") {
      let charsAt = this.startDateTime!.minutes;
      let cleanUp = false;
      for (let c of charsAt.split("")) {
        if (!this.allowdDays.includes(c)) {
          cleanUp = true;
          canCalc = false;
        }
      }
      const _minutes = _.parseInt(this.startDateTime!.minutes, 2);
      Logger.log(
        `${this.name} _minutes ${_minutes} cleanUp: ${cleanUp}`,
        LEVEL.DEBUG
      );
      if (cleanUp || (_minutes && _minutes > 59)) {
        setTimeout(() => {
          this.startDateTime!.minutes = TIME.date_lcoal_to_tt(
            new Date()
          ).minutes;
        }, 10);
      } else if (_minutes && _minutes < 10) {
        setTimeout(() => {
          this.startDateTime!.minutes = `0${this.startDateTime!.minutes}`;
        }, 100);
      } else {
        if (event.target.value && event.target.value == "0") {
          setTimeout(() => {
            this.startDateTime!.minutes = `00`;
          }, 10);
        }
      }
    } else if (type == "YEAR") {
    }

    wait(500, () => {
      if (canCalc) {
        this.calcDuration(() => {});
        this.showSaveButton = true;
      }
    });

    // wait(1500, ()  => {
    //   this.lastStartDateTime = _.cloneDeep(this.startDateTime)
    // })
  }

  startMonthSelected(event: any) {
    Logger.log(`${this.name} ${event}`, LEVEL.DEBUG);
    if (this.startDateTime) {
      this.startDateTime.month = event; //.option.value
    }
    this.calcDuration(() => {});
  }

  get saveBtnDisabled(): boolean {
    if (this.exerciseDurationInMinutes >= 1440) {
      return false;
    } else {
      return true;
    }
  }

  private checkDaysBetween() {
    if (this.endDateTime && this.startDateTime) {
      const diffInDays = differenceInDays(
        TIME.tt_toDate(this.endDateTime),
        TIME.tt_toDate(this.startDateTime)
      );
      // Logger.log(`${this.name} diffInDays: ${diffInDays}`);
      this.durationInDays = `${diffInDays}`;
      const diffInMins = differenceInMinutes(
        TIME.tt_toDate(this.endDateTime),
        TIME.tt_toDate(this.startDateTime)
      );
      // Logger.log(`${this.name} diffInMins: ${diffInMins}`);
    }
  }

  /** ADD TIME  */
  addTimeFor(itemType: string) {
    this.errorText = "";
    if (itemType == "DAY") {
      const days = _.parseInt(this.durationInDays);

      let added = days + 1;

      this.durationInDays = `${added}`; //added < 10 ? `0${added}` : `${added}`
      const start = new Date(TIME.tt_toDate(this.startDateTime!));

      this.exerciseDurationInMinutes = this.exerciseDurationInMinutes + 1440;

      this.endDateTime = TIME.date_to_tt(
        addMinutes(start, this.exerciseDurationInMinutes)
      );
    } else if (itemType == "HOUR") {
      const start = new Date(TIME.tt_toDate(this.startDateTime!));
      const hours = _.parseInt(this.durationInHours);

      let added = hours + 1;
      if (added == 24) {
        this.durationInHours = `0`;
      } else {
        this.durationInHours = `${added}`; //added < 10 ? `0${added}` : `${added}`
      }
      this.exerciseDurationInMinutes = this.exerciseDurationInMinutes + 60;
      this.endDateTime = TIME.date_to_tt(
        addMinutes(start, this.exerciseDurationInMinutes)
      );

      this.checkDaysBetween();
    }
    wait(150, () => {
      this.setupTimeline(
        TIME.tt_toDate(this.startDateTime!).getTime(),
        this.exerciseDurationInMinutes / 60
      );
    });

    this.showSaveButton = true;
  }

  subTimeFor(itemType: string) {
    if (itemType == "DAY") {
      const days = _.parseInt(this.durationInDays);
      let newDays = days - 1;
      if (days >= 1 && newDays >= 1) {
        this.durationInDays = `${newDays}`; //newDays < 10 ? `0${newDays}` : `${newDays}`
        const start = new Date(TIME.tt_toDate(this.startDateTime!));

        this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 1440;

        this.endDateTime = TIME.date_to_tt(
          addMinutes(start, this.exerciseDurationInMinutes)
        );
      }
    } else if (itemType == "HOUR") {
      const start = new Date(TIME.tt_toDate(this.startDateTime!));

      const hours = _.parseInt(this.durationInHours);

      let newHours = hours - 1;
      if (hours >= 0 && newHours >= 0) {
        Logger.log(`${this.name} hier 1: ${newHours}`, LEVEL.DEBUG);

        this.durationInHours = `${newHours}`; //newHours < 10 ? `0${newHours}` : `${newHours}`
      } else {
        Logger.log(`${this.name} hier 2: ${newHours}`, LEVEL.DEBUG);
        this.durationInHours = `23`;
      }
      this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 60;

      this.endDateTime = TIME.date_to_tt(
        addMinutes(start, this.exerciseDurationInMinutes)
      );
    } else if (itemType == "MIN") {
    }
    this.checkDaysBetween();
    wait(150, () => {
      this.setupTimeline(
        TIME.tt_toDate(this.startDateTime!).getTime(),
        this.exerciseDurationInMinutes / 60
      );
    });
    this.showSaveButton = true;
  }

  getEarliestInject(): IExerciseTimelineInjectRef | undefined {
    const latestInject = _.minBy(this.assigendExerciseInjects, function (i) {
      return Number(i.deliveryDateTime);
    });
    return latestInject;
  }
  getLatestInject(): IExerciseTimelineInjectRef | undefined {
    const latestInject = _.maxBy(this.assigendExerciseInjects, function (i) {
      return Number(i.deliveryDateTime) + Number(i.deliveryDurationInMinutes);
    });
    return latestInject;
  }
  addHourToInjects() {
    if (!this.canAddHour) {
      return;
    }
    this.changedInjectOffset += 60;
    for (var i = 0; i < this.assigendExerciseInjects.length; i++) {
      const deliveryDateTime =
        this.assigendExerciseInjects[i].deliveryDateTime + 60;
      this.assigendExerciseInjects[i].deliveryDateTime = deliveryDateTime;
      this.assigendExerciseInjects[i].leftPos = (deliveryDateTime * 121) / 60;
      this.assigendExerciseInjects[i].leftPosSmall =
        (deliveryDateTime * 61) / 60;
    }
  }
  subHourToInjects() {
    if (!this.canSubHour) {
      return;
    }
    this.changedInjectOffset -= 60;
    for (var i = 0; i < this.assigendExerciseInjects.length; i++) {
      const deliveryDateTime =
        this.assigendExerciseInjects[i].deliveryDateTime - 60;
      this.assigendExerciseInjects[i].deliveryDateTime = deliveryDateTime;
      this.assigendExerciseInjects[i].leftPos = (deliveryDateTime * 121) / 60;
      this.assigendExerciseInjects[i].leftPosSmall =
        (deliveryDateTime * 61) / 60;
    }
  }

  get canAddHour(): boolean {
    const inj = this.getLatestInject();
    if (inj) {
      const maxTime =
        Number(inj.deliveryDateTime) + Number(inj.deliveryDurationInMinutes);
      return maxTime < this.exerciseDurationInMinutes + 60;
    } else {
      return false;
    }
  }

  get canSubHour(): boolean {
    const inj = this.getEarliestInject();
    if (inj) {
      return inj.deliveryDateTime >= 60;
    } else {
      return false;
    }
  }

  private changedInjectOffset = 0;

  async save() {
    this.preloaderVisible = true;
    wait(16, () => {
      this.isLoading = true;
    });

    Logger.log(
      `adjustInjectStartTime ${this.adjustInjectStartTime}`,
      LEVEL.DEBUG
    );
    var canSave = true;

    const latestInject = this.getLatestInject();

    if (latestInject) {
      Logger.log(
        `${this.name} Latest Inject: ${latestInject.short_title} `,
        LEVEL.DEBUG
      );
      const latestInjectEndTime =
        Number(latestInject.deliveryDateTime) +
        Number(latestInject.deliveryDurationInMinutes);
      Logger.log(
        `${this.name} deliveryDateTime: ${latestInject.deliveryDateTime} deliveryDurationInMinutes: ${latestInject.deliveryDurationInMinutes} endTime: ${latestInjectEndTime}`,
        LEVEL.DEBUG
      );
      Logger.log(
        `${this.name} Ex duration in mins: ${this.exerciseDurationInMinutes} `,
        LEVEL.DEBUG
      );

      if (this.exerciseDurationInMinutes < latestInjectEndTime) {
        this.errorText = "Error. Some inject delivery time after Exercise end";
        wait(7000, () => {
          this.errorText = "";
        });
        canSave = false;
      }
    }

    if (!this.excercise) {
      return;
    }

    if (this.adjustInjectStartTime && canSave) {
      // this.currentFact = this.timelineController.getLeftSpacing()
      Logger.log(
        `${this.name} save-- space:  ${this.currentFact}`,
        LEVEL.DEBUG
      );
      // await this.fixAssignedInjectTime(Number(this.currentFact))
    }

    if (canSave && this.changedInjectOffset != 0) {
      await this.fixAssignedInjectTime(this.changedInjectOffset);
    }

    if (canSave) {
      this.excercise.dateTimeStarted = TIME.tt_toDate(
        this.startDateTime!
      ).getTime();

      this.excercise.durationInHours =
        Number(this.exerciseDurationInMinutes) / 60;
      const dto = {
        name: this.excercise.name,
        description: this.excercise.description,
        durationInHours: Number(this.excercise.durationInHours),
        dateTimeStarted: Number(this.excercise.dateTimeStarted),
        status: this.excercise.status,
      };

      const result = await this.api.apiPatch(
        "exercise",
        this.excercise.id,
        dto
      );

      wait(550, () => {
        this.isLoading = false;
      });
      // this.ui.onReloadTimeLineEvent.emit()
      setTimeout(() => {
        this.dialogRef.close("RELOAD");
      }, 250);
    } else {
      wait(260, () => {
        this.isLoading = false;
      });
    }
  }

  async borderBoxTapped(
    hourIndex: number,
    topIndex: number,
    item: IExerciseTimelineItem
  ) {
    console.log(item);
    //return
  }

  close() {
    this.dialogRef.close();
  }

  dayPlusTapped() {}
  dayMinusTapped() {}
  hourPlusTapped() {}
  hourMinusTapped() {}
  // ngInputChanged(p: string) {}

  getInjects(timelineItemId: number): IExerciseTimelineInjectRef[] {
    if (!this.excercise) {
      return [];
    }
    var items = this.assigendExerciseInjects
      .filter((item) => item.exerciseTimelineId == timelineItemId)
      .sort((a, b) => a.deliveryDateTime - b.deliveryDateTime);

    for (var i = 0; i < items.length; i++) {
      let current = items[i];

      var items2 = items.filter(
        (item) =>
          Number(item.deliveryDateTime) >= Number(current.deliveryDateTime) &&
          Number(item.deliveryDateTime) <=
            Number(current.deliveryDateTime) +
              Number(current.deliveryDurationInMinutes)
      ); //.sort((a,b) => a.deliveryDateTime - b.deliveryDateTime)
      //items[i].topPos = 0//items2.length
      let topPos = 0;
      for (var j = 0; j < items2.length; j++) {
        if (current.id != items2[j].id && !current.topPos) {
          topPos++;
          items2[j].topPos = topPos;
        }
      }

      let roleItemIndex = this.excercise.timelineItems.findIndex(
        (item) => item.id == timelineItemId
      );
      if (roleItemIndex != -1) {
        let currentHeight = this.excercise.timelineItems[roleItemIndex].height;
        this.excercise.timelineItems[roleItemIndex].height =
          topPos > currentHeight ? topPos : currentHeight;
      }
    }

    return items; //this.api.injects.filter(item => item.timelineItemId ==  timelineItemId)
  }

  isSmallSize = false;
  toggleSize() {
    this.isSmallSize = !this.isSmallSize;
  }
  get checkboxLabel(): string {
    return "adjust inject start time";
    //return this.fixedInjectStartTime ? "static inject start time" : "static inject start time"
  }
  winIsExpanded = false;
  toggleWindowSize() {
    this.dialogRef.updateSize(
      this.winIsExpanded ? "1200px" : "90%",
      this.winIsExpanded ? "70%" : "90%"
    );
    this.winIsExpanded = !this.winIsExpanded;
  }
  get sizeIcon(): string {
    return this.winIsExpanded ? "close_fullscreen" : "open_in_full";
  }
  get zoomIcon(): string {
    return this.isSmallSize ? "zoom_out" : "zoom_in";
  }
  private getCurrentEarliestDeliveryDateTime(): number {
    const earliestInject = _.minBy(
      this.assigendExerciseInjects,
      "deliveryDateTime"
    );
    if (earliestInject) {
      return earliestInject.deliveryDateTime;
    } else {
      return 0;
    }
  }

  private async fixAssignedInjectTime(fact: number) {
    Logger.log(`${this.name} fixAssignedInjectTime fact ${fact}`, LEVEL.DEBUG);
    // return
    for (var i = 0; i < this.storage.assigendExerciseInjects.length; i++) {
      //.storage
      const inj = this.storage.assigendExerciseInjects[i]; //storage.
      // Logger.log(`${this.name} inj.deliveryDateTime ${inj.deliveryDateTime}`);
      var ids = "";
      if (inj.trainingObjectives) {
        ids = _.map(inj.trainingObjectives, "id").toString();
        // Logger.log(`${this.name} ids`, ids);
      }
      const deliveryDateTime = Number(Number(inj.deliveryDateTime) + fact);
      const dto: InjectUpdateDto = {
        description: inj.description,
        short_title: inj.short_title,
        stateId: inj.state.id,
        deliveryDateTime: deliveryDateTime, //Number(Number(inj.deliveryDateTime) + fact),
        deliveryDurationInMinutes: Number(inj.deliveryDurationInMinutes),
        locationId: inj.location ? inj.location.id : -1,

        related_documents: inj.related_documents,
        related_equipment: inj.related_equipment,
        role_players_instruction: inj.role_players_instruction,
        on_scene_preparation: inj.on_scene_preparation,
        additional_guidance_caution_options:
          inj.additional_guidance_caution_options,
        expected_outcomes: inj.expected_outcomes,
        trainers_action_requirement: inj.trainers_action_requirement,
        learning_objectives: ids,
      };
      if (dto.locationId == -1) {
        delete dto.locationId;
      }
      //  Logger.log(`${this.name} dto.deliveryDateTime: ${dto.deliveryDateTime} deliveryDateTime: ${inj.deliveryDateTime}`);
      await this.api.apiPatch("inject/update-assigned", inj.id.toString(), dto);
    }
  }
}

/* ngInputChanged(p: string) {
  // this.calcDuration()
  // this.showSaveButton = true;


  // if(!this.startDateTime) { return }

  // if(p == 'DAY') {

  //   if(this.startDateTime?.day) {
  //     let cleanUp = false;
  //     for(let c of this.startDateTime!.day.split('')) {
  //       if(!this.allowdDays.includes(c)) {
  //         cleanUp = true;
  //       }
  //     }
  //     if(cleanUp) {
  //       setTimeout(()=>{
  //         this.startDateTime!.day = TIME.date_lcoal_to_tt(new Date()).day
  //       },10)
  //     }
  //   }
  // }
}
// omit_special_char(event: any) {
//   var k;
//     k = event.key//charCode;  //         k = event.keyCode;  (Both can be used)
//     //return((k >= 48 && k <= 57)); //(k >= 0 && k < 32) || (k > 96 && k < 123) || k == 8 || k == 32 ||
//     // const allowd = ['1','2','3','4','5','6','7','8','9','0']
//     // return _.parseInt(k)
//     return(this.allowd.includes(k) && k != '`')

//   } */

// endMonthSelected(event: any){
//   // this.excercise.endDateTime.month = event
//   // this.calcDuration()
//   /* this._save() */
// }
// onHoursKeyDown(e:any) {
//   Logger.log(e.charCode);
//   // if(_.parseInt(e)) {
//   //   Logger.log('is num');

//   // } else {
//   //   Logger.log('is ncjan');
//   // }
// }

// this.setupTimeline(Number(this.excercise.dateTimeStarted), this.excercise.durationInHours)
// this.calcDuration()
// this.mainWidthScaleFact = this.miniMapWidth / 5000;
// // this.hourItems = this.getExerciseDays();
//   //  Logger.log(this.hourItems);
// const dayCount = this.hourItems.filter(
//   (item) => item.startNewDate == true
// );
// const hhh = _.map(dayCount, "hoursCount");
// var sumHours = 0;
// hhh.forEach((e) => {
//   sumHours += e;
// });
// const _f1 = Math.round(this.miniMapWidth / sumHours);

// this.miniOvlItems = [];
// var full = 0;
// var _lastWidth = 0;
// // Logger.log(`${this.name} miniMapWidth: ${this.miniMapWidth} _f1: ${_f1}`);
// this.miniMapHourWidth = _f1;
// for (var i = 0; i < dayCount.length; i++) {
//   // Logger.log(dayCount[i]);
//   const _f2 = Math.round(_f1 * dayCount[i].hoursCount);
//   // Logger.log(`${this.name} _F2 ${_f2}`);
//   full += _f2;
//   this.miniOvlItems.push({
//     width: _f2,
//     day: dayCount[i].dateString,
//     left: _lastWidth,
//   });
//   _lastWidth += _f2;
// }

// subTimeFor(itemType: string){
//   if(itemType == 'DAY'){

//     const days = _.parseInt(this.durationInDays)
//     // const days = parseInt(this.durationInDays)
//     let newDays = days - 1;
//     if (days >= 1 && newDays >= 1) {
//       // Logger.log(`${this.name} days ${days}` );
//       // Logger.log(`${this.name} newDays ${newDays}` );

//       this.durationInDays = `${newDays}`//newDays < 10 ? `0${newDays}` : `${newDays}`
//       const start = new Date(TIME.tt_toDate(this.startDateTime!));

//       this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 1440;

//       this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

//     }

//    /*  const start = new Date(TIME.tt_toDate(this.startDateTime!));

//     let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

//     this.exerciseDurationInMinutes = differenceInMinutes(subDays(end,1), start)
//     this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
//     this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */
//     // this.calcDiff()
//     this.setupTimeline(TIME.tt_toDate(this.startDateTime!).getTime(), this.exerciseDurationInMinutes / 60)

//   } else if(itemType == 'HOUR'){
//     const start = new Date(TIME.tt_toDate(this.startDateTime!));

//     const hours = _.parseInt(this.durationInHours)
//     Logger.log(hours);
//     // const days = parseInt(this.durationInDays)
//     let newHours = hours - 1;
//     if (hours >= 0 && newHours >= 0) {
//       Logger.log(`${this.name} hier 1: ${newHours}`);
//       // Logger.log(`${this.name} hours ${hours}` );
//       // Logger.log(`${this.name} newHours ${newHours}` );

//       this.durationInHours = `${newHours}`//newHours < 10 ? `0${newHours}` : `${newHours}`

//     } else {
//       Logger.log(`${this.name} hier 2: ${newHours}`);
//       this.durationInHours = `23`
//     }

//     this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 60;

//       this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

//     this.checkDaysBetween()
//     this.calcDiff()
//    /*  const start = new Date(TIME.tt_toDate(this.startDateTime!));

//     let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

//     this.exerciseDurationInMinutes = differenceInMinutes(subHours(end,1), start)
//     this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
//     this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */

//   } else if(itemType == 'MIN'){

//     /* const start = new Date(TIME.tt_toDate(this.startDateTime!));

//     let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

//     this.exerciseDurationInMinutes = differenceInMinutes(subMinutes(end,1), start)
//     this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
//     this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */

//   }
//   this.showSaveButton = true;
// }

/** HIER 1 */
// this.assigendExerciseInjects = _.clone(this.storage.assigendExerciseInjects)
// for(let i = 0; i < this.assigendExerciseInjects.length; i ++) {
//   this.assigendExerciseInjects[i].short_title = `${this.assigendExerciseInjects[i].short_title} = ${i}`
// }

// Logger.log(this. assigendExerciseInjects);

// if (this.excercise.currentExerciseState == ExerciseState.SCHEDULED || this.excercise.currentExerciseState == ExerciseState.DRAFT || this.excercise.currentExerciseState == ExerciseState.RUNNING || this.excercise.currentExerciseState == ExerciseState.DONE ) {

//   // const isBore2 = isBefore(this.excercise.getDateTime(), new Date())

//   // Logger.log(`${this.name} isBore2: ${isBore2}`);

//   _startDateTime = TIME.date_lcoal_to_tt(this.excercise.getDateTime())

//   // this.lastStartDateTime = TIME.date_lcoal_to_tt(this.excercise.getDateTime())

//   // this._lastDateStartet = Number(TIME.tt_toDate(this.lastStartDateTime).getTime())
//   // this._lastDurationInHours = this.exerciseDurationInMinutes / 60

//   // this.setInitialInput(this.startDateTime);

//   // Logger.log(this.startDateTime);

//   this.selectedStartMonth = _startDateTime.month

//   _exerciseDurationInMinutes = this.excercise.durationInHours * 60
//   // this.lastExerciseDurationInMinutes = this.exerciseDurationInMinutes

//   this.durationDiffInSec = _exerciseDurationInMinutes * 60

//   const days = Math.round(this.excercise.durationInHours / 24)
//   const hours = this.excercise.durationInHours % 24
//   // const mins = this.excercise.durationInHours % 24
//   // Logger.log(`${this.name} DAYS: ${days}`);
//   // Logger.log(`${this.name} HOURS: ${hours}`);
//   // Logger.log(`${this.name} MINS: ${mins}`);

//   this.durationInDays =  `${days}`
//   this.durationInHours = `${hours}`
//   // this.durationInDays = days < 10 ? `0${days}` : `${days}`
//   // this.durationInHours = hours < 10 ? `0${hours}` : `${hours}`

// }
/** HIER 1 */

/*

          addTimeFor(itemType: string){
            this.errorText = ''
            if(itemType == 'DAY'){
              // const days = _.parseInt(this.durationInDays)

              // let added = days + 1;

              // this.durationInDays = `${added}`;//added < 10 ? `0${added}` : `${added}`
              // const start = new Date(TIME.tt_toDate(_startDateTime));

              // this.exerciseDurationInMinutes = this.exerciseDurationInMinutes + 1440;

              // this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

            } else if(itemType == 'HOUR'){
              // this.timelineController.appendHour()
              // const start = new Date(TIME.tt_toDate(this.startDateTime!));
              // const hours = _.parseInt(this.durationInHours)

              // let added = hours + 1;
              // if (added == 24) {
              //   this.durationInHours = `0`;

              // } else {
              //   this.durationInHours = `${added}`;//added < 10 ? `0${added}` : `${added}`
              // }
              // this.exerciseDurationInMinutes = this.exerciseDurationInMinutes + 60;
              // this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

              // this.checkDaysBetween()
            }
            wait(150, () => {
              //this.setupTimeline(TIME.tt_toDate(this.startDateTime!).getTime(), this.exerciseDurationInMinutes / 60)
            })
            // else if(itemType == 'MIN'){

            //   const start = new Date(TIME.tt_toDate(this.startDateTime!));
            //   // Logger.log(start);

            //   let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))
            //   // Logger.log(end);

            //   this.exerciseDurationInMinutes = differenceInMinutes(addMinutes(end,1), start)
            //   this.endDateTime = TIME.date_to_tt(addMinutes(end, this.exerciseDurationInMinutes))
            //   this.durationDiffInSec = this.exerciseDurationInMinutes * 60

            // }
            this.showSaveButton = true;
          }

          subTimeFor(itemType: string){
            /* if(itemType == 'DAY'){

              const days = _.parseInt(this.durationInDays)
              // const days = parseInt(this.durationInDays)
              let newDays = days - 1;
              if (days >= 1 && newDays >= 1) {
                // Logger.log(`${this.name} days ${days}` );
                // Logger.log(`${this.name} newDays ${newDays}` );

                this.durationInDays = `${newDays}`//newDays < 10 ? `0${newDays}` : `${newDays}`
                const start = new Date(TIME.tt_toDate(this.startDateTime!));

                this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 1440;

                this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
              }
            } else if(itemType == 'HOUR'){

              const start = new Date(TIME.tt_toDate(this.startDateTime!));

              const hours = _.parseInt(this.durationInHours)

              let newHours = hours - 1;
              if (hours >= 0 && newHours >= 0) {
                Logger.log(`${this.name} hier 1: ${newHours}`, LEVEL.DEBUG);
                // Logger.log(`${this.name} hours ${hours}` );
                // Logger.log(`${this.name} newHours ${newHours}` );

                this.durationInHours = `${newHours}`//newHours < 10 ? `0${newHours}` : `${newHours}`

              } else {
                Logger.log(`${this.name} hier 2: ${newHours}`, LEVEL.DEBUG);
                this.durationInHours = `23`
              }

                this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 60;

                this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

            } else if(itemType == 'MIN'){

            }
            this.checkDaysBetween()
            wait(150, () => {
              this.setupTimeline(TIME.tt_toDate(this.startDateTime!).getTime(), this.exerciseDurationInMinutes / 60)
            })
            this.showSaveButton = true;
          }
 */
// private calcDiff() {
//     Logger.log(`${this.name} calcDiff ::::: > `, LEVEL.DEBUG);
//     const oeStart = new Date(TIME.tt_toDate(this.lastStartDateTime!).getTime());
//     const newStart = new Date(TIME.tt_toDate(this.startDateTime!).getTime());

//     const _isAfter = isAfter(newStart, oeStart)
//     // Logger.log(`${this.name} calcDiff ::::: > oe: ${oeStart} new: ${newStart}`);
//     const newDurationInMins = Number(this.exerciseDurationInMinutes)

//     // const oeDurationInHours = Number(this.lastExerciseDurationInMinutes) / 60
//     // const newDurationInHours = Number(this.exerciseDurationInMinutes) / 60

//     // Logger.log(`${this.name} oeDurationInHours: ${oeDurationInHours} newDurationInHours: ${newDurationInHours}` );

//     const latestInject = _.maxBy(this.assigendExerciseInjects, function(i) {
//       return Number(i.deliveryDateTime) + Number(i.deliveryDurationInMinutes)
//     })

//     if(latestInject) {
//       const latestInjectTime = Number(latestInject.deliveryDateTime) + Number(latestInject.deliveryDurationInMinutes);
//       // Logger.log(`${this.name} latestInjectTime: ${latestInjectTime}`);
//       // Logger.log(`${this.name} newDurationInMins: ${newDurationInMins}` );
//       Logger.log(`${this.name} ${latestInject.deliveryDateTime} ${latestInject.short_title} `, LEVEL.DEBUG)

//       if (latestInjectTime > newDurationInMins) {
//         this.buttonErrors = [BUTTON_ERROR_TYPE.MINUS_DAY, BUTTON_ERROR_TYPE.MINUS_HOUR]

//         Logger.log(`${this.name} ERROR  latestTime >>> new Duration`, LEVEL.ERROR)
//         this.errorText = 'inject endtime date conflict'
//         // canSave = false
//       } else {
//         this.buttonErrors = []
//       }
//     }  else {
//       this.buttonErrors = []
//     }

//     const diffInMin = _isAfter ? differenceInMinutes(newStart, oeStart) : differenceInMinutes(oeStart, newStart);

//     if (_isAfter) {
//       Logger.log(`${this.name} new start is after - diffInMin: ${diffInMin}`, LEVEL.DEBUG)

//       if(this.assigendExerciseInjects.length > 0) {
//         const earliestInject = _.minBy(this.assigendExerciseInjects, 'deliveryDateTime')
//         if (earliestInject) {

//           Logger.log(`${this.name} earliestInject deliveryDateTime: ${earliestInject.deliveryDateTime} title: ${earliestInject.short_title}`, LEVEL.DEBUG);

//           const timeFact = earliestInject.deliveryDateTime > diffInMin ? diffInMin : earliestInject.deliveryDateTime

//           Logger.log(`${this.name} diffInMin ${diffInMin} timeFact: ${timeFact} earliestInject: ${earliestInject.deliveryDateTime}`,LEVEL.DEBUG);
//           this.currentFact = timeFact
//         }
//       }

//     } else {
//       Logger.log(`${this.name} new start is before - diffInMin: ${diffInMin}`, LEVEL.DEBUG);
//       if (this.assigendExerciseInjects.length > 0 && diffInMin >= 0) {
//         Logger.log(`${this.name} this.storage.assigendExerciseInjects.length > 0 ${this.assigendExerciseInjects.length}`, LEVEL.DEBUG);
//         this.currentFact = diffInMin
//       }
//     }

//     // wait(2500, () => {
//     //   this.lastStartDateTime = this.startDateTime
//     //   this.lastExerciseDurationInMinutes = this.exerciseDurationInMinutes
//     // })
//     // this.setupTimeline(newStart.getTime(), newDurationInHours)
//   }

/* private async fixAssignedInjectTime(fact: number) {
      Logger.log(`${this.name} fixAssignedInjectTime fact ${fact}`, LEVEL.DEBUG);
      // return
      for(var i = 0; i < this.storage.assigendExerciseInjects.length; i ++) { //.storage
        const inj = this.storage.assigendExerciseInjects[i] //storage.
        // Logger.log(`${this.name} inj.deliveryDateTime ${inj.deliveryDateTime}`);
        var ids = ''
        if (inj.trainingObjectives) {

          ids=  _.map(inj.trainingObjectives, 'id').toString()
          // Logger.log(`${this.name} ids`, ids);
        }
          const deliveryDateTime = Number(Number(inj.deliveryDateTime) + fact)
         const dto:InjectUpdateDto = {


          description: inj.description,
          short_title: inj.short_title,
          stateId: inj.state.id,
          deliveryDateTime: deliveryDateTime,//Number(Number(inj.deliveryDateTime) + fact),
          deliveryDurationInMinutes: Number(inj.deliveryDurationInMinutes),
          locationId: inj.location ? inj.location.id : -1,

          related_documents: inj.related_documents,
          related_equipment: inj.related_equipment,
          role_players_instruction: inj.role_players_instruction,
          on_scene_preparation: inj.on_scene_preparation,
          additional_guidance_caution_options: inj.additional_guidance_caution_options,
          expected_outcomes: inj.expected_outcomes,
          trainers_action_requirement: inj.trainers_action_requirement,
          learning_objectives: ids,

        }
        if (dto.locationId == -1) {
          delete dto.locationId
        }
        //  Logger.log(`${this.name} dto.deliveryDateTime: ${dto.deliveryDateTime} deliveryDateTime: ${inj.deliveryDateTime}`);
        await this.api.apiPatch('inject/update-assigned', inj.id.toString(), dto);

      }
    } */

/* private fixAssignedInjectTimeLocal(fact: number, reset: boolean = false) {

      for(var i = 0; i < this.assigendExerciseInjects.length; i ++) {
        const deliveryDateTime = Number(Number(this.assigendExerciseInjects[i].deliveryDateTime) + fact)

        const TimeString = TIME.formatedTimeString(
          addMinutes(this.timelineController.startDateTime, deliveryDateTime)
        );

        const oeDel = this.assigendExerciseInjects[i].oedeliveryDateTime
        const leftPosOe = this.assigendExerciseInjects[i].leftPosOe
        const leftPosSmallOe = this.assigendExerciseInjects[i].leftPosSmallOe

        if(reset && oeDel && leftPosOe && leftPosSmallOe) {
          this.assigendExerciseInjects[i].deliveryDateTime = oeDel
          this.assigendExerciseInjects[i].leftPos = leftPosOe//(deliveryDateTime * 121) / 60
          this.assigendExerciseInjects[i].leftPosSmall = leftPosSmallOe//(deliveryDateTime * 61) / 60
        }

      const newLeftPos = (deliveryDateTime * 121) / 60
      const newLeftPosSmall = (deliveryDateTime * 61) / 60
      // if (i==0) {

      //   Logger.log(`${this.name} newLeftPos: ${newLeftPos}`, LEVEL.WARN);
      //   Logger.log(`${this.name} leftPosOe:  ${leftPosOe} `, LEVEL.WARN);
      //   Logger.log(`${this.name} dDT      :  ${deliveryDateTime}`, LEVEL.WARN);
      //   Logger.log(`${this.name} dDTOe:      ${oeDel}`, LEVEL.WARN);
      // }
      this.assigendExerciseInjects[i].deliveryDateTime = deliveryDateTime
      this.assigendExerciseInjects[i].leftPos = newLeftPos//(deliveryDateTime * 121) / 60
      this.assigendExerciseInjects[i].leftPosSmall = newLeftPosSmall//(deliveryDateTime * 61) / 60
      this.assigendExerciseInjects[i].timeString = TimeString

        wait(150, () => {
        })

        // Logger.log(`${this.name} this.assigendExerciseInjects[i]: ${this.assigendExerciseInjects[i].deliveryDateTime} leftSmall: ${this.assigendExerciseInjects[i].leftPosSmall}`);
      }
    } */
