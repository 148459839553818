import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
// import { TokenstorageService } from 'src/app/services/tokenstorage.service';
// import { StorageService } from './storage.service';
import { TokenstorageService } from './tokenstorage.service';

const TOKEN_HEADER_KEY = 'authorization';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private ts: TokenstorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq = req;
    
     if(req.url.includes('/auth/lost-password') || req.url.endsWith('auth/') || req.url.endsWith('auth')){
     authReq = req.clone( { })
     console.log( req.url.includes('auth'));
     
     } else {
      const token = this.ts.getToken();
      
      // 
      if (token != null) {
        authReq = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer '+token.token) })
      } else {
        console.log(`\n\n miss token`)
        authReq = req.clone({})
      }
     }

    return next.handle(authReq)
    
  }


}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
