import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ExerciseState } from 'src/app/model/enums';
import { ExerciseClass, ExerciseI, TacticalMonth, TacticalTime } from 'src/app/model/exercise.model';
import { TIME } from 'src/app/services/helpers';
import {map, startWith} from 'rxjs/operators';
import { addDays, addHours, addMinutes, differenceInMinutes, differenceInSeconds, endOfDay, isAfter, isBefore, subDays, subHours, subMinutes } from 'date-fns';
import * as _ from 'lodash';
import { ApiService } from 'src/app/services/api.service';
import { wait } from 'src/app/services/utils/functions';
@Component({
  selector: 'app-exercise-duration-overlay',
  templateUrl: './exercise-duration-overlay.component.html',
  styleUrls: ['./exercise-duration-overlay.component.scss']
})
export class ExerciseDurationOverlayComponent implements OnInit {

  @ViewChild('_dayInput') _dayInput:ElementRef | undefined
  @ViewChild('_monthStart') _monthStart:ElementRef | undefined
  @ViewChild('_monthEnd') _monthEnd:ElementRef | undefined

  durationDays:string =''
  durationHours:string =''
  durationMinutes:string =''

  durationDiffInSec:number =0;
  startMonthFormC = new FormControl();
  
  selectedStartMonth:TacticalMonth | undefined

  allowdDays = ['1','2','3','4','5','6','7','8','9','0'];
  allowdDaysHours = ['1','2','3','4','5','6','7','8','9','0'];
  
  get isReadOnly():boolean {
    if (!this.excercise) {  
      return true
    }
    return !this.excercise.isAdmin
  }

  showSaveButton:boolean = false;
  
  months:TacticalMonth[] = [
    {viewVal:"jan", index:0},
    {viewVal:"feb", index:1},
    {viewVal:"mar", index:2},
    {viewVal:"apr", index:3},
    {viewVal:"may", index:4},
    {viewVal:"jun", index:5},
    {viewVal:"jul", index:6},
    {viewVal:"aug", index:7},
    {viewVal:"sep", index:8},
    {viewVal:"oct", index:9},
    {viewVal:"nov", index:10},
    {viewVal:"dec", index:11},
  ]
  //filteredMonths:MatTableDataSource<TacticalMonth>
  filteredMonths: Observable<TacticalMonth[]>  | undefined

  // startDateTime:TacticalTime  | undefined
  startDateTime:TacticalTime  | undefined
  exerciseDurationInMinutes: number = 0 

  
  endDateTime:TacticalTime | undefined

  //timeStart:TacticalTime  | undefined
 

  exerciseStates = ExerciseState

  excercise:ExerciseClass
  
  dayInput = 0;
  hourInput = 0;
  minInput = 0;
  yearInput = 0;

  durationInDays = '00'
  durationInHours = '00'
  durationInMinutes = '00'

  constructor(
    public dialogRef: MatDialogRef<ExerciseDurationOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data:ExerciseClass,
    private api: ApiService) {
      this.excercise = data
      const _c:TacticalTime = TIME.tt_currentUTC() 
  
    }

    ngOnInit(): void {
      const txOfs = new Date().getTimezoneOffset();
      
      console.log(`offset ${txOfs}`);
      
      setTimeout(()=>{
        if(!this.excercise) { return }
        // console.log(this.excercise);
        if (this.excercise.currentExerciseState == ExerciseState.SCHEDULED || this.excercise.currentExerciseState == ExerciseState.DRAFT || this.excercise.currentExerciseState == ExerciseState.RUNNING || this.excercise.currentExerciseState == ExerciseState.DONE ) {
        
          const isBore2 = isBefore(this.excercise.getDateTimeUTC(), new Date())
          
          console.log(`isBore2: ${isBore2}`);
          
          this.startDateTime = TIME.date_lcoal_to_tt(this.excercise.getDateTimeUTC())

          this.setInitialInput(this.startDateTime);
          
          console.log(this.startDateTime);
          
          this.selectedStartMonth = this.startDateTime.month
          this.exerciseDurationInMinutes = this.excercise.durationInHours * 60
          this.durationDiffInSec = this.exerciseDurationInMinutes * 60

          const days = Math.round(this.excercise.durationInHours / 24)
          const hours = this.excercise.durationInHours % 24
          // const mins = this.excercise.durationInHours % 24
          console.log(`DAYS: ${days}`);
          console.log(`HOURS: ${hours}`);
          // console.log(`MINS: ${mins}`);
          
          this.durationInDays =  `${days}`
          this.durationInHours = `${hours}`
          // this.durationInDays = days < 10 ? `0${days}` : `${days}`
          // this.durationInHours = hours < 10 ? `0${hours}` : `${hours}`

        } else {
          
        }

        this.calcDuration()
        this.filteredMonths = this.startMonthFormC.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this.applyFilter(name) : this.months.slice())
        );
        },500)

        wait(1200, () => {
          if(this._dayInput) {
            this._dayInput.nativeElement.focus()
            this._dayInput.nativeElement.select()
          }
        })
    }

/*     displayFn(moth: TacticalMonth): string {
      return moth && moth.viewVal ? moth.viewVal : '';
    } */

    applyFilter(name: string):TacticalMonth[] {
      const filterValue = name.toLowerCase();
  
      return this.months.filter(option => option.viewVal.toLowerCase().indexOf(filterValue) === 0);
    } 

    private setInitialInput(tt: TacticalTime) {
      console.log(' - setInitialInput - ');
      console.log(tt);
      
      
    }

    formatedString(tt?: TacticalTime) {
      if(!tt) { return '' }
      
      const dt = TIME.tt_toDate(tt);
      return TIME.formatedDateString(dt) + ' ' + TIME.formatedTimeString(dt)
    }
    calcDuration(){
      if(this.startDateTime) {
        const endDate = new Date(addMinutes(TIME.tt_toDate(this.startDateTime), this.exerciseDurationInMinutes))
        this.endDateTime = TIME.date_to_tt(endDate)
        this.exerciseDurationInMinutes = differenceInMinutes(endDate,TIME.tt_toDate(this.startDateTime))
        if(this.durationDiffInSec != (this.exerciseDurationInMinutes * 60) ){
          this.durationDiffInSec != (this.exerciseDurationInMinutes * 60) 
          this.showSaveButton = true;
        }
      }
    }

    onTFChanged(event: any, typ: string) {
      let canCalc = true; 
      if(typ == 'DAY'){
        let charsAt = this.startDateTime!.day;
        let cleanUp = false;
        for(let c of charsAt.split('')) {
          if(!this.allowdDays.includes(c)) {
            cleanUp = true;
            canCalc = false;
          }
        }
        const _day = _.parseInt(this.startDateTime!.day)
        if(cleanUp || (_day && _day > 31)) {
          setTimeout(()=>{
            this.startDateTime!.day = TIME.date_lcoal_to_tt(new Date()).day
          },10)
        }

      } else if(typ == 'HOUR'){
        let charsAt = this.startDateTime!.hours;
        let cleanUp = false;
        for(let c of charsAt.split('')) {
          if(!this.allowdDays.includes(c)) {
            cleanUp = true;
            canCalc = false;
          }
        }
        const _hours = _.parseInt(this.startDateTime!.hours)
        // console.log(`cleanUp: ${cleanUp}`);
        // console.log(`_hours: ${_hours}`);
        if(cleanUp || (_hours && _hours > 23)) {
          setTimeout(()=>{
            this.startDateTime!.hours = TIME.date_lcoal_to_tt(new Date()).hours
          },10)
        }
        // console.log(this.startDateTime);
      } else if(typ == 'MIN'){
        let charsAt = this.startDateTime!.minutes;
        let cleanUp = false;
        for(let c of charsAt.split('')) {
          if(!this.allowdDays.includes(c)) {
            cleanUp = true;
            canCalc = false;
          }
        }
        const _minutes = _.parseInt(this.startDateTime!.minutes)
        if(cleanUp || (_minutes && _minutes > 59)) {
          setTimeout(()=>{
            this.startDateTime!.minutes = TIME.date_lcoal_to_tt(new Date()).minutes
          },10)
        }
      }
      wait(500, ()  => {

        if(canCalc) {
          this.calcDuration()
          this.showSaveButton = true;
        }
      })
    }

    startMonthSelected(event: any){
      console.log(event);
      
      if(this.startDateTime) {
        this.startDateTime.month = event;//.option.value
      }
      this.calcDuration()
      /* this._save() */
    }
    // endMonthSelected(event: any){
    //   // this.excercise.endDateTime.month = event
    //   // this.calcDuration()
    //   /* this._save() */
    // }
    // onHoursKeyDown(e:any) {
    //   console.log(e.charCode);
    //   // if(_.parseInt(e)) {
    //   //   console.log('is num');
        
    //   // } else {
    //   //   console.log('is ncjan');
    //   // }
    // }
    ngInputChanged(p: string) {
      // this.calcDuration()
      // this.showSaveButton = true;


      // if(!this.startDateTime) { return }

      // if(p == 'DAY') {

      //   if(this.startDateTime?.day) {
      //     let cleanUp = false;
      //     for(let c of this.startDateTime!.day.split('')) {
      //       if(!this.allowdDays.includes(c)) {
      //         cleanUp = true;
      //       }
      //     }
      //     if(cleanUp) {
      //       setTimeout(()=>{
      //         this.startDateTime!.day = TIME.date_lcoal_to_tt(new Date()).day
      //       },10)
      //     }
      //   }
      // }
    }
    inputChanged(){
      this.calcDuration()
      this.showSaveButton = true;
      /* this._save() */
      
    }
    // omit_special_char(event: any) {   
    //   var k;  
    //     k = event.key//charCode;  //         k = event.keyCode;  (Both can be used)
    //     //return((k >= 48 && k <= 57)); //(k >= 0 && k < 32) || (k > 96 && k < 123) || k == 8 || k == 32 || 
    //     // const allowd = ['1','2','3','4','5','6','7','8','9','0']
    //     // return _.parseInt(k)
    //     return(this.allowd.includes(k) && k != '`')
         
    //   }
 

    addTimeFor(itemType: string){
      if(itemType == 'DAY'){

        const days = _.parseInt(this.durationInDays)
        // const days = parseInt(this.durationInDays)
        // console.log(`dys ${days}` );
        
        let added = days + 1;
        // console.log(`added ${added}` );
        
        this.durationInDays = `${added}`;//added < 10 ? `0${added}` : `${added}`
        const start = new Date(TIME.tt_toDate(this.startDateTime!));
        
        //let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

        this.exerciseDurationInMinutes = this.exerciseDurationInMinutes + 1440;

        this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

        // this.durationDiffInSec = this.exerciseDurationInMinutes * 60


        // old
        // const start = new Date(TIME.tt_toDate(this.startDateTime!));
        // let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

        // this.exerciseDurationInMinutes = differenceInMinutes(addDays(end,1), start)

        // this.endDateTime = TIME.date_to_tt(addMinutes(end, this.exerciseDurationInMinutes))

        // this.durationDiffInSec = this.exerciseDurationInMinutes * 60


      } else if(itemType == 'HOUR'){
        // console.log(`addTimeFor this.exerciseDurationInMinutes: ${this.exerciseDurationInMinutes} durationDiffInSec: ${this.durationDiffInSec}` );
        
        const hours = _.parseInt(this.durationInHours)
        // const days = parseInt(this.durationInDays)
        // console.log(`hours ${hours}` );
        
        let added = hours + 1;
        // console.log(`added ${added}` );
        
        this.durationInHours = `${added}`;//added < 10 ? `0${added}` : `${added}`
        const start = new Date(TIME.tt_toDate(this.startDateTime!));
        
        //let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

        this.exerciseDurationInMinutes = this.exerciseDurationInMinutes + 60;

        this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

        // old 
       /*  const start = new Date(TIME.tt_toDate(this.startDateTime!));
        
        //let end = new Date(addHours(start, 1))
         let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))
        console.log(`end:  ${end} this.durationDiffInSec: ${this.durationDiffInSec}` );
        let newEnd = addHours(end,1);
        console.log(`newEnd:  ${newEnd}` );
        this.exerciseDurationInMinutes = differenceInMinutes(newEnd, start)

        console.log(`exerciseDurationInMinutes:  ${this.exerciseDurationInMinutes}` );

        this.endDateTime = TIME.date_to_tt(end)
        //this.endDateTime = TIME.date_to_tt(addMinutes(end, this.exerciseDurationInMinutes))
         this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */


      } else if(itemType == 'MIN'){

        const start = new Date(TIME.tt_toDate(this.startDateTime!));
        // console.log(start);
        
        let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))
        // console.log(end);
        
        this.exerciseDurationInMinutes = differenceInMinutes(addMinutes(end,1), start)
        this.endDateTime = TIME.date_to_tt(addMinutes(end, this.exerciseDurationInMinutes))
        this.durationDiffInSec = this.exerciseDurationInMinutes * 60

      }
      this.showSaveButton = true;
    }
    subTimeFor(itemType: string){
      if(itemType == 'DAY'){

        const days = _.parseInt(this.durationInDays)
        // const days = parseInt(this.durationInDays)
        let newDays = days - 1;
        if (days >= 1 && newDays >= 1) {
          // console.log(`days ${days}` );
          // console.log(`newDays ${newDays}` );
          
          this.durationInDays = `${newDays}`//newDays < 10 ? `0${newDays}` : `${newDays}`
          const start = new Date(TIME.tt_toDate(this.startDateTime!));
  
          this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 1440;
  
          this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

        }

       /*  const start = new Date(TIME.tt_toDate(this.startDateTime!));
        
        let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))
        
        this.exerciseDurationInMinutes = differenceInMinutes(subDays(end,1), start)
        this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
        this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */
 

      } else if(itemType == 'HOUR'){

        const hours = _.parseInt(this.durationInHours)
        // const days = parseInt(this.durationInDays)
        let newHours = hours - 1;
        if (hours >= 0 && newHours >= 0) {
          // console.log(`hours ${hours}` );
          // console.log(`newHours ${newHours}` );
          
          this.durationInHours = `${newHours}`//newHours < 10 ? `0${newHours}` : `${newHours}`
          const start = new Date(TIME.tt_toDate(this.startDateTime!));
  
          this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 60;
  
          this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

        }

       /*  const start = new Date(TIME.tt_toDate(this.startDateTime!));
        
        let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))
        
        this.exerciseDurationInMinutes = differenceInMinutes(subHours(end,1), start)
        this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
        this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */


      } else if(itemType == 'MIN'){

        /* const start = new Date(TIME.tt_toDate(this.startDateTime!));

        
        let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

        this.exerciseDurationInMinutes = differenceInMinutes(subMinutes(end,1), start)
        this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
        this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */

      }
      this.showSaveButton = true;
    }

    async save(){
      // console.log(this.startDateTime);
      // console.log();
      // console.log(new Date().getTimezoneOffset() * 60000);
      
      if (!this.excercise) { return }
      //const tt = TIME.tt_toDate(this.startDateTime!).getTime() - (new Date().getTimezoneOffset() * 60000)
      this.excercise.dateTimeStarted = TIME.tt_toDate(this.startDateTime!).getTime()
      this.excercise.durationInHours = Number(this.exerciseDurationInMinutes) / 60
      // console.log(new Date(this.excercise.dateTimeStarted));
      
      const dto = {
        name: this.excercise.name,
        description: this.excercise.description,
        durationInHours: Number(this.excercise.durationInHours),
        dateTimeStarted: Number(this.excercise.dateTimeStarted),
        status: this.excercise.status,
      }
      console.log(dto);
      // console.log(this.excercise);
      // console.log(`start: ${this.excercise.startDateTimeDate}`);
      // console.log(`end: ${this.excercise.endDateTimeDate}`);
      
       // return
      const result = await this.api.apiPatch('exercise', this.excercise.id, dto);
      setTimeout(() => {
        this.dialogRef.close('RELOAD');
      },600)
    }
    close(){
      this.dialogRef.close();
    }
    dayPlusTapped() {

    }
    dayMinusTapped() {

    }

    hourPlusTapped() {

    }

    hourMinusTapped() {

    }
    
    
}
