import { TIME } from "../services/helpers";
import { ExerciseState } from "./enums";
import getTTDate from "../services/utils/functions";
import { addHours, addMinutes } from "date-fns";
import { iExerciseInject } from "./company.model";
import { Location } from "./location.model";
import { iExerciseTrainingObjective } from "./trainingObj.model";
import { iTrainerFeedback, TrainerFeedbackType } from "./trainerFeedback.model";
import { UTCDate } from "@date-fns/utc";

export interface ExerciseI {
  id: string;
  name: string;
  description?: string;
  companyId?: string;
  status: ExerciseState;
  durationInHours: number;
  dateTimeStarted: number;
  timelineItems: IExerciseTimelineItem[];
  exerciseUser?: IExerciseUser;
  injects?: iExerciseInject[];
  locations?: Location[];
  isDeBoarded: boolean;
  users?:IExerciseUser[]
  getDateTime: () => Date;
  getIsAdmin: (uid: string) => boolean;
}

export class ExerciseClass implements ExerciseI {
  id!: string;
  name!: string;
  description?: string;
  companyId?: string;
  status!: ExerciseState;
  durationInHours!: number;
  dateTimeStarted!: number;
  timelineItems: IExerciseTimelineItem[] = [];
  exerciseUser?: IExerciseUser;
  isDeBoarded: boolean;
  locations: Location[] = [];

  private _exerciseUser?: IExerciseUser | undefined;

  // constructor(item: Partial<ExerciseI>) {
  constructor(item: ExerciseI) {
    Object.assign(this, item);
    this.isDeBoarded = item.isDeBoarded;
    if (item.exerciseUser) {
      this._exerciseUser = item.exerciseUser;
    }
  }
  // constructor( public ex: ExerciseI) {
  //   this.id = ex.id
  // }


  getIsAdmin(uid: string): boolean {
    if (!this._exerciseUser) {
      return false;
    }
    return (
      this._exerciseUser.userId == uid &&
      this._exerciseUser.userRole == ExerciseUserRole.ADMIN
    );
  }

  get startDateTime(): TacticalTime {
    return TIME.date_lcoal_to_tt(this.getDateTime());
  }
  get endDateTime(): TacticalTime {
    return TIME.date_lcoal_to_tt(
      addHours(this.getDateTime(), this.durationInHours)
    );
  }
  get startDateTimeDate(): Date {
    return this.getDateTime();
  }
  get endDateTimeDate(): Date {
    return addHours(this.getDateTime(), this.durationInHours);
  }
  getDateTime() {
    return this.dateTimeStarted
      ? new UTCDate(Number(this.dateTimeStarted))
      : new UTCDate();
    //return this.dateTimeStarted ? new Date(Number(this.dateTimeStarted) + (new Date().getTimezoneOffset() * 60000)) : new Date()
    // return this.dateTimeStarted ? new Date(Number(this.dateTimeStarted)) : new Date()
    // return this.dateTimeStarted ? new Date(Number(this.dateTimeStarted) - (new Date().getTimezoneOffset() * 60000)) : new Date()
  }
  getDateTimeRaw() {
    // return this.dateTimeStarted ? new Date(Number(this.dateTimeStarted)) : new Date()
    return this.dateTimeStarted
      ? new UTCDate(Number(this.dateTimeStarted))
      : new UTCDate();
    // return this.dateTimeStarted ? new Date(Number(this.dateTimeStarted) - (new Date().getTimezoneOffset() * 60000)) : new Date()
  }
  getDateTimeUTC() {
    // return this.dateTimeStarted ? new Date(Number(this.dateTimeStarted) ) : new Date()
    return this.dateTimeStarted
      ? new UTCDate(Number(this.dateTimeStarted))
      : new UTCDate();
    // return this.dateTimeStarted ? new Date(Number(this.dateTimeStarted)) : new Date()
    //return this.dateTimeStarted ? new Date(Number(this.dateTimeStarted) + (new Date().getTimezoneOffset() * 60000)) : new Date()
  }

  get currentExerciseState(): ExerciseState {
    return this.status;
  }
  get isAdmin(): boolean {
    if (!this._exerciseUser) {
      return false;
    }
    return this._exerciseUser.userRole == ExerciseUserRole.ADMIN;
  }

  getLocations(): Location[] {
    return this.locations;
  }
  setLocations(val: Location[]) {
    this.locations = val;
  }
}

// const isAdmin = (exClass: ExerciseClass) => {
//   return
// }

// export type ExercistStartDate = (v: number) => Date;
// export function createExStarteDate(num: number): ExercistStartDate {
//   return (val:number) =< new Date(val);
// }

export interface IExerciseTimelineItem {
  id: number;
  title: string;
  topIndex: number;
  heightFact: number;
  itemType?: ExerciseTimelineItemType;
  injects?: IExerciseTimelineInjectRef[];
  height: number;
}
export interface IExerciseTimelineInjectRef {
  id: number;

  exerciseTimelineId: number;

  title: string;
  topIndex: number;
  topFact: number;
  topPos?: number;

  exerciseId: string;
  prevLeftPos?: number;
  leftPos: number;
  leftPosSmall: number;

  itemType: ExerciseTimelineItemType;

  deliveryDateTime: number;

  deliveryDurationInMinutes: number;
  delivery_for_team?: string;
  state: IExerciseInjectState;

  short_title: string;
  inject_no?: string;

  deliveryMethod: INJECT_DELIVERY_METHOD; //InjectI.InjectType;

  responsibility_for_delivery_by?: string; // -> XXXXX DELETED

  description?: string;

  related_documents?: string;

  related_equipment?: string;

  related_site_location?: string; //LocationI;

  role_players_instruction?: string;
  on_scene_preparation?: string;

  additional_guidance_caution_options?: string;
  expected_outcomes?: string;
  trainers_action_requirement?: string;
  animated?: boolean;
  fadedIn?: boolean;
  location?: Location;

  trainingObjectives?: iExerciseTrainingObjective[];
  timelineItem?: IExerciseTimelineItem;

  trainerFeedBack?: iTrainerFeedback;

}

/// ----> NEW BUT NOT IN USE SAME LIKE API
// export interface ExerciseTimelineItem {
//   id: number;
//   title: string;
//   topIndex: number;
//   itemType: ExerciseTimelineItemType;
//   injects?: InjectAssigned[];
// }
// export interface InjectAssigned {
//   id: number;
//   deliveryDateTime: number;
//   deliveryDurationInMinutes: number;
//   delivery_for_team?: string;
//   stateId: number;
//   state: IExerciseInjectState
//   exerciseTimelineId: number;
//   timelineItem: ExerciseTimelineItem;
//   short_title:string;
//   inject_no:string;

//   deliveryMethod:INJECT_DELIVERY_METHOD;
//   responsibility_for_delivery_by?: string;
//   description?: string;

//   related_documents?: string;

//   related_equipment?: string;

//   role_players_instruction?: string;

//   on_scene_preparation?: string;

//   additional_guidance_caution_options?: string;

//   expected_outcomes?: string;

//   trainers_action_requirement?: string;

//   exerciseId: string;

//   injectTemplateId: number;
//   // TODO
//   //trainingObjectives?: TrainingObjectExerciseInject[];

// }

export interface IExerciseInjectState {
  id: number;
  color: string;
  keyName: string;
  title: string;
}
export enum ExerciseTimelineItemType {
  EXCON = "EXCON",
  EVENTS = "EVENTS",
  TEAM = "TEAM",
  LOGISTICS = "LOGISTICS",
  SUPPORT = "SUPPORT",
  ROLEPLAY = "ROLEPLAY",
  OBSERVER = "OBSERVER",
}
export interface TacticalTime {
  day: string;
  hours: string;
  minutes: string;
  seconds: string;
  month: TacticalMonth;
  year: string;

  formated?: () => string
}
export class MyTacticalTime implements TacticalTime {

  // day: string;
  // hours: string;
  // minutes: string;
  // seconds: string;
  // month: TacticalMonth;
  // year: string;
  // constructor(item: Partial<TacticalTime>) {

  // }
  constructor(public day: string,
    public hours: string,
    public minutes: string,
    public seconds: string,
    public month: TacticalMonth,
    public year: string,
  ) {

  }

  formated() {
    return 'hello'
  }
}
export interface TacticalMonth {
  viewVal: string;
  index: number;
}

export interface MiniMapItemI {
  X: string;
  Y: string;
  color: string;
}
export interface HourItemI {
  index: number;
  dayIndex: number;
  hoursCount: number;
  startNewDate: boolean;
  dateString: string;
  hourString: string;
  hour?: number
}
export enum INJECT_DELIVERY_METHOD {
  EVENT = "EVENT",
  MAIL = "MAIL",
  PHONECALL = "PHONECALL",
  RADIOCALL = "RADIOCALL",
  SOCIALMEDIA = "SOCIALMEDIA",
  VIRTUALMEETING = "VIRTUALMEETING",
  PHYSICALMEETING = "PHYSICALMEETING",
  ROLEPLAY = "ROLEPLAY",
  OTHER = "OTHER",
  VIRTUAL_OSOCC = "VIRTUAL_OSOCC",
}

export type INJECT_DELIVERY_METHOD_LIST_ITEM = {
  itemEnum: INJECT_DELIVERY_METHOD;
  viewVal: string;
};

export interface IExerciseInjectState {
  id: number;
  color: string;
  keyName: string;
  title: string;
}

export interface IExerciseUser {
  email: string;
  id: number;
  name: string;
  userId?: string;
  userState: IExerciseUserState;
  userRole: ExerciseUserRole;
}

export enum ExerciseUserRole {
  ADMIN = "ADMIN",
  EXCON = "EXCON",
  TRAINER = "TRAINER",
  // MAIN_TRAINER = 'MAIN_TRAINER',
  // TEAM_TRAINER = 'TEAM_TRAINER',
  // TEAM = 'TEAM',
  // LOGISTICS = "LOGISTICS",
  // SUPPORT = "SUPPORT",
  // ROLEPLAY = "ROLEPLAY",
  OBSERVER = "OBSERVER",
  ALL = "ALL",
}
export enum IExerciseUserState {
  DRAFT = "DRAFT",
  INVITED = "INVITED",
  REMOVED = "REMOVED",
}
export enum IUserRole {
  BASIC = "BASIC",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
}
export enum ICompanyUserRoleType {
  VIEWER = "VIEWER",
  EDITOR = "EDITOR",
  ADMIN = "ADMIN",
}
export enum ExerciseLogType {
  COMMENT = "COMMENT",
  ASSIGN_INJECT = "ASSIGN_INJECT",
  UPDATE_INJECT = "UPDATE_INJECT",
  UPDATE_INJECT_STATE = "UPDATE_INJECT_STATE",
}

export interface LocationI {
  documentId?: string;
  locationName?: string;
  lat?: string;
  lng?: string;
  description?: string;
  realLocationInfos?: string;
  exerciseDocId: string;
}
// export interface ConnectedUser {
//   name: string
// }
export interface ExerciseLog {
  id: number;
  createdAt: number; //Date;
  user: string;
  assignedInjectName?: string;
  teamName?: string;
  message?: string;
  logType: ExerciseLogType;
  // inject?: IExerciseTimelineInjectRef
  // assignedInjectId?: number;
  state?: IExerciseInjectState;
}

export interface ExercisePerformanceTeamItem {
  id: number;
  title: string;
  itemType: ExerciseTimelineItemType;
  assignedInjects: IExerciseTimelineInjectRef[];
}
export interface InjectsPerStatus {
  status: IExerciseInjectState;
  percent: number;
  injects: IExerciseTimelineInjectRef[];
}

export interface ExerciseComment {
  team: string;
  createdAt: number; //Date;
  timelineType: ExerciseTimelineItemType;
  injectTitle: string;
  inject_no: string;
  comment: string;
  itemType: TrainerFeedbackType;
  author: string;
}
