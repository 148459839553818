<div class="ratingBarHolder">
  <div class="ratingAnimationBar" #_progressBar [ngStyle]="{'background-color':color}"></div>
 <div class="ratingbar-grid">
  <div class="d-flex">
    <div class="p-0 rb_bLeft  ratingBox"></div>
    <div class="p-0  ratingBox"></div>
    <div class="p-0  ratingBox"></div>
    <div class="p-0 rb_bRight ratingBox"></div>
  </div>
 </div>
 
</div>