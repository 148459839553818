import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { ExerciseOverviewComponent } from './pages/exercise-overview/exercise-overview.component';
import { AuthGuard } from './services/auth.guard';
import { SecureInnerGuard } from './services/secure-inner.guard';
import { ExerciseDashboardComponent } from './pages/exercise-dashboard/exercise-dashboard.component';
import { AdminComponent } from './pages/admin/admin.component';

const routes: Routes = [
  // { path: '', redirectTo: '/exercise-overview', pathMatch: 'full'},
  // { path: '', redirectTo: '/sign-in', pathMatch: 'full'},
  { path: '', redirectTo: '/exercise-overview', pathMatch: 'full'},

  {
    path: 'exercise-overview',
    component: ExerciseOverviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'exercise-dashboard/:id',
    component: ExerciseDashboardComponent,
    // component: ExerciseCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sign-in',
    component: LoginComponent,
    canActivate: [SecureInnerGuard]
  },
];
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  // scrollOffset: [0, 64],
  useHash: false, 
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
