<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium" (click)="toggleSize()">{{zoomIcon}}</mat-icon>
  <mat-icon class="dxfx_icon_btn_medium" (click)="toggleWindowSize()"> {{sizeIcon}}</mat-icon>
  <mat-icon class="dxfx_icon_btn_medium" (click)="close()">close</mat-icon>
</mat-toolbar>

<!-- p30 -->
<div class=" bgDark p0  " style="height: calc(100% - 88px)">

  <div class="d-flex flex-column p0 h100">

    <div class="p-0 upperHolder flex-grow-1"  #_time_line_mini_map_box *ngIf="hasAssignedInjects">

      <div class="timeBar" #_timeBar id="timeBarView">

        <div class="timeBarInner"  #_timeBarInner >

          <div class="hourBoxHolder md" #hour_box_holder  *ngIf="!isSmallSize">
            <!-- [class.md]="!ui.smallTimeLineItems" [class.sm]="ui.smallTimeLineItems" -->
            <div *ngFor='let hour of hourItems; let hourIndex = index;' class="p0 hour-box" id="hour-{{hour.index}}">
              <!-- [class.hour-box-big]='!ui.smallTimeLineItems' [class.hour-box-sm]='ui.smallTimeLineItems' -->
              <div class="time-copy">
                <!-- <p class="dateStr " >{{hour.dateString}}</p> -->
                <p class="dateStr " *ngIf="hour.startNewDate">{{hour.dateString}}</p>
                <p class="dateStr " *ngIf="!hour.startNewDate"></p>
                <p class="hourStr" [class.currentlyAdded]="hour.state === HourItemIStates.ADDED" [class.currentlyRemoved]="hour.state === HourItemIStates.REMOVED">{{hour.hourString}}</p>
              </div>
            </div>
          </div>

          <div class="hourBoxHolder sm" #hour_box_holder *ngIf="isSmallSize">
            <div *ngFor='let hour of hourItems; let hourIndex = index;' class="p0 hour-box-sm">
              <div class="time-copy-sm">
                <p class="dateStr-sm" *ngIf="hour.startNewDate">{{hour.dateString}}</p>
                <p class="dateStr-sm" *ngIf="!hour.startNewDate"></p>
                <p class="hourStr-sm">{{hour.hourString | slice: 0:2}}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- BOXES -->
        <!-- <mat-menu #menu="matMenu" >
          <button mat-menu-item >
            <mat-icon class="fs18" style="margin-right: 10px!important;">open_in_new</mat-icon>
            <span class="fs16">DETAIL</span>
          </button>
          <button mat-menu-item >
            <mat-icon  class="fs18" style="margin-right: 10px!important;">timer</mat-icon>
            <span class="fs16">TIME</span>
          </button>
          <button mat-menu-item >
            <mat-icon class="fs18" style="margin-right: 10px!important;">schedule</mat-icon>
            <span class="fs16">DURATION</span>
          </button>
        </mat-menu> -->

        <div id="timelineInjectsHolder" #timelineInjectsHolder  >
          <div id="injectsWrapper" *ngIf="!isLoading">
            <div *ngFor='let hour of hourItems; let hourIndex = index;'>
              <div *ngFor="let roleItem of excercise.timelineItems; let topIndex = index;">
                <div
                *ngIf="!isSmallSize"
                class="no-select"
                [class.border-box-big]='!ui.smallTimeLineItems'
                [ngStyle]="{'height':  50 + (50 * roleItem.height)  +'px'}"
                  [class.border-box]='ui.darkMode && ui.smallTimeLineItems'
                  [class.border-box-light]="!ui.darkMode && ui.smallTimeLineItems"

                  [class.border-box-added]="hour.state === HourItemIStates.ADDED"
                  [class.border-box-removed]="hour.state === HourItemIStates.REMOVED"
                  (click)="borderBoxTapped(hourIndex, topIndex, roleItem)"
                  >
                  <!-- [matMenuTriggerFor]="menu" -->
                  <div class="addedBorder" *ngIf="hour.state === HourItemIStates.ADDED"></div>
                  <div class="revovedBorder" *ngIf="hour.state === HourItemIStates.REMOVED"></div>
                  <!-- [class.border-box-new]="hour.currentlyAdded === true" -->
                </div>

                <div
                *ngIf="isSmallSize"
                class="no-select"

                [ngStyle]="{'height':  30 + (30 * roleItem.height)  +'px'}"
                  [class.border-box-sm]='ui.darkMode && ui.smallTimeLineItems'
                  [class.border-box-light]="!ui.darkMode && ui.smallTimeLineItems"

                [class.border-box-added-sm]="hour.state === HourItemIStates.ADDED || hour.state === HourItemIStates.REMOVED"
                (click)="borderBoxTapped(hourIndex, topIndex, roleItem)"
                  >
                  <div class="addedBorder" *ngIf="hour.state === HourItemIStates.ADDED"></div>
                  <div class="revovedBorder" *ngIf="hour.state === HourItemIStates.REMOVED"></div>
                  <!-- [class.border-box-new]="hour.currentlyAdded === true" -->
                </div>

              </div>
            </div>
          </div>
          <div *ngIf="!isLoading">
          <div class='team-holder' *ngFor="let roleItem of excercise.timelineItems; let topIndex = index;">
            <div *ngFor='let inject of getInjects(roleItem.id)'>
              <exercise-duration-ol-tl-item [inject]="inject" [exercise]="excercise" [roleItem]="roleItem" [scaleFact]="isSmallSize ? 0.5 : 1"></exercise-duration-ol-tl-item>
            </div>
            </div>
          </div>
        </div>
      </div>


    </div>


    <div class="p-0 d-flex  p0 p30" style="height: 50px; background: rgb(23, 24, 26) !important; border-radius: 5px; padding-top: 5px!important;" *ngIf="hasAssignedInjects">
      <!-- <div class="p-2">{{canAddHour}}</div> -->
      <div class="p-0 " style="align-self: center;">
        <mat-icon class="dxfx_icon_btn_mini_circ mr5"  matTooltip="Move all injects 1 hour back" matTooltipClass="injectTimelineTT" (click)="subHourToInjects()" *ngIf="canSubHour">chevron_left</mat-icon>
        <!-- (click)="addHourBeforeStart()" -->
        <mat-icon class="dxfx_icon_btn_mini_circ"  matTooltip="Move all injects 1 hour" matTooltipClass="injectTimelineTT" (click)="addHourToInjects()" *ngIf="canAddHour">chevron_right</mat-icon>
        <!-- (click)="removeFirstHour()" -->
      </div>
      <div class="p-0  ml-auto" style="align-self: center;">
        <mat-icon class="dxfx_icon_btn_mini_circ"  matTooltip="ADD HOUR" matTooltipClass="injectTimelineTT">add</mat-icon>
        <!-- (click)="timelineController.appendHour()" -->
        <mat-icon class="dxfx_icon_btn_mini_circ"  matTooltip="REMOVE LAST HOUR" matTooltipClass="injectTimelineTT">remove</mat-icon>
        <!-- (click)="timelineController.removeLastHour()" -->
      </div>
    </div>

    <div class="p-0 d-flex p30 flex-shrink-1" style="height: 350px;" >

      <div class="p-0" style="width: 50%;">
        <div class="d-flex flex-column p0">

          <div class="p-0 d-flex mt10" style="margin-left: 0px;">
            <div class="p-0">
              <h5>DATETIME START </h5>
            </div>
            <div class="p-0 ml-auto">
              <h5>{{formatedString(startDateTime)}}</h5>
            </div>
          </div>
          <div class="p-0" style="margin-left: 0px;">
            <mat-divider style="border-color: rgb(138,173,192,0.2);"></mat-divider>
          </div>

          <div class="p-0 d-flex  pt0" style="margin-left: 0px;"  *ngIf='startDateTime'>

            <div class="p-0 w888 formGroup textfield100">
              <mat-label class='mat-caption'>DAY</mat-label>
              <input type="text" class="formControlD digital" placeholder="01" style="width: 88px!important;"
              #_dayInput
                [readonly]='isReadOnly' maxlength="2" minlength="2" [(ngModel)]='startDateTime.day'
                (change)="onTFChanged($event, 'DAY')" >
                <!-- (ngModelChange)="ngInputChanged('DAY')" -->
            </div>

            <div class="p-0 w888 formGroup textfield100">
              <mat-label class='mat-caption'>HOUR</mat-label>
              <input type="text" class="formControlD digital" placeholder="01" style="width: 88px!important;"
                [readonly]='isReadOnly' (change)="onTFChanged($event, 'HOUR')" [(ngModel)]='startDateTime.hours'
                maxlength="2" minlength="2" >
                <!-- (ngModelChange)="ngInputChanged('HOUR')" -->
            </div>

            <div class="p-0 w888 formGroup textfield100">
              <mat-label class='mat-caption'>MIN</mat-label>
              <input type="text" class="formControlD digital" placeholder="01" style="width: 88px!important;"
                  [readonly]='isReadOnly' maxlength="2" minlength="1"
                  (change)="onTFChanged($event, 'MIN')"
                  [(ngModel)]='startDateTime.minutes'>
                  <!-- (ngModelChange)="ngInputChanged('MIN')" -->
            </div>

            <div class="p-0 w888 formGroup textfield100">
              <mat-label class='mat-caption'>MONTH</mat-label>
              <div class="tactical-month-select-button" [matMenuTriggerFor]="menu" [class.menuDisabled]='isReadOnly' >
                {{startDateTime.month.viewVal | uppercase}}
              </div>
              <mat-menu #menu="matMenu" style="width: 88px!important; text-align: center;">
                <div *ngFor="let option of months">
                  <div style="width: 88px!important; text-align: center;" mat-menu-item [disabled]='isReadOnly'
                    (click)='startMonthSelected(option)'>{{option.viewVal | uppercase}}</div>
                </div>
              </mat-menu>
            </div>

            <div class="p-0 w888 formGroup textfield100">
              <mat-label class='mat-caption'>YEAR</mat-label>
              <input type="text" class="formControlD digital" placeholder="20" style="width: 88px!important;"
                  [readonly]='isReadOnly' maxlength="2" minlength="2" [(ngModel)]='startDateTime.year'
                  (change)="onTFChanged($event, 'YEAR')" >
                  <!-- (ngModelChange)="ngInputChanged('YEAR')" -->
            </div>

          </div>



  <!-- HIER -->


          <div class="p-0 mt10" style="margin-left: 0px;">
            <mat-divider style="border-color: rgb(138,173,192,0.2);"></mat-divider>
            <!-- <h5>DATETIME END {{formatedString(endDateTime)}}</h5> -->
          </div>

          <div class="p-0 d-flex mt10" style="margin-left: 0px;">
            <div class="p-0">
              <h5>DATETIME END </h5>
            </div>
            <div class="p-0 ml-auto">
              <h5>{{formatedString(endDateTime)}}</h5>

            </div>
          </div>
          <div class="p-0" style="margin-left: 0px;">
            <mat-divider style="border-color: rgb(138,173,192,0.2);"></mat-divider>
          </div>

          <div class="p-0 d-flex pb10" style="margin-left: 0px;" *ngIf='endDateTime'>


            <div class="p-0 w888 formGroup textfield100 ">
              <mat-label class='mat-caption'>DAY</mat-label>
              <div class="formControlD digital " style="user-select: none;">
                {{endDateTime.day}}
              </div>
            </div>

            <div class="p-0 w888 formGroup textfield100 ">
              <mat-label class='mat-caption'>HOUR</mat-label>
              <div class="formControlD digital " style="user-select: none;">
                {{endDateTime.hours}}
              </div>
            </div>


            <div class="p-0 w888 formGroup textfield100 ">
              <mat-label class='mat-caption'>MIN</mat-label>
              <div class="formControlD digital " style="user-select: none;">
                {{endDateTime.minutes}}
              </div>
            </div>

            <div class="p-0 w888 formGroup textfield100 ">
              <mat-label class='mat-caption'>MONTH</mat-label>
              <div class="tactical-month-select-button " style="user-select: none; ">
              {{endDateTime.month.viewVal | uppercase}}
            </div>

            </div>
            <div class="p-0 w888 formGroup textfield100 ">
              <mat-label class='mat-caption'>YEAR</mat-label>
              <div class="formControlD digital " style="user-select: none;">
                {{endDateTime.year}}
              </div>
            </div>

          </div>

          <div class="p-0 pb10" style="margin-left: 0px;">
            <mat-divider style="border-color: rgb(138,173,192,0.2);"></mat-divider>
          </div>
          <div class="p-0 pb10" >
            <!-- <p style="color: #ffffff; font-size: 12px;">{{adjustMessage}}</p> -->
          </div>

          <!-- <div class="d-flex pb30">
            <div class="p-2 ml-auto" *ngIf='showSaveButton'>
              <ect-color-btn title="Save" icon="save" color="primary" (clicked)="save()"></ect-color-btn>

            </div>
          </div> -->

        </div>
      </div>

      <div class="p-0 align-items-center " style="width: 50%; align-content: center; justify-content: center;">

        <div class="p-0 d-flex mt10 justify-content-center" >

          <div class="p-0 ">
            <div class="d-flex flex-column ">
              <div class="p-0 tlc pt4" style="width: 60px;">
                <mat-icon class="dxfx_icon_btn_mini_circ" (click)="addTimeFor('DAY')">add</mat-icon>
              </div>
              <div class="p-0 ">
                <div class="tactical-duration-box">
                  {{durationInDays}}
                </div>
              </div>
              <div class="p-0 tlc pt4" style="width: 60px;">
                <mat-icon class="dxfx_icon_btn_mini_circ" *ngIf="!buttonErrors.includes(BUTTON_ERROR_TYPES.MINUS_DAY)" (click)="subTimeFor('DAY')">remove</mat-icon>
                <mat-icon class="dxfx_icon_btn_mini_circ_error" *ngIf="buttonErrors.includes(BUTTON_ERROR_TYPES.MINUS_DAY)">close</mat-icon>
              </div>
            </div>
          </div>

          <div class="p-0  ">
            <div class="d-flex flex-column ">
              <div class="p-0 tlc pt4" style="width: 60px;">
                <mat-icon class="dxfx_icon_btn_mini_circ" (click)="addTimeFor('HOUR')">add</mat-icon>
              </div>
              <div class="p-0 ">
                <div class="tactical-duration-box">
                  {{durationInHours}}
                </div>
              </div>
              <div class="p-0 tlc pt4" style="width: 60px; position: relative;">
                <mat-icon class="dxfx_icon_btn_mini_circ" *ngIf="!buttonErrors.includes(BUTTON_ERROR_TYPES.MINUS_HOUR)" (click)="subTimeFor('HOUR')">remove</mat-icon>
                <mat-icon class="dxfx_icon_btn_mini_circ_error" *ngIf="buttonErrors.includes(BUTTON_ERROR_TYPES.MINUS_HOUR)">close</mat-icon>
              </div>
            </div>
          </div>

         <div class="p-2" style="align-content: center;"> <h5>DURATION | DAYS / HOURS</h5> </div>

        </div>
      </div>
    </div>
  </div>

</div>

<mat-toolbar class="overlay-toolbar" >
  <p style="color: #ff0000;">{{errorText}}</p>
  <!-- <span *ngIf="hasAssignedInjects">
    <mat-checkbox [(ngModel)]="adjustInjectStartTime" (change)="adjustInjectStartTimeChanged()" value="" color="accent">{{checkboxLabel}}</mat-checkbox>
  </span> -->
  <span class="toolbar-spacer"></span>
  <!-- <p style="color: #ffffff; font-size: 12px;">{{adjustMessage}}</p> -->

  <ect-color-btn title="Save" icon="save" color="primary" (clicked)="save()" [disabled]="saveBtnDisabled" *ngIf='showSaveButton'></ect-color-btn>
</mat-toolbar>

<div class="preloader_OL" [class.preloader_OL_out]="!isLoading" [class.preloader_OL_in]="isLoading" *ngIf="preloaderVisible">
  <div class="innerLoader" style="position: absolute; left: 50%; top: 50%; margin-top: -25px; margin-left: -25px;">
    <mat-spinner diameter="25" color="accent"></mat-spinner>
  </div>
</div>
