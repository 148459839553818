import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { ALERT_BUTTON_ACTION_TYPE, ALERT_BUTTON_COLOR_TYPE } from 'src/app/model/enums';
import { ExerciseI, IUserRole } from 'src/app/model/exercise.model';
import { AlertOverlayComponent } from 'src/app/overlays/alert-overlay/alert-overlay.component';
import { CreateExerciseOverlayComponent } from 'src/app/overlays/create-exercise-overlay/create-exercise-overlay.component';
import { ApiService } from 'src/app/services/api.service';
import { LocaleService } from 'src/app/services/locale.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-exercise-overview',
  templateUrl: './exercise-overview.component.html',
  styleUrls: ['./exercise-overview.component.scss'],

})
export class ExerciseOverviewComponent  implements OnInit {
  // exercises: ExerciseI[] = []
  ROLES = IUserRole
  constructor(public api:ApiService, private router: Router, private dialog: MatDialog, public storage: StorageService, private ls: LocaleService) {}
  ngOnInit(): void {

    setTimeout(()=>{
      this.storage.loadExercises()

      
    },500)  
  }
  // async load() {
  //   const r = await this.api.apiGetP('exercise')
  //   console.log(r);
  //   this.exercises = r as ExerciseI[]
  // }

  navTo(path: string) {
    this.router.navigate(['exercise-dashboard', path])

  }
  adminPanel() {
    this.router.navigateByUrl('admin')
  }
  async fixUsers() {
    const result = await this.api.apiGetP('user/fix-users')
    console.log(result);
  }
  signOutTapped() {
    this.storage.signOut()
    setTimeout(() => {
      this.router.navigateByUrl('/sign-in')
    }, 1500)
  }

  @ViewChild('menu') menu!: MatMenu
  openMenu(e:any, ex: ExerciseI) {
    e.stopPropagation();
    e.preventDefault();
    this.menu.templateRef.elementRef.nativeElement.openMenu()
  }
  deleteExercise(e:any, ex: ExerciseI) {
    e.stopPropagation();
    e.preventDefault();
    const dialogReg = this.dialog.open(AlertOverlayComponent, {
      width:"400px",
      disableClose:true,
      data: {
        title: this.ls.locales["DELETE_EXERCISE_ALERT"]['TITLE'], //"Achtung",
        bodyMessage: this.ls.locales["DELETE_EXERCISE_ALERT"]['BODY'],
          actions: [
            {title: this.ls.locales["DELETE_EXERCISE_ALERT"]['BTN_RESUME'], color: ALERT_BUTTON_COLOR_TYPE.WARN, actionType: ALERT_BUTTON_ACTION_TYPE.RESUME},
            {title: this.ls.locales["DELETE_EXERCISE_ALERT"]['BTN_CANCEL'], color: ALERT_BUTTON_COLOR_TYPE.BASIC, actionType: ALERT_BUTTON_ACTION_TYPE.CANCEL},
          ]
        },
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe((result) => {
      if(result != null){
        console.log(result);
        
        if(result === ALERT_BUTTON_ACTION_TYPE.RESUME){
          // this._finalDeletePosition(group, pPosition)
          // this.deletePositonSubscription = null
          this.finalActionEx(ex, 'remove')
        } else {
          // this.deletePositonSubscription = null
        }
      }
    });
  
    
    
  }
  deboardExercise(e:any, ex: ExerciseI) {
    e.stopPropagation();
    e.preventDefault();
    const dialogReg = this.dialog.open(AlertOverlayComponent, {
      width:"400px",
      disableClose:true,
      data: {
        title: this.ls.locales["DEBOARD_EXERCISE_ALERT"]['TITLE'], //"Achtung",
        bodyMessage: this.ls.locales["DEBOARD_EXERCISE_ALERT"]['BODY'],
          actions: [
            {title: this.ls.locales["DEBOARD_EXERCISE_ALERT"]['BTN_RESUME'], color: ALERT_BUTTON_COLOR_TYPE.WARN, actionType: ALERT_BUTTON_ACTION_TYPE.RESUME},
            {title: this.ls.locales["DEBOARD_EXERCISE_ALERT"]['BTN_CANCEL'], color: ALERT_BUTTON_COLOR_TYPE.BASIC, actionType: ALERT_BUTTON_ACTION_TYPE.CANCEL},
          ]
        },
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe((result) => {
      if(result != null){
        console.log(result);
        if(result === ALERT_BUTTON_ACTION_TYPE.RESUME){
          this.finalActionEx(ex, 'deboard')
        }
      }
    });
  
    
    
  }
  async add() {
    this.openCreateOverlay()
    // const title = `Exercise from ${DATE_TO_STRING()}`
    // // console.log(title);
    // // return
    // const dto = {
    //   name: title,//'New Item',
    //   description: 'My new description',
    //   durationInHours: 120,
    //   companyId: 'Hc7lsDFouWOyDR8p0GA8'
    // }
    // console.log(dto);
    
    // await this.api.apiPost('exercise', dto);
    // setTimeout(()=>{
    //   this.load()
    // },500)  
  }


  private openCreateOverlay() {
    this.dialog
      .open(CreateExerciseOverlayComponent, {
        width: "70%",
        height: "70%",
        maxWidth: "850px",
        maxHeight: "700px",
        backdropClass: "backdrop-2",
      }).afterClosed().subscribe((id?: string) => {
        if (id) {
          this.navTo(id);
        }
      });
  }

  private async finalActionEx(ex: ExerciseI, path: string){
    // ex: ExerciseI
    console.log(ex);
    const r = await this.api.apiPatch(`exercise/${path}`,ex.id, {})
    console.log(r);
    this.storage.postSocket('exercise_update', {id: ex.id})
    // setTimeout(()=>{
    //   this.load()
    // },500)  
  }
    
}
