import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertI } from 'src/app/model/alert.model';
import { ALERT_BUTTON_ACTION_TYPE, ALERT_BUTTON_COLOR_TYPE } from 'src/app/model/enums';


@Component({
  selector: 'app-alert-overlay',
  templateUrl: './alert-overlay.component.html',
  styleUrls: ['./alert-overlay.component.scss']
})
export class AlertOverlayComponent  implements OnInit {

  title = ""
  constructor(public dialogRef: MatDialogRef<AlertOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertI) {
      this.title = data.title
    }

  ngOnInit(): void {
  }

  tapped(action:ALERT_BUTTON_ACTION_TYPE){
     this.dialogRef.close(action)
  }

  colorForActionColorType(type :ALERT_BUTTON_COLOR_TYPE):string{
    switch(type) {
      case ALERT_BUTTON_COLOR_TYPE.BASIC:
        return '#3F3F3F';//'basic';
      case ALERT_BUTTON_COLOR_TYPE.PRIMARY:
        return '#1976D2';// 'primary';
      case ALERT_BUTTON_COLOR_TYPE.ACCENT:
        return '#32A555';// 'accent';
      case ALERT_BUTTON_COLOR_TYPE.WARN:
        return '#D32F2F';// 'warn';
    }
  }

}
