import { CompanyInject, iCompanyInject, iExerciseInject } from "./company.model";
import { CompanyTrainingObjective, iCompanyTrainingObjective } from "./trainingObj.model";

export interface TextEditOverlayDataI{
  title:string;
  editText:string;
  singleLineTxt?: boolean
}
export enum ModOverlayDataType {
  LOADING = 'LOADING',
  CREATE_OBJ = 'CREATE_OBJ',
  MODIFY_OBJ = 'MODIFY',
  
  CREATE_COMPANY_INJECT = 'CREATE_COMPANY_INJECT',
  MODIFY_COMPANY_INJECT = 'MODIFY_COMPANY_INJECT',
}
export interface iModifyOverlayData {
  dataTye: ModOverlayDataType,
  companyId?: string;
  exerciseId?: string;
  model: CompanyTrainingObjective | CompanyInject
  // exerciseInjects?: iExerciseInject[]
  // companyInjects?: iCompanyInject[]
}