import { iCompanyInject } from "src/app/model/company.model";
import { HTTP_METHOD } from "src/app/model/enums";
import { INJECT_DELIVERY_METHOD, INJECT_DELIVERY_METHOD_LIST_ITEM } from "src/app/model/exercise.model";
function getCleanCompanyInject() : iCompanyInject {
  const item: iCompanyInject = {
    id: -1,
    companyId: '',
    short_title: '',
    inject_no: '',
    deliveryMethod: INJECT_DELIVERY_METHOD.OTHER,
    responsibility_for_delivery_by: '',
    related_documents: '',
    related_equipment: '',
    role_players_instruction: '',
    additional_guidance_caution_options: '',
    expected_outcomes: '',
    on_scene_preparation: '',
    trainers_action_requirement: '',
  };
  return item;
}

export default getCleanCompanyInject;

export const InjectDeliveryMethods : INJECT_DELIVERY_METHOD_LIST_ITEM[] = [
  { viewVal: 'EVENT', itemEnum: INJECT_DELIVERY_METHOD.EVENT },
  { viewVal: 'MAIL', itemEnum: INJECT_DELIVERY_METHOD.MAIL },
  { viewVal: 'PHONECALL', itemEnum: INJECT_DELIVERY_METHOD.PHONECALL },
  { viewVal: 'RADIOCALL', itemEnum: INJECT_DELIVERY_METHOD.RADIOCALL },
  { viewVal: 'SOCIALMEDIA', itemEnum: INJECT_DELIVERY_METHOD.SOCIALMEDIA },
  { viewVal: 'VIRTUALMEETING', itemEnum: INJECT_DELIVERY_METHOD.VIRTUALMEETING },
  { viewVal: 'PHYSICALMEETING', itemEnum: INJECT_DELIVERY_METHOD.PHYSICALMEETING },
  { viewVal: 'ROLEPLAY', itemEnum: INJECT_DELIVERY_METHOD.ROLEPLAY },
  { viewVal: 'VIRTUAL_OSOCC', itemEnum: INJECT_DELIVERY_METHOD.VIRTUAL_OSOCC },
  { viewVal: 'OTHER', itemEnum: INJECT_DELIVERY_METHOD.OTHER },
]

export function selectedInjectDeliveryMethodItem(deliveryMethod: INJECT_DELIVERY_METHOD) : INJECT_DELIVERY_METHOD_LIST_ITEM {
  return InjectDeliveryMethods.find((item) => item.itemEnum == deliveryMethod) ??  InjectDeliveryMethods[InjectDeliveryMethods.length = 1];
};

export function httpMethod (httpMethod: HTTP_METHOD): string {
  return httpMethod.toString().toLowerCase()
}