import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { iCompany } from 'src/app/model/company.model';
import { CreateExerciseDto } from 'src/app/model/dtos';
import { ExerciseI } from 'src/app/model/exercise.model';
import { iUser } from 'src/app/model/iuser.model';
import { ApiService } from 'src/app/services/api.service';
import { TIME } from 'src/app/services/helpers';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-create-exercise-overlay',
  templateUrl: './create-exercise-overlay.component.html',
  styleUrls: ['./create-exercise-overlay.component.scss']
})
export class CreateExerciseOverlayComponent implements OnInit {
  private api = inject(ApiService);
  public storage = inject(StorageService);
  currentUser:iUser | undefined

  exerciseStartDateTime: number | undefined
  selectedComp: iCompany | undefined
  newExercise: CreateExerciseDto = {
    // name: '',
    // description: '',
    durationInHours: 72,
    // companyId: '',
  }

  constructor(public dialogRef: MatDialogRef<CreateExerciseOverlayComponent>,) {
    }

  ngOnInit(): void {
    this.currentUser = this.storage.currentUser
    const currentUTC = TIME.currentUTCDate()
    // console.log(currentUTC);
    // console.log(currentUTC.getUTCHours());
    const yyyy = currentUTC.getUTCFullYear()
    const month = currentUTC.getUTCMonth() + 1;
    const day = 1
    const hours = 10
    // console.log(`${yyyy} ${month}`);
    const startDayTime:Date = new Date()
    startDayTime.setFullYear(yyyy)
    startDayTime.setMonth(month)
    startDayTime.setDate(1)
    startDayTime.setHours(hours, 0, 0,0)
    // console.log(startDayTime);
    
    this.newExercise.startDateTime = startDayTime.getTime()
    
  }

  get companies(): iCompany[] {
    if(this.storage.currentUser && this.storage.currentUser.companies) {
      return this.storage.currentUser.companies
    } else {
      return []
    }
  }

  get saveDisabled(): boolean {
    if (this.selectedComp && this.newExercise.name) {
      return false;
    } else {
      return true;
    }
  }

  onChanged(e:any) {
    const comp = e.value as iCompany
    console.log(comp);
    console.log(this.selectedComp);
    
  }
  close() {
    this.dialogRef.close();
  }
  async save() {
    if(this.selectedComp) {
      this.newExercise.companyId = this.selectedComp.companyId
      const dto = {
        name: this.newExercise.name,
        description: this.newExercise.description ?? '',
        durationInHours: 72,
        companyId: this.newExercise.companyId,
        startDateTime: this.newExercise.startDateTime
      }
      console.log(dto);
      
      const result = await this.api.apiPost('exercise', dto);
      const ex = result as ExerciseI
      // console.log(ex);
      if(ex) {
        this.dialogRef.close(ex.id);
      }
    }
    // console.log(this.newExercise);
    
    // setTimeout(()=>{
    //   this.load()
    // },500)  
  }
}
