import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExerciseClass, IExerciseTimelineInjectRef, TacticalTime } from 'src/app/model/exercise.model';
import { ApiService } from 'src/app/services/api.service';
import { TIME } from 'src/app/services/helpers';
import { StorageService } from 'src/app/services/storage.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-timline-time-change-overlay',
  templateUrl: './timline-time-change-overlay.component.html',
  styleUrls: ['./timline-time-change-overlay.component.scss']
})
export class TimlineTimeChangeOverlayComponent implements OnInit{
  showThis = false

  constructor(public dialogRef: MatDialogRef<TimlineTimeChangeOverlayComponent>
    ,@Inject(MAT_DIALOG_DATA) public data: IExerciseTimelineInjectRef,
    public api: ApiService, public ui: UiService, public storage: StorageService) {
    this.inject = data;
    // this.dialogRef.setp
  }
  startSliderValInit = 1
  durationInMinytesInit = 0
currentEx: ExerciseClass | undefined 
  inject: IExerciseTimelineInjectRef | undefined
  tacticalTime: TacticalTime = TIME.date_lcoal_to_tt(new Date())
  tickInterval:number = 1
  max = 1400;
  durationMax = 1400;
  min = 0;
  step = 10;
  startSliderVal = 1
  durationInMinytes = 0
  selectedTimeItem:string = ""
  changeTime = false
  canEditDeliveryMethod = true
  editDaysActive = false
  editHoursActive = false
  editMinutesActive = false
  startTime = ''
  endTime = ''
  ngOnInit(): void {
    // console.log(`this.inject!.deliveryDateTime srart ${this.inject!.deliveryDateTime}`);
    
    this.startSliderVal = this.inject!.deliveryDateTime
    this.durationInMinytes = this.inject!.deliveryDurationInMinutes
    this.startSliderValInit = this.inject!.deliveryDateTime
    this.durationInMinytesInit = this.inject!.deliveryDurationInMinutes
    if(this.storage.currentExer) {
      this.currentEx = this.storage.currentExer
      this.max = (this.storage.currentExer.durationInHours * 60) - this.durationInMinytesInit
      this.durationMax = (this.storage.currentExer.durationInHours * 60) - this.inject!.deliveryDateTime
      console.log(`this.api.currentExer.durationInHours ${this.storage.currentExer.durationInHours}`);
      console.log(`max ${this.max}`);
      console.log(`durationMax ${this.durationMax}`);
      
    }
  }
/*   changeTimeFor(item:string){
    
    if(item == 'DAY') {

    } else if(item == 'HOURS') {
      
      this.min = 0;
      this.max = 23;
      this.step = 1;
      this.startSliderVal = Math.floor(this.inject!.deliveryDateTime / 60)
      this.selectedTimeItem = item
    } else if(item == 'MINUTES') {
      
      this.min = 0;
      this.max = 59;
      this.step = 1;
      this.startSliderVal = this.inject!.deliveryDateTime
      this.selectedTimeItem = item
    }
    // this.formatLabel(this.startSliderVal)
    
    
    this.changeTime = true;
  } */
  // sliderChanged(e: any) {
  //   // console.log(e.target.value);
  //   let newV = Number(this.startSliderVal)  /// Number(60) 
  //   console.log(`newV ${newV}`);
  //   // leftPos: (Number(this.startSliderVal)  * 241) / 60,
  //   // leftPosSmall: 
  //   this.inject!.deliveryDateTime = Number(this.startSliderVal)
  //   this.inject!.leftPos = ((Number(this.startSliderVal)  * 241) / 60)
  //   this.inject!.leftPosSmall = ((Number(this.startSliderVal)  * 61) / 60)
   
  //   this.ui.miniTimelineUpdate.emit(this.inject!.deliveryDateTime)
  //   const hours = Math.floor(newV / 60);
  //   const minutes = newV % 60;
  //   // console.log(`h ${hours * 60} m ${minutes / 60}`);
  //   this.startTime = hours < 10 ? `0${hours}:`:`${hours}:` 
  //   this.startTime += minutes < 10 ? `0${minutes}`:`${minutes}`
    
  //   const newEv =  newV + Number(this.durationInMinytes)
  //   // console.log(newEv);
    
  //   const Endhours = Math.floor(newEv / 60);
  //   const Endminutes = newEv % 60;
  //   this.endTime = Endhours < 10 ? `0${Endhours}:`:`${Endhours}:` 
  //   this.endTime += Endminutes < 10 ? `0${Endminutes}`:`${Endminutes}`
  //   if(this.currentEx) {

  //     this.durationMax = (this.currentEx.durationInHours * 60) - this.inject!.deliveryDateTime
  //   }
  //   // console.log(this.startSliderVal);
  //   // if(this.selectedTimeItem == 'DAY') {

  //   // } else if(this.selectedTimeItem == 'HOURS') {
  //   //   const hours = Math.floor(this.startSliderVal);
  //   //   // const minutes = this.inject!.deliveryDateTime % 60;
  //   //   // this.inject!.deliveryDateTime = this.startSliderVal * 60
  //   //   console.log(this.inject!.deliveryDateTime);
      
  //   //       this.tacticalTime.hours =  hours.toString()
  //   //       // this.tacticalTime.minutes =  minutes.toString()
  //   //   this.inject!.deliveryDateTime = Number(this.startSliderVal * 60) 
  //   // } else if(this.selectedTimeItem == 'MINUTES') {
  //   //   const hours = Math.floor(this.inject!.deliveryDateTime / 60);
  //   //   const minutes = this.startSliderVal % 60;
  //   // console.log(`h ${hours * 60} m ${minutes / 60}`);
  //   // const t = (hours * 60) + (minutes)
  //   // // console.log(t);
  //   // // console.log(Math.round(t));
  //   // this.inject!.deliveryDateTime = Number(t)
  //   // console.log(this.inject!.deliveryDateTime);
  //   //   // this.inject!.deliveryDateTime = this.startSliderVal / 60
  //   //   // const hours = Math.floor(this.inject!.deliveryDateTime / 60);
  //   //   // const minutes = this.inject!.deliveryDateTime % 60;
    
  //   //   //     this.tacticalTime.hours =  hours.toString()
  //   //   //     this.tacticalTime.minutes =  minutes.toString()
  //   // }
  // }
  get durationString() : string {
    if(this.durationInMinytes > 240) {
      let hours2:number = Math.floor(this.durationInMinytes / 60);
      let mins:number = Math.floor(this.durationInMinytes % 60);
      return `${hours2}h : ${mins}min`
    } else {
      return `${this.durationInMinytes}min`
    }
    return ''
  }
  sliderDurationSliderChanged(e:any){
    // console.log(e.target.value);
    // console.log(`duration : ${this.durationInMinytes}`);
    // let hours2:number = Math.floor(this.durationInMinytes / 60);
    // console.log(`hours2 : ${hours2}`);
    // console.log(`rest =mins : ${this.durationInMinytes % 60}`);
    // console.log(`duration : ${(this.durationInMinytes % 60)}`);
    
    // console.log(this.durationInMinytes);
    let newV = Number(this.startSliderVal)  /// Number(60) 
    // console.log(`newV ${newV}`);
    // leftPos: (Number(this.startSliderVal)  * 241) / 60,
    // leftPosSmall: 
    // this.inject!.leftPos = ((Number(this.startSliderVal)  * 241) / 60)
    // this.inject!.leftPosSmall = ((Number(this.startSliderVal)  * 61) / 60)

    // this.inject!.deliveryDateTime = Number(this.startSliderVal)
    
    const hours = Math.floor(newV / 60);
    const minutes = newV % 60;
    // console.log(`h ${hours * 60} m ${minutes / 60}`);
    this.startTime = hours < 10 ? `0${hours}:`:`${hours}:` 
    this.startTime += minutes < 10 ? `0${minutes}`:`${minutes}`
    
    const newEv =  newV + Number(this.durationInMinytes)
    // console.log(newEv);
    
    const Endhours = Math.floor(newEv / 60);
    const Endminutes = newEv % 60;
    this.endTime = Endhours < 10 ? `0${Endhours}:`:`${Endhours}:` 
    this.endTime += Endminutes < 10 ? `0${Endminutes}`:`${Endminutes}`
    this.inject!.deliveryDurationInMinutes = Number(this.durationInMinytes)
    if(this.currentEx) {
      this.max = (this.currentEx.durationInHours * 60) - (Number(this.inject!.deliveryDateTime) + Number(this.inject!.deliveryDurationInMinutes))
      console.log(`new max; ${this.max}`);
      
      // this.max = (this.api.currentExer.durationInHours * 60) - this.inject!.deliveryDurationInMinutes
    }
  }
  close() {
    this.inject!.leftPos = ((Number(this.startSliderValInit)  * 241) / 60)
    this.inject!.leftPosSmall = ((Number(this.startSliderValInit)  * 61) / 60)
    this.inject!.deliveryDateTime = this.startSliderValInit
    this.inject!.deliveryDurationInMinutes = this.durationInMinytesInit
    this.dialogRef.close()
  }
  async save() {
    this.dialogRef.close(this.inject)
  }
}


