import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { InjectItemUpdateDto } from "src/app/model/dtos";
import {
  ExerciseUserRole,
  IExerciseInjectState,
  IExerciseTimelineInjectRef,
  INJECT_DELIVERY_METHOD,
} from "src/app/model/exercise.model";
import { Location } from "src/app/model/location.model";
import { ApiService } from "src/app/services/api.service";
import { StorageService } from "src/app/services/storage.service";
import * as _ from "lodash";
import { INavigationListItem } from "src/app/components/navigation-list-item/navigationListitem.model";
import { AlertOverlayComponent } from "../alert-overlay/alert-overlay.component";
import {
  ALERT_BUTTON_ACTION_TYPE,
  ALERT_BUTTON_COLOR_TYPE,
} from "src/app/model/enums";
import { AddComponentDirective } from "src/app/directives/add-component.directive";
import { wait } from "src/app/services/utils/functions";
import { LocaleService } from "src/app/services/locale.service";
interface sideNavItem {
  index: number;
  active: boolean;
  title: string;
  icon?: string;
}
@Component({
  selector: "app-inject-detail-overlay",
  templateUrl: "./inject-detail-overlay.component.html",
  styleUrls: ["./inject-detail-overlay.component.scss"],
})
export class InjectDetailOverlayComponent implements OnInit {
  @ViewChild(AddComponentDirective, { static: false })
  adHost!: AddComponentDirective;

  inject: IExerciseTimelineInjectRef | undefined;
  showHistory = false;
  canEditDeliveryMethod = true;

  ExerciseUserRoles = ExerciseUserRole;
  loaded = false;
  needToBeSaved = false;
  tickInterval: number = 1;
  durationInMinytes = 0;
  nextInjectState: IExerciseInjectState | undefined;
  sideNavItem: INavigationListItem | undefined;
  sideNavItems: INavigationListItem[] = [];
  locations: Location[] = [];
  injectLocation: Location | undefined;

  currentToggleGroupIndex = 0;

  onNaviSelectEvent(e: INavigationListItem) {
    //sideNavItem){
    this.sideNavItem = e;
    this.injectViewCOmponent(e);
  }

  // get activeSideNavId() : number {
  //   return this.sideNavItem ? this.sideNavItem.id : -1;
  // }

  onTextChanged(val: string, key: string) {
    (this.inject as any)[key] = val;
    setTimeout(() => {
      console.log(this.inject);
    }, 1000);
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + "k";
    }
    return `${value}`;
  }

  constructor(
    public dialogRef: MatDialogRef<InjectDetailOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IExerciseTimelineInjectRef,
    public api: ApiService,
    public storage: StorageService,
    private dialog: MatDialog,
    private locale: LocaleService
  ) {
    this.inject = data;
    console.log(this.inject);
    this.needToBeSaved = false;
    this.durationInMinytes = this.inject.deliveryDurationInMinutes;
  }

  ngOnInit(): void {
    if (!this.inject) {
      return;
    }

    // this.initNavItems();

    wait(200, () => {
      if (this.storage.currentExer?.getLocations()) {
        this.locations = this.storage.currentExer?.getLocations();
        if (this.inject!.location) {
          this.injectLocation = this.locations.find(
            (item) => item.id == this.inject!.location?.id
          );
        }
      }
    });

    this.dialogRef.beforeClosed().subscribe((x) => {
      console.log(`BEFORE CLOSED: ${this.needToBeSaved}`);
      if (this.needToBeSaved) {
        this.save(false);
        //   console.log(this.inject);
        //   if (!this.storage.currentExer || !this.inject) { return }

        //   const dto:InjectItemUpdateDto = {
        //    exerciseId: this.storage.currentExer.id,
        //    injectItemId: this.inject.id,
        //    description: this.inject.description,
        //    short_title: this.inject.short_title,
        //    // timelineItemId: inj.id,
        //    stateId: this.nextInjectState ? this.nextInjectState.id :  this.inject.state.id,
        //    deliveryDateTime: this.inject.deliveryDateTime,
        //    deliveryDurationInMinutes: this.inject.deliveryDurationInMinutes,
        //    locationId: this.injectLocation ?this.injectLocation.id : -1,

        //    related_documents: this.inject.related_documents,
        //    related_equipment: this.inject.related_equipment,
        //    role_players_instruction: this.inject.role_players_instruction,
        //    on_scene_preparation: this.inject.on_scene_preparation,
        //    additional_guidance_caution_options: this.inject.additional_guidance_caution_options,
        //    expected_outcomes: this.inject.expected_outcomes,
        //    trainers_action_requirement: this.inject.trainers_action_requirement,

        //  }
        //  console.log(dto);
      }
    });
  }

  onStateChanged(e: IExerciseInjectState) {
    const stateIndex = this.storage.injectStates.findIndex(
      (item) => item.id == e.id
    );
    if (stateIndex != -1) {
      this.nextInjectState = this.storage.injectStates[stateIndex];
      this.needToBeSaved = true;
      return;
    }
  }

  onDeliveryMethodChanged(e: INJECT_DELIVERY_METHOD) {
    if (this.inject) {
      this.inject.deliveryMethod = e;
      this.needToBeSaved = true;
    }
  }

  getShortTitleEnabled(): boolean {
    return true;
  }

  close() {
    const containerRef = this.adHost.viewContainerRef;
    containerRef.clear();
    console.log(this.inject?.state);
    // console.log(`CLOSE: ${this.needToBeSaved}`);
    wait(150, () => {
      this.dialogRef.close(this.inject);
    });
  }
  ngShortTitleChanged(e: any) {
    this.needToBeSaved = true;
  }
  locationChanged(e: Location) {
    // console.log(e.value);
    this.injectLocation = e;
    this.needToBeSaved = true;
    setTimeout(() => {
      console.log(this.injectLocation);
      console.log(this.inject);
    }, 1500);
  }

  getBackgroundColor(): string {
    if (this.nextInjectState) {
      return this.nextInjectState.color;
    } else {
      return this.inject ? `${this.inject.state.color}` : "#081927";
    }
  }

  async save(withClosing: boolean = true) {
    // console.log(this.inject);
    // console.log(this.inject?.location);
    // console.log(this.injectLocation );

    // return
    if (!this.storage.currentExer || !this.inject) {
      return;
    }

    const dto: InjectItemUpdateDto = {
      exerciseId: this.storage.currentExer.id,
      injectItemId: this.inject.id,
      description: this.inject.description,
      short_title: this.inject.short_title,
      // timelineItemId: inj.id,
      stateId: this.nextInjectState
        ? this.nextInjectState.id
        : this.inject.state.id,
      deliveryDateTime: this.inject.deliveryDateTime,
      deliveryDurationInMinutes: this.inject.deliveryDurationInMinutes,
      locationId: this.injectLocation ? this.injectLocation.id : -1,
      deliveryMethod: this.inject.deliveryMethod,
      related_documents: this.inject.related_documents,
      related_equipment: this.inject.related_equipment,
      role_players_instruction: this.inject.role_players_instruction,
      on_scene_preparation: this.inject.on_scene_preparation,
      additional_guidance_caution_options:
        this.inject.additional_guidance_caution_options,
      expected_outcomes: this.inject.expected_outcomes,
      trainers_action_requirement: this.inject.trainers_action_requirement,
    };
    console.log(dto);
    if (dto.locationId == -1) {
      delete dto.locationId;
    }
    this.storage.socketInjectUodate(dto);
    if (this.injectLocation || (this.inject && this.inject.location)) {
      if (this.inject!.location?.id != this.injectLocation?.id) {
        // console.log(`location needs to be changed ${this.injectLocation?.id}`);
        this.storage.injectsLocationChangedEmitter.emit();
      }
    }
    if (withClosing) {
      this.close();
    }
  }

  private injectViewCOmponent(navType: INavigationListItem) {
    console.log("injectViewCOmponent>>>>>");
    console.log(navType);

    var _injectInput: any = {}; //:{ [key: string]: string } = {}
    var _inputData = "";
    _injectInput = this.inject!;

    for (const key of Object.keys(_injectInput)) {
      if (key == navType.typeValue) {
        _inputData = _injectInput[key];
      }
    }
    // console.log(_inputData);
    // var _val = _injectInput.]
    if (navType.id == 0) {
      this.inject!.description ?? "";
    }

    const containerRef = this.adHost.viewContainerRef;
    containerRef.clear();
    // this.adHost.viewChildContainerRef.createComponent(InjectDetailOL_TextEditorComponent);
    const comp = this.adHost.createMyComponent(navType.dynComponent.component);
    comp.instance.data = _inputData; //navType.dynComponent.data
    comp.instance.inject = this.inject!;
    comp.instance.injectModel = navType.injectModel;
    comp.instance.editable = navType.dynComponent.editable;

    if (
      navType.typeValue != "TRAINER_FEEDBACK" &&
      navType.typeValue != "OBJECTIVES"
    ) {
      comp.instance.textChanged.subscribe((message: string) => {
        console.log(`message: `, message);
        if (message) {
          this.needToBeSaved = true;
          var _inject = {};
          _inject = this.inject! as Object;
          const keys = Object.keys(_inject);
          for (const key of keys) {
            if (key == navType.typeValue) {
              Object.assign(_inject, { [key]: message });
            }
          }
          this.inject! = _inject as IExerciseTimelineInjectRef;
        }
      });
    }
  }

  async removeInject() {
    this.showAlert();
  }

  private async finalRemove() {
    if (!this.storage.currentExer || !this.inject) {
      return;
    }
    const dto = {
      exerciseId: this.storage.currentExer.id,
      injectId: this.inject.id,
    };
    this.storage.postSocket("delete_inject_from_timeline", dto);
    this.close();
  }

  private showAlert() {
    const dialogReg = this.dialog
      .open(AlertOverlayComponent, {
        width: "400px",
        disableClose: true,
        data: {
          title: this.locale.locales["DELETE_INJECT_ALERT"]["TITLE"], // "Achtung",
          bodyMessage: this.locale.locales["DELETE_INJECT_ALERT"]["BODY"],
          actions: [
            {
              title: this.locale.locales["DELETE_INJECT_ALERT"]["BTN_RESUME"],
              color: ALERT_BUTTON_COLOR_TYPE.WARN,
              actionType: ALERT_BUTTON_ACTION_TYPE.RESUME,
            },
            {
              title: this.locale.locales["DELETE_INJECT_ALERT"]["BTN_CANCEL"],
              color: ALERT_BUTTON_COLOR_TYPE.BASIC,
              actionType: ALERT_BUTTON_ACTION_TYPE.CANCEL,
            },
          ],
        },
        backdropClass: "backdrop-background",
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != null) {
          console.log(result);

          if (result === ALERT_BUTTON_ACTION_TYPE.RESUME) {
            this.finalRemove();
          } else {
          }
        }
      });
  }

  getInjectState(
    val: IExerciseTimelineInjectRef
  ): IExerciseInjectState | undefined {
    return (
      this.storage.injectStates.find((item) => item.id == val.state.id) ??
      undefined
    );
  }

  /*
  private initNavItems() {
    if(!this.inject) { return }
    this.sideNavItems = [
      { id: 0,
        title: 'Generic Inject Description',
        typeValue: 'description',
        dynItemType: DynamicItemDataType.DESCRIPTION,
        dynComponent:
        new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          this.inject.description ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])
        )
        },
      { id: 1,
        title: 'Expected Outcomes',
        typeValue: 'expected_outcomes' ,
        dynItemType: DynamicItemDataType.EXPECTED_OUTCOME,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          this.inject.expected_outcomes ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 2,
        title: 'TRAINER FEEDBACK',
        typeValue: 'TRAINER_FEEDBACK',
        dynItemType: DynamicItemDataType.TRAINER_FEEDBACK,
        dynComponent: new DynamicItem(
          InjectDetailOL_TrainerFeedbackComponent,
          this.inject.description ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 3,
        title: 'OBJECTIVES' ,
        typeValue: 'OBJECTIVES',
        dynItemType: DynamicItemDataType.OBJECTIVES,
        dynComponent: new DynamicItem(
          InjectDetailOL_TrainingObjectivesComponent,
          // this.inject.learning_objectives ?? [],
          this.inject.trainingObjectives ?? [],
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 4,
        title: 'Preparations' ,
        typeValue: 'on_scene_preparation',
        dynItemType: DynamicItemDataType.ON_SCENE_PREPARATION,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          this.inject.on_scene_preparation ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 5,
        title: 'Information',
        typeValue: 'related_documents',
        dynItemType: DynamicItemDataType.RELATED_DOCUMENTS,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          this.inject.related_documents ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 6,
        title: 'Resources and Documents',
        typeValue: 'related_equipment',
        dynItemType: DynamicItemDataType.RELATED_EQUIPMENT,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          this.inject.related_equipment ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 7,
        title: 'Specific Action Requirement for Trainers' ,
        typeValue: 'trainers_action_requirement',
        dynItemType: DynamicItemDataType.TRAINERS_ACTION_REQUIRED,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          this.inject.trainers_action_requirement ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 8,
        title: 'Roleplay Instructions' ,
        typeValue: 'role_players_instruction',
        dynItemType: DynamicItemDataType.ROLE_PLAY_INSTRUCTIONS,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          this.inject.role_players_instruction ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))
        },
      { id: 9,
        title: 'Safety and Security Guidance',
        typeValue: 'additional_guidance_caution_options' ,
        dynItemType: DynamicItemDataType.ADDITIONAL_GUIDANCE_CAUTION_OPTIONS,
        dynComponent: new DynamicItem(
          InjectDetailOL_TextEditorComponent,
          this.inject.additional_guidance_caution_options ?? '',
          this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]),
        )
        },
    ];

    if(this.inject.itemType == ExerciseTimelineItemType.EVENTS || this.inject.itemType == ExerciseTimelineItemType.EXCON) {
      this.sideNavItems.splice(3, 1);
    }

    if((this.inject.itemType == ExerciseTimelineItemType.EVENTS || this.inject.itemType == ExerciseTimelineItemType.EXCON) || this.storage.exerciseUserRole == ExerciseUserRole.OBSERVER) {
      this.sideNavItems.splice(2, 1);
    }


    wait(100, () => {
      this.selectNav(this.sideNavItems[0]);
    });
  }
    */
}
