import { Directive, Type, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[add-host]'
})
export class AddComponentDirective {

  // constructor(public viewChildContainerRef: ViewContainerRef) {}
  constructor(public viewContainerRef: ViewContainerRef) {
  }

  createMyComponent<C>(componentType: Type<C>) {
    return this.viewContainerRef.createComponent(componentType);
  }
}
