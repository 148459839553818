import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';

import { ApiService } from '../services/api.service';
import { IUserRole } from '../model/exercise.model';
import { StorageService } from '../services/storage.service';

@Directive({
  selector: '[appAccess]'
})
export class AccessDirective implements OnInit {
  userRole: IUserRole[] = []
  constructor(
    private templateRef: TemplateRef<any>,
    private storage: StorageService,
    private viewContainer: ViewContainerRef
  ) { }

  @Input()
  set appAccess(role: IUserRole[]) {
      if (!role || !role.length) {
          throw new Error('Roles value is empty or missed');
      }

      this.userRole = role;
  }

  ngOnInit() {
      let hasAccess = false;

      if (this.storage.isLoggedIn && this.userRole && this.storage.currentUser && this.storage.currentUser.roles) {
        hasAccess = this.storage.currentUser.roles.includes(this.userRole[0]) ;
        // console.log(hasAccess);


        // hasAccess = this.userRole.some(r => this.authService.roles);
      }

      if (hasAccess) {
          this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
          this.viewContainer.clear();
      }
  }
}
