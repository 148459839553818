<div class="d-flex adminBtn" *ngIf='storage.currentUser'>
  <div class="p-0 ml-auto pr0" >
    <div class="uiToggleIconBtn" (click)='signOutTapped()' matTooltip='Logout'
    > <mat-icon class="icon-btn-big">power_settings_new</mat-icon>
    </div>
  </div>
  <div class="p-0 ml-auto pr0"  *appAccess='[ROLES.ADMIN]'>
    <div class="uiToggleIconBtn" (click)='adminPanel()' matTooltip='Admin View'>
       <mat-icon class="icon-btn-big">admin_panel_settings</mat-icon>
    </div>
  </div>
  <!-- <div class="p-0 ml-auto pr0"  *appAccess='[ROLES.SUPER_ADMIN]'>
    <div class="uiToggleIconBtn" (click)='fixUsers()' matTooltip='Fix Users'>
       <mat-icon class="icon-btn-big">admin_panel_settings</mat-icon>
    </div>
  </div> -->
</div>
<div class="container" *ngIf='storage.currentUser'>
  <mat-toolbar style="height: 36px; border-radius: 0px; background-color: rgba(0,0,0,0.5)!important;" class="pl0">
    <span class="toolbar-spacer"></span>
    <mat-icon class="dxfx_icon_btn_medium ml5" *appAccess='[ROLES.ADMIN]' (click)='add()'>add</mat-icon>
  </mat-toolbar>
</div>
<div class="container" *ngIf="storage.currentUser">
  <table class="table table-dark table-hover">
    <thead>
      <tr>
        <th scope="col">name</th>
        <th scope="col" class="w70 tlr" > <span *appAccess='[ROLES.ADMIN, ROLES.SUPER_ADMIN]'  class="w70">Deboarded</span> </th>
        <th scope="col" class="w90 tlr">state</th>
        <th scope="col" class="w40"> </th>
        <!-- <th scope="col" class="w40"> </th>
        <th scope="col" class="w40"> </th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let exercise of storage.exercises" >
        <td  (click)="navTo(exercise.id)">{{exercise.name}}</td>
        <td class="w70 tlr" >
          <mat-icon class="dxfx_icon_red" *ngIf="exercise.isDeBoarded">check</mat-icon>
        </td>
        <td class="w90 tlr"  (click)="navTo(exercise.id)">{{exercise.status}}</td>


        <td class="w40">
          <mat-icon class="dxfx_icon_btn_medium" [matMenuTriggerFor]="menu"  *appAccess='[ROLES.ADMIN]'>more_vert</mat-icon>
          <!-- <button mat-icon-button  >
            <mat-icon>more_vert</mat-icon>
          </button> -->
          <mat-menu  #menu="matMenu">
            <button mat-menu-item *ngIf="!exercise.isDeBoarded" (click)="deboardExercise($event, exercise)">
              <mat-icon>logout</mat-icon>
              <span>Deboard</span>
            </button>
            <button mat-menu-item  (click)="deleteExercise($event, exercise)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>

          </mat-menu>
        </td>
        <!-- <td class="w40">
          <mat-icon class="dxfx_icon_btn_medium" (click)="navTo(exercise.id)">read_more</mat-icon>
        </td>
        <td class="w40">
          <mat-icon class="dxfx_icon_btn_medium_WARN" *appAccess='[ROLES.ADMIN]' (click)="deleteExercise($event, exercise)">delete</mat-icon>
        </td>
        <td class="w40">
          <mat-icon class="dxfx_icon_btn_medium_WARN" *appAccess='[ROLES.ADMIN]' (click)="deboardExercise($event, exercise)">logout</mat-icon>
        </td> -->

      </tr>

    </tbody>

    </table>

  <!-- <div class="d-flex flex-column">
    <div class="p-2" *ngFor="let exer of exercises" style="height: 60px; background-color: rgba(0, 0, 0, 0.9); cursor: pointer;" (click)="navTo(exer.id)">
      <div class="d-flex align-items-center" style="height: 100%;">
        <div class="p-2">{{exer.name}}</div>
        <div class="p-2 ml-auto">{{exer.status}}</div>
      </div>
    </div>
  </div> -->
</div>
