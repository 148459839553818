import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ExerciseUserRole, IExerciseInjectState, IExerciseTimelineInjectRef, INJECT_DELIVERY_METHOD } from 'src/app/model/exercise.model';
import { DynamicItemDataType, INavigationListItem } from '../navigation-list-item/navigationListitem.model';
import { DynamicItem } from 'src/app/model/dynamic.types';
import { InjectDetailOL_TextEditorComponent } from 'src/app/overlays/inject-detail-overlay/components/inject-detail-ol-texteditor.component';
import { InjectDetailOL_TrainerFeedbackComponent } from 'src/app/overlays/inject-detail-overlay/components/inject-detail-ol-trainerFeedback.component';
import { InjectDetailOL_TrainingObjectivesComponent } from 'src/app/overlays/inject-detail-overlay/components/inject-detail-ol-trainingObjective.component';
import { AddComponentDirective } from 'src/app/directives/add-component.directive';
import { Location } from 'src/app/model/location.model';
import { StorageService } from 'src/app/services/storage.service';
import { iExerciseInject, InjSelectViewMode } from 'src/app/model/company.model';
import { wait } from 'src/app/services/utils/functions';

@Component({
  selector: 'app-inject-detail-component',
  templateUrl: './inject-detail.component.html',
  styleUrls: ['./inject-detail.component.scss']
})
export class InjectDetailComponent implements OnInit{
  @ViewChild(AddComponentDirective, {static: false}) adHost!: AddComponentDirective;

  @Input() timelineInjectRef?: IExerciseTimelineInjectRef | undefined

  @Input() companyInjectTemplate?: iExerciseInject | undefined

  @Input() subNavbarPaddingLeft: number | undefined

  @Input() showIdAndTitle: boolean  = false
  @Input() currentViewMode!: InjSelectViewMode

  VIEW_MODES = InjSelectViewMode
  public storage = inject(StorageService);
  sideNavItem: INavigationListItem | undefined
  locations: Location[] = []
  injectLocation: Location | undefined
  nextInjectState:IExerciseInjectState | undefined
  sideNavItems: INavigationListItem[] = []

  title = ''
  ngOnInit(): void {
    if (this.storage.currentExer) {

      this.locations = this.storage.currentExer.locations
    }
    if (this.timelineInjectRef) {
      console.log(this.timelineInjectRef);

      this.title = this.timelineInjectRef.short_title
      this.sideNavItems = [
        { id: 0, title: 'Description',  typeValue: 'description', dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.description ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
        { id: 1, title: 'EXPECTED OUTCOME', typeValue: 'expected_outcomes' , dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.expected_outcomes ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
        { id: 2, title: 'TRAINER FEEDBACK' ,typeValue: 'TRAINER_FEEDBACK',  dynComponent: new DynamicItem(InjectDetailOL_TrainerFeedbackComponent, this.timelineInjectRef.description ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
        { id: 3, title: 'OBJECTIVES' ,typeValue: 'OBJECTIVES',  dynComponent: new DynamicItem(InjectDetailOL_TrainingObjectivesComponent, this.timelineInjectRef.trainingObjectives ?? [],this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
        { id: 4, title: 'ON SCENE PREPARATION AND EQUIPMENT' ,typeValue: 'on_scene_preparation',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.on_scene_preparation ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
        { id: 5, title: 'RELATED DOCUMENTS', typeValue: 'related_documents',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.related_documents ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
        { id: 6, title: 'TRAINERS ACTION REQUIRED' ,typeValue: 'trainers_action_requirement',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.trainers_action_requirement ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
        { id: 7, title: 'ROLE PLAY INSTRUCTIONS' ,typeValue: 'role_players_instruction',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.role_players_instruction ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
        { id: 8, title: 'ADDITIONAL GUIDANCE CAUTION OPTIONS ', typeValue: 'additional_guidance_caution_options' ,  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.timelineInjectRef.additional_guidance_caution_options ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON]))},
      ]
    } else  if (this.companyInjectTemplate) {
      this.title = this.companyInjectTemplate.short_title
      this.sideNavItems = [
        { id: 0, title: 'Description',  typeValue: 'description',
          dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.description ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.DESCRIPTION },
        { id: 1, title: 'EXPECTED OUTCOME', typeValue: 'expected_outcomes' , dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.expected_outcomes ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.EXPECTED_OUTCOME },
        // { id: 2, title: 'TRAINER FEEDBACK' ,typeValue: 'TRAINER_FEEDBACK',  dynComponent: new DynamicItem(InjectDetailOL_TrainerFeedbackComponent, this.timelineInjectRef.description ?? '')},
        // { id: 3, title: 'OBJECTIVES' ,typeValue: 'OBJECTIVES',  dynComponent: new DynamicItem(InjectDetailOL_TrainingObjectivesComponent, this.inject.trainingObjectives ?? [])},
        { id: 4, title: 'ON SCENE PREPARATION AND EQUIPMENT' ,typeValue: 'on_scene_preparation',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.on_scene_preparation ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.ON_SCENE_PREPARATION},
        { id: 5, title: 'RELATED DOCUMENTS', typeValue: 'related_documents',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.related_documents ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.RELATED_DOCUMENTS },
        { id: 6, title: 'TRAINERS ACTION REQUIRED' ,typeValue: 'trainers_action_requirement',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.trainers_action_requirement ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.TRAINERS_ACTION_REQUIRED },
        { id: 7, title: 'ROLE PLAY INSTRUCTIONS' ,typeValue: 'role_players_instruction',  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.role_players_instruction ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.ROLE_PLAY_INSTRUCTIONS},
        { id: 8, title: 'ADDITIONAL GUIDANCE CAUTION OPTIONS ', typeValue: 'additional_guidance_caution_options' ,  dynComponent: new DynamicItem(InjectDetailOL_TextEditorComponent, this.companyInjectTemplate.additional_guidance_caution_options ?? '',this.storage.getAccess([ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON])), dynItemType: DynamicItemDataType.ADDITIONAL_GUIDANCE_CAUTION_OPTIONS},
      ]
    }

    wait(500, () => {
      const item = this.sideNavItems[0]
      if(item) {
        this.selectNav(item)
      }
    })
  }
  getBackgroundColor():string{
    if (this.timelineInjectRef) {
      return `${this.timelineInjectRef.state.color}`;
    } else {
      return '#081927';
    }

  }

  get activeSideNavId() : number {
    return this.sideNavItem ? this.sideNavItem.id : -1;
  }

  selectNav(e: INavigationListItem) {
    this.sideNavItem = e;
    this.injectViewCOmponent(e)
  }

  private dataFromNavItemType(type: DynamicItemDataType) : any {
    switch (type) {
      case DynamicItemDataType.DESCRIPTION:
        return this.companyInjectTemplate ? this.companyInjectTemplate.description : this.timelineInjectRef ? this.timelineInjectRef.description : ''
      case DynamicItemDataType.EXPECTED_OUTCOME:
        return this.companyInjectTemplate ? this.companyInjectTemplate.expected_outcomes : this.timelineInjectRef ? this.timelineInjectRef.expected_outcomes : ''
      case DynamicItemDataType.ON_SCENE_PREPARATION:
        return this.companyInjectTemplate ? this.companyInjectTemplate.on_scene_preparation : this.timelineInjectRef ? this.timelineInjectRef.on_scene_preparation : ''
      case DynamicItemDataType.RELATED_DOCUMENTS:
        return this.companyInjectTemplate ? this.companyInjectTemplate.related_documents : this.timelineInjectRef ? this.timelineInjectRef.related_documents : ''
      case DynamicItemDataType.TRAINERS_ACTION_REQUIRED:
        return this.companyInjectTemplate ? this.companyInjectTemplate.trainers_action_requirement : this.timelineInjectRef ? this.timelineInjectRef.trainers_action_requirement : ''
      case DynamicItemDataType.ROLE_PLAY_INSTRUCTIONS:
        return this.companyInjectTemplate ? this.companyInjectTemplate.role_players_instruction : this.timelineInjectRef ? this.timelineInjectRef.role_players_instruction : ''
      case DynamicItemDataType.ADDITIONAL_GUIDANCE_CAUTION_OPTIONS:
        return this.companyInjectTemplate ? this.companyInjectTemplate.additional_guidance_caution_options : this.timelineInjectRef ? this.timelineInjectRef.additional_guidance_caution_options : ''
      default:
        return ''
    }
  }
  private injectViewCOmponent(navType: INavigationListItem){
    // if(navType.id == 0) {
    //   this.timelineInjectRef!.description ?? ''
    // }

    const containerRef = this.adHost.viewContainerRef;
    containerRef.clear();
    const comp = this.adHost.createMyComponent(navType.dynComponent.component);

    if (navType.dynItemType) {
      comp.instance.data = this.dataFromNavItemType(navType.dynItemType)

    } else {
      comp.instance.data = navType.dynComponent.data
    }
    comp.instance.editable = navType.dynComponent.editable
    // comp.instance.inject = this.timelineInjectRef!
    if (navType.typeValue != 'TRAINER_FEEDBACK' && navType.typeValue != 'OBJECTIVES') {

      comp.instance.textChanged.subscribe((message: string)=> {
        if(message) {
          var _inject = {}
          if (this.timelineInjectRef) {
            _inject = this.timelineInjectRef! as Object

          } else if (this.companyInjectTemplate) {

            _inject = this.companyInjectTemplate! as Object
          }
          const keys = Object.keys(_inject)
          for(const key of keys) {
            if (key == navType.typeValue) {
              // console.log(navType.typeValue);
              // console.log('>>>>>');
              // console.log(message);
              Object.assign(_inject, { [key]: message})
            }
          }
          if (this.timelineInjectRef) {
            this.timelineInjectRef = _inject as IExerciseTimelineInjectRef
            console.log( this.timelineInjectRef);
          } else if (this.companyInjectTemplate) {
            this.companyInjectTemplate = _inject as iExerciseInject
            console.log(this.companyInjectTemplate);
          }

        }
      })
    }
    // const inject = new InjectDetailTextEditorComp(InjectDetailOL_TextEditorComponent, str);
    // const componentFactory = this.resolver.resolveComponentFactory(inject.component);
    // const componentRef = componentFactory.create(in)
    // (componentRef.instance).exercise = inject.exercise;
    // setTimeout(()=>{
    //   // this.updateScreenAspects(ControllerService.currentScreenSize);
    //   // this.resizeToolbarHeight();
    // },1500)
    // this.ui.setViewMode(VIEW_MODE.TIMELINE_VIEW);
  }

  locationChanged(e:Location) {
    this.injectLocation = e
    setTimeout(() => {
      console.log(this.injectLocation);
      console.log(this.timelineInjectRef);
    },1500)
  }

  onStateChanged(e: IExerciseInjectState) {
    const stateIndex = this.storage.injectStates.findIndex(item => item.id == e.id)
    if (stateIndex != -1) {
      this.nextInjectState = this.storage.injectStates[stateIndex]
      return
    }
  }

  onDeliveryMethodChanged(e:INJECT_DELIVERY_METHOD) {
    if(this.companyInjectTemplate) {
      this.companyInjectTemplate.deliveryMethod = e;
    } else if(this.timelineInjectRef) {
      this.timelineInjectRef.deliveryMethod = e;
    }
  }
  backToOverview() {

  }
  getInjectState(val: IExerciseTimelineInjectRef): IExerciseInjectState | undefined {
    return this.storage.injectStates.find(item => item.id == val.state.id) ?? undefined
  }
  get injectNoError():boolean {
    if(this.currentViewMode == this.VIEW_MODES.CREATE_INJECT) {

      const index2 = this.storage.assigendExerciseInjects.findIndex(item2 => item2.inject_no!.trim().toUpperCase() == this.inject_no.trim().toUpperCase())
      return this.inject_no.length < 3 || index2 != -1 ? true : false
    } else {


      return this.inject_no.length < 3  ? true : false
    }
  }
  get injectShortError():boolean {
    return this.short_title.length > 3 ? false : true
  }
 errorCopy: string = "Inject No required"
  injectNoKeyDown(e:any) {
    const index = this.storage.companyInjects.findIndex(item => item.inject_no.trim().toUpperCase() == this.inject_no.trim().toUpperCase())
    console.log(index);
    const index2 = this.storage.assigendExerciseInjects.findIndex(item2 => item2.inject_no!.trim().toUpperCase() == this.inject_no.trim().toUpperCase())
    console.log(index2);
    if(index2 != -1) {
      this.errorCopy = 'Inject No already exists'
    } else {
      if (this.inject_no.length < 3) {
        this.errorCopy = 'min. 3 Chars'
      } else {

        this.errorCopy = ''
      }
    }
    //this.errorCopy = ''
    // this.injectNoHasError = false
    // this.errorCopy = ''
  }

  get inject_no(): string {
    if(this.companyInjectTemplate) {
      return this.companyInjectTemplate.inject_no
    } else if(this.timelineInjectRef){
      return this.timelineInjectRef.inject_no ?? ''
    } else {
      return ''
    }
  }
  set inject_no(val:string) {
    if(this.companyInjectTemplate) {
     this.companyInjectTemplate.inject_no = val
    } else if(this.timelineInjectRef) {
      this.timelineInjectRef.inject_no = val
    }
  }
  get short_title(): string {
    if(this.companyInjectTemplate) {
      return this.companyInjectTemplate.short_title
    } else if(this.timelineInjectRef){
      return this.timelineInjectRef.short_title ?? ''
    } else {
      return ''
    }
  }
  set short_title(val:string) {
    if(this.companyInjectTemplate) {
     this.companyInjectTemplate.short_title = val
    } else if(this.timelineInjectRef) {
      this.timelineInjectRef.short_title = val
    }
  }
}
