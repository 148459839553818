<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium" (click)="close()">close</mat-icon>
</mat-toolbar>

<div class=" bgDark p0 p30 ">
  <div class="d-flex flex-column p0">

    <!-- <div class="p-0" style="margin-left: 0px;">
      <h5>DATETIME START</h5>
    </div> -->

    <div class="p-0 d-flex" style="margin-left: 0px;">
      <div class="p-0">
        <h5>DATETIME START </h5>
      </div>
      <div class="p-0 ml-auto">
        <h5>{{formatedString(startDateTime)}}</h5>
      </div>
    </div>
    <div class="p-0" style="margin-left: 0px;">
      <mat-divider style="border-color: rgb(138,173,192,0.2);"></mat-divider>
    </div>

    <div class="p-0 d-flex  pt0" style="margin-left: 0px;"  *ngIf='startDateTime'>
     
      <div class="p-0 w888 formGroup textfield100">
        <mat-label class='mat-caption'>DAY</mat-label>
        <input type="text" class="formControlD digital" placeholder="01" style="width: 88px!important;"
        #_dayInput
          [readonly]='isReadOnly' maxlength="2" minlength="2" [(ngModel)]='startDateTime.day'
          (change)="onTFChanged($event, 'DAY')" (ngModelChange)="ngInputChanged('DAY')">
      </div>

      <div class="p-0 w888 formGroup textfield100">    
        <mat-label class='mat-caption'>HOUR</mat-label>
        <input type="text" class="formControlD digital" placeholder="01" style="width: 88px!important;"
          [readonly]='isReadOnly' (change)="onTFChanged($event, 'HOUR')" [(ngModel)]='startDateTime.hours'
          maxlength="2" minlength="2" (ngModelChange)="ngInputChanged('HOUR')">
      </div>

      <div class="p-0 w888 formGroup textfield100">
        <mat-label class='mat-caption'>MIN</mat-label>
        <input type="text" class="formControlD digital" placeholder="01" style="width: 88px!important;"
            [readonly]='isReadOnly' maxlength="2" minlength="2" (change)="onTFChanged($event, 'MIN')"
            [(ngModel)]='startDateTime.minutes' maxlength="2" minlength="2" (ngModelChange)="ngInputChanged('MIN')"
            [(ngModel)]='startDateTime.minutes' (change)='inputChanged()'>
      </div>

      <div class="p-0 w888 formGroup textfield100">
        <mat-label class='mat-caption'>MONTH</mat-label>
        <div class="tactical-month-select-button" [matMenuTriggerFor]="menu" [class.menuDisabled]='isReadOnly' >
          {{startDateTime.month.viewVal | uppercase}}
        </div>
        <mat-menu #menu="matMenu" style="width: 88px!important; text-align: center;">
          <div *ngFor="let option of months">
            <div style="width: 88px!important; text-align: center;" mat-menu-item [disabled]='isReadOnly'
              (click)='startMonthSelected(option)'>{{option.viewVal | uppercase}}</div>
          </div>
        </mat-menu>
      </div>

      <div class="p-0 w888 formGroup textfield100">
        <mat-label class='mat-caption'>YEAR</mat-label>
        <input type="text" class="formControlD digital" placeholder="20" style="width: 88px!important;"
            [readonly]='isReadOnly' maxlength="2" minlength="2" [(ngModel)]='startDateTime.year'
            (change)='inputChanged()'>
      </div>
       
    </div>

    <!-- <div class="p-0" style="margin-left: 0px; margin-right: 10px; padding-top: 10px!important;">
      <mat-divider style="border-color: rgb(138,173,192,0.2);"></mat-divider>
      <mat-label class='mat-caption'>DURATION | DAYS / HOURS</mat-label>
    </div> -->
    
    <!-- <div class="p-0" style="margin-left: 0px;">
      <mat-divider style="border-color: rgb(138,173,192,0.2);"></mat-divider>
    </div> -->

    <div class="p-0 d-flex mt10" style="margin-left: 0px;">


      <div class="p-0 w888 formGroup textfield100 ">
        <div class="d-flex flex-column ">
          <div class="p-0 tlc pt4" style="width: 60px;">
            <mat-icon class="dxfx_icon_btn_mini_circ" (click)="addTimeFor('DAY')">add</mat-icon>
          </div>
          <div class="p-0 ">
            <div class="tactical-duration-box">
              {{durationInDays}}
            </div>
          </div>
          <div class="p-0 tlc pt4" style="width: 60px;">
            <mat-icon class="dxfx_icon_btn_mini_circ" (click)="subTimeFor('DAY')">remove</mat-icon>
          </div>
        </div>
      </div>

      <div class="p-0 w888 formGroup textfield100 ">
        <div class="d-flex flex-column ">
          <div class="p-0 tlc pt4" style="width: 60px;">
            <mat-icon class="dxfx_icon_btn_mini_circ" (click)="addTimeFor('HOUR')">add</mat-icon>
          </div>
          <div class="p-0 ">
            <div class="tactical-duration-box">
              {{durationInHours}}
            </div>
          </div>
          <div class="p-0 tlc pt4" style="width: 60px;">
            <mat-icon class="dxfx_icon_btn_mini_circ" (click)="subTimeFor('HOUR')">remove</mat-icon>
          </div>
        </div>
      </div>

        <!-- <div class="p-2 pt0 tactical-duration-boxDisabled" >
          <mat-label class='mat-caption'>DAYS</mat-label>
          <div class="d-flex flex-column jusify-content-center align-items-center">
            <div class="p-0">
              <mat-icon class="dxfx_icon_btn_mini" (click)="addTimeFor('DAY')">add</mat-icon>
            </div>
            <div class="p-0">

              <div class="formGroup ">
                <div class="tactical-duration-box">
               
                {{durationInDays}}
                </div>
              </div>

            </div>
            <div class="p-0">
              <mat-icon class="dxfx_icon_btn_mini" (click)="subTimeFor('DAY')">remove</mat-icon>
            </div>
          </div>
        </div>

        <div class="p-2 pt0" style="margin-right: 14px;">
          <div class="d-flex flex-column jusify-content-center align-items-center">
            <mat-label class='mat-caption'>HOURS</mat-label>
            <div class="p-0">
              <mat-icon class="dxfx_icon_btn_mini" (click)="addTimeFor('HOUR')">add</mat-icon>
            </div>
            <div class="p-0">
              <div class="formGroup textfield100">
                <div class="tactical-duration-box">
                
                   {{durationInHours}}
                </div>
              </div>

            </div>
            <div class="p-0">
              <mat-icon class="dxfx_icon_btn_mini" (click)="subTimeFor('HOUR')">remove</mat-icon>
            </div>

          </div> 
        </div>
-->
      
     <div class="p-2 flex-fill" style="align-content: center;"> <h5>DURATION | DAYS / HOURS</h5> </div>

    </div>

    
    <div class="p-0" style="margin-left: 0px;">
      <mat-divider style="border-color: rgb(138,173,192,0.2);"></mat-divider>
      <!-- <h5>DATETIME END {{formatedString(endDateTime)}}</h5> -->
    </div>

    <div class="p-0 d-flex" style="margin-left: 0px;">
      <div class="p-0">
        <h5>DATETIME END </h5>
      </div>
      <div class="p-0 ml-auto">
        <h5>{{formatedString(endDateTime)}}</h5>
        
      </div>
    </div>
    <div class="p-0" style="margin-left: 0px;">
      <mat-divider style="border-color: rgb(138,173,192,0.2);"></mat-divider>
    </div>

    <div class="p-0 d-flex pb10" style="margin-left: 0px;" *ngIf='endDateTime'>
      
  
      <div class="p-0 w888 formGroup textfield100 ">
        <mat-label class='mat-caption'>DAY</mat-label>
        <div class="formControlD digital " style="user-select: none;">
          {{endDateTime.day}}
        </div>
      </div>

      <div class="p-0 w888 formGroup textfield100 ">
        <mat-label class='mat-caption'>HOUR</mat-label>
        <div class="formControlD digital " style="user-select: none;">
          {{endDateTime.hours}}
        </div>
      </div>


      <div class="p-0 w888 formGroup textfield100 ">
        <mat-label class='mat-caption'>MIN</mat-label>
        <div class="formControlD digital " style="user-select: none;">
          {{endDateTime.minutes}}
        </div>
      </div>

      <div class="p-0 w888 formGroup textfield100 ">
        <mat-label class='mat-caption'>MONTH</mat-label>
        <div class="tactical-month-select-button " style="user-select: none; ">
        {{endDateTime.month.viewVal | uppercase}}
      </div>
  
      </div>
      <div class="p-0 w888 formGroup textfield100 ">
        <mat-label class='mat-caption'>YEAR</mat-label>
        <div class="formControlD digital " style="user-select: none;">
          {{endDateTime.year}}
        </div>
      </div>

    </div>

    <!-- <div class="d-flex pb30">
      <div class="p-2 ml-auto" *ngIf='showSaveButton'>
        <ect-color-btn title="Save" icon="save" color="primary" (clicked)="save()"></ect-color-btn>
  
      </div>
    </div> -->


  </div>

</div>

<mat-toolbar class="overlay-toolbar" >
  <span class="toolbar-spacer"></span>
  <ect-color-btn title="Save" icon="save" color="primary" (clicked)="save()" *ngIf='showSaveButton'></ect-color-btn>
</mat-toolbar>