import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INavigationListItem } from './navigationListitem.model';

@Component({
  selector: 'app-navigation-list-item',
  templateUrl: './navigation-list-item.component.html',
  styleUrls: ['./navigation-list-item.component.scss']
})
export class NavigationListItemComponent {
  @Input() model!: INavigationListItem;
  @Input() active!: boolean;
  
  @Output() selected = new EventEmitter<INavigationListItem>();

  onTapped() {
    this.selected.emit(this.model);
  }
}
