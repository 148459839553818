import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ExerciseTimelineItemType, IExerciseTimelineItem } from 'src/app/model/exercise.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-timelinetype-modify',
  templateUrl: './timelinetype-modify.component.html',
  styleUrls: ['./timelinetype-modify.component.scss']
})
export class TimelinetypeModifyComponent implements OnInit, OnDestroy {
  @Input() item!: IExerciseTimelineItem
  @Output() onUpdate: EventEmitter<IExerciseTimelineItem> = new EventEmitter<IExerciseTimelineItem>();
  timelineName = ''
  show = false
  // selectedItem: IExerciseTimelineItem | undefined
  itemType?: ExerciseTimelineItemType | undefined
  itemTypes: ExerciseTimelineItemType[] = [
    ExerciseTimelineItemType.EXCON,
    ExerciseTimelineItemType.EVENTS,
    ExerciseTimelineItemType.TEAM,
    // ExerciseTimelineItemType.LOGISTICS,
    // ExerciseTimelineItemType.SUPPORT,
    // ExerciseTimelineItemType.ROLEPLAY,
    ExerciseTimelineItemType.OBSERVER,
  ]
  constructor(private api: ApiService) {
    this.itemType = undefined
    
    console.log('constructor');
  }
  ngOnInit(): void {
    console.log('ngOnInit');
    if (this.item.itemType) {
      this.itemType = this.item.itemType as ExerciseTimelineItemType

    }
    this.timelineName = this.item.title
    console.log(this.itemType);
    setTimeout(()=>{
      
      this.show = true
    },30)
    // this.selectedItem = this.item
    // if(this.selectedItem) {
    //   console.log(this.selectedItem);
    //   this.itemType = this.item.itemType as ExerciseTimelineItemType
    //   this.timelineName = this.item.title
    //   console.log(this.itemType);
    //   setTimeout(()=>{
        
    //     this.show = true
    //   },30)
    // }
    
  }
  ngOnDestroy(): void {
      console.log('DESTRY');
      
  }
  async load() {
   
  }
  onChanged(e:any) {
    console.log(e.value);
    this.itemType = e.value
    this.timelineName = e.value
    
  }
  save() {
    if(this.itemType) {

      this.item.title = this.timelineName
      this.item.itemType = this.itemType
      this.onUpdate.emit(this.item)
    }
  }
  get teamRoleSelected(): boolean {
    if(this.itemType && this.itemType == ExerciseTimelineItemType.TEAM) {
      return true
    }
    return false
  }
  get roleNameError(): boolean {
    if(this.timelineName.length < 4 && this.teamRoleSelected) {
      return true
    }
    return false
  }
  get saveBtnDisabled(): boolean {
    if(!this.itemType) {
      return true
    }
    if(this.itemType && this.itemType != ExerciseTimelineItemType.TEAM) {
      return false
    } else if(!this.roleNameError) {
      return false
    } else {
      return true
    }
  }
}
