import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import gsap from 'gsap';
@Component({
  selector: 'performance-progress-inject',
  templateUrl: './performance-progress-inject.component.html',
  styleUrls: ['./performance-progress-inject.component.scss']
})
export class PerformanceProgressInjectComponent  implements OnInit {
  @ViewChild('_progressBar') _progressBar!:ElementRef
  @Input() percent!:number
  @Input() color!:string
  showWarn = false
  constructor() { }

  ngOnInit(): void {
    
    const newWidth = 420 * this.percent
    const fadeDelay = this.getRandomTime()
    
   setTimeout(()=>{
  /*   this.showWarn = newWidth <=120; */
  gsap.fromTo(this._progressBar.nativeElement, { 'width':0, opacity:1},
    {duration: 0.35,  'width':newWidth , opacity:1, ease: 'Expo.easeOut', });
    
   },fadeDelay)
  }
  private getRandomTime(){
    let min:number = 300;
    let max:number = 1000;
    return Math.random() * (max - min) + min; 
  }

}
