import { Component, OnInit } from '@angular/core';
import { ExcelService } from '../services/excel.service';
import { StorageService } from '../services/storage.service';
import { iExerciseInject } from '../model/company.model';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-excel-example',
  template: `
  <button (click)="exportToExcel()">Export to Excel</button>
`,
  // templateUrl: './excel-example.component.html',
  styleUrls: ['./excel-example.component.scss']
})
export class ExcelExampleComponent implements OnInit{
  exerciseInjects: iExerciseInject[] = []
  // data = [
  //   { Name: 'John Doe', Age: 30, City: 'New York' },
  //   { Name: 'Jane Smith', Age: 25, City: 'San Francisco' },
  //   // Add more data as needed
  // ];
  constructor(private excelService: ExcelService, private storage: StorageService, private api: ApiService) {}
  ngOnInit(): void {
      this.load()
  }
  exportToExcel(): void {
    // this.data, 
    //this.excelService.generateExcel('user_data');
    //this.excelService.generateList(this.storage.assigendExerciseInjects, this.exerciseInjects);

  }
  private async loadInjects() : Promise<iExerciseInject[]> {
    if(!this.storage.currentExer) { return [] }
    const injectsByEx = await this.api.apiGetP(`inject/templates-by-exercise/${this.storage.currentExer.id}`)
    const out = injectsByEx as iExerciseInject[];
    return out  
  }
  
  private async load() {
    // if(!this.storage.currentExer) { return }
    // const injectsByEx = await this.api.apiGetP(`inject/templates-by-exercise/${this.storage.currentExer.id}`)
    this.exerciseInjects = await this.loadInjects()//injectsByEx as iExerciseInject[];
    
  }
}
