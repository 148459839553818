import { iUser } from "./iuser.model";

export interface AuthResponse {
  // status: ResponseStatusType
  // payload: string | AuthToken
  type: AUTH_RESPONSE_TYPE
  token?: string;
  user?: iUser;
}
export enum ResponseStatusType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}
export interface AuthToken {
  token: string
}


export enum AUTH_RESPONSE_TYPE {
  SUCCESS = 'SUCCESS',
  NEED_NEW_PW = 'NEED_NEW_PW',
  ERROR_EMAIL_NOT_FOUND = 'ERROR_EMAIL_NOT_FOUND',
  ERROR_WRONG_PASSWORD = 'ERROR_WRONG_PASSWORD',
  ERROR_USER_BLOCKED = 'ERROR_USER_BLOCKED',
}

export enum AUTH_VIEW_MODES {
  LOADING = 'LOADING',
  // RESPONSE_MESSAGE = 'RESPONSE_MESSAGE',
  SIGN_IN = 'SIGN_IN',
  LOST_PASSWORD = 'LOST_PASSWORD',
  SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
}