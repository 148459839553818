<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <button mat-icon-button  (click)='close()'><mat-icon style="color:#48a8b2; cursor: pointer;   -webkit-user-select:none;">close</mat-icon></button>
</mat-toolbar>

<div class="olWrap" *ngIf="exerciseId" >
  <div class="scrollWrap">
    <div class="d-flex  " style="width: 100%;">
      <div class="p-0 w60p"  [ngStyle]="{'height': (screenSize.height - 80) + 'px' }" >
        
        <app-exercise-users-view 
        (onCloseWithReload)="onCloseWithReload()"
        [exerciseId]="exerciseId"></app-exercise-users-view>
      </div>
      <div class="p-0 w40p" >
        <app-exercise-timelines-modify-view 
        (onCloseWithReload)="onCloseWithReload()"
        [exerciseId]="exerciseId"></app-exercise-timelines-modify-view>
        
      </div>
    </div>
      
  </div>
</div>