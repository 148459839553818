import { Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyInject, iCompanyInject } from 'src/app/model/company.model';
import { StorageService } from '../../services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { HTTP_METHOD } from 'src/app/model/enums';
import { INJECT_DELIVERY_METHOD } from 'src/app/model/exercise.model';

@Component({
  selector: 'app-inject-import-overlay',
  templateUrl: './inject-import-overlay.component.html',
  styleUrls: ['./inject-import-overlay.component.scss']
})
export class InjectImportOverlayComponent implements OnInit {
  @ViewChild('csvReader',{static: false}) csvReader: any;
  injectDisplayedColumns: string[] = ['inject_no', 'name', 'DELIVERY METHOD'];
  injectsDataSource:any;
  companyInjects:iCompanyInject[] = []
  includedCompanyInjects: iCompanyInject[] = []
  public records: any[] = [];  
  isRecordComplete = false; 
  companyDocId:string;
  private injectImportSet = ''
  private storage = inject(StorageService);
  private api = inject(ApiService);

  constructor(public dialogRef: MatDialogRef<InjectImportOverlayComponent>
    ,@Inject(MAT_DIALOG_DATA) public data:string) { 

      this.companyDocId = data
      
    }

  ngOnInit(): void {
    this.includedCompanyInjects = this.storage.companyInjects
    this.injectImportSet = `Import-${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}-${new Date().getHours()}:${new Date().getMinutes()}`
  }

  uploadListener($event: any): void {  
  
    let text = [];  
    let files = $event.srcElement.files;  
  
    if (this.isValidCSVFile(files[0])) {  
  
      let input = $event.target;  
      let reader = new FileReader();  
      reader.readAsText(input.files[0]);  
  
     

      reader.onload = () => {  
        let csvData = reader.result;  
        
        ///console.log(reader.result);
        
        /* let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);  
  
        let headersRow = this.getHeaderArray(csvRecordsArray);  
        
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);   
        */
       this.records = this.getInjectListFromImport(JSON.parse(reader.result!.toString()));   
       console.log(this.records.length);
      };  
      reader.onloadend = () => {
      
        // this.records.shift()
        this.injectsDataSource = new MatTableDataSource<CompanyInject>(this.records);
      }
      reader.onerror = function () {  
        console.log('error is occured while reading file!');  
      };  
  
    } else {  
      alert("Please import valid .csv file.");  
      this.fileReset();  
    }  
  }
  getInjectListFromImport(csvRecordsArray: any) {
    let csvArr:iCompanyInject[] = [];  
  
    for (let i = 0; i < csvRecordsArray.length; i++) {  
      let curruntRecord = csvRecordsArray[i]
      
     
        var injectRecord:iCompanyInject = {
          
          id: -1,
          companyId: this.companyDocId,
          inject_no: this.getInjectItem(curruntRecord, 'inject_no'),
          short_title: this.getInjectItem(curruntRecord, 'short_title'),
          
          deliveryMethod: this.getInjectItem(curruntRecord, 'delivery_method') == 'NA' ? 'OTHER' : this.getInjectItem(curruntRecord, 'delivery_method'),
          responsibility_for_delivery_by: '',
          
          description: this.getInjectItem(curruntRecord, 'description'),
          related_documents: this.getInjectItem(curruntRecord, 'related_documents'),
          related_equipment: this.getInjectItem(curruntRecord, 'related_equipment'),
          //related_site_location: this.getInjectItem(curruntRecord, 'related_site_location'),
          
          role_players_instruction: this.getInjectItem(curruntRecord, 'role_players_instruction'),
          on_scene_preparation: this.getInjectItem(curruntRecord, 'on_scene_preparation'),
          additional_guidance_caution_options: this.getInjectItem(curruntRecord, 'additional_guidance_caution_options'),
          expected_outcomes: this.getInjectItem(curruntRecord, 'expected_outcomes'),
          trainers_action_requirement: this.getInjectItem(curruntRecord, 'trainers_action_requirement'),
          inject_set: this.injectImportSet
        }
        
        csvArr.push(injectRecord); 
      
    }
     
    this.isRecordComplete = true; 
    return csvArr;  
  }
  private allDlMethods: INJECT_DELIVERY_METHOD[] = [
    INJECT_DELIVERY_METHOD.EVENT,
    INJECT_DELIVERY_METHOD.MAIL,
    INJECT_DELIVERY_METHOD.OTHER,
    INJECT_DELIVERY_METHOD.PHONECALL,
    INJECT_DELIVERY_METHOD.PHYSICALMEETING,
    INJECT_DELIVERY_METHOD.RADIOCALL,
    INJECT_DELIVERY_METHOD.ROLEPLAY,
    INJECT_DELIVERY_METHOD.SOCIALMEDIA,
    INJECT_DELIVERY_METHOD.VIRTUALMEETING,
    INJECT_DELIVERY_METHOD.VIRTUAL_OSOCC,
  ]
  getInjectItem(item: any, key: string): any {
    if (key == 'delivery_method') {
      
      return this.allDlMethods.includes(item[key]) ? item[key] : "OTHER"
    } else {
      return item[key] != null ? item[key] : 'NA';
    }
    
  }
  isValidCSVFile(file: any) {  
    return file.name.endsWith(".json");  
  }
  async importIntoDataBase(){


    // if (this.companyInject && this.companyInject.inject_no.length >= 3 && this.companyInject.short_title.length > 3) {
    //   if(this.companyInject)  {

    //     const dto = {
    //       ...this.companyInject,
    //       id: undefined,
    //       companyId: this.olData.companyId!
    //     }
          
    //     const r = await this.api.apiReq(HTTP_METHOD.POST, `inject/company-inject`,dto )
    //     this.storage.addCompanyInject(r as iCompanyInject)
    //     this.close()
    //   }
    //   return 
    // }

    for (const __inject of this.records) {
      console.log(__inject);
      const dto = {
        ...__inject,
        id: undefined,
        companyId: this.companyDocId,
      }
      delete dto.id;
      const r = await this.api.apiReq(HTTP_METHOD.POST, `inject/company-inject`,dto )
      console.log(r);
      
        this.storage.addCompanyInject(r as iCompanyInject)

      
    }

    // setTimeout(()=>{
    //   this.records = [];
    //   this.fileReset();
    //   this.dialogRef.close('imported');
    // },500)
  
  }

  isIncluded(inj: iCompanyInject) : boolean {
    const index = this.includedCompanyInjects.findIndex(item => item.inject_no == inj.inject_no)
    return index != -1
  }

  fileReset() {  
    this.csvReader.nativeElement.value = "";  
    this.records = [];  
  }  
  close() {
    this.dialogRef.close()
  }

}
