import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { ExerciseClass } from 'src/app/model/exercise.model';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-dashboard-role',
  template: `
    <div class="p-0 h40 mr3  d-flex tt-border  align-items-center  pr3 pl3" #containerHolder [class.dark]="ui.darkMode">
          <div class="p-0 h30 pt5 pl5 bgDark uc fs14 font-darker " style="width: 75px;"  [class.bgWhite]="!ui.darkMode">
            ROLE:
          </div>
          <div class="p-0 tt-box-sm tlc pl10 pr10"  [class.dark]="ui.darkMode">{{exercise.exerciseUser?.userRole}}</div>
        </div>
  `,
  styles: [
  ]
})
export class DashboardRoleComponent {
  @Input() exercise!: ExerciseClass
  @ViewChild("containerHolder", { static: false }) containerHolder: ElementRef | undefined
  public ui = inject(UiService);
  
}
