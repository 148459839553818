import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _locales:any
  constructor() {
    this._locales = LOCALES
   }

   get locales() {
     return this._locales;
   }
}
const LOCALES = {
  DELETE_EXERCISE_ALERT :{
    TITLE: "ATTENTION!",
    BODY: "Your are about to delete an entire exercise. This step can not be undone!",
    BTN_RESUME: "DELETE",
    BTN_CANCEL: "CANCEL",
  },
  DELETE_TIMELINE_ALERT :{
    TITLE: "ATTENTION!",
    BODY: "Your are about to delete this timeline. This step can not be undone!",
    BTN_RESUME: "DELETE",
    BTN_CANCEL: "CANCEL",
  },
  DELETE_INJECT_ALERT :{
    TITLE: "ATTENTION!",
    BODY: "Your are about to delete the selected inject. This step can not be undone!",
    BTN_RESUME: "DELETE",
    BTN_CANCEL: "CANCEL",
  },
  DEBOARD_EXERCISE_ALERT :{
    TITLE: "ATTENTION!",
    BODY: "Your are about to deboard an entire exercise. This step can not be undone!",
    BTN_RESUME: "YES",
    BTN_CANCEL: "CANCEL",
  },
  DELETE_EXERCISE_USER_ALERT :{
    TITLE: "ATTENTION!",
    BODY: "Your are about to delete user from exercise. This step can not be undone!",
    BTN_RESUME: "YES",
    BTN_CANCEL: "CANCEL",
  },
}