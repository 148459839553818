<div class="p-0 db-nb-icon-btn" *exerciseAccess='model.access'
[class.db-nb-icon-btn-toggle]="isToggle" [class.db-nb-icon-btn]="!isToggle"
[class.db-nb-icon-btn-toggle-active]="isActive"
>
  <mat-icon class="icn" (click)='tapped()'
  matTooltip='{{tooltip}}'
  matTooltipClass="navTT"
  >
  <!-- <span class="material-symbols-outlined">
    export_notes
    </span>
    export_notes -->
  {{model.icon}}
</mat-icon>
</div>
<!-- matTooltipClass='navigationTT' -->
