import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExerciseUserRole, IExerciseTimelineItem } from 'src/app/model/exercise.model';
import { ApiService } from 'src/app/services/api.service';
import {CdkDragDrop, moveItemInArray, } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { AlertOverlayComponent } from 'src/app/overlays/alert-overlay/alert-overlay.component';
import { ALERT_BUTTON_ACTION_TYPE, ALERT_BUTTON_COLOR_TYPE } from 'src/app/model/enums';
import { LocaleService } from 'src/app/services/locale.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-exercise-timelines-modify-view',
  templateUrl: './exercise-timelines-modify-view.component.html',
  styleUrls: ['./exercise-timelines-modify-view.component.scss']
})
export class ExerciseTimelinesModifyViewComponent implements OnInit {
  isAddingMode = false
  @Input() exerciseId!: string;
  @Output() onCloseWithReload = new EventEmitter<void>();
  timelines: IExerciseTimelineItem[] = []
  timelineName = ''
  selectedTlItem: IExerciseTimelineItem | undefined

  constructor(public api: ApiService, private dialog: MatDialog, private ls: LocaleService, private storage: StorageService) {

  }

  ngOnInit(): void {
    this.load()
  }
  get inputDisabled() : boolean {
    return this.storage.isExerciseDeboarded
  }
  async load() {
    const r = await this.api.apiGetP('exercise/timelines/'+ this.exerciseId)

    this.timelines = r as IExerciseTimelineItem[];
   this.timelines.sort((a,b) => a.topIndex - b.topIndex)
  }

  async drop(event: CdkDragDrop<unknown>) {
    if(this.inputDisabled) { return }
    console.log(`previousIndex ${event.previousIndex} event.currentIndex: ${event.currentIndex}`);

    moveItemInArray(this.timelines, event.previousIndex, event.currentIndex);

    for(var i=0; i< this.timelines.length; i++) {
      this.timelines[i].topIndex = i
      const dto = {
        exerciseId: this.exerciseId,
        title:  this.timelines[i].title,
        topIndex:  this.timelines[i].topIndex,
        itemType:  this.timelines[i].itemType,
      }
      await this.api.apiPatch('exercise/update-timeline', this.timelines[i].id.toString(), dto);
    }
    this.onCloseWithReload.emit()
  }

  onCreateNew() {
    if(this.inputDisabled) { return }
    const latestItem = _.maxBy(this.timelines, 'topIndex')
    const newIndex = latestItem ? latestItem!.topIndex + 1 : 0
    this.selectedTlItem = {
      id: -1,
      title: '',
      heightFact: 1,
height: 0,
      topIndex: newIndex,
      itemType: undefined
    }
    this.isAddingMode = true
  }
  onCloseCreateNew() {
    this.isAddingMode = false
    this.selectedTlItem = undefined
  }
  openTimeline(tl: IExerciseTimelineItem) {
    if(this.inputDisabled) { return }
    // this.selectedTlItem = undefined
    this.isAddingMode = false

    setTimeout(()=>{
      this.selectedTlItem = tl
      // this.selectedTlItem.itemType = tl.itemType as ExerciseTimelineItemType

    },20)
    setTimeout(()=>{

      this.isAddingMode = true
    },30)
  }

  deleteTimeline(tl: IExerciseTimelineItem) {
    if(this.inputDisabled) { return }
    const dialogReg = this.dialog.open(AlertOverlayComponent, {
      width:"400px",
      disableClose:true,
      data: {
        title: this.ls.locales["DELETE_TIMELINE_ALERT"]['TITLE'], //"Achtung",
        bodyMessage: this.ls.locales["DELETE_TIMELINE_ALERT"]['BODY'],
          actions: [
            {title: this.ls.locales["DELETE_TIMELINE_ALERT"]['BTN_RESUME'], color: ALERT_BUTTON_COLOR_TYPE.WARN, actionType: ALERT_BUTTON_ACTION_TYPE.RESUME},
            {title: this.ls.locales["DELETE_TIMELINE_ALERT"]['BTN_CANCEL'], color: ALERT_BUTTON_COLOR_TYPE.BASIC, actionType: ALERT_BUTTON_ACTION_TYPE.CANCEL},
          ]
        },
      // data: {
      //   title:"Achtung",
      //   bodyMessage:"Achtung......? Diese Aktion kann nicht rückgängig gemacht werden.",
      //     actions: [
      //       {title:'Delete', color: ALERT_BUTTON_COLOR_TYPE.WARN, actionType: ALERT_BUTTON_ACTION_TYPE.RESUME},
      //       {title:'Cancel', color: ALERT_BUTTON_COLOR_TYPE.BASIC, actionType: ALERT_BUTTON_ACTION_TYPE.CANCEL},
      //     ]
      //   },
      backdropClass:"backdrop-background"
    }).afterClosed().subscribe((result) => {
      if(result != null){
        console.log(result);

        if(result === ALERT_BUTTON_ACTION_TYPE.RESUME){
          this.finalRemoveTimeline(tl);
        } else {

        }
      }
    });
  }

  private async finalRemoveTimeline(tl: IExerciseTimelineItem) {
    await this.api.apiGetP(`exercise/delete-timeline/${tl.id}`);
    this.isAddingMode = false
    this.load()
  }


  ExerciseUserRoles = ExerciseUserRole;
  async onUpdate(e:IExerciseTimelineItem) {
    console.log(e);
    if(e.id == -1){
    const dto = {
      exerciseId: this.exerciseId,
      title: e.title,
      topIndex: e.topIndex,
      itemType: e.itemType,
      color: e.color
    }
    await this.api.apiPost('exercise/timeline', dto);
    this.isAddingMode = false
    } else {
      const dto = {
        exerciseId: this.exerciseId,
        title: e.title,
        topIndex: e.topIndex,
        itemType: e.itemType,
        color: e.color
      }
      await this.api.apiPatch('exercise/update-timeline', e.id.toString(), dto);
      this.isAddingMode = false
    }
    this.load()
    this.onCloseWithReload.emit()
  }

}
