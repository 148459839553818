import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyInject, iCompanyInject } from 'src/app/model/company.model';
import { HTTP_METHOD } from 'src/app/model/enums';
import { ModOverlayDataType, iModifyOverlayData } from 'src/app/model/texteditOLdata.model';
import { CompanyTrainingObjective, iCompanyTrainingObjective } from 'src/app/model/trainingObj.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-modify-overlay',
  templateUrl: './modify-overlay.component.html',
  styleUrls: ['./modify-overlay.component.scss']
})
export class ModifyOverlayComponent {
  selectedTrainingObj: iCompanyTrainingObjective | undefined
  companyInject: iCompanyInject | undefined
  olData: iModifyOverlayData | undefined
  viewType: ModOverlayDataType = ModOverlayDataType.LOADING
  VIEWTYPES = ModOverlayDataType
  constructor(
    public storage: StorageService,
    private api: ApiService,
    public dialogRef: MatDialogRef<ModifyOverlayComponent>
    ,@Inject(MAT_DIALOG_DATA) public data: iModifyOverlayData,
    ) {
      this.olData = data
      this.viewType = data.dataTye
      // console.log(data);
      // const yy = this.data.model instanceof CompanyTrainingObjective;
      // const yy2 = this.data.model instanceof CompanyInject;
      // console.log(yy) // Will be true 
      // console.log(yy2) // Will be true 
      switch (data.dataTye) {
        case ModOverlayDataType.CREATE_OBJ:
          if (data.model instanceof CompanyTrainingObjective) {
            this.selectedTrainingObj = data.model.item
          }
          return
        case ModOverlayDataType.MODIFY_OBJ:
          if (data.model instanceof CompanyTrainingObjective) {
            this.selectedTrainingObj = data.model.item
          }
          return
        case ModOverlayDataType.CREATE_COMPANY_INJECT:
          if (data.model instanceof CompanyInject) {
            this.companyInject = data.model.item
          }
          return
        case ModOverlayDataType.MODIFY_COMPANY_INJECT:
          if (data.model instanceof CompanyInject) {
            this.companyInject = data.model.item
          }
          return
        
      }

      // if ((data.dataTye == ModOverlayDataType.CREATE_OBJ || data.dataTye == ModOverlayDataType.MODIFY_OBJ) && data.model instanceof CompanyTrainingObjective) {
      //   this.selectedTrainingObj = data.model.item
      // }
      
    }

    
    close() {
      this.dialogRef.close()
    }

    async save(){
      console.log(this.selectedTrainingObj);
      console.log(this.olData);
      
      if (!this.olData) { return  }
      switch (this.olData.dataTye) {
        case ModOverlayDataType.CREATE_OBJ:
          if(this.selectedTrainingObj)  {

            const r = await this.api.apiReq(HTTP_METHOD.POST, `trainingobjective/company`, {
              companyId: this.olData.companyId!,
              title: this.selectedTrainingObj.title,
            })
            this.storage.companyObjectives = r as iCompanyTrainingObjective[]
            this.close()
          }
          return 
        case ModOverlayDataType.MODIFY_OBJ:
          if(this.selectedTrainingObj)  {

            const r = await this.api.apiReq(HTTP_METHOD.PATCH, `trainingobjective/company/${this.selectedTrainingObj.id}`, {
              companyId: this.olData.companyId!,
              title: this.selectedTrainingObj.title,
              id: this.selectedTrainingObj.id,
            })
            this.storage.companyObjectives = r as iCompanyTrainingObjective[]
            this.close()
          }  

          return 
        case ModOverlayDataType.CREATE_COMPANY_INJECT:
          if (this.companyInject && this.companyInject.inject_no.length >= 3 && this.companyInject.short_title.length > 3) {
            if(this.companyInject)  {

              const dto = {
                ...this.companyInject,
                id: undefined,
                companyId: this.olData.companyId!
              }
                
              const r = await this.api.apiReq(HTTP_METHOD.POST, `inject/company-inject`,dto )
              this.storage.addCompanyInject(r as iCompanyInject)
              this.close()
            }
            return 
          }
          return 
        case ModOverlayDataType.MODIFY_COMPANY_INJECT:
          if (this.companyInject && this.companyInject.inject_no.length >= 3 && this.companyInject.short_title.length > 3) {
            const dto = {
              ...this.companyInject,
              // id: undefined,
              companyId: this.olData.companyId!
            }
            console.log(dto);
              
            const r = await this.api.apiReq(HTTP_METHOD.PATCH, `inject/company-inject/${this.companyInject.id}`,dto )
            this.storage.addCompanyInject(r as iCompanyInject)
            this.close()
            return
          }
          return 
          
      }

      // if (this.olData && this.selectedTrainingObj) {

      //   if(this.selectedTrainingObj.id == -1) {
      //     const dto = {
      //       companyId: this.olData.companyId!,
      //       title: this.selectedTrainingObj.title,
      //     };
      //     const a = await this.selectedTrainingObj.id == -1 ? this.api.apiPost(`trainingobjective/company`,dto) : this.api.apiPatch(`trainingobjective/company`,this.selectedTrainingObj.id.toString(), dto)
      //     const companyObjectives:iCompanyTrainingObjective[]  = a as iCompanyTrainingObjective[]
      //     console.log(dto);
      //     console.log(a);
      //     this.dialogRef.close(companyObjectives)
      //   } else {
      //     const dto = {
      //       companyId: this.olData.companyId!,
      //       id: this.selectedTrainingObj.id,
      //       title: this.selectedTrainingObj.title,
      //     };
      //     const a = await this.api.apiPatch(`trainingobjective/company`,this.selectedTrainingObj.id.toString(), dto)
      //     const companyObjectives:iCompanyTrainingObjective[]  = a as iCompanyTrainingObjective[]
      //     console.log(dto);
      //     console.log(a);
      //     this.dialogRef.close(companyObjectives)
      //   }
        
        
      // }
    }
 
    get saveEnabled():boolean {

      if (!this.olData) { return false }
      switch (this.olData.dataTye) {
        case ModOverlayDataType.CREATE_OBJ:
          if (this.selectedTrainingObj && this.selectedTrainingObj.title.length > 3) {
            return true
          }
          return false
        case ModOverlayDataType.MODIFY_OBJ:
          if (this.selectedTrainingObj && this.selectedTrainingObj.title.length > 3) {
            return true
          }
          return false
        case ModOverlayDataType.CREATE_COMPANY_INJECT:
          if (this.companyInject && this.companyInject.inject_no.length >= 3 && this.companyInject.short_title.length > 3) {
            return true
          }
          return false
        case ModOverlayDataType.MODIFY_COMPANY_INJECT:
          if (this.companyInject && this.companyInject.inject_no.length >= 3 && this.companyInject.short_title.length > 3) {
            return true
          }
          return false
        
      }
      return false
    }
}
