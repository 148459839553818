import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { httpMethod } from './utils/companyIn.help';
import { HTTP_METHOD } from '../model/enums';
import { Observable } from 'rxjs';
import { ChatMessage } from '../model/chatMessage.model';

// const UID= 'uid';
@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private endpoint: string = environment.endpoints.apiBasePath;
  private push_endpoint: string = environment.endpoints.PUSH_ENDPOINT;
  // private push_endpoint2: string = environment.endpoints.PUSH_ENDPOINT2;
  constructor(private http: HttpClient) { 

  }
  
  async apiGetP(path:string){
    const _res = await this.http.get(this.endpoint + path).toPromise()
    return _res
  }
  async apiPost(path:string, item:Object){
    const _res = await this.http.post(this.endpoint + path,item).toPromise()
    return _res
  }
  async apiPatch(path:string, id: string, item:Object){
    const _res = await this.http.patch(`${this.endpoint}${path}/${id}`, item).toPromise()
    return _res
  }
  async apiDeleteP(path:string){
    const _res = await this.http.delete(this.endpoint + path).toPromise()
    return _res
  }

  // getChatMessages(id: string): Observable<ChatMessage[]> {
  //   return this.http.get<ChatMessage[]>(`${this.endpoint}exercise/chat-messages/${id}`);
    
  //   // return this.http.get<ChatMessage[]>(`${this.endpoint}`);
  // }

  async apiReq(method: HTTP_METHOD, url: string, payload: Object) {
    const r = await this.http.request(httpMethod(method),`${this.endpoint}${url}`, { body: payload}).toPromise()
    return r
  }

  async apiPushMessage(item:Object){
    const _res1 = await this.http.post(this.push_endpoint+'channel-post',item).toPromise()
    // const _res1 = await this.http.post(this.push_endpoint+'test-channel',item).toPromise()
    console.log(_res1);
    
    // // return _res1
    // const _res = await this.http.post(this.push_endpoint+'post-channel-four',item).toPromise()
    // console.log(_res);
    // const _res2 = await this.http.post(this.push_endpoint2+'post-channel-four',item).toPromise()
    // console.log(_res2);
  }
  // async signIn(email: string, pw: string ) {
  //   const r = await this.http.post(`${this.endpoint}auth/`, {
  //     email: email, 
  //     password: pw
  //   }).toPromise();
  //   const authR = r as AuthResponse;
  //   console.log(authR);
  //   if (authR.token && (authR.type == AUTH_RESPONSE_TYPE.SUCCESS)) {
  //     this.saveToken({token: authR.token})
  //     this._isLoggedIn = true
  //   } else if (authR.token && (authR.type == AUTH_RESPONSE_TYPE.NEED_NEW_PW || authR.type == AUTH_RESPONSE_TYPE.SUCCESS)) {
  //     this.saveToken({token: authR.token})
  //   }
  //     return authR


    
  // }
  // async setNewPassword(email: string, pw: string ) {
  //   const r = await this.http.post(`${this.endpoint}auth/set-new-password`, {
  //     email: email, 
  //     password: pw
  //   }).toPromise();
  //   const authR = r as AuthResponse;
  //   console.log(authR);
  //   if (authR.token && (authR.type == AUTH_RESPONSE_TYPE.SUCCESS)) {
  //     this.saveToken({token: authR.token})
  //     this._isLoggedIn = true
  //   } else if (authR.token && (authR.type == AUTH_RESPONSE_TYPE.NEED_NEW_PW)) {
  //     this.saveToken({token: authR.token})
  //   }
  //   return authR


    
  // }
  // async recoverPassword(email: string): Promise<AuthResponse> {
  //   const r = await this.http.post(`${this.endpoint}auth/lost-password`, {
  //     email: email, 
  //   }).toPromise();
  //   const authR = r as AuthResponse;
  //   return authR
  // }

  signOut(): void {
    // window.sessionStorage.removeItem();
    // window.sessionStorage.removeItem(UID);
    window.sessionStorage.clear();
    // this._isLoggedIn = false;
  }



  
  
}

