import {
  Component,
  effect,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IExerciseTimelineInjectRef } from "src/app/model/exercise.model";
import { ApiService } from "src/app/services/api.service";
import { StorageService } from "src/app/services/storage.service";
import { wait } from "src/app/services/utils/functions";
import { gsap } from "gsap";
import { UiService } from "src/app/services/ui.service";
@Component({
  selector: "app-injects-toolbar",
  templateUrl: "./injects-toolbar.component.html",
  styleUrls: ["./injects-toolbar.component.scss"],
})
export class InjectsToolbarComponent implements OnInit, OnDestroy {
  resizeTimeout: any;
  resizer: ResizeObserver | undefined;
  @ViewChild("scrollGContainer") scrollGContainer: ElementRef | undefined;
  @ViewChild("textInputContainer") textInputContainer: ElementRef | undefined;
  @ViewChild("textInput") textInput: ElementRef | undefined;
  @ViewChild("_scrCnt") _scrCnt: ElementRef | undefined;

  currentCOunt = 0;
  constructor(
    public api: ApiService,
    public storage: StorageService,
    public ui: UiService,
    private injector: Injector
  ) {
    this.initializeLogging();
    // effect(() => {
    //   console.log(`The current count is: ${storage.testLocal().length}`);
    // });
  }
  initializeLogging(): void {
    effect(
      () => {
        if (this.currentCOunt != this.storage.testLocal().length) {
          this.currentCOunt = this.storage.testLocal().length;
          // this.checkScroller()
          wait(500, () => {
            // console.log(this._scrCnt?.nativeElement.scrollTop);
            this.checkScroller();
          });
        }
        // console.warn(`The current count is: ${this.storage.testLocal().length}`);
      },
      { injector: this.injector }
    );
  }
  pHeight = 330;
  pInnerHeight = 38;
  pScrollerHeight = 330 - 38;
  // chatMessages: ChatMessageLocal[] = []
  // get chatMessages():ChatMessage[] {
  //   return this.storage.chatMessages
  // }
  private scroll = (event: any) => {
    // console.log(event);
    if (event.target.scrollTop == 0) {
      // console.log('load more');
      this.storage.loadMOreMessages();
    }
    // if(this._scrCnt) {
    //   const newXX = this._scrCnt.nativeElement.scrollHeight - this._scrCnt.nativeElement.clientHeight;
    //   console.log(newXX);
  };

  private checkScroller() {
    if (this._scrCnt) {
      const newXX =
        this._scrCnt.nativeElement.scrollHeight -
        this._scrCnt.nativeElement.clientHeight;
      gsap.to(this._scrCnt.nativeElement, {
        duration: 0.65,
        scrollTop: newXX,
        delay: 0.6,
        ease: "expo.inOut",
      });
    }
  }
  ngOnDestroy(): void {}
  ngOnInit(): void {
    wait(2000, () => {
      // console.log(this._scrCnt?.nativeElement.scrollTop);
      this.checkScroller();
    });

    const scroller2 = document.getElementById("chtContainer");
    if (scroller2) {
      scroller2.addEventListener("scroll", this.scroll, false);
    }
    const scroller = document.getElementById("textInputContainer");
    if (scroller) {
      this.resizer = new ResizeObserver(this.resise.bind(this));
      // var observer = new MutationObserver(this.resise)
      //   (mutations) {
      //   // console.log('size changed!');
      //   // console.log(mutations[0]);
      //   const d = (mutations[0].target as any)
      //   console.log(d['clientHeight']);

      // })
      // var target = document.querySelector('.mydiv');
      this.resizer.observe(scroller);
      // observer.observe(scroller, {
      //   // attributes: true,
      //   // childList: true,
      //   // subtree: true,
      //   // characterData: false,

      //   // characterData: false,
      //   // characterDataOldValue: true,
      //   // attributeFilter: ['klzu']
      //   // attributeFilter?: string[];
      //   // /** Set to true if attributes is true or omitted and target's attribute value before the mutation needs to be recorded. */
      //   // attributeOldValue?: boolean;
      //   // /** Set to true if mutations to target's attributes are to be observed. Can be omitted if attributeOldValue or attributeFilter is specified. */
      //   // attributes?: boolean;
      //   // /** Set to true if mutations to target's data are to be observed. Can be omitted if characterDataOldValue is specified. */
      //   // characterData?: boolean;
      //   // /** Set to true if characterData is set to true or omitted and target's data before the mutation needs to be recorded. */
      //   // characterDataOldValue?: boolean;
      //   // /** Set to true if mutations to target's children are to be observed. */
      //   // childList?: boolean;
      //   // /** Set to true if mutations to not just target, but also target's descendants are to be observed. */
      //   // subtree?: boolean;

      // })
      // scroller.addEventListener('resize', this.resise, false)
    }
    wait(1000, () => {
      // document.getElementById('textInputContainer')?.addEventListener('resize',)
      // this.chatMessages = this.fixMessages(this.storage.chatMessages)
      // if (this.messages$) {
      //   this.messages$.subscribe(
      //     (values:ChatMessage[]) => {
      //       // this.fixMessages(values)
      //       values.forEach((e: ChatMessage) => {
      //         // if (e.uid == 'FBSSpszUqadFqxRTdq6Ci6EFxNF3') {
      //         // }
      //         this.chatMessages.push(e)
      //         // console.log(e);
      //       })
      //     }
      //   )
      // }
    });
  }
  // messages$: Observable<ChatMessage[]> | undefined;
  showFilter = true;
  searchTxt: string = "";
  messageText: string = "";

  onResize() {
    // if (this.resizeTimeout) {
    //   clearTimeout(this.resizeTimeout);
    // }
    //   this.resizeTimeout = setTimeout((() => {
    // }).bind(this), 500);
    //    console.log(this.textInputContainer?.nativeElement.clientHeight);
    // console.log(this.textInput?.nativeElement.clientHeight);
  }

  resise(e: any) {
    // console.log('resise')
    // console.log(e);
    // console.log(e[0].contentRect.height);
    // const d = (e[0].target as any)
    // console.log(d['clientHeight']);
    // console.log(this.textInput);

    // console.log(this.textInputContainer?.nativeElement.clientHeight);
    // console.log(this.textInput.nativeElement.clientHeight);
    // this.onResize()
    // console.log(this.textInputContainer?.nativeElement.clientHeight);
    // console.log(this.textInput?.nativeElement.clientHeight);
    this.pInnerHeight = this.textInput
      ? this.textInput.nativeElement.clientHeight
      : 38;
    // this.pScrollerHeight = this.textInputContainer? this.textInputContainer.nativeElement.clientHeight : 330
    const _pScrollerHeight = this.textInputContainer
      ? this.textInputContainer.nativeElement.clientHeight
      : 330;
    this.pHeight = 330 + this.pInnerHeight * 2;
    // console.log(`this.pInnerHeight: ${this.pInnerHeight}`);
    // console.log(`this.pHeight: ${this.pHeight}`);
    // console.log(`this.pScrollerHeight: ${this.pScrollerHeight}`);
    // wait(200, () => {
    //   gsap.to(this.scrollGContainer?.nativeElement, {duration: 1, height: (_pScrollerHeight * 2), onComplete: () => {
    //     console.log('complete');

    //   }})
    //})
  }

  toogleStateFor(item: any) {}
  toggleToolbarSize() {}
  onSearch(e: string) {
    console.log(e);
  }

  filteredInjects(): IExerciseTimelineInjectRef[] {
    let f1 = this.storage.assigendExerciseInjects.filter((item) =>
      this.storage.filteredStates.includes(item.state.keyName)
    );
    if (this.searchTxt.length > 1) {
      return f1
        .filter(
          (item) =>
            item.short_title
              .trim()
              .toLowerCase()
              .includes(this.searchTxt.trim().toLowerCase()) ||
            item.timelineItem?.title
              .trim()
              .toLowerCase()
              .includes(this.searchTxt.trim().toLowerCase()) ||
            item
              .inject_no!.trim()
              .toLowerCase()
              .includes(this.searchTxt.trim().toLowerCase())
        )
        .sort((a, b) => a.deliveryDateTime - b.deliveryDateTime);
    } else {
      return f1.sort((a, b) => a.deliveryDateTime - b.deliveryDateTime);
    }
  }
  // get chatMessages():ChatMessage[] {
  //   const msgs = []

  //   return this._chatMessages
  // }
  getBorderColor(item: string): string {
    switch (item) {
      case "DRAFT":
        return "2px solid #3EFFD2";
      case "REQUESTED":
        return "2px solid #00FFFF";
      case "CANCELED":
        return "2px solid #6E7880";
      case "PLANNED":
        return "2px solid #03B0FD";
      case "PREPARED":
        return "2px solid #FABB7B";
      case "RUN":
        return "2px solid #B139F3";
      case "DELIVERED":
        return "2px solid #5AE387";
      default:
        return "#5AE387";
    }
  }
  getBackgroundColor(item: string): string {
    switch (item) {
      case "DRAFT":
        return "#3EFFD2";
      case "REQUESTED":
        return "#00FFFF";
      case "CANCELED":
        return "#6E7880";
      case "PLANNED":
        return "#03B0FD";
      case "PREPARED":
        return "#FABB7B";
      case "RUN":
        return "#B139F3";
      case "DELIVERED":
        return "#5AE387";

      default:
        return "#5AE387";
    }
  }
  sendMessage() {
    if (this.messageText.length > 3) {
      this.storage.sendPush(this.messageText);
      wait(250, () => {
        this.messageText = "";
      });
    }
  }
  resizeEvent(e: any) {
    // console.log('res');
    console.log(e);
    console.log(this.textInputContainer?.nativeElement.clientHeight);
    console.log(this.textInput?.nativeElement.clientHeight);
    // wait(1000, () => {
    //   console.log(this.textInputContainer?.nativeElement.clientHeight);
    // console.log(this.textInput?.nativeElement.clientHeight);
    // })
  }
}
