<mat-toolbar class="overlay-toolbar font-main">
  <!-- duration -->
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_mini" (click)="close()">close</mat-icon>
</mat-toolbar>
<div class="container-fluid bgDarg " style="height: calc(100% - 88px);" >

  <div class="d-flex flex-column jusify-content-center align-items-center">
 <!--    <div class="p-2 h36" style="width: 100%;">

      <mat-slider [min]="min" [max]="max" [step]="step"   (input)='sliderChanged($event)' style="width: 100%;">
        <input matSliderThumb [(ngModel)]="startSliderVal" >
      </mat-slider>
      
    </div> -->
    <div class="p-2 h36" style="width: 100%;">
      <mat-slider min="60" [max]="durationMax" step="10"  style="width: 100%;">
        <input matSliderThumb [(ngModel)]="durationInMinytes" (input)='sliderDurationSliderChanged($event)'>
      </mat-slider>
    </div>

    <div class="p-2 ">
      <div class="times" style="width: 100%; font-size: 36px;">{{durationString}}</div>
      <!-- <div class="times" style="width: 100%; font-size: 36px;">{{startTime}} - {{endTime}}</div> -->
    </div>
   
  </div>

</div>
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_mini" (click)="save()">save</mat-icon>
</mat-toolbar>