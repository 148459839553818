import { Component, inject, Input } from '@angular/core';
import { MiniMapItemI } from '../timeline/timeline.component';
import { IExerciseTimelineInjectRef } from 'src/app/model/exercise.model';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'minimap-item',
  templateUrl: './minimap-item.component.html',
  styleUrls: ['./minimap-item.component.scss']
})
export class MinimapItemComponent {
  @Input() item!:IExerciseTimelineInjectRef
  @Input() fact!:number
  @Input() hourWidth!:number

  private ui = inject(UiService)
  clicked(){
    // const width: number = Number(this.item.deliveryDurationInMinutes) * this.fact
    // console.log(this.fact);
    // console.log(this.item);
    // console.log(`this.item.deliveryDurationInMinutes ${this.item.deliveryDurationInMinutes}`);
    // console.log(`wwwwidth ${(this.item.deliveryDurationInMinutes / 60) * this.hourWidth}`);
  }
  get itemWidth(): number {
    // const width: number = (Number(this.item.deliveryDurationInMinutes) ) * this.fact
    const width: number = (Number((this.item.deliveryDurationInMinutes / 60) * this.hourWidth))
    return width
  }
  get itemLeft(): number {
    
    const left: number = this.ui.smallTimeLineItems ? (this.item.leftPosSmall * this.fact) : (this.item.leftPos * this.fact)
    return left
  }
}
