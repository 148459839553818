<mat-card class="mt1 p0 h100 w100" [class.mat-card-light]="!ui.darkMode">
  <!-- style="background-color: #292d35!important;"  -->
  <div class="d-flex flex-column">
    <div class="p-0  d-flex ">
      <div class="p-2 pb0">EXERCISE CHAT</div>
      <div class="p-2 ml-auto ">
        <mat-icon class='dxfx_icon_btn_mini' *ngIf="ui.isNotificationOn"
          (click)='ui.toggleNotification()'>notifications</mat-icon>
        <mat-icon class='dxfx_icon_btn_mini' *ngIf="!ui.isNotificationOn"
          (click)='ui.toggleNotification()'>notifications_off</mat-icon>

      </div>
    </div>
  </div>
  <div class="d-flex flex-column " [ngStyle]="{'height': pHeight + 'px'}" style="overflow: hidden;" #scrollGContainer>

    <div class="p-0 scrCnt chatContainer" style=" overflow-y: scroll; padding: 10px!important;"
      [ngStyle]="{'height': 'calc(100% - ' +pInnerHeight+'px)'}" #_scrCnt id="chtContainer">
      <!---->
      <!-- <div class="p-2 scrCnt" style=" overflow-y: scroll; padding: 10px;"  [ngStyle]="{'height': 'calc(100% - ' +pInnerHeight+'px)'}"> -->

      <!-- height: calc(100%  - 38px);  -->


      <div class="d-flex flex-column mb1" *ngFor="let comment of storage.chatMessages; let i = index">

        <div class="p-0  chatMessageDateHeader jusify-content-center tlc" *ngIf="comment.dayString != undefined">
          {{comment.dayString}}
        </div>

        <!-- [isLast]="i == storage.chatMessages.length - 1" -->
        <toolbar-chat-item [messageItem]="comment"></toolbar-chat-item>


      </div>



    </div>
    <div class="p-0 mt-auto d-flex  align-items-center " #textInputContainer id="textInputContainer">
      <div class="p-0 flex-fill">
        <!-- (resize)="resizeEvent($event)" -->
        <!-- <textarea class="formControl " style=" font-size: 14px!important;" 
        placeholder="..." #textInput
        rows="1"
        [(ngModel)]="messageText" (keydown.enter)="sendMessage()"> </textarea> -->
        <input type="text" class="formControl textFieldH35" placeholder="..." #input
          [disabled]="storage.isExerciseDeboarded" [(ngModel)]="messageText" (keydown.enter)="sendMessage()">
      </div>
      <div class="p-0">
        <mat-icon class="dxfx_icon_btn_medium ml5" (click)='sendMessage()'>send</mat-icon>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column mt10">
    <div class="p-2  pb0">
      INJECT LIST
    </div>
  </div>

  <div class="d-flex pt0 pb0">
    <div class="p-2 flex-fill pt0 pb0">
      <input type="text" class="formControl textfield100 textFieldH35" placeholder="Search..." #input
        [(ngModel)]="searchTxt" (ngModelChange)='onSearch($event)'>
    </div>
  </div>

  <div class="d-flex pl15 pt15" *ngIf='showFilter'>
    <div class="p-2 filterCheckBox " *ngFor='let filterItem of storage.filteredInjectStates;' [matTooltip]="filterItem"
      matTooltipClass="chatTT" [class.state_in]='storage.includesState(filterItem)'
      [class.state_out]='!storage.includesState(filterItem)'
      [ngStyle]="{'background-color': getBackgroundColor(filterItem), 'border': getBorderColor(filterItem)}"
      (click)='storage.toogleStateFor(filterItem)'>
    </div>
  </div>

  <div class="inject-item-holder " #injectsContainer
    style=" padding-bottom: 50px!important; overflow-y: scroll; height: 100%;">
    <div *ngFor='let injectItem of filteredInjects()'>
      <app-injects-toolbar-action-item [inject]='injectItem'></app-injects-toolbar-action-item>
    </div>
  </div>
</mat-card>

<!-- <timeline-action-item [inject]='injectItem' [exerciseDocId]='exerciseDocId'
      (comfirmed)='confirmedTapped($event);'></timeline-action-item> -->



<!--   <div class="d-flex inject-header" style="background-color: #000000;">
    <div class="p-2 toolbar-title fs12 head-text" >
      INJECTS
      ACTIONS
    </div>

    <div class="p-2 pl0 ml-auto">
      <mat-icon *ngIf='!ui.isToolbarHeightExpanded' matTooltip='maximise'
      class='icon-btn pt0 pb0 time-modify' (click)='toggleToolbarSize()'>height</mat-icon>
      <mat-icon *ngIf='ui.isToolbarHeightExpanded' matTooltip='minimize'
      class='icon-btn pt0 pb0 time-modify' (click)='toggleToolbarSize()'>close_fullscreen</mat-icon>
    </div>
  </div> -->