import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ect-color-btn',
  templateUrl: './color-btn.component.html',
  styleUrls: ['./color-btn.component.scss']
})
export class ColorBtnComponent implements OnInit {

  @Input() disabled:boolean = false
  @Input() color:string = 'base'
  
  @Input() title!:string
  @Input() icon!:string
  @Input() iconOnly:boolean = false
  @Input() circular:boolean = false
  @Output() clicked = new EventEmitter();
  constructor() { 
    if(this.color == null){}
  }

  ngOnInit(): void {

  }
  tapped(){
    this.clicked.emit()
  }

}
