
<div class="authTable " >

  <div class="_content " #_content>

    <mat-card class='content-card bgDarg_mid' *ngIf="viewMode == VIEW_MODES.SIGN_IN">

      <div class="logoContainer w100">
        <img src="assets/logo4.png" alt="">
      </div>

      <div class="">
        restore
      </div>
      <div class=" pt20 pb20">
        <form
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        spellcheck="false">
        <input type="text" class="formControl textfield100 textFieldH40" placeholder="Email" #emailTF required value="" (keydown)="emailKeyDown($event)">
      </form>
      </div>
      <!-- readonly onfocus="this.removeAttribute('readonly');" [(ngModel)]="email" autocomplete="nope" -->
      <div class=" mt10  pb20">
        <form
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        spellcheck="false">
        <!-- <input type="{{pwType}}"  class="formControl" placeholder="Password" #userPassword required
        (keydown.enter)="SignIn(emailTF.value, userPassword.value)"> -->
        <div class="d-flex align-items-center">
          <div class="p-0 flex-fill"><input type="{{pwType}}" class="formControl" placeholder="Password" #userPassword required  ></div>
          <div class="p-0" style="height: 22px;"><mat-icon class="dxfx_icon_btn_medium ml5" (click)="toggleVisibility()">{{icon}}</mat-icon></div>
        </div>
      </form>
      </div>


       <div class=" mt10" >
        <div class="sign-in-button" (click)="SignIn(emailTF.value, userPassword.value)">
          RESTORE PASSWORD
        </div>
      </div>
      <!-- <div class="forgotPassword mt10" >
        <span  (click)='viewMode = VIEW_MODES.LOST_PASSWORD'>Forgot Password?</span>
      </div> -->
    </mat-card>


    <mat-card class='content-card' *ngIf="viewMode == VIEW_MODES.LOST_PASSWORD">
      <div class="formGroup pb20">
        <input type="text" class="formControl" placeholder="EMAIL" #lostEmail required>
      </div>

       <div class="mt10 pt20" style="padding-bottom: 25px;">
        <div class="sign-in-button" (click)="lostPassword(lostEmail.value)">
          Send
        </div>
      </div>
      <div class="forgotPassword">
        <span  (click)='viewMode = VIEW_MODES.SIGN_IN'>Has Account?</span>
      </div>
    </mat-card>


    <mat-card class='content-card' *ngIf="viewMode == VIEW_MODES.SET_NEW_PASSWORD">
      <div class="logoContainer w100">
        <img src="assets/logo4.png" alt="">
      </div>
      <div class="formGroup pb20" >
        <input type="password" class="formControl" placeholder="New Password" name="firstNewPassword" id="firstNewPassword"
        [(ngModel)]='firstPassword' (ngModelChange)="passwordChanged($event)"  required>
      </div>

      <div class="formGroup pb20">
        <input type="password" class="formControl" name="secondNewPassword" id="secondNewPassword"
        placeholder="Repeat New Password " [disabled]='!upperCaseCharOK || !specialCharOk || !charLengthOk'
        [(ngModel)]='secondPassword' (ngModelChange)="secondPasswordChanged($event)"  required
        >
        <!-- (keydown.enter)="authService.SignIn(userName.value, userPassword.value)" -->
      </div>


      <div class="formGroup d-flex flex-column pt0" *ngIf="viewMode == VIEW_MODES.SET_NEW_PASSWORD">
        <div class="p-0" style="height: 18px;">
          <div class="d-flex p0">
            <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
              [class.checkOk]='charLengthOk' [class.checkWait]='!charLengthOk' >check_circle_outline</mat-icon></div>
            <div class="p-0 pl10" style="font-size: 12px;">min. length 8 chars </div>
          </div>
        </div>
        <div class="p-0" style="height: 18px;">
          <div class="d-flex p0">
            <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
              [class.checkOk]='upperCaseCharOK' [class.checkWait]='!upperCaseCharOK' >check_circle_outline</mat-icon></div>
            <div class="p-0 pl10" style="font-size: 12px;">min 1 uppercase character</div>
          </div>
        </div>
        <div class="p-0" style="height: 18px;">
          <div class="d-flex p0">
            <div class="p-0"><mat-icon style="font-size: 16px; margin-top: 2px;"
              [class.checkOk]='specialCharOk' [class.checkWait]='!specialCharOk' >check_circle_outline</mat-icon></div>
            <div class="p-0 pl10" style="font-size: 12px;">{{items}}</div>
          </div>
        </div>
      </div>

      <div class="formGroup pt20">
        <button mat-flat-button color='primary' [disabled]='!saveNewPasswordEnabled' style="width: 100%; height: 38px;  padding-bottom: 6px;"
        (click)="setNewPassword()"
        >Save new password</button>
      </div>
    </mat-card>
  </div>
</div>
