import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import {
  IExerciseInjectState,
  IExerciseTimelineInjectRef,
  TimeChangeOverlayData,
} from "src/app/model/exercise.model";
import { LEVEL, Logger } from "src/app/services/logger.service";
import { StorageService } from "src/app/services/storage.service";
import { InjectDetailOL_ChangeTimeOverlay } from "./components/inject-detail-ol-status-change-time-overlay.component";
import { UiService } from "src/app/services/ui.service";
import {
  ExerciseRunning,
  INJECT_TIME_CHANGE_TYPE,
  InjectStates,
  TIME_MODE,
} from "src/app/model/enums";
import { TIME } from "src/app/services/helpers";
import { addMinutes, differenceInMinutes, subMinutes } from "date-fns";
import * as _ from "lodash";
import { isNegative } from "src/app/services/utils/functions";

@Component({
  selector: "inject-detail-ol-state",
  template: `
    <div class="d-flex flex-column p0">
      <div class="p-0  uc itemTitle fs12 m0 mb3">{{ title }}</div>
      <!-- <div class="p-2 flex-fill" *ngIf='!canEdit'>
              <div class="textfield100 select-form-field font-main">{{inject.deliveryMethod}}</div>
            </div> -->
      <div class="p-0">
        <!-- *ngIf='canEdit' -->
        <mat-form-field
          appearance="outline"
          class="textfield100 select-form-field pb0"
        >
          <mat-select
            [(value)]="selectedState"
            (selectionChange)="onStateChanged($event)"
            [disabled]="!canChange"
            class="font-main"
          >
            <mat-option
              *ngFor="let injectState of storage.injectStates"
              [value]="injectState"
            >
              {{ injectState.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="d-flex flex-column p0" style="height: 66px;">
          <div class="p-2 flex-fill p0 uc itemTitle fs12 m0">{{title}}</div>
          <div class="p-2 flex-fill" *ngIf='!canEdit'>
            <div class="textfield100 select-form-field font-main">{{inject.deliveryMethod}}</div>
          </div>
          <div class="p-0 flex-fill " *ngIf='selectable'>
            <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
            <mat-select [value]='selectedState'  (selectionChange)='onStateChanged($event)' [disabled]='disabled' class="font-main">
              <mat-option *ngFor="let injectState of injectStates" [value]="injectState">
                {{injectState.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div class="p-0 d-flex h100 align-items-center borderR"  *ngIf='!selectable'>
            <div class="p-2 pl10" [ngStyle]="{'color':inject.state.color}" style="font-size: 18px; font-weight: 500;">{{inject.state.title}}</div>
          </div>
        </div> -->
  `,
  styleUrls: ["./inject-detail-overlay.component.scss"],
})
export class InjectDetailOL_StateComponent implements OnInit {
  @Input() inject!: IExerciseTimelineInjectRef;
  @Input() injectState?: IExerciseInjectState;
  @Input() title!: string;
  @Input() selectable: boolean = true;
  @Input() canChange: boolean = false;
  @Output() onChanged = new EventEmitter<IExerciseInjectState>();
  // injectStates: IExerciseInjectState[] = []
  selectedState: IExerciseInjectState | undefined;
  currentState: IExerciseInjectState | undefined;
  // canEdit = true
  // isSaving = false
  // disabled = false

  public ui = inject(UiService);
  constructor(public storage: StorageService, private dialog: MatDialog) {}

  ngOnInit(): void {
    // console.log(this.inject);
    // this.injectStates = this.storage.injectStates
    this.selectedState = this.injectState; //this.injectStates.find(item => item.id == this.inject.state.id) ?? undefined
    // console.log(this.selectedState);
    this.currentState = this.selectedState;

    Logger.log(
      `Current State ${JSON.stringify(this.currentState)}`,
      LEVEL.DEBUG
    );
    Logger.log(
      `Selected State ${JSON.stringify(this.selectedState)}`,
      LEVEL.DEBUG
    );
  }
  onStateChanged(e: MatSelectChange) {
    if (
      this.storage.currentExer &&
      ExerciseRunning(this.storage.currentExer.currentExerciseState)
    ) {
      if (this.currentState?.id != this.selectedState?.id) {
        // Logger.log(
        //   `Current State ${JSON.stringify(this.currentState)}`,
        //   LEVEL.DEBUG
        // );
        // Logger.log(
        //   `Selected State ${JSON.stringify(this.selectedState)}`,
        //   LEVEL.DEBUG
        // );

        if (this.selectedState?.keyName == InjectStates.RUN) {
          this.openChangeTimeOverlay(INJECT_TIME_CHANGE_TYPE.START_TIME);
        } else if (this.selectedState?.keyName == InjectStates.DELIVERED) {
          const currentDateTime = new Date();
          const newDeliveryDateTime: number = Math.round(
            (Number(this.inject.leftPosSmall) / 61) * 60
          );

          // Logger.log(
          //   `newDeliveryDateTime : ${newDeliveryDateTime}`,
          //   LEVEL.DEBUG
          // );

          const startDateTime = new Date(
            addMinutes(
              this.storage.currentExer.getDateTimeRaw(),
              newDeliveryDateTime
            )
          );
          const diffInMins = differenceInMinutes(
            currentDateTime,
            startDateTime
          );
          // Logger.log(`diffInMins : ${diffInMins}`, LEVEL.DEBUG);

          if (!isNegative(diffInMins) && diffInMins > 15) {
            this.openChangeTimeOverlay(INJECT_TIME_CHANGE_TYPE.DURATION);
          } else {
          }
        }
        this.onChanged.emit(e.value);
      }
    }
  }
  // get textColorForInject():string{
  //   return "#1E2A33";
  //   // if(inject.state.keyName === 'REQUESTED'){
  //   //   return "#1E2A33";
  //   // }
  //   // else {
  //   //   return  "#ffffff"
  //   // }

  // }

  openChangeTimeOverlay(mode: INJECT_TIME_CHANGE_TYPE) {
    console.log(inject);
    const olData: TimeChangeOverlayData = {
      inject: this.inject,
      mode: mode,
    };
    this.dialog
      .open(InjectDetailOL_ChangeTimeOverlay, {
        width: "480px",
        height: "320px",
        data: olData,
        panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
        backdropClass: "fs_backdrop",
        disableClose: true,
      })
      .afterClosed()
      .subscribe((mode: TIME_MODE) => {
        Logger.log(`mode ${mode}`, LEVEL.DEBUG);
        if (mode && mode == TIME_MODE.CURRENT) {
          this.saveNewInjectTime(olData.mode);
        }
      });
  }

  saveNewInjectTime(mode: INJECT_TIME_CHANGE_TYPE) {
    if (this.storage.currentExer) {
      const currentDateTime = new Date();
      const tzOffset = new Date().getTimezoneOffset();

      let minsFromCurrentTime = 0;
      // console.warn(`isNegative: ${isNegative(tzOffset)} `);
      // console.warn(`offset: ${tzOffset} `);
      if (isNegative(tzOffset)) {
        minsFromCurrentTime = differenceInMinutes(
          subMinutes(currentDateTime, tzOffset),
          this.storage.currentExer.getDateTimeRaw()
        );
      } else {
        minsFromCurrentTime = differenceInMinutes(
          addMinutes(currentDateTime, tzOffset),
          this.storage.currentExer.getDateTimeRaw()
        );
      }
      console.warn(`minsFromCurrentTime: ${minsFromCurrentTime} `);
      // this.currentDuration = this.inject.deliveryDurationInMinutes;
      if (mode == INJECT_TIME_CHANGE_TYPE.START_TIME) {
        this.inject.leftPos = (minsFromCurrentTime * 121) / 60;
        this.inject.leftPosSmall = (minsFromCurrentTime * 61) / 60;
        this.inject.deliveryDateTime = minsFromCurrentTime;
      } else if (mode == INJECT_TIME_CHANGE_TYPE.DURATION) {
        const newDeliveryDateTime: number = Math.round(
          (Number(this.inject.leftPosSmall) / 61) * 60
        );
        const startDateTime = new Date(
          addMinutes(
            this.storage.currentExer.getDateTimeRaw(),
            newDeliveryDateTime
          )
        );
        Logger.log(`newDeliveryDateTime : ${newDeliveryDateTime}`, LEVEL.DEBUG);
        let diffInMins = 0;
        // console.warn(`isNegative: ${isNegative(tzOffset)} `);
        // console.warn(`offset: ${tzOffset} `);
        if (isNegative(tzOffset)) {
          diffInMins = differenceInMinutes(
            subMinutes(currentDateTime, tzOffset),
            startDateTime
          );
        } else {
          diffInMins = differenceInMinutes(
            addMinutes(currentDateTime, tzOffset),
            startDateTime
          );
        }
        // const diffInMins = differenceInMinutes(
        //   this.currentDateTime,
        //   startDateTime
        // );
        Logger.log(`diffInMins : ${diffInMins}`, LEVEL.DEBUG);
        // this.newDuration = diffInMins;

        // const startDateTime = new Date(
        //   addMinutes(
        //     this.storage.currentExer.getDateTimeRaw(),
        //     newDeliveryDateTime
        //   )
        // );
        // const diffInMins = differenceInMinutes(currentDateTime, startDateTime);
        Logger.log(`diffInMins : ${diffInMins}`, LEVEL.DEBUG);
        this.inject.deliveryDurationInMinutes = diffInMins;
      }
      // Logger.log(
      //   `minsFromStartToCurrentTime : ${minsFromCurrentTime}`,
      //   LEVEL.DEBUG
      // );
      const dto = {
        exerciseId: this.storage.currentExer.id,
        injectItemId: this.inject.id,
        timelineItemId: this.inject.id,
        stateId: this.inject.state.id,
        deliveryDateTime: minsFromCurrentTime,
        deliveryDurationInMinutes: this.inject.deliveryDurationInMinutes,
      };
      console.log(dto);
      this.storage.socketInjectUodate(dto);
    }
  }
}
