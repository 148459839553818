
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAP_STYLE_2, MAP_STYLE_3, sty44 } from "src/app/services/utils/mapstyle";
import { StorageService } from '../../services/storage.service';
import { GoogleMap } from '@angular/google-maps';
import { Location } from 'src/app/model/location.model';
import * as _ from 'lodash';
// import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Subscription } from "rxjs";

@Component({
  selector: 'timeline-mini-google-map',
  template: `
             <google-map  [options]="options" width='100%' height="100%"   #_map >
              
              <map-marker 
              *ngFor="let marker of markers"
              [position]="marker.position"
              [options]="marker.options"
              
              
              >
              </map-marker>
            
          </google-map>
          <!-- <div id="map" style="height: 100%;width: 100%"></div> -->
  `,
    
})
export class TimelineMiniGoogleMapComponent implements OnInit, OnDestroy {
  @ViewChild('_map' , {static: false}) _map: GoogleMap | undefined
  exerciseLocations: Location[] = []
  // locations2: Location[] = []
  markers:any[] = [];
  // zoom = 1
  // styles = MAP_STYLE_3
  options: google.maps.MapOptions = {
    center: {lat: 25, lng: 0},
    draggable: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    backgroundColor: '#202B31',  
    disableDefaultUI: true,
    styles: MAP_STYLE_3,
    zoom: 1,
    // mapId: 'DEMO_MAP_ID',
    // mapId: 'MMMID',
    zoomControl: false,
    scrollwheel: false,
    scaleControl: false,
    panControl: false,
  };
  subS: Subscription | undefined
  get assignedInjectLocations(): Location[] {
    return this.storage.assigendExerciseInjectsLocations
  }
  constructor(public storage:StorageService) {}
  ngOnDestroy(): void {
    if  (this.subS) {
      this.subS.unsubscribe()
    }
  }
  ngOnInit(): void {
    this.subS = this.storage.injectsLocationChangedEmitter.subscribe(x => {
      
      setTimeout(()=> {
        this.initLocations()
        
      },500)
    })
    setTimeout(()=> {
      this.initLocations()
      // this.storage.initInjectLocations()
      // this.initMap()
    },500)
  }

  private initLocations() {
    if(this.storage.currentExer) {

      this.exerciseLocations = this.storage.currentExer.locations;

      
      if(this.exerciseLocations.length > 0) {
        var bounds = new google.maps.LatLngBounds();
        this.markers = []

        for (let i = 0; i < this.exerciseLocations.length; i++) {

          const currentExerciseLocation = this.exerciseLocations[i];
          const injectsWithLocation = this.storage.assigendExerciseInjects.filter(item => item.location?.id == currentExerciseLocation.id);
          console.log(`injectsWithLocation count: ${injectsWithLocation.length}`);
          
          const position = new google.maps.LatLng(Number(currentExerciseLocation.lat!),Number(currentExerciseLocation.lng!))

          this.markers.push({
            map: this._map!,
            title: currentExerciseLocation.id!,
            // label: "1",
            position: position,
            // {
            //   lat: Number(element.lat!),
            //   lng: Number(element.lng!)
            // },
            options : { icon: '/assets/marker/markerBlue6.png', draggable: true, label: {
              text: `${injectsWithLocation.length}`, color: '#242F3E', fontSize: '16px', fontWeight: '500'
            } },
            // options : {  label: '12' },
            animation: google.maps.Animation.DROP,
          })
          bounds.extend(position); 
        }
        if (this.exerciseLocations.length > 1 ) {
  
          this._map!.fitBounds(bounds);
        }

        

      }

      // this.locations2 = []
      // let f1 = this.storage.assigendExerciseInjects.filter(item => item.location != null);
      // console.log(f1);
      
      // if (f1.length > 0) {
      //   const lll = _.map(f1, 'location') as Location[]
      //   console.log(lll);
      //   // this.locations2 = lll;
      // }
      // this.locations = this.storage.currentExer.getLocations()
      

        // if(this.locations.length > 0) {
        //   for (let i = 0; i < this.locations.length; i++) {

        //     var count = 0;

        //     let items = 
        //     const element = this.locations[i];
            
        //     const position = new google.maps.LatLng(Number(element.lat!),Number(element.lng!))
          // this.markers.push({
          //     map: this._map!,
          //     title: "element.id",
          //     label: "1",
          //     position: {
          //       lat: Number(element.lat!),
          //       lng: Number(element.lng!)
          //     },
          //     options : { icon: '/assets/marker/markerBlue6.png', draggable: true, label: {
          //       text: '12', color: '#242F3E', fontSize: '16px', fontWeight: '500'
          //     } },
          //     // options : {  label: '12' },
          //     animation: google.maps.Animation.DROP,
          //   })
          //   bounds.extend(position); 
          // }
          // if (this.locations.length > 1 ) {
    
          //   this._map!.fitBounds(bounds);
          // }
    
        // }
      }

 
  }
  
  /* async  initMap() {
    // Request needed libraries.
    const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
  
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      // this._map!.googleMap! as HTMLElement,
      {
        zoom: 1,
        center: { lat: 25, lng: 0 },
        mapId: '5e9f18e128881eb4',
        
        disableDefaultUI: true,
        mapTypeControl: false
      }
    );
  // this._map!.googleMap!.setMapTypeId('DEMO_MAP_ID')
  // this._map!.options.mapId = 'DEMO_MAP_ID'
    const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });
  
    // Create an array of alphabetical characters used to label the markers.
    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  
    // Add some markers to the map.
    const markers = this.locations.map((p, i) => {
      const label = labels[i % labels.length];
      const pinGlyph = new google.maps.marker.PinElement({
        glyph: label,
        glyphColor: "white",
      })
      const element = p
        
            const position = new google.maps.LatLng(Number(element.lat!),Number(element.lng!))
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: pinGlyph.element,
      });
  
      // markers can only be keyboard focusable when they have click listeners
      // open info window when marker is clicked
      marker.addListener("click", () => {
        infoWindow.setContent(position.lat + ", " + position.lng);
        infoWindow.open(map, marker);
        // infoWindow.open(this._map?.googleMap, marker);
      });
      return marker;
    });
  
    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({ markers, map });
    // new MarkerClusterer({ markers, map: this._map!.googleMap });
  } */
}