import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class Logger {
  constructor() {}

  static log(message: string, level: LEVEL) {
    switch (level) {
      case LEVEL.DEBUG:
        console.log(`%c ${message}`, "color:#3EB7FF");
        break;
      case LEVEL.WARN:
        console.log(`%c ${message}`, "color:#D2AC32");
        break;
      case LEVEL.ERROR:
        console.log(`%c ${message}`, "color:#DD6161");
        break;
    }
  }
}

export enum LEVEL {
  DEBUG = "DEBUG",
  ERROR = "ERROR",
  WARN = "WARN",
}
