
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IExerciseTimelineInjectRef, INJECT_DELIVERY_METHOD, INJECT_DELIVERY_METHOD_LIST_ITEM } from "src/app/model/exercise.model";
import { InjectDeliveryMethods, selectedInjectDeliveryMethodItem } from "src/app/services/utils/companyIn.help";

@Component({
  selector: 'inject-detail-ol-delivery-method',
  template: `
      <div class="d-flex flex-column p0">

        <div class="p-0  uc itemTitle fs12 m0 mb3">deliveryMethod</div>
        <div class="p-2 flex-fill" *ngIf='!canChange'>
          <div class="textfield100 select-form-field font-main">{{deliveryMethod}}</div>
        </div>

      <div class="p-0" *ngIf='canChange'>
        <mat-form-field appearance="outline" class="textfield100 select-form-field pb0"
          *ngIf='canChange'>
            <mat-select [(ngModel)]='selectedDelivertMethod'  (ngModelChange)='onDeliMethodChanged($event)' [disabled]='disabled' class="font-main">
              <mat-option *ngFor="let method of deliveryMethods" [value]="method">
                {{method.viewVal}}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </div>

    </div>
  `,
    styleUrls: ['./inject-detail-overlay.component.scss']
})
export class InjectDetailOL_DeliveryMethodComponent implements OnInit {
  // @Input() inject!: IExerciseTimelineInjectRef
  @Input() canChange:boolean = false
  @Input() deliveryMethod:INJECT_DELIVERY_METHOD | undefined
  @Output() onChanged = new EventEmitter<INJECT_DELIVERY_METHOD>();

  deliveryMethods: INJECT_DELIVERY_METHOD_LIST_ITEM[] = []
  selectedDelivertMethod: INJECT_DELIVERY_METHOD_LIST_ITEM | undefined

  
  // canEditDeliveryMethod = true

  disabled = false
  
  ngOnInit(): void {
    this.deliveryMethods = InjectDeliveryMethods
    if (this.deliveryMethod) {

      this.selectedDelivertMethod = selectedInjectDeliveryMethodItem(this.deliveryMethod)
      //this.selectedDelivertMethod = selectedInjectDeliveryMethodItem(this.inject.deliveryMethod)
      console.log(this.selectedDelivertMethod);  
    }
  }

  onDeliMethodChanged(e: INJECT_DELIVERY_METHOD_LIST_ITEM) {
    this.selectedDelivertMethod = e
    console.log(this.selectedDelivertMethod);
    //this.inject.deliveryMethod = e.itemEnum
    this.onChanged.emit(this.selectedDelivertMethod.itemEnum);
  }
  
  selectionCHanged(e: any) {
    console.log(e);
    
  }
}