import { Component, Input, OnInit } from "@angular/core";
import { iCompanyInject, iExerciseInject } from "src/app/model/company.model";
import { INJECT_DELIVERY_METHOD_LIST_ITEM } from "src/app/model/exercise.model";
import { Location } from "src/app/model/location.model";
import { InjectDeliveryMethods, selectedInjectDeliveryMethodItem } from "src/app/services/utils/companyIn.help";

@Component({
  selector: 'inject-template-detail',
  template: `
  
      <div class="d-flex p0 ">
        <div class="p-2">
          <div class="formGroup textfield100">
            <mat-label class='mat-caption uc'>Inject No.*</mat-label>
            <input type="text"  
            class=" textfield100 textFieldH40 formControl"  required [disabled]='!editable' 
            
            placeholder="inject_no" style="text-transform: uppercase;"
            [(ngModel)]="inject.inject_no" >
    
          </div>

        </div>
    
        <div class="p-2 flex-fill" style="min-width: 250px;">
          <mat-label class='mat-caption uc'>Short title</mat-label>
          <input type="text" class="textfield100 textFieldH40" placeholder="Short title"
          [class.formControlError]='injectShortError' [class.formControl] = '!injectShortError' 
                  [(ngModel)]="inject.short_title" >
        </div>

      </div>

      <div class="d-flex p0 ">
      <div class="p-2 w33p">
          <mat-label class='mat-caption uc'>deliveryMethod:</mat-label>
           <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
             <mat-select [(ngModel)]='selectedDelivertMethod' (ngModelChange)='onDeliMethodChanged($event)' >
               <mat-option *ngFor="let exer of deliveryMethods" [value]="exer">
                 {{exer.viewVal}}
               </mat-option>
             </mat-select>
           </mat-form-field>
         </div>

        <div class="p-2 w33p">
          <mat-label class='mat-caption uc'>location:</mat-label>
           <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
             <mat-select >
               <mat-option *ngFor="let loca of locations" [value]="loca">
                 {{loca.title}}
               </mat-option>
             </mat-select>
           </mat-form-field>
         </div>
      </div>


        <div class="d-flex flex-wrap pt0">
          <div class="p-2 flex-fill" style="width: 50%;">
            <mat-label class='mat-caption uc'>Related documents</mat-label>
            <div class="formGroup textfield100">
              <textarea class="formControl" [(ngModel)]="inject.related_documents" rows="5" cols="4" [disabled]='isSaving'></textarea>
            </div>
          </div>
          <div class="p-2 flex-fill" style="width: 50%;">
            <mat-label class='mat-caption uc'>additional_guidance_caution_options</mat-label>
            <div class="formGroup textfield100">
              <textarea class="formControl" [(ngModel)]="inject.additional_guidance_caution_options" rows="5" cols="4" [disabled]='isSaving'></textarea>
            </div>
          </div>
        </div>
    
        <div class="d-flex flex-wrap pt0" >
          <div class="p-2 flex-fill" style="width: 50%;">
            <mat-label class='mat-caption uc'>expected_outcomes</mat-label>
            <div class="formGroup textfield100">
              <textarea class="formControl" [(ngModel)]="inject.expected_outcomes" rows="5" cols="4" [disabled]='isSaving'></textarea>
            </div>
          </div>
          <div class="p-2 flex-fill" style="width: 50%;">
            <mat-label class='mat-caption uc'>on_scene_preparation</mat-label>
            <div class="formGroup textfield100">
              <textarea class="formControl" [(ngModel)]="inject.on_scene_preparation" rows="5" cols="4" [disabled]='isSaving'></textarea>
            </div>
          </div>
        </div>
    
           <div class="d-flex flex-wrap pt0">
            <div class="p-2 flex-fill" style="width: 50%;">
              <mat-label class='mat-caption uc'>Description</mat-label>
              <div class="formGroup textfield100">
                <textarea class="formControl" [(ngModel)]="inject.description" rows="5" cols="4" [disabled]='isSaving'></textarea>
              </div>
            </div>
            <div class="p-2 flex-fill" style="width: 50%;">
              <mat-label class='mat-caption uc'>Related equipment:</mat-label>
                <div class="formGroup textfield100">
                <textarea class="formControl" [(ngModel)]="inject.related_equipment" rows="5" cols="4" [disabled]='isSaving'></textarea>
              </div>
            </div>
          </div>
    
          <div class="d-flex flex-wrap pt0" >
            <div class="p-2 flex-fill" style="width: 50%;">
              <mat-label class='mat-caption uc'>role_players_instruction</mat-label>
              <div class="formGroup textfield100">
                <textarea class="formControl" [(ngModel)]="inject.role_players_instruction" rows="5" cols="4" [disabled]='isSaving'></textarea>
              </div>
            </div>
            <div class="p-2 flex-fill" style="width: 50%;">
              <mat-label class='mat-caption uc'>Trainers action requirement:</mat-label>
                <div class="formGroup textfield100">
                <textarea class="formControl" [(ngModel)]="inject.trainers_action_requirement" rows="5" cols="4" [disabled]='isSaving'></textarea>
              </div>
            </div>
          </div>
    

  `,
    styleUrls: ['./inject-select-overlay.component.scss']
})
export class InjectTemplateDetailComponent implements OnInit {
  @Input() inject!: iExerciseInject
  @Input() locations!: Location[] 
  deliveryMethods: INJECT_DELIVERY_METHOD_LIST_ITEM[] = []
  selectedDelivertMethod: INJECT_DELIVERY_METHOD_LIST_ITEM | undefined
  errorCopy: string = ""
  
  editable = false
  isSaving = false
  disabled = false
  isMainInfos = true

  injectNoKeyDown(e:any) {
    
    this.errorCopy = ''
  }
  ngOnInit(): void {
    console.log(this.inject);
    this.deliveryMethods = InjectDeliveryMethods
    this.selectedDelivertMethod = selectedInjectDeliveryMethodItem(this.inject.deliveryMethod)
    console.log(this.selectedDelivertMethod);
    
    
  }
  onDeliMethodChanged(e: INJECT_DELIVERY_METHOD_LIST_ITEM) {
    this.selectedDelivertMethod = e
    console.log(this.selectedDelivertMethod);
    this.inject.deliveryMethod = e.itemEnum
    
  }
  get injectNoError():boolean {
    return this.inject.inject_no.length > 3 ? false : true
  }
  get injectShortError():boolean {
    return this.inject.short_title.length > 3 ? false : true
  }
  get injectNoHasError():boolean {
    return true
  }
}