
import { Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { DynamicComponent } from "src/app/model/dynamic.component";
import { ExerciseTimelineItemType, ExerciseUserRole, IExerciseTimelineInjectRef } from "src/app/model/exercise.model";
import { StorageService } from "src/app/services/storage.service";
import * as _ from 'lodash';
import { IToggleButtonItem } from "src/app/components/toggle-button/toggleButtonItem.model";
import { trainerFeedbackTypeFromString, trainerFeedbackVoteTypeFromString, wait } from "src/app/services/utils/functions";
import { TrainerFeedbackType, TrainerFeedbackVoteType, iTrainerFeedbackVoteItem } from "src/app/model/trainerFeedback.model";
import { CreateTrainerVoteCommentDto, UpdateTrainerFeedbackDto } from "src/app/model/dtos";
import { TrainerfeedbackVoteBtns, TrainerfeedbackRateBtns } from "src/app/services/utils/ui.utils";
import { LEVEL, Logger } from "src/app/services/logger.service";

@Component({
  selector: 'inject-detail-ol-trainer-feedback',
  template: `
    <div class="w100" style="height: calc(100% - 0px); overflow: hidden;">
      <div class="container-fluid " style="height: 90px;">
         <div class="d-flex flex-column">
          <div class="p-0 d-flex w100 justify-content-center">
            <div class="p-1 " *ngFor="let voteBtn of feedbackRateButtons" >
              <app-toggle-button
              [active]="activeVoteBtnId == voteBtn.enum"
              [disabled]="storage.getAccess([ExerciseUserRoles.OBSERVER])"
              [model]="voteBtn"  (selected)="selectRatingItem($event)" ></app-toggle-button>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column">
          <div class="p-0 d-flex w100 justify-content-center">
            <div class="p-1 " *ngFor="let feedBackVoteItem of feedbackVoteButtons" >
              <app-toggle-button
              [disabled]="storage.getAccess([ExerciseUserRoles.OBSERVER])"
              [active]="activeFeedbackVoteButtonBtnId == feedBackVoteItem.id"
              [model]="feedBackVoteItem"  (selected)="selectVoteItem($event)" ></app-toggle-button>
            </div>
          </div>
        </div>
      </div>

        <div class=" w100 h100" style="height: calc(100%  - 180px);  overflow-y: scroll; padding: 10px;" *ngIf="inject && inject.vote">
          <div class="d-flex flex-column m_b10" *ngFor="let comment of TrainerFeedBackStream" >

            <div class="p-0 w100 voteMessageDate"  >
              {{comment.user?.name}} {{comment.createdAt | date: "dd/MM/YYYY, hh:mm" }}
            </div>
            <div class="p-0 w100 voteMessageComment"  >
            {{comment.comment}}
            </div>
          </div>

        </div>

        <div class="d-flex" style="height: 90px; background-color: rgb(30, 42, 51);" *ngIf="selectedFeedbackVoteButton">
          <div class="p-2 formGroup textfield100" >
            <textarea class="formControl" [(ngModel)]="inputMessage" ></textarea>
          </div>
          <div class="p-2 w120">
            <ect-color-btn class="mr5" title="Send" icon="send" color="primary" [disabled]="!selectedFeedbackVoteButton || inputMessage.length < 3" (clicked)="postComment()" ></ect-color-btn>
          </div>
        </div>

      </div>
  `,
    styleUrls: ['../inject-detail-overlay.component.scss']
})
export class InjectDetailOL_TrainerFeedbackComponent implements OnInit, OnDestroy, DynamicComponent {
  @Input() inject!:IExerciseTimelineInjectRef
  @Input() data!:any

  // timeout: any
  storage = inject(StorageService);
  inputMessage = ''
  isloading: boolean = false

  feedbackRateButtons: IToggleButtonItem[] = TrainerfeedbackRateBtns();
  feedbackVoteButtons: IToggleButtonItem[] = TrainerfeedbackVoteBtns();

  selectedFeedbackRateButton: IToggleButtonItem | undefined
  selectedFeedbackVoteButton: IToggleButtonItem | undefined
  ExerciseUserRoles = ExerciseUserRole

  ngOnInit(): void {
    // this.durationCount = 0

    Logger.log(`InjectDetailOL_TrainerFeedbackComponent INIT > ${this.inject.inject_no}`, LEVEL.DEBUG)
    console.log(this.inject);

    if ( this.inject && (this.inject.itemType == ExerciseTimelineItemType.TEAM) && this.inject.vote ) {

      const voteBtnIndex = this.feedbackRateButtons.findIndex(item => trainerFeedbackVoteTypeFromString(item.title) == this.inject!.vote)
      if (voteBtnIndex != -1) {
        this.selectedFeedbackRateButton = this.feedbackRateButtons[voteBtnIndex]
      }
      setTimeout(() => {
        // console.log(this.TrainerFeedBackStream);
        this.initFeedbackBtnCounting()
      },100)
    // console.log(`inject trainer feedback`)
    // console.log(this.inject.trainerFeedBack);

    //   // console.log(`index: ${index}`);


    //   if(this.trainerFeedback) {
    //     console.log(`Trainer feedback found `);
    //     console.log(this.trainerFeedback);
    //   }
    setTimeout(() => {
      this.selectVoteItem(this.feedbackVoteButtons[0])
    },600)
    }

  }
  selectRatingItem(e: IToggleButtonItem) {//sideNavItem){
    if (this.storage.getAccess([ExerciseUserRole.OBSERVER])) { return }
    if(this.selectedFeedbackRateButton && this.inject && this.inject.vote) {
      //&& trainerFeedbackVoteTypeFromString(this.feedbackRateButton.title) == TrainerFeedbackVoteType.NOT_RATED
      // AlertI
      // show alert .. rating can not be changed
      this.selectedFeedbackRateButton = e;
      const vote: TrainerFeedbackVoteType = trainerFeedbackVoteTypeFromString(e.title);
      this.changeFeedback(vote)
    }
  }
  selectVoteItem(e: IToggleButtonItem) {//sideNavItem){
    // console.log(new Date().getTime());
    if (this.storage.getAccess([ExerciseUserRole.OBSERVER])) { return }
    this.selectedFeedbackVoteButton = e;
    setTimeout(() => {
      // console.log(this.TrainerFeedBackStream);
      this.initFeedbackBtnCounting()
    },100)
  }

  private initFeedbackBtnCounting() {
    if(!this.inject) { return  }
    for(var i = 0; i < this.feedbackVoteButtons.length; i ++) {

      const inject = this.storage.assigendExerciseInjects.find(item => item.id == this.inject.id);
      if(inject && inject.feedbacks) {
        const itemType: TrainerFeedbackType = trainerFeedbackTypeFromString(this.feedbackVoteButtons[i].title);
        const items = inject.feedbacks.filter(item => item.itemType == itemType)
        this.feedbackVoteButtons[i].preTitle = items.length > 0 ? ` (${items.length})` : ''
      } else {
        this.feedbackVoteButtons[i].preTitle = ''
        // this.feedbackVoteButtons[i].preTitle = '(0) '
      }
    }
  }

  get activeFeedbackVoteButtonBtnId() : number {
    return this.selectedFeedbackVoteButton ? this.selectedFeedbackVoteButton.id : -1;
  }
  get activeVoteBtnId() : any {
    if (!this.inject || !this.inject.vote) {
      return TrainerFeedbackVoteType.NOT_RATED
    } else {

      const inject = this.storage.assigendExerciseInjects.find(item => item.id == this.inject.id);
      if(!inject || !inject.vote) {
        return TrainerFeedbackVoteType.NOT_RATED
      } else {
        return inject.vote
      }
    }
    // return this.inject && this.inject.vote ? this.inject.vote : TrainerFeedbackVoteType.NOT_RATED
  }
  // get activeVoteBtnId() : number {
  //   return this.selectedFeedbackRateButton ? this.selectedFeedbackRateButton.id : -1;
  // }

  async changeFeedback(vote: TrainerFeedbackVoteType) {
    Logger.log('postSocket > change_trainer_feedback', LEVEL.DEBUG)
    if(this.inject) {
      const dto: UpdateTrainerFeedbackDto = {
        injectId: this.inject.id,
        vote: vote,
      }
      this.storage.postSocket('change_trainer_feedback', dto);
    }
  }

  postComment() {
    // if(this.inputMessage.length > 4 && this.inject && this.feedbackVoteButton && this.inject.trainerFeedBack) {
    if(this.inputMessage.length > 3 && this.inject && this.selectedFeedbackVoteButton) {
      const dto: CreateTrainerVoteCommentDto = {
        assignedInjectId: this.inject.id,
        comment: this.inputMessage,
        itemType: trainerFeedbackTypeFromString(this.selectedFeedbackVoteButton.title),
        // exerciseTrainerfeedbackId: this.trainerFeedback.id,
      }
      this.storage.postSocket('trainer_feedback_comment', dto);
      wait(150, ()=> {
        this.inputMessage = ''
      });
      wait(250, ()=> {
        this.initFeedbackBtnCounting()
      });
    }
  }

  get TrainerFeedBackStream(): iTrainerFeedbackVoteItem[] {
    if(!this.inject || !this.selectedFeedbackVoteButton) { return [] }
    const inject = this.storage.assigendExerciseInjects.find(item => item.id == this.inject.id);
    if(inject && inject.feedbacks) {
      const itemType: TrainerFeedbackType = trainerFeedbackTypeFromString(this.selectedFeedbackVoteButton.title);
      const items = inject.feedbacks.filter(item => item.itemType == itemType)
      return _.orderBy(items, ['createdAt'],
        ['asc']); //items.sort((a, b) => a.createdAt - b.createdAt)
    } else {
      return []
    }
  }

  // get trainerFeedback(): iTrainerFeedback | undefined {
  //   if(!this.inject ) { return undefined }
  //   return this.inject.trainerFeedBack
  // }

  ngOnDestroy(): void {
    this.selectedFeedbackRateButton = undefined
    this.selectedFeedbackVoteButton = undefined
  }
}
