<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container-fluid h100 bgMid">
  <div class="d-flex flex-column" *ngIf="user">
    <div class="p-2 d-flex">
      <div class="p-2">{{user.email}}</div>
      <div class="p-2">{{user.name}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div class="p-2 d-flex">
      <div class="p-2 ml-auto"><mat-icon class="dxfx_icon_btn_medium" [matMenuTriggerFor]="menu">add</mat-icon></div>
      <mat-menu  #menu="matMenu">
        <button mat-menu-item  *ngFor="let comp of companiesFiltered"
        (click)="addCompToUser(comp)">
          <span>{{comp.name}}</span>
        </button>

      </mat-menu>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="p-2">

          <div class="p-0 d-flex flex-column" *ngFor="let c of user.companies" >
            <div class="p-0">
            <mat-card>
              <mat-card-content>
           <div class="p-0 d-flex h100 align-items-center">
            <!-- <div class="p-1 cIconBox"><mat-icon class="dxfx_icon_btn_medium" matTooltip="{{c.role}}" matTooltipClass="chatTT">{{c.icon}}</mat-icon></div> -->
            <div class="p-1 cTitle">{{c.name}}</div>
            <div class="p-1 w33p ml-auto">
               <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
                 <mat-select [(ngModel)]='c.role'  (ngModelChange)='onRoleChanged($event, c)'>
                   <mat-option *ngFor="let role of roles" [value]="role">
                     {{role}}
                   </mat-option>
                 </mat-select>
               </mat-form-field>
             </div>
             <div class="p-1 "><mat-icon class="dxfx_icon_btn_medium" (click)='onRemoveUserFromComp(c)' >delete</mat-icon></div>
           </div>

          </mat-card-content>
        </mat-card>
      </div>
          </div>

    </div>
    <mat-divider ></mat-divider>
  </div>
</div>
