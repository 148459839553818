<div class="container-fluid p0" style="height: 100%; overflow: hidden;">
  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
  </mat-toolbar>

  <div class="container-fluid p0" style=" height: calc(100% - 88px); overflow-y: hidden;">

    <div class="container-fluid p0 h100 bgDarg">
    
      <div class="d-flex h100">

        <div class="p-2" style="width: 400px;">
          
          <div class="d-flex flex-column">
            <div class="p-2 tlr">
              <mat-icon class="dxfx_icon_btn_medium ml5" (click)='addLocation()' *ngIf="!location">add</mat-icon>
            </div>
            <div class="p-2" style="height: 200px; overflow-y: scroll; border: 1px solid rgba(255, 255, 255, 0.1);">

              <mat-nav-list>
     
                <mat-list-item *ngFor="let loca of locations" 
                 [activated]="location == loca" 
                 [class.is-active]="location == loca"
                 [class.non-active]="location != loca"
                 
                 (click)="selectionSelect(loca)">
                  
           
                <div>{{loca.title}}</div>
                </mat-list-item>
              </mat-nav-list>
            <!-- </mat-action-list> -->
            </div>

            <div class="p-2" >
              <mat-label class='mat-caption uc'>title</mat-label>
              <input type="text" class="formControl textfield100 textFieldH40" placeholder="Short title"
                      [(ngModel)]="locationTitle" >
            </div>
            <div class="p-2 " >
              <mat-label class='mat-caption uc'>description</mat-label>
              <div class="formGroup textfield100">
                <textarea class="formControl" [(ngModel)]="locationDescription" rows="5" cols="4" ></textarea>
              </div>
            </div>

            <div class="p-2" >
              <mat-label class='mat-caption uc'>lat</mat-label>
              <input type="text" class="formControl textfield100 textFieldH40"
                      [value]="location?.lat" disabled>
            </div>

            <div class="p-2" >
              <mat-label class='mat-caption uc'>lng</mat-label>
              <input type="text" class="formControl textfield100 textFieldH40" 
              [value]="location?.lng" disabled>
            </div>

          </div>
         
          
          

        </div>

        <div class="p-2 flex-fill h100">
          <!-- [zoom]="zoom" -->
          <!-- (mapClick)="mapTapped($event)" -->
          <google-map    [options]="options" width='100%' height="100%"    #_map>
            <!-- [options]="marker.options" -->
            <map-marker 
            *ngFor="let marker of markers"
            [position]="marker.position"
            [options]="{icon: location?.id == marker.title ? '/assets/marker/markerBlue5.png' : '/assets/marker/markerBlue5_inactive.png', draggable: location?.id == marker.title}"
            (mapDragend)='dragChanged($event, marker)'
            (mapClick)="mapMousedown($event, marker)"
            >
            <!-- [options]="{icon: location?.id == marker.title ? '/assets/marker/markerBlue5.png' : '/assets/marker/markerRed5.png', draggable: location?.id == marker.title}" -->
            </map-marker>
          <!-- (mapDragstart)='mapDragstart($event, marker)' -->
            <!-- gmpClickable="true"
            gmpDraggable="true"
            draggable="true" -->
            <!-- (dragend)="markerDrag($event)" -->
          <!-- draggable="true" -->
          <!-- (mapMousedown)="mapMousedown($event)" -->
          <!-- [label]="marker.label"
          [title]="marker.title" -->
          <!-- (mapClick)="mapMarkerTapped(marker)" -->
        </google-map>
        </div>

      </div>
    




    </div>

  </div>

  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <!-- <ect-color-btn class="mr5" title="Save and insert" icon="save" color="primary" [disabled]="!canSaveAndPushIntoTimeline" (clicked)="saveAndPushIntoTimeline()" ></ect-color-btn> -->
    <ect-color-btn *ngIf="hasChanges" class="mr5" title="save" icon="save" color="primary" [disabled]="!saveEnabled" (clicked)="save()" ></ect-color-btn>
  </mat-toolbar>
</div>