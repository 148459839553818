<mat-toolbar class="overlay-toolbar ">
  <ect-color-btn class="mr5" title="Overview" icon="dns" color="primary"  (clicked)="route('exercise-overview')" ></ect-color-btn>
  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="currentViewMode" (ngModelChange)="viewModeChanged($event)">
    <mat-button-toggle [value]="ADMIN_VIEW_MODES.VIEW_INJECTS">Injects</mat-button-toggle>
    <mat-button-toggle [value]="ADMIN_VIEW_MODES.VIEW_TR_OBJ">TrainingObjectives</mat-button-toggle>
  </mat-button-toggle-group>
  <span class="toolbar-spacer"></span>
</mat-toolbar>
<div class="container-fluid p0" *ngIf="currentUser" cdkDropListGroup>
  <div class="d-flex flex-column ">
        
    <!-- COMPANIES -->
    <div class="p-2 bgMid ">
     <div class="d-flex">
       
      <div class="p-2 w50p d-flex flex-column">
        <div class="p-0" style="max-width: 450px;">
          <mat-label class='mat-caption'>Company:</mat-label>
         <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
           <mat-select [(ngModel)]='selectedCompany'  (ngModelChange)='onCompanyChanged($event)'  class="">
             <mat-option *ngFor="let comp of currentUser.companies" [value]="comp">
               {{comp.name}}
             </mat-option>
           </mat-select>
         </mat-form-field>
        </div>

        <div class="p-0 d-flex ">
          <div class="p-2 flex-fill" style="min-width: 250px;">
            <mat-label class='mat-caption uc'>Suche...</mat-label>
            <input type="text" class="formControl textfield100 textFieldH40" placeholder="Search..."
                    [(ngModel)]="searchTxt"  (ngModelChange)='onSearch($event)' >
          </div>
          <div class="p-2 ml-auto" style="max-width: 250px;" *ngIf="selectedCompany">
            <mat-label class='mat-caption'>INJECT SET:</mat-label>
            <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
              <mat-select [(ngModel)]='selectedInjectSet'  (ngModelChange)='onInjectSetChanged($event)'  class="">
                <mat-option  value="all">
                  
                </mat-option>
                <mat-option *ngFor="let comp of storage.injectSets" [value]="comp">
                  {{comp}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        
       </div>
       
       <!-- <div class="p-2 w33p">
       </div> -->
       

       <div class="p-2 w33p">
        <mat-label class='mat-caption'>Exercise:</mat-label>
         <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
           <mat-select [(ngModel)]='selectedE'  (ngModelChange)='onExerciseChanged($event)'  class="">
             <mat-option *ngFor="let exer of exercisesFlat" [value]="exer">
               {{exer.name}}
             </mat-option>
           </mat-select>
         </mat-form-field>
       </div>


     </div>
    </div> 
    <!-- ENDE COMPANIES -->
    
    <div class="p-2 d-flex pt0">

      <div class="p-2 w50p pt0">
        <!-- INJECT VIEW -->
        <div class="d-flex flex-column p-2" *ngIf="currentViewMode === ADMIN_VIEW_MODES.VIEW_INJECTS">
          <div class="p-2 d-flex p-0 ml-auto">
            <!-- <input type="file" #csvReader name="Upload CSV" id="txtFileUpload" (change)="uploadListener($event)" accept=".json" />  -->
            <ect-color-btn  [iconOnly]="true"  class="mr5" title="Import from json" icon="download" color="primary" [disabled]="disabled" (clicked)="importInject()" ></ect-color-btn>
            <ect-color-btn [iconOnly]="true" class="mr5" title="Create new inject" icon="add" color="primary" [disabled]="disabled" (clicked)="editInject(undefined)" ></ect-color-btn>
          </div>
      
          <div class="p-2 pt0 pb0 "  style="height: calc(100vh - 330px); overflow: hidden!important;">
            <table class="table table-dark table-hover p0">
              <thead >
                <tr>
                  <th scope="col" style="width: 180px;">INNJECT NO</th>
                  <th scope="col">TITLE</th>
                  <th scope="col" style="width: 180px; text-align: end;">DELIVERY METHOD</th>
                  <th scope="col" style="width: 44px; text-align: end;"> </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <div style="height: 100%; overflow-y: scroll; " cdkDropList [cdkDropListData]='storage.companyInjects'
                [cdkDropListEnterPredicate]="noReturnPredicate" >
              <div class="example-box d-flex" *ngFor="let item of companyInjectsFiltered"
                  (cdkDragMoved)="dragMoved($event)"
                  [cdkDragDisabled]="isInExerInjects(item)" cdkDrag>
                <div *cdkDragPreview class="p-2">{{item.inject_no}}</div>
                <div class="p-2" style="width: 180px;">{{item.inject_no}}</div>
                <div class="p-2">{{item.short_title}}</div>
                <div class="p-2 ml-auto" style="width: 180px;">{{item.deliveryMethod}}</div>
                <div class="p-2" style="width: 34px; text-align: end;"><mat-icon class="dxfx_icon_btn_mini" (click)="deleteInject(item)">delete</mat-icon></div>
                <div class="p-2" style="width: 34px; text-align: end;"><mat-icon class="dxfx_icon_btn_mini" (click)="editInject(item)">edit</mat-icon></div>
                <div class="p-2" style="width: 34px; text-align: end;">
                  <mat-icon class="dxfx_icon_btn_mini" *ngIf="!isInExerInjects(item)" (click)="onMoveInjectToExercise(item)">arrow_forward</mat-icon>
                </div>
                
              </div>
            </div>
          </div>

        </div>
        <!-- EMDE INJECT VIEW -->
  
        <!-- TRObj VIEW -->
        <div class="d-flex flex-column p-2" *ngIf="currentViewMode === ADMIN_VIEW_MODES.VIEW_TR_OBJ">
          <div class="p-2 d-flex ml-auto">
            <!-- <ect-color-btn class="mr5" title="Training Objective" icon="add" color="primary"  (clicked)="createNewTraining()" ></ect-color-btn> -->
            <ect-color-btn [iconOnly]="true" class="mr5" title="Training Objective" icon="add" color="primary"  (clicked)="editObjective(undefined)" ></ect-color-btn>
          </div>
  
          <div class="p-2 pt0 pb0 "  style="height: calc(100vh - 330px); overflow: hidden!important;">
            <table class="table table-dark table-hover p0">
              <thead >
                <tr>
                  <th scope="col" style="width: 80px;">id</th>
                  <th scope="col">TITLE</th>
                  <th scope="col" style="width: 44px; text-align: end;"> </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <div style="height: 100%; overflow-y: scroll; " cdkDropList [cdkDropListData]='storage.companyObjectives'
                [cdkDropListEnterPredicate]="noReturnPredicate" >
              <div class="example-box d-flex" *ngFor="let item of storage.companyObjectives"
                  (cdkDragMoved)="dragMoved($event)"
                  cdkDrag>
                  <!-- [cdkDragDisabled]="isInExerInjects(item)" -->
                <!-- <div *cdkDragPreview class="p-2">{{item.id}}</div> -->
                <div class="p-2" style="width: 80px;">{{item.id}}</div>
                <div class="p-2  flex-fill">{{item.title}}</div>
              
                <div class="p-2" style="width: 34px; text-align: end;"><mat-icon class="dxfx_icon_btn_mini" (click)="editObjective(item)">edit</mat-icon></div>
              </div>
            </div>
          </div>

        </div>
        <!-- ENDE TRObj VIEW -->
      </div>

      <!-- EXERCISES -->  
      <div class="p-2 w50p" >
        
        <div class="d-flex flex-column">
          <div class="p-2 ">
          
          </div> 
  
          <div class="p-2" *ngIf="currentViewMode === ADMIN_VIEW_MODES.VIEW_INJECTS" style="height: calc(100vh - 330px); overflow: hidden!important;">
            <div cdkDropList [cdkDropListData]='storage.exerciseInjectsAdmin' style="min-height: 250px; overflow-y: scroll;"
            class="example-list" (cdkDropListDropped)="drop($event)">
            <!-- <div class="example-box" style="cursor: auto;" *ngFor="let item of exerciseInjectsFiltered" cdkDrag> -->
              <div class="example-box" style="cursor: auto;" *ngFor="let item of storage.exerciseInjectsAdmin" cdkDrag>
                <div class="p-2 p0 pl10">
                  {{item.inject_no}}
                </div>
                <div class="p-2 p0 ml-auto " style="height: 20px!important; width: 20px!important;">
                  <mat-icon class='icon-btn p0' *ngIf="selectedE && !selectedE.isDeBoarded" (click)='deleteInjectFromExercise(item.id)' >delete_forever</mat-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="p-2" *ngIf="currentViewMode === ADMIN_VIEW_MODES.VIEW_TR_OBJ">
            <div cdkDropList [cdkDropListData]='storage.exerciseTrainingObjectsAdmin' style="min-height: 250px;"
            class="example-list" (cdkDropListDropped)="dropTraining($event)">
            <!-- <div class="example-box" style="cursor: auto;" *ngFor="let item of exerciseInjectsFiltered" cdkDrag> -->
              <div class="example-box" style="cursor: auto;" *ngFor="let item of storage.exerciseTrainingObjectsAdmin" cdkDrag>
                <div class="p-2 p0 pl10">
                  {{item.title}}
                </div>
                <div class="p-2 p0 ml-auto " style="height: 20px!important; width: 20px!important;">
                  <mat-icon class='icon-btn p0' (click)='deleteTrainingFromExercise(item.id)' >delete_forever</mat-icon>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- EMDE EXERCISES -->  
    </div>
 </div> 
</div>