import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ExerciseClass, ExerciseUserRole } from 'src/app/model/exercise.model';
import { UiService } from 'src/app/services/ui.service';
import { wait } from 'src/app/services/utils/functions';

@Component({
  selector: 'app-dashboard-user',
  template: `
       <div class="p-0 h40 mr3  d-flex tt-border  align-items-center  pr3 pl3" #headerWrap [class.dark]="ui.darkMode">
          <div class="p-0 h30   pt5 pl5 bgDark uc fs14 font-darker " style="width: 75px;" [class.bgWhite]="!ui.darkMode">EMAIL:</div>
          <div class="p-0  tt-box-sm tlc pl10 pr10" [matMenuTriggerFor]="menu" style="cursor: pointer; user-select: none;"  [class.dark]="ui.darkMode">{{exercise.exerciseUser?.email}}</div>
          <div class="p-0 h30 pt5 pr5 bgDark uc fs14 font-darker " [class.bgWhite]="!ui.darkMode"
            style="width: 20px; border-left: 1px solid rgb(15, 19, 20);">
            <mat-icon class='icon-btn' [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="signOutTapped.emit()">
                <mat-icon>power_settings_new</mat-icon>
                <span>Logout</span>
              </button>
              <button mat-menu-item (click)="routeToAdmin.emit()"
                *exerciseAccess='[ExerciseUserRoles.ADMIN]'>
                <mat-icon>admin_panel_settings</mat-icon>
                <span>Admin View</span>
              </button>
            </mat-menu>
          </div>
        </div>
  `,
  styles: [
  ]
})
export class DashboardUserComponent implements OnInit{
  ExerciseUserRoles = ExerciseUserRole;
  @ViewChild("headerWrap", { static: false }) headerWrap: ElementRef | undefined
  @Input() exercise!: ExerciseClass
  @Output() routeToAdmin = new EventEmitter();
  @Output() signOutTapped = new EventEmitter();
public ui = inject(UiService)
  clientWidth: number = 300
  ngOnInit(): void {
      wait(1000, () => {
        this.clientWidth = this.headerWrap?.nativeElement.clientWidth
      })
  }
}
