import { Component, inject, Input } from '@angular/core';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-dashboard-time',
  template: `
    <div class="p-0 h40 mr3 d-flex tt-border align-items-center pl3 pr3" [class.dark]="ui.darkMode">
            <div class="p-0 h30 pt5 pl5 bgDark uc fs14 font-darker " [ngStyle]="{'width': width +'px'}" [class.bgWhite]="!ui.darkMode">
              {{title}}
            </div>
            <div class="p-0 w30 tt-box-sm tlc" [class.dark]="ui.darkMode">{{times[0]}}</div>
            <div class="p-0 w30 tt-box-sm tlc" [class.dark]="ui.darkMode">{{times[1]}}</div>
            <div class="p-0 w30 tt-box-sm tlc" [class.dark]="ui.darkMode">{{times[2]}}</div>
            <div class="p-0 w40 tt-box-sm tlc" [class.dark]="ui.darkMode">{{times[3]}}</div>
            <div class="p-0 w30 tt-box-sm tlc" [class.dark]="ui.darkMode">{{times[4]}}</div>
            <!-- <div class="p-0 w30 tt-box-sm tlc">{{currentTimeArray[5]}}</div> -->
          </div>
  `,
  styles: [
  ]
})
export class DashboardTimeComponent {
  @Input() times!: string[]
  @Input() title!: string
  @Input() width!: number;
  public ui = inject(UiService);
}
