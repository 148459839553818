import { ScrollStrategy, ScrollStrategyOptions } from "@angular/cdk/overlay";
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { addMinutes } from "date-fns";
import { Subscription } from "rxjs";
import {
  ExerciseClass,
  ExerciseUserRole,
  IExerciseTimelineInjectRef,
  IExerciseTimelineItem,
} from "src/app/model/exercise.model";
import { TimlineTimeChangeOverlayComponent } from "src/app/overlays/timline-time-change-overlay/timline-time-change-overlay.component";
import { ApiService } from "src/app/services/api.service";
import { TIME } from "src/app/services/helpers";
import { UiService } from "src/app/services/ui.service";
import { StorageService } from "../../services/storage.service";
import { InjectItemUpdateDto } from "src/app/model/dtos";

@Component({
  selector: "timeline-inject-item",
  templateUrl: "./timeline-inject-item.component.html",
  styleUrls: ["./timeline-inject-item.component.scss"],
})
export class TimelineInjectItemComponent implements OnInit, OnDestroy {
  @Input() inject!: IExerciseTimelineInjectRef;
  @Input() exercise!: ExerciseClass;
  @Input() roleItem!: IExerciseTimelineItem;
  animatedInjectId = 0;
  scrollStrategy!: ScrollStrategy;
  @ViewChild("_drag") _drag!: ElementRef;
  // @ViewChild('menu') menu!: HTMLElement
  fadedIn = false;
  isAnimated = false;
  isDragging = false;
  dragDisabled = true;
  dragStartetX = 0;
  animationSub: Subscription | undefined;
  clickCount = 0;
  startDateTime = "";
  downInterval: any;
  downTimeout: any;
  aniIn = false;
  ExerciseUserRoles = ExerciseUserRole;
  constructor(
    public ui: UiService,
    public api: ApiService,
    public storage: StorageService,
    private dialog: MatDialog,
    private readonly scrollStrategyOptions: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {
    this.scrollStrategy = this.scrollStrategyOptions.reposition();
    // console.log(`ngOnInit ${this.inject.id}`);
    // console.log(`ngOnInit ${this.inject.animated}`);
    const randomTime = Math.round(Math.random() * (400 - 150) + 150);
    // console.log(`randomTime: ${randomTime}`);

    if (!this.inject.animated) {
      setTimeout(() => {
        setTimeout(() => {
          this.fadedIn = true;
        }, randomTime);
        setTimeout(() => {
          this.aniIn = true;
          this.inject.fadedIn = true;
        }, 500);
      }, 350);
    } else {
      setTimeout(() => {
        this.fadedIn = true;
      }, randomTime);
    }
    this.animationSub = this.ui.injectAnimation.subscribe(
      (inject: IExerciseTimelineInjectRef) => {
        if (inject.id == this.inject.id) {
          this.startAnimation();
        } else {
          if (this.isAnimated) {
            this.isAnimated = false;
          }
        }
      }
    );
    // setTimeout(() => {
    //   console.log(this._drag.nativeElement.attributes);

    // }, 1600)
  }
  // onTapped() {
  //   // this.clickCount ++

  // }
  // onMouseDown(e: any) {
  //   this.onMDStart()
  //   e.preventDefault();
  //   return
  // }
  // onMouseMove(e: any) {
  //   e.preventDefault();
  //   console.log('onMouseMove');
  // }

  get tooltip(): string {
    return `${this.inject.inject_no} \n${this.inject.short_title}`
  }
  private startTime() {
    // clearTimeout(this.downTimeout)
    this.downTimeout = setTimeout(() => {
      if (this.isDragging) {
        this.startTime();
        this.dragDisabled = false;
      } else {
        this.dragDisabled = true;
        console.log("onDoubleTapped TIMEOUT");
      }
    }, 3000);
  }
  onDoubleTapped() {
    console.log(new Date().getTimezoneOffset() * 60000);

    this.dragDisabled = false;
    let pos2: number = Number(this.inject.leftPosSmall);

    const newDeliveryDateTime: number = Math.round((pos2 / 61) * 60);
    if (this.storage.currentExer) {
      this.startDateTime = TIME.formatedTimeString(
        addMinutes(
          this.storage.currentExer.getDateTimeRaw(),
          newDeliveryDateTime
        )
      );
    }
    this.startTime();
  }

  get dragEnabled(): boolean {
    return this.clickCount > 4;
  }
  async changeTimeAndDuration(inj: IExerciseTimelineInjectRef, e: any) {
    this.dialog.closeAll();

    this.dialog
      .open(TimlineTimeChangeOverlayComponent, {
        width: "350px",
        height: "210px",

        backdropClass: "backdrop-2",
        // position: { top:  `${e.pageY + 60}px`, },
        // left: `${e.pageX}px` ,
        data: inj,
      })
      .afterClosed()
      .subscribe((_data?: IExerciseTimelineInjectRef) => {
        if (_data) {
          const dto: InjectItemUpdateDto = {
            exerciseId: this.exercise.id,
            injectItemId: _data.id,
            // timelineItemId: inj.id,
            stateId: _data.state.id,
            deliveryDateTime: inj.deliveryDateTime,
            deliveryDurationInMinutes: _data.deliveryDurationInMinutes,
          };
          console.log(dto);
          this.storage.socketInjectUodate(dto);
        }
      });
  }

  dragStartet(e: any, inject: IExerciseTimelineInjectRef) {
    console.log("\n START");
    this.isDragging = true;
    this.dragStartetX = e.event.x;
    console.log(
      `\n this.dragStartetWidth ${inject.leftPos} : ${inject.leftPosSmall}`
    );
  }
  dragend(e: any, inject: IExerciseTimelineInjectRef) {
    // const pos = inject.leftPos + e.distance.x
    // let pos2:number = Number(inject.leftPosSmall)
    // let posBig:number = Number(inject.leftPos)
    let pos2: number = Number(
      this.ui.smallTimeLineItems ? inject.leftPosSmall : inject.leftPos
    );

    console.log(
      `e.distance.x: ${e.distance.x} inject.leftPosSmall: ${inject.leftPosSmall}`
    );

    this.isDragging = false;

    if (e.distance.x > 0) {
      const fact = Math.abs(e.distance.x);
      console.log(`e.distance.x > 0 fact: ${fact}`);
      pos2 = pos2 + fact;
      // posBig = posBig + (fact * 121 ) / 60
      // posBig = posBig + (fact * 241 ) / 60
    } else {
      const fact = Math.abs(e.distance.x);
      console.log(`e.distance.x > 0 fact: ${fact}`);
      pos2 = pos2 - fact;
      // posBig = posBig - (fact * 121 ) / 60
      // posBig = posBig - (fact * 241 ) / 60
    }
    inject.leftPosSmall = pos2 < 0 ? 0 : pos2;
    // console.log(`posBig: ${posBig}`);

    // const newDeliveryDateTime: number = Math.round((pos2 / 61) * 60)
    const posScaleFact = this.ui.smallTimeLineItems ? 61 : 121;
    const newDeliveryDateTime: number = Math.round((pos2 / posScaleFact) * 60);
    console.log(`newDeliveryDateTime: ${newDeliveryDateTime}`);
    inject.deliveryDateTime = newDeliveryDateTime;

    const dto = {
      exerciseId: this.exercise.id,
      injectItemId: inject.id,
      timelineItemId: inject.id,
      stateId: inject.state.id,
      deliveryDateTime: newDeliveryDateTime,
      deliveryDurationInMinutes: inject.deliveryDurationInMinutes,
    };
    console.log(dto);
    this.storage.socketInjectUodate(dto);
    // console.log(`\n pos2: ${pos2} leftPosSmall: ${inject.leftPosSmall} leftPos: ${inject.leftPos} delDt: ${inject.deliveryDateTime}`);
  }
  aniStart() {
    console.log("SsS");
  }
  onDragMoved(e: any, inject: IExerciseTimelineInjectRef) {
    // console.log(e);
    // clearTimeout(this.downTimeout)
    // this.dragDisabled = false
    let pos2: number = Number(
      this.ui.smallTimeLineItems ? inject.leftPosSmall : inject.leftPos
    );
    this.isDragging = true;
    if (e.distance.x > 0) {
      const fact = Math.abs(e.distance.x);
      console.log(`e.distance.x > 0 fact: ${fact}`);
      pos2 = pos2 + fact;
      // posBig = posBig + (fact * 241 ) / 60
    } else {
      const fact = Math.abs(e.distance.x);
      console.log(`e.distance.x > 0 fact: ${fact}`);
      pos2 = pos2 - fact;
      // posBig = posBig - (fact * 241 ) / 60
    }
    // inject.leftPosSmall = pos2 < 0 ? 0 : pos2
    // console.log(`posBig: ${posBig}`);

    const posScaleFact = this.ui.smallTimeLineItems ? 61 : 121;
    const newDeliveryDateTime: number = Math.round((pos2 / posScaleFact) * 60);
    if (this.storage.currentExer) {
      this.startDateTime = TIME.formatedTimeString(
        addMinutes(
          this.storage.currentExer.getDateTimeRaw(),
          newDeliveryDateTime
        )
      );
    }
    // if(this.dragStartetX != e.distance.x) {

    //   this.dragStartetX = e.distance.x
    //   inject.deliveryDurationInMinutes
    // }
    // const r = Number(e.event.clientX) - Number(this.dragStartetX)
    // const nw = Number(Number(this.dragStartetWidth) + Number(r))
    // console.log(`this.dragStartetX ${this.dragStartetX} e ${e.event.clientX} r : ${r} nw: ${nw}`);
    // inject.deliveryDurationInMinutes = nw
    // let itemX = inject.deliveryDateTime
    // itemX += (itemX * e.distance.x)
    // console.log(`inject.mins: ${inject.deliveryDurationInMinutes} delta: ${e.distance.x} itemX: ${e.event.x - this.dragStartetX}`);

    //inject.deliveryDurationInMinutes = e.event.x - this.dragStartetX//inject.deliveryDurationInMinutes + (e.distance.x)
  }
  getTopPosByIndex(inject: IExerciseTimelineInjectRef): string {
    // return(28 + (teamIndex * 60)).toString() +'px';
    // return(14 + (teamIndex * 60)).toString() +'px';
    let start = 3; //+ (teamIndex * 60);
    let height: number = 0;
    for (var i = 0; i < this.exercise.timelineItems.length; i++) {
      if (
        this.roleItem.topIndex >= this.exercise.timelineItems[i].topIndex &&
        this.roleItem.id != this.exercise.timelineItems[i].id
      ) {
        height += 60 + this.exercise.timelineItems[i].height * 60;
      }
    }
    let top = inject.topPos ? inject.topPos : 0;
    start += height;
    start += top * 59 + 5;
    return start.toString() + "px";

    // let start = 3//+ (teamIndex * 60);
    // let height: number = 0
    // for(var i = 0; i < this.exercise.timelineItems.length; i ++) {
    //   if (this.roleItem.topIndex >= this.exercise.timelineItems[i].topIndex && this.roleItem.id != this.exercise.timelineItems[i].id) {
    //     height += 60 +(this.exercise.timelineItems[i].height * 60)
    //   }
    // }
    // let top = inject.topPos ? inject.topPos : 0
    // start += height
    // start += (top  * 59) + 3
    // return(start).toString() +'px';
  }
  getTextColorForInject(inject: IExerciseTimelineInjectRef): string {
    return "#1E2A33";
    // if(inject.state.keyName === 'REQUESTED'){
    //   return "#1E2A33";
    // }
    // else {
    //   return  "#ffffff"
    // }
  }

  get left() {
    return this.aniIn || this.inject.fadedIn
      ? this.ui.smallTimeLineItems
        ? this.inject.leftPosSmall
        : this.inject.leftPos
      : this.inject.prevLeftPos
      ? this.inject.prevLeftPos
      : 0;
  }
  get styles(): any {
    return {
      transform:
        "translate(" +
        this.left +
        "px, " +
        this.getTopPosByIndex(this.inject) +
        ")",
      color: this.getTextColorForInject(this.inject),
      width:
        (this.ui.smallTimeLineItems
          ? this.inject.deliveryDurationInMinutes
          : this.inject.deliveryDurationInMinutes * 2) + "px",
      transition: "all 0.35s cubic-bezier(0, 0, 0.2, 1)",
      "transition-delay": "0s",
    };
    // return {'transform': 'translate(' + (this.ui.smallTimeLineItems ? this.inject.leftPosSmall : this.inject.leftPos)  + 'px, ' + this.getTopPosByIndex(this.inject) + ')','background-color': this.inject.state.color, 'color':this.getTextColorForInject(this.inject), 'width': (  this.ui.smallTimeLineItems ? (this.inject.deliveryDurationInMinutes ) : (this.inject.deliveryDurationInMinutes * 4)) +'px', 'opacity': (this.isAnimated ? 0.5 : 1)}
  }
  private startAnimation() {
    this.isAnimated = true;
    setTimeout(() => {
      this.isAnimated = false;
    }, 5000);
  }
  async openDetail() {
    if (this.inject) {
      this.ui.openInjectDetailOverlay(this.inject);
    }
  }
  // async openDetailTest(inject:IExerciseTimelineInjectRef){
  //   console.log(this.roleItem);

  //   let start = 5//+ (teamIndex * 60);
  //   let height: number = 0
  //   for(var i = 0; i < this.exercise.timelineItems.length; i ++) {
  //     if (this.roleItem.topIndex >= this.exercise.timelineItems[i].topIndex && this.roleItem.id != this.exercise.timelineItems[i].id) {
  //       height += 55 + (this.exercise.timelineItems[i].height * 5)
  //     }
  //   }
  //   console.log(height)
  //   let top = inject.topPos ? inject.topPos : 0
  //   console.log(`top ${top}`)
  //   start += height
  //   start += (top  * 55) +5
  //   console.log((start).toString() +'px')
  // }
  ngOnDestroy(): void {
    if (this.animationSub) {
      this.animationSub.unsubscribe();
      this.animationSub = undefined;
    }
  }
}
