import { DynamicItem } from "src/app/model/dynamic.types";

export interface INavigationListItem {
  id: number;
  title: string;
  typeValue: string;
  icon?: string;
  dynComponent: DynamicItem
  dynItemType?: DynamicItemDataType
}

export enum DynamicItemDataType {
  DESCRIPTION = 'DESCRIPTION',
  EXPECTED_OUTCOME = 'EXPECTED_OUTCOME',
  TRAINER_FEEDBACK = 'TRAINER_FEEDBACK',
  OBJECTIVES = 'OBJECTIVES',
  ON_SCENE_PREPARATION = 'ON_SCENE_PREPARATION',
  RELATED_DOCUMENTS = 'RELATED_DOCUMENTS',
  TRAINERS_ACTION_REQUIRED = 'TRAINERS_ACTION_REQUIRED',
  ROLE_PLAY_INSTRUCTIONS = 'ROLE_PLAY_INSTRUCTIONS',
  ADDITIONAL_GUIDANCE_CAUTION_OPTIONS = 'ADDITIONAL_GUIDANCE_CAUTION_OPTIONS',
}
