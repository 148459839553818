export interface iCompanyTrainingObjective  {
  id: number;
  companyId: string;
  title: string;
}
export class CompanyTrainingObjective {
  item: iCompanyTrainingObjective
  constructor(iten: iCompanyTrainingObjective) {
    this.item = iten
  }
}

export interface iExerciseTrainingObjective  {
  id: number;
  exerciseId: string;
  companyId: string;
  title: string;
}
export class ExerciseTrainingObjective {
  item: iExerciseTrainingObjective
  constructor(iten: iExerciseTrainingObjective) {
    this.item = iten
  }
}