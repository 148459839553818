<mat-toolbar style="height: 36px; border-radius: 0px; background-color: rgba(0,0,0,0.5)!important;" class="pl0">
  <span class="toolbar-spacer"></span>
  <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="d-flex">
  <div class="p-2" >
    <input type="file" #csvReader name="Upload CSV" id="txtFileUpload" (change)="uploadListener($event)" accept=".json" />    
  </div>
  <div class="p-2" >
    <button *ngIf="isRecordComplete" mat-flat-button color="accent" (click)="importIntoDataBase()">Übernehmen</button>
  </div>
</div>
<table mat-table [dataSource]="injectsDataSource" matSort style="width: 100%; border-radius: 0px; " >      
  <ng-container matColumnDef="inject_no">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 150px;"><span class='pl10'>INNJECT NO</span></th>
    <td mat-cell *matCellDef="let element" style="width: 150px;"><span class='pl10'>{{element.inject_no}}</span></td>
  </ng-container>

  <ng-container matColumnDef="name" >
    <th mat-header-cell *matHeaderCellDef >NAME</th>
    <td mat-cell *matCellDef="let element">{{element.short_title}}</td>
  </ng-container>

  <!-- <ng-container matColumnDef="OBJECT TYPE" justify='end' >
    <th mat-header-cell *matHeaderCellDef style="width: 150px;">OBJECT TYPE</th>
    <td mat-cell *matCellDef="let element">{{element.objectType}}</td>
  </ng-container> -->

  <ng-container matColumnDef="DELIVERY METHOD" justify='end'>
    <th mat-header-cell *matHeaderCellDef style="width: 150px;">DELIVERY METHOD</th>
    <td mat-cell *matCellDef="let element">{{element.deliveryMethod}}</td>
  </ng-container>

  <tr mat-header-row  *matHeaderRowDef="injectDisplayedColumns" ></tr>
  <tr mat-row class="element-row"  [class.warn]="isIncluded(row)"
  *matRowDef="let row; columns: injectDisplayedColumns;" ></tr>
  
</table>

