import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import gsap from 'gsap';
import { IExerciseTimelineItem } from 'src/app/model/exercise.model';
import { StorageService } from 'src/app/services/storage.service';
import { wait } from 'src/app/services/utils/functions';
import { IExerciseTimelineInjectRef } from '../../model/exercise.model';
import { TrainerFeedbackVoteType } from 'src/app/model/trainerFeedback.model';
interface votItem {
  voteType: TrainerFeedbackVoteType
  width: number
  color: string
  count: number
}
@Component({
  selector: 'teamperformance-progress-bar',
  templateUrl: './teamperformance-progress-bar.component.html',
  styleUrls: ['./teamperformance-progress-bar.component.scss']
})
export class TeamperformanceProgressBarComponent  implements OnInit {
  @ViewChild('_holder') _holder!:ElementRef
  @ViewChild('_barHolder') _barHolder!:ElementRef
  
  @Input() injects!: IExerciseTimelineInjectRef[]
  // @Input()  timelineItem!: IExerciseTimelineItem
  private storage = inject(StorageService)

  // injects:IExerciseTimelineInjectRef[] = []
  votItems:votItem[] = [
    { voteType: TrainerFeedbackVoteType.BELOW, color: '#03B0FD', width: 0, count: 0 },
    { voteType: TrainerFeedbackVoteType.MET, color: '#00FFFF', width: 0, count: 0 },
    { voteType: TrainerFeedbackVoteType.ABOVE, color: '#3EFFD2', width: 0, count: 0 },
    { voteType: TrainerFeedbackVoteType.NOT_RATED, color: '#323232', width: 0, count: 0 },
  ]
 
  showWarn = false
  color = '#323454'
  TrainerFeedbackVoteTypes = TrainerFeedbackVoteType 



  ngOnInit(): void {
    
    // wait(200, () => {
    //   // console.log(this._holder.nativeElement.clientWidth);
    //   this.injects = this.storage.assigendExerciseInjects.filter(item => item.exerciseTimelineId == this.timelineItem.id)
    //   // console.log(this.injects);
    // })
    wait(500, () => {
      // console.log(this._barHolder.nativeElement.clientWidth);
      const www = (this._barHolder.nativeElement.clientWidth) - (4 * 20)
      const fact = www / this.injects.length
      console.log(`FACT: ${fact} count: ${this.injects.length}`);
      
      this.votItems.forEach((e) => {
        const t = this.injects.filter(o => o.trainerFeedBack?.vote == e.voteType)
        // console.log(`(fact * t.length): ${(fact * t.length)}`);
        const w = 20 + (fact * t.length)
        // const w = 40 + (www / 4)
        // console.log(`w: ${w}`);
        e.width =  w//(www / 4)
        e.count = t.length
      })
    })
  //   const newWidth = 420 * this.percent
  //   const fadeDelay = this.getRandomTime()
    
  //  setTimeout(()=>{
  // /*   this.showWarn = newWidth <=120; */
  //   gsap.fromTo(this._progressBar.nativeElement, { 'width':0, opacity:1},
  //     {duration: 0.35,  'width':newWidth , opacity:1, ease: 'Expo.easeOut', });
      
  //   },fadeDelay)
  }
  private getRandomTime(){
    let min:number = 300;
    let max:number = 1000;
    return Math.random() * (max - min) + min; 
  }

}
