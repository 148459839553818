import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { INavigationToggleBtn } from './navigation-toggle-button.model';
import { wait } from 'src/app/services/utils/functions';

@Component({
  selector: 'navigation-toggle-button',
  templateUrl: './navigation-toggle-button.component.html',
  styleUrls: ['./navigation-toggle-button.component.scss']
})
export class NavigationToggleButtonComponent implements OnInit {
  @ViewChild('holder') holder: ElementRef | undefined
  @Input() model!: INavigationToggleBtn;
  @Input() active!: boolean;
  
  @Output() selected = new EventEmitter<INavigationToggleBtn>();

  ngOnInit(): void {
      wait(250, () => {
        if(this.holder) {
          this.model.offsetLeft = (this.holder.nativeElement.offsetLeft + (this.holder.nativeElement.clientWidth / 2)) - 11
        }
      })
  }
  onTapped() {
    this.selected.emit(this.model);
  }
}
