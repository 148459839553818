import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import * as _ from 'lodash';
import { UserGroups_VIEW_MODE } from 'src/app/model/enums';
import { Subscription } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { ScreenSize } from 'src/app/model/screenSize.model';

@Component({
  selector: 'app-fullscreen-overlay',
  templateUrl: './fullscreen-overlay.component.html',
  styleUrls: ['./fullscreen-overlay.component.scss']
})
export class FullscreenOverlayComponent implements OnInit,OnDestroy {


  resizeSubscription: Subscription | undefined

  exerciseId: string | undefined
  screenSize!: ScreenSize
 
  viewModes = UserGroups_VIEW_MODE
  currentMode = UserGroups_VIEW_MODE.TIMELINE_LIST
  closeWithReload = false;

  constructor(
    public dialogRef: MatDialogRef<FullscreenOverlayComponent>
    ,@Inject(MAT_DIALOG_DATA) public data: string,
    public ui: UiService,
    public api: ApiService) {
      this.exerciseId = data;
      this.screenSize = this.ui.currentScreenSize
      this.closeWithReload = false
  }
  ngOnInit(): void {
    this.resizeSubscription = this.ui.windowResize.subscribe((size: ScreenSize) => {
      this.screenSize = size
    })

  }
  

  onCloseWithReload() {
    console.log('onCloseWithReload');
    this.closeWithReload = true
  }

  close() {
    // console.log(this.timelines);
    if (this.closeWithReload === true) {
      this.dialogRef.close('reload')
    } else {
      this.dialogRef.close()
    }
    
  }


  ngOnDestroy(): void {
      if(this.resizeSubscription) {
        this.resizeSubscription.unsubscribe()
      }
  }
}
