
<div class="container-fluid p0" *ngIf='inject != null'>
  <mat-card style="min-height: 200px;" class="pt0">
    <div class="p-2 d-flex">
      <!-- <ect-color-btn class="mr5" title="Import from json" icon="dns" color="primary" [disabled]="disabled" (clicked)="action()" ></ect-color-btn> -->
      <ect-color-btn class="mr5" title="save" icon="save" color="primary" [disabled]="disabled" (clicked)="saveItem()" ></ect-color-btn>
      <div class="p-2 ml-auto">
        <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
      </div>
    </div>
  <!-- <div class="d-flex p0 pt5">
    <div class="p-2 ml-auto">
      <div class="uiToggleBtn uc" (click)='backToInjectOverView()' *ngIf='!isSaving'
        [class.uiToggleBtnUnActive]='isMainInfos'
        [class.uiToggleBtnActive]='!isMainInfos'>Back
      </div>
    </div>
    <div class="p-2 " >
      <div class="uiToggleBtn uc" (click)='save()' *ngIf='!isSaving'
        [class.uiToggleBtnUnActive]='isMainInfos'
        [class.uiToggleBtnActive]='!isMainInfos'>Save
      </div>
      <div style="width: 30px;" *ngIf='isSaving' class="">
        <mat-spinner [diameter]='25' color='accent' ></mat-spinner>
      </div>
    </div>
  </div> -->

<!--   <div class="d-flex flex-wrap pb0" >
    <div class="p-2" style="max-width: 205px!important;">
      <mat-label class='mat-caption uc'>objectType</mat-label>
      <mat-form-field appearance="outline" class="textfield100 select-form-field " >
        <mat-select [(value)]="inject.objectType" (selectionChange)='objectTypeChanged($event)' [disabled]='isSaving'>
          <mat-option *ngFor="let objectType of objectTypes" [value]="objectType" >
              {{objectType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="p-2 flex-fill " >
      <mat-label class='mat-caption uc'>deliveryMethod</mat-label>
      <mat-form-field appearance="outline" class="textfield100 select-form-field" >
        <mat-select [(value)]='inject.deliveryMethod'  (selectionChange)='deliveryMethodChanged($event)' [disabled]='isSaving'>
          <mat-option *ngFor="let method of injectMethods" [value]="method">
            {{method}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="p-2 flex-fill" >
      <mat-label class='mat-caption uc'>State</mat-label>
      <mat-form-field appearance="outline" class="textfield100 select-form-field" color='accent'>
        <mat-select [(value)]='inject.state.value' (selectionChange)='stateChanged($event)' [disabled]='isSaving'  >
          <mat-option *ngFor="let state of injectStates" [value]="state.value">
            <span class="uc">{{state.value}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div> -->

  <div class="d-flex p0 ">
    <div class="p-2">
      <div class="formGroup textfield100">
        <mat-label class='mat-caption uc'>Inject No.*</mat-label>
        <input type="text"  [class.formControl] = '!injectNoHasError' [class.formControlError]='injectNoHasError'
        class=" textfield100 textFieldH40"  required [disabled]='isSaving' (keydown)='injectNoKeyDown($event)'
        placeholder="inject_no" style="text-transform: uppercase;"
        [(ngModel)]="inject.inject_no" >
      </div>
      <mat-error class='mat-caption uc error' *ngIf="injectNoHasError">
        {{errorCopy}}
      </mat-error>
    </div>

    <div class="p-2 flex-fill" style="min-width: 250px;">
      <mat-label class='mat-caption uc'>Short title</mat-label>
      <input type="text" class="formControl textfield100 textFieldH40" placeholder="Short title"
              [(ngModel)]="inject.short_title" >
    </div>
  </div>

<!--    <div class="d-flex p0 ">
      <div class="p-2 flex-fill" style="min-width: 250px;">
        <mat-label class='mat-caption uc'>Related documents</mat-label>
        <input type="text" class="formControl textfield100 textFieldH40" placeholder="GOOGLE DRIVE URL"
               [(ngModel)]="inject.related_documents" >
      </div>
    </div>-->

    <div class="d-flex flex-wrap pt0" *ngIf='isMainInfos'>
      <div class="p-2 flex-fill" style="width: 50%;">
        <mat-label class='mat-caption uc'>Related documents</mat-label>
        <div class="formGroup textfield100">
          <textarea class="formControl" [(ngModel)]="inject.related_documents" rows="5" cols="4" [disabled]='isSaving'></textarea>
        </div>
      </div>
      <div class="p-2 flex-fill" style="width: 50%;">
        <mat-label class='mat-caption uc'>additional_guidance_caution_options</mat-label>
        <div class="formGroup textfield100">
          <textarea class="formControl" [(ngModel)]="inject.additional_guidance_caution_options" rows="5" cols="4" [disabled]='isSaving'></textarea>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap pt0" *ngIf='isMainInfos'>
      <div class="p-2 flex-fill" style="width: 50%;">
        <mat-label class='mat-caption uc'>expected_outcomes</mat-label>
        <div class="formGroup textfield100">
          <textarea class="formControl" [(ngModel)]="inject.expected_outcomes" rows="5" cols="4" [disabled]='isSaving'></textarea>
        </div>
      </div>
      <div class="p-2 flex-fill" style="width: 50%;">
        <mat-label class='mat-caption uc'>on_scene_preparation</mat-label>
        <div class="formGroup textfield100">
          <textarea class="formControl" [(ngModel)]="inject.on_scene_preparation" rows="5" cols="4" [disabled]='isSaving'></textarea>
        </div>
      </div>
    </div>

       <div class="d-flex flex-wrap pt0" *ngIf='isMainInfos'>
        <div class="p-2 flex-fill" style="width: 50%;">
          <mat-label class='mat-caption uc'>Description</mat-label>
          <div class="formGroup textfield100">
            <textarea class="formControl" [(ngModel)]="inject.description" rows="5" cols="4" [disabled]='isSaving'></textarea>
          </div>
        </div>
        <div class="p-2 flex-fill" style="width: 50%;">
          <mat-label class='mat-caption uc'>Related equipment:</mat-label>
            <div class="formGroup textfield100">
            <textarea class="formControl" [(ngModel)]="inject.related_equipment" rows="5" cols="4" [disabled]='isSaving'></textarea>
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap pt0" *ngIf='isMainInfos'>
        <div class="p-2 flex-fill" style="width: 50%;">
          <mat-label class='mat-caption uc'>role_players_instruction</mat-label>
          <div class="formGroup textfield100">
            <textarea class="formControl" [(ngModel)]="inject.role_players_instruction" rows="5" cols="4" [disabled]='isSaving'></textarea>
          </div>
        </div>
        <div class="p-2 flex-fill" style="width: 50%;">
          <mat-label class='mat-caption uc'>Trainers action requirement:</mat-label>
            <div class="formGroup textfield100">
            <textarea class="formControl" [(ngModel)]="inject.trainers_action_requirement" rows="5" cols="4" [disabled]='isSaving'></textarea>
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap pb20" *ngIf='isMainInfos'>
        <!-- <div class="p-2 flex-fill" style="min-width: 250px;">
          <mat-label class='mat-caption uc'>Related documents</mat-label>
          <input type="text" class="formControl textfield100" placeholder="GOOGLE DRIVE URL"
                  [(ngModel)]="newInject.related_documents" >
        </div> -->
      <!--   <div class="p-2 flex-fill" style="min-width: 250px;">
          <mat-label class='mat-caption uc'>role_players_instruction</mat-label>
          <div class="formGroup textfield100">
            <textarea class="formControl" [(ngModel)]="newInject.role_players_instruction" rows="5 " ></textarea>
          </div>
        </div> -->
        <!-- <div class="p-2 flex-fill" style="min-width: 250px;">
          <mat-label class='mat-caption uc'>Trainers action requirement:</mat-label>
          <div class="formGroup textfield100">
            <textarea class="formControl" [(ngModel)]="newInject.trainers_action_requirement" rows="5 " ></textarea>
          </div>
        </div> -->
      </div>
    </mat-card>
    </div>
