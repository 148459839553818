
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IExerciseTimelineInjectRef, INJECT_DELIVERY_METHOD_LIST_ITEM } from "src/app/model/exercise.model";
import { Location } from "src/app/model/location.model";
import { InjectDeliveryMethods, selectedInjectDeliveryMethodItem } from "src/app/services/utils/companyIn.help";

@Component({
  selector: 'inject-detail-ol-locations',
  template: `

         <div class="d-flex flex-column p0">
          <div class="p-0  uc itemTitle fs12 m0 mb3">location</div>
            
          <div class="p-0" >
            <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
            <mat-select [(value)]='injectLocation'  (selectionChange)='locationChanged($event)' [disabled]='!canChange' class="font-main">
              <mat-option *ngFor="let location of locations" [value]="location">
              {{location.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
        </div>
  `,
    styleUrls: ['../inject-detail-overlay.component.scss']
})
export class InjectDetailOL_LocationsComponent implements OnInit {
  @Input() locations!: Location[]
  @Input() injectLocation: Location | undefined
  @Input() canChange:boolean = false
  @Output() selected = new EventEmitter<Location>();
  
  canEditDeliveryMethod = true
  // isSaving = false
  // disabled = false
  

  
  ngOnInit(): void {
    
  }
  locationChanged(e:any) {
    this.selected.emit(e.value);
  }
  
}