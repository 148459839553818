
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="bgDarg pt5" style="height: calc(100% - 93px);">

  <div class="d-flex flex-column h100">
    <div class="p-0" style="height:calc(100% - 164px); overflow: hidden;" >
      <!-- *ngIf="logDataSource" -->

      <div class="scroller" style="height: 100%; overflow-y:scroll;">

        <table mat-table [dataSource]="logDataSource" matSort style="width: 100%; border-radius: 0px; " >      
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w180p"><span class='pl10'>DATE-TIME</span></th>
            <td mat-cell *matCellDef="let element" class="w180p">
              <!-- <span class='pl10'>{{element.createdAt | date:'short':'UTC +0'}}</span> -->
              <span class='pl10'>{{element.createdAt | date: 'MM/dd/yyyy, HH:mm'}}</span>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="logType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w180p"><span class='pl10'>TYPE</span></th>
            <td mat-cell *matCellDef="let element" class="w180p">
              {{element.logType}}
          </ng-container>
          
          <ng-container matColumnDef="assignedInjectName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header  class="w250"><span class='pl10'>INNJECT</span></th>
            <td mat-cell *matCellDef="let element"  class="w250">
              <span class='pl10' *ngIf="element.assignedInjectName">
              {{element.assignedInjectName}}
            </span>
          </td>
          </ng-container>

          <ng-container matColumnDef="teamName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="w180p"><span class='pl10'>TEAM</span></th>
            <td mat-cell *matCellDef="let element"  class="w180p">
              <span class='pl10' *ngIf="element.teamName">{{element.teamName}}</span>
            </td>
          </ng-container>
          
      
          <ng-container matColumnDef="user_id" >
            <th mat-header-cell *matHeaderCellDef   class="w180p">USER</th>
            <td mat-cell *matCellDef="let element"  class="w180p">{{element.user}}</td>
          </ng-container>
      
          <ng-container matColumnDef="message" justify='end' >
            <th mat-header-cell *matHeaderCellDef >LOG</th>
            <td mat-cell *matCellDef="let element" 
             style="max-width: 540px;" >
             <!-- display: flex; height: 100%; align-items: center; -->
            <div class="stateIconBox mr5" *ngIf="element.state" [ngStyle]="{'border-color': element.state.color, 'color': element.state.color }">
              <div class="stateIconBoxTxt">
                {{element.state?.title}}
              </div>
            </div>
            <div class="truncate-overflowT">

              {{element.message}}
            </div>
          </td>
          </ng-container>
      
          
          <tr mat-header-row  *matHeaderRowDef="dispCols; sticky: true" ></tr>
          <tr mat-row  class="element-row pointer" [class.element-row-active]="selectedLogbookEntry?.id == row.id " *matRowDef="let row; columns: dispCols;" (click)='injectTapped(row)'  ></tr>
        </table>
      </div>
    </div>
    <div class="p-2  flex-fill ">
        
      <div class="d-flex flex-column p0">
        <div class="p-0 d-flex itemTitle fs12">

          <div class="p-2 uc">
            Add Comment {{commentFor}}
          </div>
          <div class="p-2  ml-auto  ">
            {{wordCount}} of 400
          </div>
        </div>
        <div class="p-2 flex-fill p0">
          <textarea class="formControl" [(ngModel)]="message" (mousedown)="clear()" (ngModelChange)="onChange($event)" maxlength="400"
          placeholder="Message" rows="4" cols="4" [disabled]='isSaving || inputDisabled'></textarea>
        </div>
        <!-- <div class="p-2">{{wordCount}} of 400</div> -->
       <!--  <div class="p-2">
          <div class="d-fllex">
            <div class="p-0 ml-auto" *ngIf="message != null && message != '' ">
              && !selectedLogbookEntry
              <div class="uiToggleBtn uc"  (click)='save()'>save</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

  </div>

</div>

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <ect-color-btn class="mr5" title="Save" icon="save" color="primary" [disabled]="message == null || message == ''" (clicked)="save()" ></ect-color-btn>
  <!-- <ect-color-btn class="mr5" title="Save" icon="save" color="primary" [disabled]="saveDisabled" (clicked)="save()" ></ect-color-btn> -->
</mat-toolbar>
